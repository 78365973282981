// UI component to export the active AOI

import { Stack, Button, Typography, Select, styled, FormControl, MenuItem, SelectChangeEvent } from "@mui/material";
import { ReactNode, useState } from "react";
import { theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import useStore from "../store";
import React from "react";
import { TAoiExportFormat } from "./AoiInterfaces";
import { ExportAoi } from "./AoiOps";
import MyCircularProgress from "../Components/CircularProgress";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiExportProps
{
}

//-------------------------------------------------------------------------------
// AOI export component
//-------------------------------------------------------------------------------
const AoiExport = (props: AoiExportProps) => 
{
  // Get needed state data from the store
  const { store_setAoiUIMode, store_aoi, store_aoiIsExporting, store_setAoiIsExporting,
        } = useStore();

  const [exportFormat, setExportFormat] = useState<TAoiExportFormat>('shp');  // Default to Shapefile










  //-------------------------------------------------------------------------------
  // Export the active AOI.
  //-------------------------------------------------------------------------------
  const onExportAoiButton = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!store_aoi || !store_aoi.aoi_id || !exportFormat) return;

    const result: boolean = await ExportAoi(store_aoi.aoi_id, exportFormat);

    // If the export worked, exit the export UI state
    if(result === true)
      store_setAoiUIMode('default');
  }

  //-------------------------------------------------------------------------------
  // Cancel the export operation.
  //-------------------------------------------------------------------------------
  const onCancelExport = () => 
  {
    store_setAoiIsExporting(false);
    store_setAoiUIMode('default');
  }
  
  //-------------------------------------------------------------------------------
  // The user has changed the output format combo box.
  //-------------------------------------------------------------------------------
  const onOutputFormatChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    setExportFormat(event.target.value as TAoiExportFormat);
  }






  // ******************************************************************************************
  // Main render - EXPORTING state
  // ******************************************************************************************

  if(store_aoiIsExporting)
  return (
    <Stack>
      <Stack direction='row' sx={{ alignItems: 'center' }}>
        <MyCircularProgress />
        <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
          Exporting AOI...
        </Typography>
      </Stack>

      <Button size='small' sx={{ fontSize: '1rem', mt: 2, color: theme_limeGreen }} 
              onClick={onCancelExport}>
        Cancel
      </Button>
    </Stack>
  )






  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1.2rem', color: theme_textColorBlended }}>
        Export the active AOI
      </Typography>

      {/* Output format combo box */}

      <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center', mt: 1 }}>

        <Typography sx={{ fontSize: '0.9rem', color: theme_textColorMain, opacity: 0.7, mr: 2 }}>
          Output Format
        </Typography>

        <FormControl>
          <CustomSelect
            id="aoi-export-select-format"
            variant='standard' size='small' 
            value={exportFormat}
            onChange={onOutputFormatChanged}
            sx={{ color: theme_orange, opacity: 1, fontSize: '1.1rem' }}
            // input={<OutlinedInput label="States"/>}
            // MenuProps={MenuProps}
            // renderValue={(selected) => (
            //   <Box sx={{ }}>
            //     {selected as string}
            //   </Box>
            // )}
          >
            <MenuItem key='geojson' value='geojson'>GeoJSON</MenuItem>
            <MenuItem key='gpkg' value='gpkg'>GeoPackage</MenuItem>
            <MenuItem key='kml' value='kml'>KML</MenuItem>
            <MenuItem key='shp' value='shp'>Shapefile</MenuItem>
          </CustomSelect>
        </FormControl>
      </Stack>

      {/* Export button */}



      

      {/* Offer the user a cancel button to back out */}

      <Stack direction='row' sx={{ width: '100%', mt: 4, alignContent: 'center', justifyContent: 'space-around'}}>

        <Button size='small' variant='outlined' sx={{ px: 3, fontSize: '1rem', fontWeight: 'bold', color: theme_orange }} 
                onClick={onExportAoiButton}>
          Export
        </Button>

        <Button size='small' sx={{ fontSize: '1rem', color: theme_limeGreen }} 
                onClick={onCancelExport}>
          Cancel
        </Button>

      </Stack>

    </Stack>    
  )
}

export default AoiExport;






// Customized MUI Select
const CustomSelect = styled(Select)(() => (
  {
    fontSize: '0.9rem', 
    color: theme_textColorMain, 
    opacity: 0.8,
  
    // These change the underline color when using the standard variant
    ':before': { borderBottomColor: theme_textColorBlended },
    ':after': { borderBottomColor: theme_textColorBlended },
  
    '& .MuiSvgIcon-root': { color: theme_textColorBlended }
  }));


