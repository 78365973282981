// Base component for user account pages (log in, sign up, pwd reset, confirm code, etc)

import { Container, createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import { Copyright } from '../Account/UserOps';

const theme = createTheme();

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AccountPageBaseProps
{
  children?: React.ReactNode;
  minWidth?: string;
}

//-------------------------------------------------------------------------------
// User account pages base component.
//-------------------------------------------------------------------------------
export default function AccountPageBase(props: AccountPageBaseProps) 
{


  
  // Main render

  return (

    <div style={{ backgroundImage: "url(https://images.squarespace-cdn.com/content/v1/5eb08f475674db04ac75aec5/1591059926958-QET4PEL9R3F73NE6PLJO/shutterstock_298180979+%5BConverted%5D-01.jpg?format=2500w)",
                  backgroundSize: 'cover', height: '100vh', position: "relative", display: "flex",
                  justifyContent: "center", alignItems: "center", boxShadow: '5' }}>

      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs" sx={{ bgcolor: 'white', borderRadius: 2, boxShadow: 5, minWidth: props.minWidth }}>
          <CssBaseline />

            {props.children}

          <Copyright sx={{ mt: 0, mb: 4 }} />

        </Container>
      </ThemeProvider>
    </div>
    
  )
}