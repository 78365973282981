// Rounded input box (text box + ok/cancel buttons)

import { Box, IconButton, inputLabelClasses, Stack } from "@mui/material";
import React from "react";
import { RoundedTextField } from "../CustomMuiStyles";
import { theme_bgColorMain, theme_orange } from "../Theme";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface RoundedInputBoxProps
{
  title: string;
  onAccept: any;
  onCancel: any;
  isDisabled?: boolean;
  value: string | null;
  setValue: any;
}

//-------------------------------------------------------------------------------
// Rounded input box (text box + ok/cancel buttons).
//-------------------------------------------------------------------------------
export default function RoundedInputBox2(props: RoundedInputBoxProps) 
{
  const { title, onAccept, onCancel, isDisabled, value, setValue } = props;




  //-------------------------------------------------------------------------------
  // The user changed the text field.
  //-------------------------------------------------------------------------------
  const onTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  {
    setValue(event.target.value);
  }

  //-------------------------------------------------------------------------------
  // The text field is losing focus.
  //-------------------------------------------------------------------------------
  const onBlurTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  {
    onCancel();
  }

  //-------------------------------------------------------------------------------
  // The text field is losing focus.
  //-------------------------------------------------------------------------------
  const onAcceptButtonMouseDown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => 
  {
    //event.preventDefault();

    onAccept();
  }



  
  // Main render

  return (

    <Stack direction='row' sx={{ width: '100%' }}>

      <RoundedTextField margin='none' variant='outlined' id={title} onBlur={(e) => onBlurTextField(e)}
                label={title} name={title} autoFocus value={value}
                onChange={onTextChanged} disabled={isDisabled}
                sx={{ width: '100%', boxShadow: 2 }} focused
                      InputLabelProps={{ sx: { color: theme_bgColorMain, opacity: 0.3, [`&.${inputLabelClasses.shrink}`]: { color: theme_bgColorMain, m: '10px', opacity: 0.5 }}
                    }}/>

      <Box sx={{ width: '50px', borderRadius: 100, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                 bgcolor: theme_orange, color: theme_bgColorMain, boxShadow: 2, alignItems: 'center' }}>

        <Stack sx={{ mr: '10px' }}>
          <IconButton size="small" sx={{ p: 0, mt: '4px', mb: '3px' }} onMouseDown={(e) => onAcceptButtonMouseDown(e)}>
            <CheckIcon sx={{ color: 'darkgreen', width: '22px', height: '22px'}}/>
          </IconButton>

          <IconButton size="small" sx={{ p: 0 }} onClick={(e) => onCancel()}>
            <CloseIcon sx={{ color: 'darkred', width: '22px', height: '22px'}}/>
          </IconButton>
        </Stack>

      </Box>

      {/* <Button variant='contained' onClick={onAccept} disabled={isDisabled}
              sx={{ width: buttonWidth ? buttonWidth : '30px', borderRadius: 100, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                    bgcolor: theme_orange, color: theme_bgColorMain, boxShadow: 2 }}>
        {buttonText ? buttonText : <div>Ok</div>}
      </Button> */}

    </Stack>

  )
}