// UI component to share the active project with another user

import { Stack, Typography, Button, Box } from "@mui/material";
import { theme_textColorBlended, theme_limeGreen, theme_textColorMain, theme_bgColorLight, theme_bgColorLight1, theme_bgColorMain } from "../Theme";
import useStore from "../store";
import { ToastNotification } from "../ToastNotifications";
import { ShareProject } from "./ProjectOps";
import { ChangeEvent, useState } from "react";
import { CustomTextField } from "../LayerLibrary/EditLayer/EditLayer";


const USER_COMMENT_MAX_LENGTH = 200;

export interface IProjectSharingOrg
{
  id: number;
  name: string;
  users: IProjectSharingOrgUser[];
}

export interface IProjectSharingOrgUser
{
  id: string;
  name: string;
}




//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ProjectShareProps
{
}

//-------------------------------------------------------------------------------
// Project share component
//-------------------------------------------------------------------------------
const ProjectShare = (props: ProjectShareProps) => 
{
  // Get needed state data from the store
   const { store_project, store_setProjectUIMode, store_userInfo, store_projectSharingOrgs, 
           store_orgUsersLoading, 
         } = useStore();


  const [userMessage, setUserMessage] = useState<string|undefined>(undefined);
         




  //-------------------------------------------------------------------------------
  // Share the active project with the specified user.
  //-------------------------------------------------------------------------------
  async function onShareProject(clickedOrgUser: IProjectSharingOrgUser)
  {
    // Share the active project with the specified user
    const result: boolean = await ShareProject(clickedOrgUser.id, userMessage);
    if(!result) return;

    // Exit project share mode
    store_setProjectUIMode('default');

    // Notify the user the request worked
    ToastNotification('success', `Your project has been shared with ${clickedOrgUser.name}`);
  }

  //-------------------------------------------------------------------------------
  // Cancel switching projects.
  //-------------------------------------------------------------------------------
  const onCancelShareProject = () => 
  {
    store_setProjectUIMode('default');
    setUserMessage(undefined);
  }

  //-------------------------------------------------------------------------------
  // The user comment has changed.
  //-------------------------------------------------------------------------------
  function OnUserMessageChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    setUserMessage(newValue);
  }










  // Main render - error state

  if(!store_project || !store_userInfo)
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Project share unhandled state
    </Box>    
  )

  // Main render - org users is loading

  if(store_orgUsersLoading)
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Loading...
    </Box>
  )

  // Main render

  return (

    <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>

      <Typography sx={{ mt: -1, mb: 1, fontSize: '0.9rem', color: theme_textColorBlended }}>
        Share this project with another user:
      </Typography>

      <Stack direction='column' sx={{ width: '100%', alignItems: 'center', 
             maxHeight: '160px', overflow: 'auto' }}>

        {store_projectSharingOrgs.map(projSharingOrg => 

          <Stack sx={{ width: '100%' }}>
            {projSharingOrg.id === store_project.organization_id
              ?
                <Stack key={projSharingOrg.id} sx={{ width: '100%', alignItems: 'center' }}>

                  <Box sx={{ width: '90%' }}>
                    {projSharingOrg.users.map(orgUser =>

                      <Box key={orgUser.id} sx={{ width: '100%' }}>
                        {orgUser.id !== store_userInfo?.id
                          ?
                            <Button variant='outlined' size='small' fullWidth
                                    sx={{ width: '100%', my: '2px', textTransform: 'capitalize',
                                    color: theme_textColorMain, bgcolor: theme_bgColorMain+'40', borderRadius: 5, fontSize: '0.7rem' }}
                                    onClick={() => onShareProject(orgUser)}>
                              {orgUser.name}
                            </Button>
                          :null
                        }
                      </Box>
                    )}
                  </Box>
                </Stack>
              :null
            }
          </Stack>
        )}
      </Stack>

      {/* User message */}

      <CustomTextField name='publisher' variant='standard' size='small' autoComplete='off' multiline maxRows={5}
                       value={userMessage} onChange={OnUserMessageChanged}
                       inputProps={{ maxLength: USER_COMMENT_MAX_LENGTH }}
                       label={<Typography sx={{ color: theme_textColorBlended }}>Message</Typography>}
                       sx={{ p:0, mt: 2, width: '100%' }}/>

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 2, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancelShareProject}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default ProjectShare;


