// Create a new custom layer

import useStore from '../store';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, styled } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { ILayerLibraryGroup } from './LayerLibraryInterfaces';
import { useState } from 'react';
import { CreateNewCustomLayer } from './LayerLibraryOps';
import { ToastNotification } from '../ToastNotifications';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { ILayer } from '../Layers/LayerInterfaces';
import { LAYER_NAME_MAX_LENGTH } from './EditLayer/EditLayerInfo';


const FILE_UPLOAD_MAX_SIZE_MB = 500;
const FILE_UPLOAD_MAX_SIZE_BYTES = FILE_UPLOAD_MAX_SIZE_MB * 1024 * 1024;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface CreateNewLayerProps
{
  showDialog: boolean;
  setShowDialog: any;
  layerLibraryGroup?: ILayerLibraryGroup;
  onNotifyNewLayerHasBeenCreated: any;
}

//-------------------------------------------------------------------------------
// Create a new custom layer
//-------------------------------------------------------------------------------
export function CreateNewLayer(props: CreateNewLayerProps) 
{
  // Get needed state data from the store
  const { store_creatingNewLayer, 
        } = useStore();


  const [layerName, setLayerName] = useState<string>('');
  const [uploadFile, setUploadFile] = useState<File|undefined>(undefined);
  const [uploadFileName, setUploadFileName] = useState<string|undefined>(undefined);










  //-------------------------------------------------------------------------------
  // Cancel without editing the new group.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    // Disable closing the window if a layer is currently being created
    if(store_creatingNewLayer) return;

    // Reset local props to default before we close
    if(layerName) setLayerName('');
    if(uploadFile) setUploadFile(undefined);
    if(uploadFileName) setUploadFileName(undefined);

    // Close this dialog
    props.setShowDialog(false);
  }

  //-------------------------------------------------------------------------------
  // The layer name has changed.
  //-------------------------------------------------------------------------------
  function OnLayerNameChanged(newName: string)
  {
    if(newName.length > LAYER_NAME_MAX_LENGTH)
      return;

    setLayerName(newName);
  }

  //-------------------------------------------------------------------------------
  // Accept the data (used for both Create and Edit modes).
  //-------------------------------------------------------------------------------
  const OnCreateLayer = async () => 
  {
    if(!props.layerLibraryGroup) return;

    // Verify input

    if(!layerName || layerName.trim().length === 0)
    {
      ToastNotification('error', 'Please enter a name for the new custom layer');
      return;
    }

    if(!uploadFileName || uploadFileName.trim().length === 0 || !uploadFile)
    {
      ToastNotification('error', 'Please select a Shapefile');
      return;
    }
  
    // Create the new layer

    const newLayer: ILayer | undefined = await CreateNewCustomLayer(uploadFile, layerName, props.layerLibraryGroup?.layer_library_group_id);
    if(!newLayer)
      return;

    // Reset local props to default before we close
    if(layerName) setLayerName('');
    if(uploadFile) setUploadFile(undefined);
    if(uploadFileName) setUploadFileName(undefined);
    
    // Close the "Create New Layer" dialog
    props.setShowDialog(false);

    // Notify the caller (LayerLibrary) that a new layer has been created
    props.onNotifyNewLayerHasBeenCreated(props.layerLibraryGroup.layer_library_group_id, newLayer);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a file to upload.
  //-------------------------------------------------------------------------------
  const OnUploadFileSelected = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!event.target.files || event.target.files.length === 0) return;

    const file: File = event.target.files[0];
    if(!file) return;

    if(!file.name) return;  // If the user cancels

    if(file.size > FILE_UPLOAD_MAX_SIZE_BYTES)
    {
      ToastNotification('error', `The file you selected is too large (over ${FILE_UPLOAD_MAX_SIZE_MB} MB)`);
      return;
    }

    setUploadFile(file);
    setUploadFileName(file.name);

    // If the layer name text box is empty, auto-fill it with the filename (without extension)
    if(!layerName || layerName.trim().length ===0) 
      setLayerName(file.name.substring(0, file.name.lastIndexOf('.')) || file.name);
  }







  





  if(!props.layerLibraryGroup) return null;

  // Main render

  return (

    // <Dialog open={props.showDialog} onClose={OnClose} maxWidth='md' PaperProps={{ sx: { height: '40vh' }}}>
    <Dialog open={props.showDialog} onClose={OnClose} maxWidth='md'>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', color: theme_textColorMain }}>
              Create a New Custom Layer
          </Typography>

          <IconButton size="small" onClick={OnClose} disabled={store_creatingNewLayer === true}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0, background: theme_bgColorGradient2 }}>

        {store_creatingNewLayer
          ?
            <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'center', width: '100%', mt: 2, height: '150px' }}>
              <CircularProgress/>
              <Stack direction='column' sx={{ ml: 3 }}>
                <Typography sx={{ fontSize: '1.4rem', color: theme_textColorBlended, opacity: 1, textAlign: 'left' }}>
                  Creating new layer...
                </Typography>
                <Typography sx={{ fontSize: '0.6rem', mt: 0.5, color: theme_limeGreen, opacity: 0.7, textAlign: 'left' }}>
                  For larger Shapefiles this could take a few minutes.
                </Typography>
              </Stack>
            </Stack>
          :
            <Stack sx={{ mx: 2, mt: 2 }}>

              <Stack direction='row' alignItems='center'>
                <Typography sx={{ fontSize: '0.9rem', color: theme_textColorBlended }}>
                  Group:
                </Typography>
                <Typography sx={{ ml: 1, fontSize: '0.9rem', color: theme_orange, opacity: 0.8 }}>
                  {props.layerLibraryGroup.name}
                </Typography>
              </Stack>

              <CustomTextField variant='outlined' autoFocus size='small' id="newLayerName" name="newLayerName" fullWidth
                               inputProps={{ maxLength: LAYER_NAME_MAX_LENGTH }}
                               label='Layer Name' value={layerName} onChange={(e)=>OnLayerNameChanged(e.target.value)} 
                               sx={{ p:0, mt: 3 }}/>

              <Stack sx={{ width: '100%', alignItems: 'center', my: 4 }}>
                <label htmlFor="upload-shapefile">

                  <input style={{ display: 'none' }} id="upload-shapefile" name="upload-shapefile" type="file" 
                        onChange={OnUploadFileSelected} accept=".zip"/>

                  <Button variant='text' component="span"
                          sx={{ color: theme_limeGreen, fontSize : '1.2rem', alignItems: 'center', textTransform: 'none' }}>
                    <DriveFolderUploadIcon sx={{ color: theme_limeGreen, width: '36px', height: '36px', mr: '8px' }}/>
                    Select a Shapefile (ZIP)
                  </Button>
                </label>

                {uploadFileName && uploadFileName.trim().length > 0
                  ?
                    <Typography sx={{ mt: 1, fontSize: '1.0rem', color: theme_orange }}>
                      {uploadFileName}
                    </Typography>
                  :
                    <Typography sx={{ mt: 1, fontSize: '1.0rem', color: theme_textColorBlended, opacity: 0.5 }}>
                      [ No Shapefile selected ]
                    </Typography>
                }

              </Stack>
            </Stack>
        }

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Button variant='outlined' onClick={OnClose} disabled={store_creatingNewLayer === true} sx={{ width: '100px' }}>
          Cancel
        </Button>
        <Button variant='contained' onClick={(_)=>OnCreateLayer()} disabled={store_creatingNewLayer === true}
                sx={{ width: '200px', fontWeight: 'bold', ml: 2 }}>
          Create Layer
        </Button>

      </DialogActions>

    </Dialog>
  )
}







// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
 const CustomTextField = styled(TextField)(() => (
{

  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain,
  },  
  "& .MuiOutlinedInput-notchedOutline": 
  {
    borderColor: theme_textColorBlended,
  },
  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    }
  },
  "&:hover": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_orange,
    }
  },
}));
