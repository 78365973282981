// UI component to create a new AOI

import { Stack, Button, Typography } from "@mui/material";
import { useState } from "react";
import { theme_limeGreen, theme_textColorBlended } from "../Theme";
import { CreateNewAoi } from "./AoiOps";
import useStore from "../store";
import React from "react";
import RoundedInputBox from "../Components/RoundedInputBox";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiCreationProps
{
}

//-------------------------------------------------------------------------------
// AOI creation component
//-------------------------------------------------------------------------------
const AoiCreation = (props: AoiCreationProps) => 
{
  // Get needed state data from the store
  const { store_setAoiUIMode, 
        } = useStore();

  const [newAoiName, setNewAoiName] = useState<string>('');



  //-------------------------------------------------------------------------------
  // Create a new AOI.
  //-------------------------------------------------------------------------------
  const onCreateNewAoiButton = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newAoiName) return;

    CreateNewAoi(newAoiName, undefined, true);
  }

  //-------------------------------------------------------------------------------
  // Cancel the AOI creation operation.
  //-------------------------------------------------------------------------------
  const onCancel = () => 
  {
    store_setAoiUIMode('default');
  }












  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1rem', color: theme_textColorBlended }}>
        Create new Area of Interest (AOI)
      </Typography>

      <Typography sx={{ mb: 2.5, fontSize: '0.8rem', color: theme_textColorBlended }}>
        You will be able to define one or more polygon sections by drawing on the map.
      </Typography>

      {/* New AOI:  name + save button */}

      <RoundedInputBox title='New area of interest' onAccept={onCreateNewAoiButton}
                       value={newAoiName} setValue={setNewAoiName}/>

      {/* Cancel button */}

      <Button size='small' sx={{ mt: 1.5, fontSize: '1rem', color: theme_limeGreen }} onClick={onCancel}>
        Cancel
      </Button>

      {/* If there is at least one saved aoi in the list, offer a CANCEL button.
          If there are no other saved AOIs, offer the "Add Saved" button. */}

      {/* {store_project && store_project.aois && store_project.aois.length >= 1
        ?
          <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} onClick={onCancel}>
            Cancel
          </Button>
        :null
          <Stack sx={{ mt: 1 }}>

            <Typography sx={{ mt: 2, mb: 2, fontSize: '0.9rem', color: theme_textColorBlended }}>
              You can also create a new AOI by using one of these methods:
            </Typography>

            <FeatureButton onClick={onAddSavedAoi} icon={<SavedSearchIcon sx={{ color: theme_limeGreen, width: '16px', height: '16px', mr: '4px' }}/>}>
              Add an AOI from other projects
            </FeatureButton>

            <label htmlFor="upload-file">
              <input style={{ display: 'none' }} id="upload-file" name="upload-file" type="file" onChange={onUploadFileSelected}
                    accept=".geojson,.json, application/geo+json" />

              <Button variant='text' component="span"
                      sx={{ pl: '3px', pr: '3px', pt: '2px', pb: '2px', color: theme_limeGreen, 
                            fontSize : '0.8rem', alignItems: 'center', textTransform: 'none' }}>
                <DriveFolderUploadIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>
                Import AOI from a Geojson file
              </Button>
            </label>

            <FeatureButton onClick={null} icon={<HighlightAltIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
              Create AOI by selecting parcels
            </FeatureButton>

          </Stack>
      } */}

    </Stack>    
  )
}

export default AoiCreation;




