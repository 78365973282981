// Parcel identify component

import { Button, IconButton, Stack, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorMain, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { FriendlyCurrency, FriendlyNumber, CapitalizeAllWords } from "../Globals";
import { DEFAULT_PARCEL_ATTRIBUTES, IParcelAttributes } from "./ParcelInterfaces";
import { CapitalizeParcelOwner, UpdateParcelSelectionLayerOnMap } from "./ParcelOps";
import { GetParcelLayerAttribute } from "../AttributeOps";
import { IVectorLayerAttribute } from "../Layers/LayerInterfaces";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';


const PARCEL_IDENTIFY_PANEL_MIN_WIDTH = 240;
const PARCEL_IDENTIFY_PANEL_MAX_WIDTH = 300;

interface IDynamicAttrib
{
  name: string;
  value: string;
}


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelPanelInfoProps
{
}

//-------------------------------------------------------------------------------
// Parcel identify component
//-------------------------------------------------------------------------------
const ParcelIdentify = (props: ParcelPanelInfoProps) => 
{
  // Get needed state data from the store
  const { store_selectedParcels, store_selectedParcelsTotalAcres, store_project,
          store_setSelectedParcels, store_setSelectedParcelsTotalAcres, 
          store_parcelSearchIsRunning, store_setParcelTableViewer, 
          store_setParcelAttribSelector, store_parcelSearchResultsViewerMode, 
        } = useStore();





  //-------------------------------------------------------------------------------
  // Get the sum of the specified numerical attribute for the selected parcel(s).
  //-------------------------------------------------------------------------------
  function GetParcelAttributeSum(attributeName: string): number | undefined
  {
    if(!store_selectedParcels || store_selectedParcels.length < 0) return undefined;

    let totalValue: number | undefined = undefined;

    for(let i=0; i < store_selectedParcels.length; i++)
    {
      const attrib = store_selectedParcels[i].attributes;
      if(!attrib) return undefined;

      const value: number = attrib[attributeName as keyof IParcelAttributes] as number;
      if(!value) return undefined;

      if(totalValue === undefined)
        totalValue = value;
      else
        totalValue += value;
    }

    if(!totalValue)
      return undefined;

    return totalValue;
  }

  //-------------------------------------------------------------------------------
  // Get the value of the specified attribute for the selected parcel(s).
  //
  // If multiple parcels are selected, we only return the value if it's the same
  // for all selected parcels.
  //-------------------------------------------------------------------------------
  function GetParcelAttributeCommonValue(attributeName: string): string | number | undefined
  {
    if(!store_selectedParcels || store_selectedParcels.length < 0) return undefined;

    let commonValue: string | number | undefined = undefined;
    for(let i=0; i < store_selectedParcels.length; i++)
    {
      const attrib = store_selectedParcels[i].attributes;
      if(!attrib) return undefined;

      const value = attrib[attributeName as keyof IParcelAttributes];
      if(!value) return undefined;

      if(commonValue === undefined)
        commonValue = value;
      else if(commonValue !== value)
        return undefined;
    }

    return commonValue;
  }

  //-------------------------------------------------------------------------------
  // Get address line 2 for the selected parcel(s).
  //
  // If multiple parcels are selected, we only return the value if it's the same
  // for all selected parcels.
  //-------------------------------------------------------------------------------
  function GetParcelAttributesAddressLine2(): string | undefined
  {
    // Get all the common attributes we have
    
    const city = GetParcelAttributeCommonValue('mail_city') as string;
    const state = GetParcelAttributeCommonValue('mail_state2') as string;
    const zip = GetParcelAttributeCommonValue('mail_zip') as string;

    let addressStr = '';
    if(city)
      addressStr += CapitalizeAllWords(city); // auto-capitalize each word in the address

    if(state)
    {
      if(addressStr.length > 0) addressStr += ', ';
      addressStr += state.toLocaleUpperCase();  // The state is always all upper case
    }

    if(zip)
    {
      if(addressStr.length > 0) addressStr += ' ';
      addressStr += zip;
    }

    return addressStr !== '' ? addressStr : undefined;
  }

  //-------------------------------------------------------------------------------
  // Clear all selected parcels.
  //-------------------------------------------------------------------------------
  const onDeselectAllParcels = () => 
  {
    // Clear selection in the state store
    store_setSelectedParcels([]);
    store_setSelectedParcelsTotalAcres(0);

    // Update the map
    UpdateParcelSelectionLayerOnMap();
  }

  //-------------------------------------------------------------------------------
  // COUNTY render sub-component.
  //-------------------------------------------------------------------------------
  function RenderCounty()
  {
    const county = GetParcelAttributeCommonValue('county');
    if(!county) return null;
    return (
      <Typography sx={{ fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.8, textTransform: 'capitalize' }}>
        {county} County
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // OWNER render sub-component.
  //-------------------------------------------------------------------------------
  function RenderOwner()
  {
    const owner = GetParcelAttributeCommonValue('owner');
    if(!owner || typeof owner !== 'string') return null;

    return (
      <Typography sx={{ mt: '5px', fontSize: '0.9rem', color: theme_orange, textTransform: 'none' }}>
        {CapitalizeParcelOwner(owner)}
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // ADDRESS LINE 1 render sub-component.
  //-------------------------------------------------------------------------------
  function RenderAddressLine1()
  {
    const addressLine1 = GetParcelAttributeCommonValue('mailadd');
    if(!addressLine1) return null;
    let recap = CapitalizeAllWords(addressLine1 as string);
    return (
      <Typography sx={{ mt: 0.4, fontSize: '0.8rem', color: theme_limeGreen, textTransform: 'capitalize' }}>
        {recap}
     </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // ADDRESS LINE 2 render sub-component.
  //-------------------------------------------------------------------------------
  function RenderAddressLine2()
  {
    const addressLine2 = GetParcelAttributesAddressLine2();
    if(!addressLine2) return null;
    return (
      <Typography sx={{ fontSize: '0.8rem', color: theme_limeGreen, textTransform: 'none' }}>
        {addressLine2}
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // CDL MAJORITY render sub-component.
  //-------------------------------------------------------------------------------
  function RenderCDLMajority()
  {
    const cdlMajority = GetParcelAttributeCommonValue('cdl_majority_category');
    if(!cdlMajority) return null;
    return (
      <Stack direction='row' sx={{ mt: 1 }}>
        <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, textTransform: 'capitalize' }}>
          Land Cover
        </Typography>

        <Typography sx={{ ml: '5px', fontSize: '0.6rem', color: theme_limeGreen, opacity: 0.8, textTransform: 'capitalize' }}>
          {cdlMajority}
        </Typography>

        {/* If there is a percent and only one parcel is selected, add it in */}
        {store_selectedParcels.length === 1 && store_selectedParcels[0].attributes && store_selectedParcels[0].attributes.cdl_majority_percent
          ?
            <Typography sx={{ ml: 0.5, fontSize: '0.6rem', color: theme_limeGreen, opacity: 0.8, textTransform: 'capitalize' }}>
              ({store_selectedParcels[0].attributes.cdl_majority_percent}%)
            </Typography>
          :null
        }
      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // PARCEL NUMBER render sub-component.
  //-------------------------------------------------------------------------------
  function RenderParcelNumber()
  {
    // Only returned when 1 parcel is selected
    if(store_selectedParcels.length !== 1 || !store_selectedParcels[0].attributes || !store_selectedParcels[0].attributes.parcelnumb) return null;
    return (
      <Typography sx={{ mt: 1, fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.8, textTransform: 'capitalize' }}>
        Parcel {store_selectedParcels[0].attributes.parcelnumb}
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // PARCEL VALUE render sub-component.
  //-------------------------------------------------------------------------------
  function RenderParcelValue()
  {
    const value = GetParcelAttributeSum('parval');
    if(!value) return null;
    return (
      <Stack direction='column' sx={{ mx: '10px', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, textTransform: 'none', textAlign: 'center' }}>
          Parcel Value
        </Typography>
        <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, textTransform: 'capitalize' }}>
          {FriendlyCurrency(value)}
        </Typography>
      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // LAND VALUE render sub-component.
  //-------------------------------------------------------------------------------
  function RenderParcelLandValue()
  {
    const value = GetParcelAttributeSum('landval');
    if(!value) return null;
    return (
      <Stack direction='column' sx={{ mx: '10px', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, textTransform: 'none', textAlign: 'center' }}>
          Land Value
        </Typography>
        <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, textTransform: 'capitalize' }}>
          {FriendlyCurrency(value)}
        </Typography>
      </Stack>
    )
  }
  
  //-------------------------------------------------------------------------------
  // IMPROVEMENT VALUE render sub-component.
  //-------------------------------------------------------------------------------
  function RenderParcelImprovementValue()
  {
    const value = GetParcelAttributeSum('improvval');
    if(!value) return null;
    return (
      <Stack direction='column' sx={{ mx: '10px', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, textTransform: 'none', textAlign: 'center' }}>
          Improvement
        </Typography>
        <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, textTransform: 'capitalize' }}>
          {FriendlyCurrency(value)}
        </Typography>
      </Stack>
    )
  }
  
  //-------------------------------------------------------------------------------
  // ACRES render sub-component.
  //-------------------------------------------------------------------------------
  function RenderParcelAcres()
  {
    const acresStr = FriendlyNumber(store_selectedParcelsTotalAcres, 2);
    const acres = acresStr + (acresStr === '1' ? ' acre' : ' acres');
    return (
      <Typography sx={{ fontWeight: 'bold', fontSize: '1.3rem', color: '#ffe082', textTransform: 'none' }}>
        {acres}
      </Typography>
    )
  }

  //-------------------------------------------------------------------------------
  // View search results (in table form, in a large popup window).
  //-------------------------------------------------------------------------------
  function OnOpenParcelTableViewer()
  {
    store_setParcelTableViewer(true);
  }

  //-------------------------------------------------------------------------------
  // Renders attributes not in the default list and which the user currently has 
  // in their "active" list.
  // 
  // NOTE: If multiple parcels are selected, it ADDS the value for all of them.
  //-------------------------------------------------------------------------------
  function RenderDynamicAttributes()
  {
    if(!store_project) return null;

    // Look through the user's active attribute list, and find any that are not in the default list

    const extraAttribs: IDynamicAttrib[] = [];

    for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
    {
      const attribName: string = store_project.user_settings.parcel_attribute_list[i].name;
      if(DEFAULT_PARCEL_ATTRIBUTES.find(v => v === attribName) === undefined)
      {
        // Find this attribute's properties
        const attrib: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(attribName);
        if(!attrib) 
          continue;

        let attribValueStr: string | undefined = undefined;

        if(attrib.data_type === 'number' && (attrib.can_add === true || store_selectedParcels.length === 1))
        {
          // NOTE:  Only certain numerical attributes can be added together (like dollar amounts, or acres).
          //        Others such as id numbers, percentages, or years cannot be.  If more than one
          //        parcel is selected and the attribute is not addable, we skip it completely.

          // Number: Render the sum of all selected parcels
          const attribValueNum: number | undefined = GetParcelAttributeSum(attribName);
          attribValueStr = FriendlyNumber(attribValueNum, attrib.decimal_places);
          if(attribValueStr && attrib.unit && attrib.unit.length > 0)
            attribValueStr += " " + attrib.unit;
        }
        else if(attrib.data_type === 'string' || attrib.data_type === 'enum')
        {
          // String: Render the value but only if it's common for all selected parcels
          attribValueStr = GetParcelAttributeCommonValue(attribName)?.toString();
        }
        else 
          continue;

        // If the value is undefined, we skip this attribute
        if(!attribValueStr)
          continue;

        // Add the attribute to the dynamnic render list
        extraAttribs.push( 
        {
          name: attrib.display_name,
          value: attribValueStr
        });
      }
    }

    if(extraAttribs.length === 0)
      return null;

    return (
      <Stack sx={{ overflow: 'auto', maxHeight: '80px' }}>
        {extraAttribs.map(attrib => 
          <Stack key={attrib.name} direction='row' sx={{ mt: '1px', alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, textTransform: 'none' }}>
              {attrib.name}
            </Typography>
            <Typography sx={{ ml: '5px', fontSize: '0.6rem', color: theme_limeGreen, opacity: 0.8, textTransform: 'none' }}>
              {attrib.value}
            </Typography>
          </Stack>
        )}
      </Stack>
    )
  }

  //-------------------------------------------------------------------------------
  // Open the attribute selection window.
  //-------------------------------------------------------------------------------
  const OnAttribSelectionButton = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    store_setParcelAttribSelector(true);
  }



















  // If we're in parcel results viewer mode, don't show this
  //if(store_parcelSearchResultsViewerMode) return null;

  // Hide the parcel identify panel which a search is running
  if(store_parcelSearchIsRunning) return null;

  // No selected parcels
  if(store_selectedParcels.length === 0) return null;

  // We are zoomed out too far, can't see the parcels
  //if(store_project && store_project.user_settings.mapView.zoom && store_project.user_settings.mapView.zoom < MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS) return null;

  // Main render - we are zoomed in with at least one parcel selected

  return (

    <Stack sx={{ bgcolor: theme_textColorBlended, borderRadius: 1, mt: 1, boxShadow: 3, 
                 minWidth: `${PARCEL_IDENTIFY_PANEL_MIN_WIDTH}px`,
                 maxWidth: `${PARCEL_IDENTIFY_PANEL_MAX_WIDTH}px` }}>
      <Stack direction='column' sx={{ bgcolor: theme_bgColorMain, borderRadius: 1, m: '1px', p: '7px', alignItems: 'left' }}>

        {/* If any parcel are selected, show how many + clear and details buttons */}

        {store_parcelSearchResultsViewerMode
          ?
            <Typography sx={{ width: '100%', fontSize: '0.8rem', mb: 1, color: theme_orange, opacity: 0.8, textTransform: 'none', textAlign: 'center' }}>
              Search summary for {store_selectedParcels.length} matching parcel{store_selectedParcels.length===1?'':'s'}
            </Typography>
            :null
        }

        {store_selectedParcels.length >= 1 && store_parcelSearchResultsViewerMode === false
          ?
            <Stack direction='row' sx={{ width: '100%', alignItems: 'center', mb: 1, justifyContent: 'space-between' }}>

              <Typography sx={{ width: '100%', fontSize: '0.7rem', color: theme_orange, opacity: 0.8, textTransform: 'none', textAlign: 'center' }}>
                {store_selectedParcels.length} parcel{store_selectedParcels.length===1?'':'s'}
              </Typography>

              <Button variant='outlined' onClick={(_)=>onDeselectAllParcels()}
                      sx={{ p:0, minWidth: '40px', ml: 1 }}>
                <Typography sx={{ color: theme_textColorBlended, textTransform: 'none', fontSize: '0.7rem' }}>
                  Clear
                </Typography>
              </Button>

              <IconButton onClick={OnAttribSelectionButton}
                          sx={{ p:'1px', m:0, border: 1, borderRadius: 1, borderColor: theme_textColorBlended+'A0', ml: 1 }}>
                <FormatListBulletedIcon sx={{ color: theme_textColorBlended, width: '15px', height: '15px' }}/>
              </IconButton>

              <Button variant='outlined' onClick={(_)=>OnOpenParcelTableViewer()} sx={{ p:0, ml: 1,minWidth: '75px' }}>
                <Typography sx={{ color: theme_limeGreen, textTransform: 'none', fontSize: '0.7rem' }}>
                   View Details
                </Typography>
              </Button>

            </Stack>
          :null
        }

        {/* Parcel info - default/static section.  These are always present and nicely formatted. */}

        <RenderParcelAcres/>

        <Stack direction='row' sx={{ mt: '4px', mb: '4px', alignItems: 'center', justifyContent: 'center' }}>
          <RenderParcelValue/>
          <RenderParcelLandValue/>
          <RenderParcelImprovementValue/>
        </Stack>

        <RenderOwner/>
        <RenderAddressLine1/>
        <RenderAddressLine2/>

        <RenderParcelNumber/>
        <RenderCounty/>

        <RenderCDLMajority/>

        {/* Parcel info - dynamic section.  These are attributes the user has selected as "active" which
            are not part of the default attributes shown above - presented as simple key/value pairs */}

        <RenderDynamicAttributes/>

        {/* <RenderTotalSoilOrganicCarbon/> */}

      </Stack>
    </Stack>

  )
}



export default ParcelIdentify;



