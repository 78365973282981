// NRR/impact info component

import { Box, Link, Stack, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";
import { NRR_INFO_STATIC_IMAGES_BASE_URL } from "../Globals";
import { theme_bgColorMain } from "../Theme";
import { INRR } from "./BundleInterfaces";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface NrrInfoPanelProps
{
  nrr: INRR;
}

//-------------------------------------------------------------------------------
// NRR info component
//-------------------------------------------------------------------------------
const NrrInfoPanel = (props: NrrInfoPanelProps) => 
{





  // There is no description info
  if(!props.nrr || !props.nrr.description) return null;

  // Main render

  return (

    <Stack sx={{ mb: '-1px', mt: '-1px', mr: '-5px', ml: '-5px', p: 1, borderRadius: 1 }}>

      {/* NRR name */}

      <Stack direction='row' sx={{ justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>

        {props.nrr.description.image && props.nrr.description.image.length > 0
          ?
            <Box component="img" alt="" src={NRR_INFO_STATIC_IMAGES_BASE_URL + encodeURIComponent(props.nrr.description.image)}
                sx={{ height: '80px', borderRadius: 2, p: '4px', mt: '-40px', ml: '-11px', bgcolor: '#CDE2FD', boxShadow: 2 }}/>
          :null
        }

        <TypographyWithAutoTooltip sx={{ color: theme_bgColorMain, opacity: 1, fontSize: '1rem', 
                                         fontWeight: 'bold', mb: 1, width: '100%', textAlign: 'left', ml: 1.5 }}>
          {props.nrr.name}
        </TypographyWithAutoTooltip>

      </Stack>

      {/* NRR description text (can contain HTML tags) */}

      {props.nrr.description.text
        ?
          <Typography component='span' sx={{ color: theme_bgColorMain, opacity: 1, fontSize: '0.8rem' }}>
            {/* NOTE: Even though this HTML comes from our own db, it is run through 
                      a sanitizer to strip it of all unsafe code/scripting just in case. */}
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.nrr.description.text)}} style={{whiteSpace: 'pre-line'}}/>
          </Typography>
        :null
      }

      {/* NRR description urls */}

      {props.nrr.description.urls && props.nrr.description.urls.length > 0
        ?
          <Stack sx={{ mt: 1 }}>
            {props.nrr.description.urls.map(url => 
              <Link key={url} href={url} rel="noopener noreferrer" target="_blank" 
                    sx={{ mt: 0.4, fontSize: '0.6rem', color: '#255BF7', opacity: 1 }}>
                {url}
              </Link>
            )}
          </Stack>
        :null
      }

    </Stack>
  )
}

export default NrrInfoPanel;



