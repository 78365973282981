// Custom button component

import { Stack, Button } from "@mui/material";
import React from "react";
import { theme_limeGreen } from "../Theme";

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface FeatureButtonProps
{
  children: React.ReactNode;
  fontSize?: any;
  onClick: any;
  icon: React.ReactNode;
  disabled?: boolean;
}

//-------------------------------------------------------------------------------
// Simple icon/button used in the left-side drawer menu.
// Currently used for AOI interface.
//-------------------------------------------------------------------------------
export default function FeatureButton(props: FeatureButtonProps) 
{
  const { children, fontSize, onClick, icon, disabled, ...other } = props;


  
  // Main render

  return (
    <Stack direction='row'>

      <Button variant='text' onClick={onClick} {...other} disabled={disabled}
              sx={{ pl: '3px', pr: '3px', pt: '2px', pb: '2px', color: theme_limeGreen, 
                    fontSize: fontSize ? fontSize : '0.8rem', alignItems: 'center', textTransform: 'none' }}>

        {icon}

        {children}

      </Button>

    </Stack>
  );
}
