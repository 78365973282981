// PieChart component, but with built-in MUI tooltip added

import { useState } from 'react';
import { Box, Tooltip } from "@mui/material";
import { PieChart, PieChartProps } from 'react-minimal-pie-chart';
import { FriendlyCurrency } from '../Globals';
import { IHbvScenario } from './HbvInterfaces';
import { PIE_CHART_BG_COLOR } from './HbvChart';


const HIDE_PERCENT_LABELS_BELOW_THIS_PERCENT: number = 3; // chart labels below 3% are hidden (too crowded and often collide, and not as important)

type BaseData = IPieChartWithToolTipProps['data'][number];

export interface IPieChartWithToolTipProps extends PieChartProps
{
  scenario: IHbvScenario;
  selectedImpactIndex: number | undefined;
  setSelectedImpactIndex: any;
  selectedPopoutImpactIndex: number | undefined;
  setSelectedPopoutImpactIndex: any;
}

//-------------------------------------------------------------------------------
// PieChart component, but with built-in MUI tooltip added
//-------------------------------------------------------------------------------
function PieChartWithToolTip(props: IPieChartWithToolTipProps)
{
  const [hovered, setHovered] = useState<number | null>(null);


  const data = props.data.map(({ title, ...entry }) => 
  {
    return { ...entry, tooltip: title };
  });






  // Main render

  return (

    <Tooltip title={hovered !== null ? makeTooltipContent(data[hovered]) : ''} followCursor={true}>
      <Box sx={{ alignItems: 'center' }}>

        <PieChart

          data={data}

          // "selects" the pie segment when under the mouse
          // NOTE: To enable hover tooltip, change onMouseOver to: setHovered(index);
          onMouseOver={(_, index) => { setHovered(null); props.setSelectedPopoutImpactIndex(index); }}
          onMouseOut={() => { setHovered(null); props.setSelectedPopoutImpactIndex(undefined); }}

          // Toggles selection of the clicked pie segment
          //onClick={(_, index) => { props.setSelectedImpactIndex(index === props.selectedImpactIndex ? undefined : index) }}

          // Makes pie segments pop out when selected
          segmentsShift={(index) => (index === props.selectedImpactIndex ? 4 : 0)}

          // Turns it into a doughnut chart with a small space between pie segments
          lineWidth={30} paddingAngle={2}

          // A very dim background color that mostly blends into the normal background
          // This is here to make the "extra" pie piece added to comparison charts look "empty"
          background={PIE_CHART_BG_COLOR}

          // Turn it into a half-pie
          startAngle={180}
          lengthAngle={180}
          viewBoxSize={[100, 50]}

          // Add percent labels (only for 3% or higher)
          label={({ dataEntry }) => dataEntry.percentage > HIDE_PERCENT_LABELS_BELOW_THIS_PERCENT ? Math.round(dataEntry.percentage) + '%' : ''}
          labelStyle={(index) => ({
            fill: data[index].color,
            fontSize: '3px',
            fontFamily: 'sans-serif',
          })}
          labelPosition={105}

          // Reduce the radius slightly to make sure the percent labels fit
          // NOTE: This value is relative to the 'viewBoxSize'
          radius={39.5}
        />

      </Box>
    </Tooltip>
  )
}


//-------------------------------------------------------------------------------
// Generates the tooltip content for pie segments.
//-------------------------------------------------------------------------------
function makeTooltipContent(entry: BaseData & { tooltip: BaseData['title']; })
{
  return `${entry.tooltip} - ${FriendlyCurrency(entry.value)}`;
}



export default PieChartWithToolTip;