// Parcel attributes selector item (with Drag-and-Drop support)

import { theme_textColorBlended, theme_limeGreen, theme_orange, theme_textColorMain } from '../../Theme';
import { Checkbox, IconButton, Stack, Typography } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ILocalActiveAttribItem } from './AttribSelectorActivePanel';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AttribSelectorActiveItemProps
{
  sortableAttrib: ILocalActiveAttribItem;
  OnAttribCheckBoxChange: any;
}

//-------------------------------------------------------------------------------
// Parcel attributes selector item (with Drag-and-Drop support)
//-------------------------------------------------------------------------------
export function AttribSelectorActiveItem(props: AttribSelectorActiveItemProps) 
{
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.sortableAttrib.id});

  const topDivStyle = 
  {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
  }


























  // Main render

  return (

    <div ref={setNodeRef} style={topDivStyle}>

      <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>

        <IconButton size='small' {...listeners} {...attributes}
                    sx={{ ml: -0.2, cursor: 'grab', height: '30px', width: '30px' }}>
          <DragIndicatorIcon color='primary' fontSize="small" sx={{ opacity: 0.4 }}/>
        </IconButton>

        <Checkbox size='medium' checked={true} 
            onChange={(e,c)=>props.OnAttribCheckBoxChange(e,c,props.sortableAttrib.attrib)}
            sx=
            {{ 
              mr: 1, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
              '&.Mui-checked': { color: theme_textColorBlended } 
            }} />

        <Stack>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Typography sx={{ color: theme_limeGreen }}>
              {props.sortableAttrib.attrib.display_name}
            </Typography>
            <Typography sx={{ ml: 1, color: theme_orange, fontSize: '0.7rem', opacity: 0.7 }}>
              {props.sortableAttrib.attrib.unit}
            </Typography>
          </Stack>

          <Typography sx={{ fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.65 }}>
            {props.sortableAttrib.attrib.description}
          </Typography>

        </Stack>
      </Stack>
    </div>
  )
}



