// UI component to rename the active AOI

import { Stack, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { theme_limeGreen, theme_textColorBlended } from "../Theme";
import useStore from "../store";
import React from "react";
import { RenameActiveAoi } from "./AoiOps";
import RoundedInputBox from '../Components/RoundedInputBox';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiRenameProps
{
}

//-------------------------------------------------------------------------------
// AOI rename component
//-------------------------------------------------------------------------------
const AoiRename = (props: AoiRenameProps) => 
{
  // Get needed state data from the store
  const { store_setAoiUIMode, store_aoi, store_aoiIsSaving, 
        } = useStore();

  const [newAoiName, setNewAoiName] = useState<string>('');

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    if(store_aoi)
      setNewAoiName(store_aoi.aoi_name);

  }, [store_aoi, setNewAoiName])



  //-------------------------------------------------------------------------------
  // The user changed the new AOI name text field.
  //-------------------------------------------------------------------------------
  // const onNewAoiNameChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  // {
  //   setNewAoiName(event.target.value);
  // }

  //-------------------------------------------------------------------------------
  // Rename the saved AOI.
  //-------------------------------------------------------------------------------
  const onRenameAoiButton = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newAoiName) return;
    if(!store_aoi || !store_aoi.aoi_id) return;

    // If the name hasn't changed, exit rename mode and do nothing
    if(newAoiName.toLowerCase() === store_aoi.aoi_name.toLowerCase()) 
    {
      store_setAoiUIMode('default');
      return;
    }

    RenameActiveAoi(newAoiName);
  }

  //-------------------------------------------------------------------------------
  // Cancel the rename operation.
  //-------------------------------------------------------------------------------
  const onCancelRename = () => 
  {
    store_setAoiUIMode('default');
  }
  



  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1.2rem', color: theme_textColorBlended }}>
        Rename the active AOI
      </Typography>

      {/* Rename AOI:  name + save button */}

      <RoundedInputBox title='New AOI Name' onAccept={onRenameAoiButton} isDisabled={store_aoiIsSaving} 
                       value={newAoiName} setValue={setNewAoiName}/>

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancelRename}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default AoiRename;


