// HBV details panel (left-side of map)

import { IconButton, Stack, styled, ToggleButton, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight, theme_bgColorMain, theme_orange, theme_textColorBlended } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { ResetHbv } from "./HbvOps";
import HbvPanelCore from './HbvPanelCore';


export const HBV_PANEL_WIDTH_PIXELS = 350;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface HbvPanelProps
{
}

//-------------------------------------------------------------------------------
// HBV panel component
//-------------------------------------------------------------------------------
const HbvPanel = (props: HbvPanelProps) => 
{
  // Get needed state data from the store
  const { store_hbvScenario, store_drawerOpen,  
        } = useStore();

 
  

  

  //-------------------------------------------------------------------------------
  // Clear the active HBV data (which auto-closes the panel).
  //-------------------------------------------------------------------------------
  const onClose = async () => 
  {
    ResetHbv();
  }




  // ******************************************************************************************
  // Main render - an HBV scenario is active
  // ******************************************************************************************

  if(store_hbvScenario && store_hbvScenario.hbv_data)
  return (

    <Stack direction='column' sx={{ width: '100%', height: '100%', mt: '5px' }}>

      {/* Close button */}

      <Stack direction='row' sx={{ width: '100%', justifyContent: 'right' }}>
        <IconButton size="small" onClick={onClose}
                    sx={{ p: 0, bgcolor: theme_bgColorMain, width: '30px', height: '30px' }}>
          <CloseIcon sx={{ opacity: 0.7, width: '16px', height: '16px', color: theme_textColorBlended }} />
        </IconButton>
      </Stack>

      <Stack direction='column' 
             sx={{ width: '100%', height: '100%', mt: `${store_drawerOpen?-30:50}px`, alignItems: 'center' }}>

        {/* Panel title */}

        <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
          Highest and Best Value
        </Typography>

        {/* HBV panel core */}

        <HbvPanelCore scenario={store_hbvScenario} allowScenarioRefresh={true}/>

      </Stack>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall through (should never happen)
  // ******************************************************************************************

  return (
    <div>No HBV panel data</div>
  )
}





// Custom style for the 3D toggle icon button
export const MyToggleIconButton = styled(ToggleButton)(
  {
    color: theme_textColorBlended,
    borderColor: theme_bgColorLight,
  
    '&:hover': 
    {
      borderColor: theme_orange,
    },
  
    // selected (and overides hover color)
    "&.Mui-selected, &.Mui-selected:hover": 
    {
      color: theme_orange,
      //backgroundColor: theme_bgColorLight1
    }
  })


export default HbvPanel;
