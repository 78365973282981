//  GIS helper functions

import * as turf from '@turf/turf'
import { GeoJSON, Feature, Geometry, MultiPolygon, Polygon } from 'geojson';
import Debug from "./Debug";
import { IParcel } from './Parcels/ParcelInterfaces';

const SQUARE_METERS_PER_ACRE = 4046.8564224;

//-------------------------------------------------------------------------------
// Returns the bbox for the specified multipolygon (square meters).
//-------------------------------------------------------------------------------
export function GetGeojsonExtentBBox(geojson: any): turf.helpers.BBox
{
  return turf.bbox(geojson); // It should already be in WSG84/EPSG:4326 (lat/long)
}

//-------------------------------------------------------------------------------
// Returns the lat and long coordinate of the center of mass for the specified GeoJSON.
//-------------------------------------------------------------------------------
export function GetPolygonCenterPoint(geojson: any): number[]
{
  const centerPoint = turf.centerOfMass(geojson);
  const x: number = centerPoint.geometry.coordinates[0];
  const y: number = centerPoint.geometry.coordinates[1];

  return [x,y];
}

//-------------------------------------------------------------------------------
// Get the total surface area (in acres) of the specified geojson.
//-------------------------------------------------------------------------------
export function GetGeojsonAcres(geojson: any): number
{
  // Get surface area in square meters
  const aoiAreaMeters = turf.area(geojson);

  return aoiAreaMeters / SQUARE_METERS_PER_ACRE;  // acres
}

//-------------------------------------------------------------------------------
// Returns the acres in a bbox around the specified multipolygon.
//-------------------------------------------------------------------------------
export function GetGeojsonBBoxAcres(geom: any): number
{
  // Figure out the extent BBOX for the AOI
  const bbox = turf.bbox(geom); // It should already be in WSG84/EPSG:4326 (lat/long)

  // Get the surface area and convert it to acres
  const bboxPolygon = turf.bboxPolygon(bbox);
  const aoiAreaMeters = turf.area(bboxPolygon);
  const areaAcres = aoiAreaMeters / SQUARE_METERS_PER_ACRE;  // acres

  return areaAcres;
}

//-------------------------------------------------------------------------------
// Returns TRUE if any of the polygons in the specified geojson self-intersect.
//
// Only works with Geojson FeatureCollection where each polygon is in a separate 
// feature.
//-------------------------------------------------------------------------------
export function DetectSelfIntersection(geojson: any): boolean
{
  if(geojson.type !== 'FeatureCollection')
  {
    Debug.error('GisOps.DetectSelfIntersection> Data is not in Geojson FeatureCollection format');
    return false;
  }

  for(let i=0; i < geojson.features.length; i++)
  {
    const kinks = turf.kinks(geojson.features[i]);
    if(kinks.features.length > 0) 
      return true;  // self-intersection found
  }

  return false;  // self-intersection not found
}

//-------------------------------------------------------------------------------
// Returns TRUE if any 2 polygons in the specified geojson intersect each other.
//
// Only works with Geojson FeatureCollection where each polygon is in a separate 
// feature.
//-------------------------------------------------------------------------------
export function DetectIntersection(geojson: any): boolean
{
  if(geojson.type !== 'FeatureCollection')
  {
    Debug.error('GisOps.DetectIntersection> Data is not in Geojson FeatureCollection format');
    return false;
  }

  if(geojson.features.length <= 1)
    return false; // intersection is not possible with 0 or 1 polygons

  for(let i=0; i < geojson.features.length; i++)
  {
    for(let j=i; j < geojson.features.length; j++)
    {
      if(i===j) continue; // don't test intersection with self

      const intersectionData = turf.intersect(geojson.features[i], geojson.features[j]);
      if(intersectionData !== null) 
        return true;  // intersection between 2 polygon found
    }

    return false; // intersection not found
  }

  Debug.error('GisOps.DetectIntersection> Unknown format');
  return false;
}

//-------------------------------------------------------------------------------
// Convert the specified Geojson MultiPolygon into a Geojson FeatureCollection, 
// with one polygon per Feature.
//-------------------------------------------------------------------------------
export function ConvertMultiPolygonToFeatureCollection(geom: MultiPolygon | undefined): GeoJSON | undefined
{
  if(!geom) return undefined;
  
  if(geom.type !== 'MultiPolygon')
  {
    Debug.error('AoiOps.ConvertMultiPolygonToFeatureCollection> geom is not in MultiPolygon format');
    return undefined;
  }
  
  const geojson: GeoJSON = 
  {
    type: "FeatureCollection",
    features: []
  }

  for(let i=0; i < geom.coordinates.length; i++)
  {
    const geometry: Geometry = 
    {
      type: 'Polygon',
      coordinates: geom.coordinates[i]
    }

    const feature: Feature = 
    {
      type: 'Feature',
      geometry: geometry,
      properties: []
    }

    geojson.features.push(feature);
  }

  return geojson;
}

//-------------------------------------------------------------------------------
// Convert the specified Geojson Polygon into a Geojson FeatureCollection.
//-------------------------------------------------------------------------------
export function ConvertPolygonToFeatureCollection(geom: Polygon | undefined): GeoJSON | undefined
{
  if(!geom) return undefined;
  
  if(geom.type !== 'Polygon')
  {
    Debug.error('AoiOps.ConvertPolygonToFeatureCollection> geom is not in Polygon format');
    return undefined;
  }
  
  const geojson: GeoJSON = 
  {
    type: "FeatureCollection",
    features: []
  }

  const geometry: Geometry = 
  {
    type: 'Polygon',
    coordinates: geom.coordinates
  }

  const feature: Feature = 
  {
    type: 'Feature',
    geometry: geometry,
    properties: []
  }

  geojson.features.push(feature);

  return geojson;
}

//-------------------------------------------------------------------------------
// Convert the specified Feature GeoJSON into a MultiPolygon GeoJSON.
//
// NOTE: This is used when an AOI is created from selected parcels (the API needs 
//       MultiPolygon format).
//-------------------------------------------------------------------------------
export function ConvertFeatureToMultiPolygon(feature: any): GeoJSON
{
  const multiPolyGeojson: GeoJSON = 
  {
    type: "MultiPolygon",
    coordinates: []
  }

  const geometry : Geometry = feature.geometry;
  if (geometry.type === 'Polygon')
    multiPolyGeojson.coordinates.push(geometry.coordinates);
  else if (geometry.type === 'MultiPolygon')
  {
    for(let p=0; p < geometry.coordinates.length; p++)
      multiPolyGeojson.coordinates.push(geometry.coordinates[p]);
  }
  else
    Debug.warn(`GisOps.ConvertFeatureToMultiPolygon> Invalid geometry type (${geometry.type})`);

  // Done
  return multiPolyGeojson;
}

//-------------------------------------------------------------------------------
// Convert the specified FeatureCollection GeoJSON into a MultiPolygon GeoJSON.
//
// NOTE: This is used when an AOI is saved (the API needs MultiPolygon format).
//-------------------------------------------------------------------------------
export function ConvertFeatureCollectionToMultiPolygon(fc: any): GeoJSON
{
  const multiPolyGeojson: GeoJSON = 
  {
    type: "MultiPolygon",
    coordinates: []
  }

  for(let f=0; f < fc.features.length; f++)
  {
    const geometry : Geometry = fc.features[f].geometry;
    if (geometry.type === 'Polygon')
      multiPolyGeojson.coordinates.push(geometry.coordinates);
    else if (geometry.type === 'MultiPolygon')
    {
      for(let p=0; p < geometry.coordinates.length; p++)
        multiPolyGeojson.coordinates.push(geometry.coordinates[p]);
    }
    else
      Debug.warn(`GisOps.ConvertFeatureCollectionToMultiPolygon> Invalid geometry type (${geometry.type})`);
  }

  // Done
  return multiPolyGeojson;
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified point is inside the specified Polygon or MultiPolygon.
//-------------------------------------------------------------------------------
export function IsPointInsidePolygon(lng: number, lat: number, geojson: any): boolean
{
  const result: boolean | undefined = turf.inside(turf.point([lng, lat]), geojson);
  if(result === undefined) return false;

  return result;
}

//-------------------------------------------------------------------------------
// Joins any adjancent parcels (Polygons or MultiPolgons) and produces a 
// Feature GeoJSON.
//-------------------------------------------------------------------------------
export function JoinAdjacentParcels(parcels: IParcel[]): Feature
{
  let union: any = parcels[0].geometry;
  for(let i=1; i < parcels.length; i++)
    union = turf.union(union, parcels[i].geometry);

  return union;
}

//-------------------------------------------------------------------------------
// Get the number of polygons in the specified GeoJSON.
// Returns -1 if an error occurs.
//-------------------------------------------------------------------------------
export function GetPolygonCount(geojson: GeoJSON): number
{
  if(geojson.type === 'Polygon')
    return 1;

  if(geojson.type === 'MultiPolygon')
    return geojson.coordinates.length;

  if(geojson.type === 'Feature')
    return -1;  // not supported yet

  if(geojson.type === 'FeatureCollection')
    return -1;  // not supported yet

  return -1;  // error
}

//-------------------------------------------------------------------------------
// 
//-------------------------------------------------------------------------------
export function LoadMultiPolygonIntoGeoJSON(multipolygon: MultiPolygon): GeoJSON
{
  const outputGeoJson: GeoJSON = 
  {
    type: "MultiPolygon",
    coordinates: [],
  }

  for(let j=0; j < multipolygon.coordinates.length; j++)
    outputGeoJson.coordinates.push(multipolygon.coordinates[j]);
  
  return outputGeoJson;
}
