// Edit layer

import useStore from '../../store';
import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tab, Tabs, TextField, Typography, styled } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_bgColorMain, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { ILayer, ILayerDescription, ILayerStyleInfo, IVectorLayerAttribute } from '../../Layers/LayerInterfaces';
import { useEffect, useState } from "react";
import { CallServer } from "../../CallServer";
import Debug from '../../Debug';
import { EditLayerStyle, ValidateLayerStyle } from './EditLayerStyle';
import { EditLayerAttributes, IVectorLayerAttributeEdited, ValidateLayerAttributes } from './EditLayerAttributes';
import InfoIcon from '@mui/icons-material/Info';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { EditLayerInfo, ValidateLayerDescription, ValidateLayerName } from './EditLayerInfo';
import { EditLayerLegend, ValidateLegend } from './EditLayerLegend';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { ILegend } from '../../Legend/LegendInterfaces';
import CategoryIcon from '@mui/icons-material/Category';
import { EditLayerGroups } from './EditLayerGroups';
import { SPECIAL_UNGROUPPED_LAYERS_GROUP_ID } from '../LayerLibraryGroupButtons';
import { AddLayerToMap, RemoveLayerFromMap } from '../../Layers/LayerOps';
import { DEV_MODE } from '../../Globals';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerProps
{
}

//-------------------------------------------------------------------------------
// Edit layer
//-------------------------------------------------------------------------------
export function EditLayer(props: EditLayerProps) 
{
  // Get needed state data from the store
  const { store_project, store_addOrUpdateLayer, store_editLayer, store_setEditLayer, 
        } = useStore();

  const [activeTab, setActiveTab] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');

  // Data for tab 1: Layer Info
  const [newLayerName, setNewLayerName] = useState<string|undefined>(store_editLayer?.friendlyName);
  const [newLayerDescription, setNewLayerDescription] = useState<ILayerDescription|undefined>(store_editLayer?.description);
  const [layerNameWasChanged, setLayerNameWasChanged] = useState<boolean>(false);
  const [layerDescriptionWasChanged, setLayerDescriptionWasChanged] = useState<boolean>(false);

  // Data for tab 2: Layer Legend
  const [newLegend, setNewLegend] = useState<ILegend|undefined>(store_editLayer?.legend);
  const [legendWasChanged, setLegendWasChanged] = useState<boolean>(false);

  // Data for tab 3: Layer Style
  const [newStyleInfo, setNewStyleInfo] = useState<ILayerStyleInfo|undefined>(store_editLayer?.style_info);
  const [styleWasChanged, setStyleWasChanged] = useState<boolean>(false);

  // Data for tab 4: Layer Attributes  
  const [newLayerAttribs, setNewLayerAttribs] = useState<IVectorLayerAttributeEdited[]|undefined>(store_editLayer?.attributes as IVectorLayerAttributeEdited[]|undefined);
  const [attribListWasChanged, setAttribListWasChanged] = useState<boolean>(false);
  
  // Data for tab 5: Groups
  const [newLayerGroups, setNewLayerGroups] = useState<number[]|undefined>(store_editLayer?.layer_library_group_ids as number[]|undefined);
  const [groupListWasChanged, setGroupListWasChanged] = useState<boolean>(false);
  






  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Initialize the editor info each time the "Edit Layer" window is opened

    if(store_editLayer)
      ResetDialog();

  }, [store_editLayer]);

  //-------------------------------------------------------------------------------
  // Cancel without editing the new group.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    ResetDialog();

    store_setEditLayer(undefined);
  }

  //-------------------------------------------------------------------------------
  // Resets the form.
  //-------------------------------------------------------------------------------
  function ResetDialog()
  {
    setErrorMessage('');
    setLayerNameWasChanged(false);
    setLayerDescriptionWasChanged(false);
    setLegendWasChanged(false);
    setStyleWasChanged(false);
    setAttribListWasChanged(false);
    setGroupListWasChanged(false);

    if(!store_editLayer) return;

    setNewLayerName(store_editLayer.friendlyName);
    setNewStyleInfo(store_editLayer.style_info);
    setNewLayerGroups(store_editLayer.layer_library_group_ids);

    const attribsEdited: IVectorLayerAttributeEdited[] = store_editLayer.attributes as IVectorLayerAttributeEdited[];
    for(let i=0; i < attribsEdited.length; i++)
      attribsEdited[i].edited = false;
    setNewLayerAttribs(attribsEdited);

    if(store_editLayer.description)
      setNewLayerDescription(store_editLayer.description);
    else
      setNewLayerDescription(
      {
        sourceUrl: '',
        timePeriod: '',
        publisher: '',
        shortDescription: '',
        longDescription: '',
        citations: [],
        images: [],
        keywords: ''
      });

    if(store_editLayer.legend)
    {
      const newLegend: ILegend = { ...store_editLayer.legend }

      // Force the entries array to be defined - otherwise it can crash the layer editor
      if(newLegend.entries === undefined)
        newLegend.entries = [];

      if(newLegend.sort_by === undefined)
        newLegend.sort_by = 'none';

      setNewLegend(newLegend);
    }
    else
      setNewLegend(
      {
        entries: [],
        sort_by: 'none',
        sort_order: 'asc',
        units: '',
      })
  }

  //-------------------------------------------------------------------------------
  // Switch tabs.
  //-------------------------------------------------------------------------------
  const OnTabChange = (event: React.SyntheticEvent, newValue: number) => 
  {
    setActiveTab(newValue);
  }

  //-------------------------------------------------------------------------------
  // Accept changes.
  //-------------------------------------------------------------------------------
  function OnAcceptChanges()
  {
    if(!store_editLayer || !store_project || !store_project.project_id) return;

    setErrorMessage('');

    // If nothing has changed, no need to make the API call
    if(!layerNameWasChanged && !layerDescriptionWasChanged && !legendWasChanged && !styleWasChanged && 
       !attribListWasChanged && !groupListWasChanged)
    {
      OnClose();
      return;
    }

    // Get form data and validate

    let validationErrMsg = '';

    // Validate the layer name

    if(layerNameWasChanged)
    {
      validationErrMsg = ValidateLayerName(newLayerName);
      if(validationErrMsg !== '') { setErrorMessage(validationErrMsg); return; }
    }

    // Validate the layer description

    if(layerDescriptionWasChanged)
    {
      validationErrMsg = ValidateLayerDescription(newLayerDescription);
      if(validationErrMsg !== '') { setErrorMessage(validationErrMsg); return; }
    }

    // Validate the layer legend

    if(legendWasChanged)
    {
      validationErrMsg = ValidateLegend(newLegend);
      if(validationErrMsg !== '') { setErrorMessage(validationErrMsg); return; }
    }

    // Validate the layer style

    if(styleWasChanged)
    {
      validationErrMsg = ValidateLayerStyle(newStyleInfo);
      if(validationErrMsg !== '') { setErrorMessage(validationErrMsg); return; }
    }

    // Validate the layer attributes list

    if(attribListWasChanged)
    {
      validationErrMsg = ValidateLayerAttributes(newLayerAttribs);
      if(validationErrMsg !== '') { setErrorMessage(validationErrMsg); return; }
    }

    // Call server to save the changes

    const server = new CallServer();
    server.Add('project_id', store_project.project_id);
    server.Add('layer_id', store_editLayer.id);
    if(layerNameWasChanged && newLayerName)
      server.Add('name', newLayerName);
    if(layerDescriptionWasChanged)
      server.Add('description', JSON.stringify(newLayerDescription));
    if(legendWasChanged)
      server.Add('legend', JSON.stringify(newLegend));
    if(styleWasChanged)
      server.Add('style_info', JSON.stringify(newStyleInfo));
    if(attribListWasChanged && newLayerAttribs)
    {
      // Only submit edited attributes (not all of them)
      let editedAttributes: IVectorLayerAttribute[] = [];
      for(let i=0; i < newLayerAttribs.length; i++)
        if(newLayerAttribs[i].edited)
        {
          const attrib: IVectorLayerAttributeEdited = newLayerAttribs[i];
          delete attrib['edited']; // No need to send the 'edited' key to the API
          editedAttributes.push(attrib);
        }
      server.Add('attributes', JSON.stringify(editedAttributes));
    }
    if(groupListWasChanged && newLayerGroups)
    {
      // If the layer is not part of any groups, it will still be part of the local fake "no group" group (with id -1).
      // We don't submit that -1  group id to the API, since it doesn't know what it is, we just send an
      // empty json array.
      if(newLayerGroups.length === 1 && newLayerGroups[0] === SPECIAL_UNGROUPPED_LAYERS_GROUP_ID)
        server.Add('groups', JSON.stringify([]));
      else
        server.Add('groups', JSON.stringify(newLayerGroups));
    }

    server.Call('put', '/layer').then(result => 
    {
      if(!store_editLayer) return;

      if(result.success)
      {
        Debug.log('SUCCESS! Layer changes have been saved');

        // The changes have been saved to the database - now we need to update the changes
        // in the state store as well.

        let updatedLayer: ILayer = store_editLayer;
        if(layerNameWasChanged)
          updatedLayer.friendlyName = newLayerName ?? '';
        if(layerDescriptionWasChanged && newLayerDescription)
          updatedLayer.description = newLayerDescription;
        if(newLayerDescription && newLayerDescription.keywords)
          updatedLayer.description.keywordsArray = newLayerDescription.keywords.split(",").map((item: string) => item.trim().toLowerCase());
        if(styleWasChanged)
          updatedLayer.style_info = newStyleInfo;
        if(legendWasChanged)
          updatedLayer.legend = newLegend;
        if(attribListWasChanged && store_editLayer.attributes)
          updatedLayer.attributes = newLayerAttribs;
        if(groupListWasChanged && newLayerGroups)
          updatedLayer.layer_library_group_ids = newLayerGroups;

        store_addOrUpdateLayer(updatedLayer);

        // If the layer's style was changed while the layer was active, force the layer to refresh
        if(styleWasChanged && updatedLayer.activeInProject)
        {
          RemoveLayerFromMap(updatedLayer.id);
          AddLayerToMap(updatedLayer.id);
        }

        // Success - close the dialog
        OnClose();
      }
      else
      {
        Debug.error('FAILURE! err=' + result.errorCode + ' - ' + result.errorMessage);

        if(result.errorMessage === '{"detail":"Layer name is already in use"}')
          setErrorMessage('A layer with that name already exists');
        else
          setErrorMessage('Unable to save changes');
      }
    });
  }

  //-------------------------------------------------------------------------------
  // Renders a tab label.  Shows "saved/not saved" status for the tab.
  //-------------------------------------------------------------------------------
  function RenderTabLabel(tabName: string, status: boolean)
  {
    return (
      <Stack>
        <Typography>
          {tabName}
        </Typography>
        {status === true
          ?
            <Typography sx={{ fontSize: '0.6rem', color: theme_errorRed, fontWeight: 'bold', bgcolor: theme_bgColorMain, px: 0.4, borderRadius: 1 }}>
              unsaved changes
            </Typography>
          :
            <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, px: 0.4, borderRadius: 1, textTransform: 'none' }}>
              no changes
            </Typography>
        }
      </Stack>
    )
  }







  





  if(!store_editLayer) return null;

  // Main render

  return (

    <Dialog disablePortal open={store_editLayer!==undefined} onClose={OnClose} maxWidth='xl'  
            PaperProps={{ sx: { minWidth: '30%', width: '45%', maxWidth: '45%', height: '90vh' }}}>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center'}}>
            
            <Typography sx={{ ml: 1, fontSize: '1.3rem', fontWeight:' bold', color: theme_textColorMain }}>
              {store_editLayer.friendlyName}
            </Typography>

            {DEV_MODE
              ?
                <Typography sx={{ ml: 2, color: theme_textColorBlended, fontSize: '0.8rem', opacity: 0.8 }}>
                  {store_editLayer.id}
                </Typography>
              :null
            }
          </Stack>          

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0, background: theme_bgColorGradient2 }}>

        {/* TAB definition (the AppBar was added to make the Tab header sticky inside a MUI Dialog) */}

        <AppBar position='sticky' sx={{ bgcolor: theme_bgColorLight1 }}>
          <Tabs value={activeTab} onChange={OnTabChange} centered>
            
            <CustomTab icon={<InfoIcon sx={{ color: theme_limeGreen }} />} 
                       label={RenderTabLabel('Layer Info', layerNameWasChanged || layerDescriptionWasChanged)}/>

            <CustomTab icon={<FormatListBulletedIcon  sx={{ color: theme_limeGreen }}/>}
                       label={RenderTabLabel('Legend', legendWasChanged)}/>

            <CustomTab icon={<ColorLensIcon sx={{ color: theme_limeGreen }}/>} 
                       label={RenderTabLabel('Style', styleWasChanged)}/>

            <CustomTab icon={<LocalOfferIcon sx={{ color: theme_limeGreen }}/>} 
                       label={RenderTabLabel('Attributes', attribListWasChanged)}/>

            <CustomTab icon={<CategoryIcon sx={{ color: theme_limeGreen }}/>}
                       label={RenderTabLabel('Groups', groupListWasChanged)}/>

          </Tabs>
        </AppBar>

        {/* Layer Info tab */}

        <CustomTabPanel value={activeTab} index={0}>
          <EditLayerInfo layer={store_editLayer} 
                         newLayerName={newLayerName} setNewLayerName={setNewLayerName}
                         newLayerDescription={newLayerDescription} setNewLayerDescription={setNewLayerDescription}
                         setLayerNameWasChanged={setLayerNameWasChanged}
                         setLayerDescriptionWasChanged={setLayerDescriptionWasChanged}/>
        </CustomTabPanel>

        {/* Legend tab */}

        <CustomTabPanel value={activeTab} index={1}>
          <EditLayerLegend layer={store_editLayer} 
                           newLegend={newLegend} setNewLegend={setNewLegend}
                           setLegendWasChanged={setLegendWasChanged}/>
        </CustomTabPanel>

        {/* Style tab */}

        <CustomTabPanel value={activeTab} index={2}>
          <EditLayerStyle layer={store_editLayer} 
                          newStyleInfo={newStyleInfo} 
                          setNewStyleInfo={setNewStyleInfo} setStyleWasChanged={setStyleWasChanged}/>
        </CustomTabPanel>

        {/* Attributes tab */}

        <CustomTabPanel value={activeTab} index={3}>
          <EditLayerAttributes layer={store_editLayer}
                               newLayerAttribs={newLayerAttribs} setNewLayerAttribs={setNewLayerAttribs}
                               setAttribListWasChanged={setAttribListWasChanged}/>
        </CustomTabPanel>

        {/* Groups tab */}

        <CustomTabPanel value={activeTab} index={4}>
          <EditLayerGroups layer={store_editLayer}
                           newLayerGroups={newLayerGroups} setNewLayerGroups={setNewLayerGroups}
                           setGroupListWasChanged={setGroupListWasChanged}/>
        </CustomTabPanel>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack direction='column' sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
          
          {/* Error message */}

          {errorMessage && errorMessage.length > 0
            ?
              <Typography sx={{ mb: 3, fontSize: '1.1rem', fontWeight:'normal', color: theme_errorRed, opacity: 1,
                                textAlign: 'center', borderRadius: 1, bgcolor: theme_errorRed+'30', px: 2, py:1 }}>
                {errorMessage}
              </Typography>
            :null
          }

          {/* CANCEL and ACCEPT CHANGES buttons */}

          <Stack direction='row'>
            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='outlined' onClick={OnClose} sx={{ mr: 3, width: '100px' }}>
                Cancel
              </Button>
            </Stack>

            <Stack sx={{ alignItems: 'center' }}>
              <Button variant='contained' onClick={_=>OnAcceptChanges()}
                      sx={{ width: '200px', fontWeight: 'bold' }}>
                Accept Changes
              </Button>
            </Stack>
          </Stack>

        </Stack>

      </DialogActions>
    </Dialog>
  )
}







// Custom TextField
export const CustomTextField = styled(TextField)(() => (
{
  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain+'C0',
  },  
  "& .MuiOutlinedInput-notchedOutline": 
  {
    borderColor: theme_textColorBlended,
  },
  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    }
  },
  "&:hover": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_orange,
    }
  },

  // These turn off the up/down arrows when type=number
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },  
  "& input[type=number]": 
  {
    MozAppearance: "textfield",
  },  
  
  // These change the underline color when using the standard variant
  '& .MuiInput-underline:before': { borderBottomColor: theme_textColorBlended },
  '& .MuiInput-underline:after': { borderBottomColor: theme_textColorBlended },
}));


interface TabPanelProps 
{
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) 
{
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const CustomTab = styled(Tab)(
{
  // Inactive tab text/icon color
  color: 'white',
  opacity: 0.6,
  fontWeight: 'normal',
  textTransform: 'capitalize',

  "&.Mui-selected": 
  {
    // Selected tab text/icon color
    color: theme_textColorMain,
    opacity: 1,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    backgroundColor: theme_textColorBlended+'45',
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
  }
});


