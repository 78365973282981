// Layer library component

import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, Drawer, Grid, IconButton, InputAdornment, Stack, TextField, Tooltip, Typography, styled, useTheme } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight1, theme_bgColorGradient2, theme_textColorBlended, theme_textColorMain, theme_bgColorGradient, theme_orange, theme_bgColorMain, theme_bgColorLight } from "../Theme";
import LayerLibraryItem from "./LayerLibraryItem";
import CloseIcon from '@mui/icons-material/Close';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { DeleteCustomLayer, DeleteLayerLibraryGroup, GetLayerLibraryGroup, GetLayersForGroup } from "./LayerLibraryOps";
import { useState } from "react";
import { ILayerLibraryGroup } from "./LayerLibraryInterfaces";
import { ILayer } from "../Layers/LayerInterfaces";
import SearchIcon from '@mui/icons-material/Search';
import { CreateNewGroup } from "./CreateNewGroup";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import YesNoDialog from "../Components/YesNoDialog";
import { EditGroup } from "./EditGroup";
import LayerLibraryNewLayerItem from "./LayerLibraryNewLayerItem";
import { CreateNewLayer } from "./CreateNewLayer";
import { EditLayer } from "./EditLayer/EditLayer";
import { LayerLibraryGroupButtons } from "./LayerLibraryGroupButtons";
import { ToastNotification } from "../ToastNotifications";



export const LAYER_LIBRARY_DRAWER_WIDTH = 200;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerLibraryProps
{
}

//-------------------------------------------------------------------------------
// Layer library component
//-------------------------------------------------------------------------------
const LayerLibrary = (props: LayerLibraryProps) => 
{
  // Get needed state data from the store
  const { store_layerLibraryActive, store_setLayerLibraryActive, store_layers, 
          store_layerLibraryGroups, store_project, store_setEditLayer,
          store_editLayerFromLayerLibrary, store_setEditLayerFromLayerLibrary, 
        } = useStore();

  const theme = useTheme();

  // 2 ways to filter - by group, or by search text
  const [filterByGroupID, setFilterByGroupID] = useState<number|undefined>(undefined);
  const [filterBySearchText, setFilterBySearchText] = useState<string>('');

  // Keeps track of which layers are currently visible based on the filters (by group or by search)
  const [visibleLayerIDs, setVisibleLayerIDs] = useState<number[]>([]);

  const [showCreateNewGroupWindow, setShowCreateNewGroupWindow] = useState<boolean>(false);
  const [showEditGroupWindow, setShowEditGroupWindow] = useState<boolean>(false);

  const [clickedGroup, setClickedGroup] = useState<ILayerLibraryGroup|undefined>(undefined);
  const [confirmDeleteGroup, setConfirmDeleteGroup] = useState<boolean>(false);

  const [showCreateLayerWindow, setShowCreateLayerWindow] = useState<boolean>(false);
  //const [showEditLayerWindow, setShowEditLayerWindow] = useState<boolean>(false);
  
  //const [clickedLayer, setClickedLayer] = useState<ILayer|undefined>(undefined);
  const [layerToBeDeleted, setLayerToBeDeleted] = useState<ILayer|undefined>(undefined);
  const [confirmDeleteLayer, setConfirmDeleteLayer] = useState<boolean>(false);



  //-------------------------------------------------------------------------------
  // Apply a group filter based on the group button that was clicked.
  //-------------------------------------------------------------------------------
  const OnGroupButtonClick = async (layerLibraryGroup: ILayerLibraryGroup, clickedLayerGroupID: number|undefined) => 
  {
    if(!layerLibraryGroup) return;

    // If a search filter was already set, clear it
    setFilterBySearchText('');

    // If the clicked group was already the active group filter, remove the filter (show all layer)
    if(clickedLayerGroupID && clickedLayerGroupID === filterByGroupID)
    {
      setFilterByGroupID(undefined);
      setVisibleLayerIDs([]);
      return;
    }

    UpdateVisibleLayerIDsForClickedGroup(layerLibraryGroup.layer_library_group_id);

    setFilterByGroupID(layerLibraryGroup.layer_library_group_id);
  }

  //-------------------------------------------------------------------------------
  // Refresh the "visibleLayerIDs" list.
  //-------------------------------------------------------------------------------
  function UpdateVisibleLayerIDsForClickedGroup(clickedGroupID: number)
  {
    // Get the layers for the clicked group
    const layersForGroup: ILayer[] = GetLayersForGroup(clickedGroupID);

    // Update the 'visibleLayerIDs' state array

    const newVisibleLayerIDs: number[] = [];
    for(let i=0; i < layersForGroup.length; i++)
      newVisibleLayerIDs.push(layersForGroup[i].id);

    setVisibleLayerIDs(newVisibleLayerIDs);
  }

  //-------------------------------------------------------------------------------
  // Returns TRUE if the specified layer is currently visible (ie. not filtered out
  // by either group or search text filtering).
  //-------------------------------------------------------------------------------
  function IsLayerVisible(layer_id: number): boolean
  {
    // If no filters are applied, all layers are visible
    if(!filterByGroupID && !filterBySearchText) return true;

    const found = visibleLayerIDs.find(id => id === layer_id) !== undefined;
    return found;
  }

  //-------------------------------------------------------------------------------
  // Returns all the currently visible layers for the specified group.
  //-------------------------------------------------------------------------------
  function GetVisibleLayersForGroup(layer_library_group_id: number)
  {
    let group_layers: ILayer[] = [];
  
    for(let i=0; i < store_layers.length; i++)
      if(store_layers[i].layer_library_group_ids && 
         store_layers[i].layer_library_group_ids.find(group_id => group_id === layer_library_group_id) &&
         IsLayerVisible(store_layers[i].id))
        group_layers.push(store_layers[i]);
  
    // Sort the layers by name (asc)
    group_layers = group_layers.sort((a: ILayer, b: ILayer) => a.friendlyName.localeCompare(b.friendlyName));
        
    return group_layers;
  }

  //-------------------------------------------------------------------------------
  // Clear all filters, show all layers.
  //-------------------------------------------------------------------------------
  const onClearFiltersButtonClick = () => 
  {
    setFilterBySearchText('');
    setFilterByGroupID(undefined);
    setVisibleLayerIDs([]);
  }

  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    setFilterBySearchText('');
    setFilterByGroupID(undefined);
    setVisibleLayerIDs([]);

    store_setLayerLibraryActive(false);
  }


  //-------------------------------------------------------------------------------
  // The user typed in the search box.
  //-------------------------------------------------------------------------------
  const onSearchTextChange = (valueRaw: string) => 
  {
    const newVisibleLayerIDs: number[] = [];

    const value = valueRaw.toLocaleLowerCase();

    for(let i=0; i < store_layers.length; i++)
    {
      // First try to match by layer name

      if(store_layers[i].friendlyName.toLocaleLowerCase().includes(value))
      {
        newVisibleLayerIDs.push(store_layers[i].id);
        continue;
      }
      
      // Next try to match by keywords

      if(store_layers[i].description && store_layers[i].description.keywords && store_layers[i].description.keywords.toLocaleLowerCase().includes(value))
      {
        newVisibleLayerIDs.push(store_layers[i].id);
        continue;
      }
    }

    setFilterByGroupID(undefined);
    setFilterBySearchText(valueRaw);
    setVisibleLayerIDs(newVisibleLayerIDs);
  }

  //-------------------------------------------------------------------------------
  // Returns TRUE if the specified layer library group has no layers. 
  //-------------------------------------------------------------------------------
  // function IsLayerLibraryGroupEmpty(layer_library_group_id: number): boolean
  // {
  //   for(let i=0; i < store_layers.length; i++)
  //     if(store_layers[i].layer_library_group_ids && store_layers[i].layer_library_group_ids.find(group_id => group_id === layer_library_group_id))
  //       return false;

  //   return true;
  // }

  //-------------------------------------------------------------------------------
  // Returns a list of all layer library group that have at least one layer.
  //-------------------------------------------------------------------------------
  // function GetNonEmptyLayerLibraryGroups(): ILayerLibraryGroup[]
  // {
  //   const newList: ILayerLibraryGroup[] = [];

  //   for(let i=0; i < store_layerLibraryGroups.length; i++)
  //     if(!IsLayerLibraryGroupEmpty(store_layerLibraryGroups[i].layer_library_group_id))
  //       newList.push(store_layerLibraryGroups[i]);

  //   return newList;
  // }

  //-------------------------------------------------------------------------------
  // Pop up the "Create New Layer Library Group" window.
  //-------------------------------------------------------------------------------
  // const OnCreateNewGroup = () => 
  // {
  //   setShowCreateNewGroupWindow(true);
  // }

  //-------------------------------------------------------------------------------
  // Edit a custom layer library group.
  //-------------------------------------------------------------------------------
  function OnEditGroup(group: ILayerLibraryGroup)
  {
    setClickedGroup(group);
    setShowEditGroupWindow(true);
  }

  //-------------------------------------------------------------------------------
  // Confirm the deletion of a custom layer library group.
  //-------------------------------------------------------------------------------
  function OnConfirmDeleteGroup(group: ILayerLibraryGroup)
  {
    if(GetLayersForGroup(group.layer_library_group_id).length > 0)
    {
      ToastNotification('error', 'You can only delete a custom group if it contains no layers.  To delete this group, you will first need to delete any layers it contains.');
      return;
    }

    setClickedGroup(group);
    setConfirmDeleteGroup(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the specified layer library group.
  // 
  // NOTE: This will only be triggered following Yes/No confirmation from the user.
  //-------------------------------------------------------------------------------
  async function OnDeleteGroup()
  {
    if(!clickedGroup) return;

    setConfirmDeleteGroup(false);

    await DeleteLayerLibraryGroup(clickedGroup.layer_library_group_id);
  }

  //-------------------------------------------------------------------------------
  // If the "Create New Layer" button is clicked, pop up the layer creation window.
  //-------------------------------------------------------------------------------
  function OnShowCreateNewLayerWindow(group: ILayerLibraryGroup)
  {
    setClickedGroup(group);
    setShowCreateLayerWindow(true);
  }

  //-------------------------------------------------------------------------------
  // Edit the specified layer.
  //-------------------------------------------------------------------------------
  function OnEditLayer(layer: ILayer)
  {
    store_setEditLayerFromLayerLibrary(true);
    store_setEditLayer(layer);
  }

  //-------------------------------------------------------------------------------
  // Confirm the deletion of a custom layer.
  //-------------------------------------------------------------------------------
  function OnConfirmDeleteLayer(layer: ILayer)
  {
    setLayerToBeDeleted(layer);
    setConfirmDeleteLayer(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the specified layer.
  // 
  // NOTE: This will only be triggered following Yes/No confirmation from the user.
  //-------------------------------------------------------------------------------
  async function OnDeleteLayer()
  {
    if(!layerToBeDeleted) return;

    setConfirmDeleteLayer(false);

    await DeleteCustomLayer(layerToBeDeleted.id);
  }

  //-------------------------------------------------------------------------------
  // Notification that the the custom layer.
  //-------------------------------------------------------------------------------
  function onNotifyNewLayerHasBeenCreated(layer_library_group_id: number, layer: ILayer)
  {
    // Refresh the visible layers ID list
    UpdateVisibleLayerIDsForClickedGroup(layer_library_group_id);

    // Open the "Edit layer" window
    store_setEditLayerFromLayerLibrary(true);
    store_setEditLayer(layer);
  }













  // If we don't have the layers or layer groups, can't continue
  if(!store_project || !store_layers || store_layers.length===0 || !store_layerLibraryGroups) return null;

  // Main render

  return (

    <Dialog open={store_layerLibraryActive} onClose={(_)=>OnClose()} fullWidth maxWidth="lg"
            PaperProps={{ sx: { height: '90vh' }}}>

      {/* Dialog title */}

      <AppBar sx={{ height: '60px', width: '100%', bgcolor: theme_bgColorLight1, justifyContent: 'center', 
                    position: 'relative', zIndex: theme.zIndex.drawer+1 }}>

        <Stack direction='row' sx={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ width: '370px', alignItems: 'center' }}>
            <LocalLibraryIcon sx={{ ml: 1, color: theme_textColorBlended, width: '40px', height: '40px' }}/>
            <Typography sx={{ ml: 2, fontSize: '1.8rem', fontWeight: 'bold', color: theme_textColorMain, opacity: 0.9 }}>
              Layer Library
            </Typography>
          </Stack>

          {/* Search box */}

          <Stack direction='row' sx={{ width:'100%', alignItems: 'center', justifyContent: 'start', ml: 2 }}>

            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <SearchIcon sx={{ color: theme_textColorBlended, mr: 1, my: 0.5 }} />
              <CustomTextField id="input-with-sx" 
                    variant='outlined' size="small" value={filterBySearchText}
                    label=
                    {
                      <Typography sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                        Search Layers
                      </Typography>
                    } 
                    onChange={(e)=>onSearchTextChange(e.target.value)}
                    InputProps=
                    {{
                        endAdornment: (
                          <InputAdornment position="end" sx={{ visibility: filterBySearchText===''?'hidden':'visible' }}>
                            <IconButton tabIndex={-1} onClick={(_)=>onClearFiltersButtonClick()} sx={{ color: theme_textColorBlended }}>
                              <CloseIcon/>
                            </IconButton>
                          </InputAdornment>
                        )
                    }}/>
            </Box>

          </Stack>

        </Stack>

        {/* Close X button */}

        <IconButton aria-label="close" onClick={(_)=>OnClose()} 
                    sx={{ position: 'absolute', right: 8, top: 8, color: theme_textColorBlended }}>
          <CloseIcon />
        </IconButton>

      </AppBar>

      {/* Dialog content */}

      <DialogContent sx={{ p: 0, background: theme_bgColorGradient2 }}>

        <Drawer sx={{ width: `${LAYER_LIBRARY_DRAWER_WIDTH}px`, flexShrink: 0,
                    '& .MuiDrawer-paper': { width: `${LAYER_LIBRARY_DRAWER_WIDTH}px`, boxSizing: 'border-box', background: theme_bgColorGradient } }}
              variant='permanent' anchor='left' open={true} PaperProps={{ style: { position: "absolute" } }} 
              transitionDuration={{ enter: 150, exit: 250 }}>

          {/* Layer group buttons */}
          <LayerLibraryGroupButtons filterByGroupID={filterByGroupID} OnGroupButtonClick={OnGroupButtonClick}
                                    setShowCreateNewGroupWindow={setShowCreateNewGroupWindow}/>

        </Drawer>

        <Stack direction='row' sx={{ width: `calc(100% - ${LAYER_LIBRARY_DRAWER_WIDTH}px)`, ml: `${LAYER_LIBRARY_DRAWER_WIDTH}px` }}>

          {/* Error message when no matches are found */}

          {filterBySearchText.length > 0 && visibleLayerIDs.length === 0
            ?
              <Typography sx={{ m:2, fontSize: '1.4rem', color: theme_orange, width: '100%' }}>
                No matches found.
              </Typography>
            :null
          }

          {/* Layer list grid */}

          <Stack direction='column' sx={{ width: '100%'  }}>

            {store_layerLibraryGroups.map(function(layerLibraryGroup, i)
            {
              // If we're filtering by group, and this layer is not part of that group, skip it.
              // NOTE: This can happen when a layer is part of multiple groups.
              if(filterByGroupID && filterByGroupID !== layerLibraryGroup.layer_library_group_id) return null;

              const visibleLayersInGroup: ILayer[] = GetVisibleLayersForGroup(layerLibraryGroup.layer_library_group_id);

              // If no layers are visible, and it's due to a search filter, we can completely skip displaying this group
              if(visibleLayersInGroup.length === 0 && filterBySearchText) return null;
              return (

                <Stack key={layerLibraryGroup.layer_library_group_id} direction='column' sx={{ mb: 2 }}>

                  <Stack direction='row' sx={{ bgcolor: theme_bgColorLight1+'A0', m: 1, borderRadius: 2, boxShadow: 4, justifyContent: 'space-between', alignItems: 'center' }}>
                  
                    <Typography sx={{ ml: '70px', width: '100%', textTransform: 'none', color: theme_textColorBlended, fontSize: '1.3rem',
                                      py: 0.5, fontWeight: 'bold', textAlign: 'center' }}>
                      {layerLibraryGroup.name}
                    </Typography>

                    <Stack direction='row' sx={{ mr: 0.5, alignItems: 'center', justifyContent: 'flex-end' }}>

                      {layerLibraryGroup.DeleteLayerLibraryGroup === true
                        ?
                          <Tooltip title='Delete this custom layer library group'>
                            <IconButton onClick={(_)=>OnConfirmDeleteGroup(layerLibraryGroup)} 
                                        sx={{ width: '30px', height: '30px', bgcolor: theme_bgColorMain+'70', boxShadow: 2 }}>
                              <DeleteForeverIcon sx={{ width: '18px', height: '18px', color: theme_textColorMain, opacity: 0.5 }}/>
                            </IconButton>
                          </Tooltip>
                        :null
                      }

                      {layerLibraryGroup.EditLayerLibraryGroup === true
                        ?
                          <Tooltip title='Rename this custom layer library group'>
                            <IconButton onClick={(_)=>OnEditGroup(layerLibraryGroup)} 
                                        sx={{ ml: 1, width: '30px', height: '30px', bgcolor: theme_bgColorMain+'70', boxShadow: 2 }}>
                              <EditIcon sx={{ width: '18px', height: '18px', color: theme_textColorMain, opacity: 0.5 }}/>
                            </IconButton>
                          </Tooltip>
                        :null
                      }

                    </Stack>
                  
                  </Stack>

                  {/* Layer cards for this group */}

                  <Grid container spacing={0}>
                    {visibleLayersInGroup.map(layer => 
                      <LayerLibraryItem key={layer.id} layer={layer} layerLibraryGroup={layerLibraryGroup} 
                                        OnEditLayer={OnEditLayer} OnConfirmDeleteLayer={OnConfirmDeleteLayer}/>
                    )}

                    {/* A special item which allows adding new custom layers (if the user has required permissions) */}
                    {/* NOTE: It does not appear if the user typed in the search/filter box */}
                    {!filterBySearchText && layerLibraryGroup.isCustomGroup && layerLibraryGroup.CreateCustomLayer === true
                      ?
                        <LayerLibraryNewLayerItem layerLibraryGroup={layerLibraryGroup} OnShowCreateNewLayerWindow={OnShowCreateNewLayerWindow}/>
                      :null
                    }
                    
                  </Grid>

                </Stack>
              )
            })}

          </Stack>

        </Stack>

        {/* Window to create a new layer library group */}
        <CreateNewGroup showDialog={showCreateNewGroupWindow} setShowDialog={setShowCreateNewGroupWindow}/>

        {/* Window to edit/rename a new layer library group */}
        <EditGroup group={clickedGroup} showDialog={showEditGroupWindow} setShowDialog={setShowEditGroupWindow}/>

        {/* Window to create a new custom layer */}
        <CreateNewLayer layerLibraryGroup={clickedGroup} onNotifyNewLayerHasBeenCreated={onNotifyNewLayerHasBeenCreated}
                        showDialog={showCreateLayerWindow} setShowDialog={setShowCreateLayerWindow}/>

        {/* Window to edit a layer */}
        {store_editLayerFromLayerLibrary
          ?
            <EditLayer/>
          :null
        }

        {/* "Delete Group" Yes/No confirmation dialog */}
        <YesNoDialog open={confirmDeleteGroup} setOpen={setConfirmDeleteGroup} onYes={OnDeleteGroup}>
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended, width: '42px', height: '42px'  }} />
            <Box sx={{ color: theme_textColorMain, fontSize: '1.2rem' }}>Delete layer library group</Box>
            <Box sx={{ ml: 1, color: theme_orange, fontSize: '1.3rem', fontWeight: 'bold' }}>{GetLayerLibraryGroup(clickedGroup?.layer_library_group_id)?.name}</Box>
            <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
          </Stack>
        </YesNoDialog>

        {/* "Delete Layer" Yes/No confirmation dialog */}
        <YesNoDialog open={confirmDeleteLayer} setOpen={setConfirmDeleteLayer} onYes={OnDeleteLayer}>
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended, width: '42px', height: '42px'  }} />
            <Box sx={{ color: theme_textColorMain, fontSize: '1.2rem' }}>Delete layer</Box>
            <Box sx={{ ml: 1, color: theme_orange, fontSize: '1.3rem', fontWeight: 'bold' }}>{layerToBeDeleted?.friendlyName}</Box>
            <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
          </Stack>
        </YesNoDialog>

      </DialogContent>

      {/* Close button */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>
        <Button variant='contained' autoFocus onClick={(_)=>OnClose()}>
          Close
        </Button>
      </DialogActions>

    </Dialog>
  )
}


export default LayerLibrary;


// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
const CustomTextField = styled(TextField)(() => (
  {
  
    '& .MuiInputBase-root': 
    {
      color: theme_textColorMain,
    },  
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    },
    "&.Mui-focused": 
    {
      "& .MuiOutlinedInput-notchedOutline": 
      {
        borderColor: theme_textColorBlended,
      }
    },
    "&:hover": 
    {
      "& .MuiOutlinedInput-notchedOutline": 
      {
        borderColor: theme_orange,
      }
    },
  }));
  