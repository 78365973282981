// AccordionSummary custom style.

import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from "@mui/material";


export const DrawerMenuAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  //padding: 0, 
  //marginTop: -10, 
  //marginBottom: -10,
  //height: '30px',

  minHeight: 52,
  maxHeight: 52,
  overflow: 'hidden',

  '&.Mui-expanded': 
  {
    // minHeight: 55,
    // maxHeight: 55,
    //backgroundColor: '#FFF2F2',
  },

  //backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, 0.2)',
  //flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': 
  // {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}));
