// Create new layer library group window

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, styled } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { ToastNotification } from '../ToastNotifications';
import { CreateNewLayerLibraryGroup } from './LayerLibraryOps';


export const LAYER_LIBRARY_GROUP_NAME_MAX_CHARS = 25;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface CreateNewGroupProps
{
  showDialog: boolean;
  setShowDialog: any;
}

//-------------------------------------------------------------------------------
// Create new layer library group window
//-------------------------------------------------------------------------------
export function CreateNewGroup(props: CreateNewGroupProps) 
{
  const [newGroupName, setNewGroupName] = useState<string>('');








  //-------------------------------------------------------------------------------
  // Cancel without creating the new group.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    props.setShowDialog(false);
  }

  //-------------------------------------------------------------------------------
  // The group name has changed.
  //-------------------------------------------------------------------------------
  function OnGroupNameTextChange(newName: string)
  {
    if(newName.length > LAYER_LIBRARY_GROUP_NAME_MAX_CHARS)
      return;

    setNewGroupName(newName);
  }

  //-------------------------------------------------------------------------------
  // Accept the info and create the new group.
  //-------------------------------------------------------------------------------
  const OnCreateNewGroup = async () => 
  {
    if(!newGroupName || newGroupName.trim().length === 0)
    {
      ToastNotification('error', 'Please enter in a name for new layer library group');
      return;
    }

    const ret: boolean = await CreateNewLayerLibraryGroup(newGroupName);
    if(ret === false)
      return;

    // Success - close the window
    props.setShowDialog(false);
  }


















  // Main render

  return (

    <Dialog open={props.showDialog} onClose={OnClose} maxWidth='md'>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', color: theme_textColorMain }}>
            Name of the new group
          </Typography>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0, background: theme_bgColorGradient2 }}>

        <Stack sx={{ mx: 2, mt: 2 }}>

          <CustomTextField variant='outlined' autoFocus size='small' id="newGroupName" name="newGroupName" fullWidth
                    value={newGroupName} onChange={(e)=>OnGroupNameTextChange(e.target.value)} sx={{ p:0, m:0 }}/>

        </Stack>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='outlined' onClick={OnClose} sx={{ width: '100px' }}>
            Cancel
          </Button>
        </Stack>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='contained' onClick={(_)=>OnCreateNewGroup()} sx={{ width: '150px' }}>
            Create Group
          </Button>
        </Stack>

      </DialogActions>

    </Dialog>
  )
}







// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
 const CustomTextField = styled(TextField)(() => (
{

  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain,
  },  
  "& .MuiOutlinedInput-notchedOutline": 
  {
    borderColor: theme_textColorBlended,
  },
  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    }
  },
  "&:hover": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_orange,
    }
  },
}));
