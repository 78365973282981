// Bring in a saved AOI from other projects (created by the same user)

import { Stack, Typography, Button } from "@mui/material";
import { theme_textColorBlended,  theme_textColorMain, theme_limeGreen, theme_orange, theme_errorRed } from "../Theme";
import useStore from "../store";
import { IProjectAoi } from "../Projects/ProjectInterfaces";
import { IAoiListItem } from "./AoiInterfaces";
import { AddAOIToAOIGroup, LoadAoi } from "./AoiOps";
import { FriendlyNumber } from "../Globals";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";
import { CustomTextField } from "../LayerLibrary/EditLayer/EditLayer";
import { ChangeEvent, useState } from "react";

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiSelectionProps
{
}

//-------------------------------------------------------------------------------
// AOI selection component
//-------------------------------------------------------------------------------
const AoiSelection = (props: AoiSelectionProps) => 
{
  // Get needed state data from the store
  const { store_aoiList, store_setAoiUIMode, store_projectAddAoi, store_project, 
          store_aoi,
        } = useStore();

  const [filter, setFilter] = useState<string|undefined>(undefined);





  //-------------------------------------------------------------------------------
  // An AOI was selected for import into the active project.
  //-------------------------------------------------------------------------------
  async function onAoiClicked(clickedAoiListItem: IAoiListItem)
  {
    if(!store_project || !store_project.aoi_group_id) return;

    // Add the new AOI to the AOI group of the active project
    AddAOIToAOIGroup(clickedAoiListItem.aoi_id, store_project.aoi_group_id);

    // Add a new AOI to the project in the state store

    const newProjectAoi: IProjectAoi = 
    {
      aoi_id: clickedAoiListItem.aoi_id,
      aoi_name: clickedAoiListItem.aoi_name
    }

    store_projectAddAoi(newProjectAoi);

    // Exit aoi selection mode
    store_setAoiUIMode('default');

    // Load the AOI
    LoadAoi(clickedAoiListItem.aoi_id);
  }

  //-------------------------------------------------------------------------------
  // Cancel AOI selection.
  //-------------------------------------------------------------------------------
  const onCancel = () => 
  {
    // Exit aoi selection mode
    store_setAoiUIMode('default');
  }
  
  //-------------------------------------------------------------------------------
  // The filter text field has changed.
  //-------------------------------------------------------------------------------
  function OnFilterChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;
    setFilter(newValue);
  }

  //-------------------------------------------------------------------------------
  // Returns a list of AOIs, but takes the filter into account (if there is one).
  //-------------------------------------------------------------------------------
  function GetFilteredAOIList(): IAoiListItem[]
  {
    // If no filter is set, return the same AOI list
    if(!filter) return store_aoiList;

    const fixedFilter = filter.toLowerCase().trim();
    if(fixedFilter === '') return store_aoiList;

    const newList: IAoiListItem[] = [];

    for(let i=0; i < store_aoiList.length; i++)
      if(store_aoiList[i].aoi_name.toLowerCase().trim().includes(fixedFilter) && store_aoiList[i].aoi_name !== store_aoi?.aoi_name)
        newList.push(store_aoiList[i]);

    return newList;
  }

  //-------------------------------------------------------------------------------
  // Returns the total number of AOIs the user has, taking the active filter 
  // into account.
  //-------------------------------------------------------------------------------
  function GetFilteredAOICount(): number
  {
    return GetFilteredAOIList().length;
  }










  // Main render

  return (

    <Stack direction='column' sx={{ width: '100%', alignItems: 'center', mt: -1 }}>

      <Typography sx={{ mb: 0, fontSize: '0.9rem', color: theme_textColorBlended }}>
         Import an AOI from another project
      </Typography>

      {store_aoiList && store_aoiList.length === 0
      ?
        <Typography sx={{ m: 1, color: theme_textColorMain, opacity: 0.7 }}>
          No other AOIs found.
        </Typography>
        :null
      }

      <Stack sx={{ width: '100%' }}>

        {/* If there are more than 10 AOIs in the list, show a search/filter box */}

        {store_aoiList.length > 10 
          ?
            <CustomTextField name='aoi-selection-filter' variant='standard' size='small' autoComplete='off'
                              value={filter} onChange={OnFilterChanged} inputProps={{ maxLength: 30 }}
                              label=
                              {
                                <Stack direction='row' sx={{ alignItems: 'center' }}>
                                  <Typography sx={{ color: theme_limeGreen+'C0', fontSize: '0.8rem' }}>
                                    Search {store_aoiList.length} AOIs
                                  </Typography>
                                  {filter && filter.length > 0
                                    ?
                                      <Typography sx={{ fontSize: '0.8rem', color: theme_errorRed+'FF', ml: 1 }}>
                                        {GetFilteredAOICount()} {GetFilteredAOICount()===1?'match':'matches'}
                                      </Typography>
                                    :null
                                  }
                                </Stack>
                              }
                              sx={{ p:0, mt: 1, mb: 1.5, width: '100%' }}/>
          :null
        }

        <Stack sx={{ mt: 1, width: '100%', overflow: 'auto', maxHeight: '280px', alignItems: 'center' }}>

          {GetFilteredAOIList().map(aoiListItem =>
            <Stack key={aoiListItem.aoi_id} sx={{ width: '100%' }}>
              <Button key={aoiListItem.aoi_id} variant='outlined' size='small' fullWidth 
                      onClick={() => onAoiClicked(aoiListItem)}
                      sx={{ width: '95%', mt: '4px', mb: '4px', textTransform: 'none', 
                            color: theme_textColorMain, fontSize: '0.8rem', borderRadius: 100 }}>

                <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>
                  <TypographyWithAutoTooltip sx={{ fontSize: '0.9rem', maxWidth: '100%' }}>
                    {aoiListItem.aoi_name}
                  </TypographyWithAutoTooltip>

                  <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-evenly' }}>
                    {aoiListItem.acres
                      ?
                        <Typography sx={{ fontSize: '0.7rem', color: theme_orange, opacity: '0.8' }}>
                          {FriendlyNumber(aoiListItem.acres)} acres
                        </Typography>
                      :null
                    }
                    {aoiListItem.num_poly
                      ?
                        <Typography sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: '0.8'}}>
                          {aoiListItem.num_poly} section{aoiListItem.num_poly===1?'':'s'}
                        </Typography>
                      :null
                    }
                  </Stack>
                </Stack>

              </Button>
            </Stack>
          )}
        </Stack>

      </Stack>

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancel}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default AoiSelection;

