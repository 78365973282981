// A title bar for dialogs or other panels

import React from "react";
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


export interface DialogTitleProps
{
  children?: React.ReactNode;
  icon?: React.ReactNode;
  onClose: () => void;
  width: string;
}

export const DialogTitleBar = (props: DialogTitleProps) =>
{
  const { children, icon, onClose, width, ...other } = props;

  return (

    <DialogTitle sx={{ m: 0, p: 2, width:`${width}` }} {...other}>

      <Stack direction='column' sx={{ mr: 0 }}>

        <Stack direction='row'>
          <Box sx={{ width: '28px', height: '28px', mt: '2px', mr: '18px' }}>
            {icon}
          </Box>
          <Typography sx={{ fontSize: '1.6rem', mb: '5px', color: '#1C305A' }}>
            {children}
          </Typography>
        </Stack>

        <Box sx={{ width: '100%', height: '3px', borderRadius: 1, bgcolor: '#E7A44F' }}/>
      </Stack>

      {onClose ?
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute',
                    right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      : null}

    </DialogTitle>

  );
};