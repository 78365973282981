// Layer library item component

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { theme_bgColorMain, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { LAYER_LIBRARY_LAYERS_PER_ROW, LAYER_LIBRARY_LAYER_IMAGE_HEIGHT, LAYER_LIBRARY_LAYER_IMAGE_BORDER, CUSTOM_GROUP_PLACEHOLDER_IMAGE } from "./LayerLibraryItem";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ILayerLibraryGroup } from "./LayerLibraryInterfaces";
import { LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL } from "../Globals";



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerLibraryNewLayerItemProps
{
  layerLibraryGroup: ILayerLibraryGroup;
  OnShowCreateNewLayerWindow: any;
}

//-------------------------------------------------------------------------------
// Layer library item component
//-------------------------------------------------------------------------------
const LayerLibraryNewLayerItem = (props: LayerLibraryNewLayerItemProps) => 
{



  //-------------------------------------------------------------------------------
  // Create a new custom layer.
  //-------------------------------------------------------------------------------
  // const OnCreateLayer = () => 
  // {
  //   props.OnCreateNewLayer(props.layerLibraryGroup);
  // }












  // Main render

  return (

    <Stack sx={{ width: `calc(${100/LAYER_LIBRARY_LAYERS_PER_ROW}% - 20px)`, m: '10px' }}>

      <Stack sx={{ position: 'relative', borderRadius: 3, boxShadow: 4, 
                   bgcolor: '#606060',
                   width: '100%', height: LAYER_LIBRARY_LAYER_IMAGE_HEIGHT, justifyContent: 'center' }}>

        {/* Default background image for custom layers */}
        <Box component="img" alt="" src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(CUSTOM_GROUP_PLACEHOLDER_IMAGE)}
             sx={{ height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px`, 
                   borderRadius: 2.8, m: `${LAYER_LIBRARY_LAYER_IMAGE_BORDER}px`, opacity: 0.85 }}/>

        {/* Large circle "ADD" button */}
        <Stack sx={{ position: 'absolute', top:0, left:0,
                    height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px`, 
                   width: `calc(100% - ${LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px)`, 
                   borderRadius: 2.8, m: `${LAYER_LIBRARY_LAYER_IMAGE_BORDER}px`, 
                   justifyContent: 'center', alignItems: 'center' }}>
          <IconButton sx={{ bgcolor: theme_bgColorMain+'90', boxShadow: 8,
                            color: theme_textColorBlended+'90' }}
                      onClick={_=>props.OnShowCreateNewLayerWindow(props.layerLibraryGroup)}>
            <AddCircleOutlineIcon sx={{ width: '120px', height: '120px' }}/>
          </IconButton>
        </Stack>

      </Stack>

      <Typography sx={{ mt: '7px', fontSize: '1.3rem', color: theme_textColorBlended, textAlign: 'left' }}>
        Create a new custom layer...
      </Typography>

      <Typography sx={{ mt: '7px', fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.6, textAlign: 'left' }}>
        You can create a new custom layer by uploading your own shapefile.  All users in your organization will be able to see and use your new layer.
      </Typography>

    </Stack>
  )
}


export default LayerLibraryNewLayerItem;
