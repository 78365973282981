// NRR/impact info component

import { Link, Stack, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { DEV_MODE } from "../Globals";
import useStore from "../store";
import { theme_bgColorMain, theme_textColorBlended } from "../Theme";
import { INRR, INRRImpact, INRRImpactLink } from "./BundleInterfaces";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ImpactInfoPanelProps
{
  nrr: INRR;
  impact: INRRImpact;
}

//-------------------------------------------------------------------------------
// Impact info component
//-------------------------------------------------------------------------------
const ImpactInfoPanel = (props: ImpactInfoPanelProps) => 
{
  // Get needed state data from the store
  const { store_getNrrImpactLink, 
        } = useStore();




  //-------------------------------------------------------------------------------
  // Render the optional NRR-impact link info (if available).
  //-------------------------------------------------------------------------------
  function NrrImpactLinkPanel()
  {
    const nrrImpactLink: INRRImpactLink | undefined = store_getNrrImpactLink(props.nrr.nrr_id, props.impact.impact_id);
    if(!nrrImpactLink || !nrrImpactLink.description) return null;

    return (

      <Stack sx={{ mt: 2 }}>

        {/* <Typography sx={{ color: theme_bgColorMain, opacity: 1, fontSize: '0.9rem', mb: 1 }}>
          {props.nrr.name}
        </Typography> */}

        <Typography component='span' sx={{ color: theme_bgColorMain, opacity: 0.8, fontSize: '0.8rem' }}>
          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(nrrImpactLink.description.text)}} style={{whiteSpace: 'pre-line'}}/>
        </Typography>

        {nrrImpactLink.description.urls && nrrImpactLink.description.urls.length > 0
          ?
            <Stack sx={{ mt: 1 }}>
              {nrrImpactLink.description.urls.map(url => 
                <Link key={url} href={url} rel="noopener noreferrer" target="_blank" 
                      sx={{ mt: 0.4, fontSize: '0.6rem', color: '#255BF7' }}>
                  {url}
                </Link>
              )}
            </Stack>
          :null
        }

      </Stack>
    )
  }








  // There is no description info
  if(!props.nrr || !props.impact || !props.impact.description) return null;

  // Main render

  return (

    <Stack sx={{ mb: '-1px', mt: '-1px', mr: '-5px', ml: '-5px', p: 1, borderRadius: 1 }}>

      {/* Impact name */}

      <Stack direction='row' sx={{ alignItems: 'top', justifyContent: 'space-between' }}>
        <Typography sx={{ color: theme_bgColorMain, opacity: 1, fontWeight: 'bold', fontSize: '1rem', mb: 1 }}>
          {props.impact.name}
        </Typography>
          {DEV_MODE 
            ?
              <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem', opacity: 1, m: '5px' }}>
                id  {props.impact.impact_id}
              </Typography>
            :null
          }          
        </Stack>

      {/* Impact description text (can contain HTML tags) */}

      {props.impact.description.text
        ?
          <Typography component='span' sx={{ color: theme_bgColorMain, opacity: 0.8, fontSize: '0.8rem' }}>
            {/* NOTE: Even though this HTML comes from our own db, it is run through 
                      a sanitizer to strip it of all unsafe code/scripting just in case. */}
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.impact.description.text)}} style={{whiteSpace: 'pre-line'}}/>
          </Typography>
        :null
      }

      {/* Impact description urls */}

      {props.impact.description.urls && props.impact.description.urls.length > 0
        ?
          <Stack sx={{ mt: 1 }}>
            {props.impact.description.urls.map(url => 
              <Link key={url} href={url} rel="noopener noreferrer" target="_blank" 
                    sx={{ mt: 0.4, fontSize: '0.6rem', color: '#255BF7' }}>
                {url}
              </Link>
            )}
          </Stack>
        :null
      }

      {/* NRR-impact link description (if available) */}
      <NrrImpactLinkPanel/>

    </Stack>
  )
}

export default ImpactInfoPanel;



