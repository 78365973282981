// Edit layer info

import { Box, Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {  theme_bgColorLight1, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import { ILayer, ILayerDescription, ILayerDescriptionCitation } from '../../Layers/LayerInterfaces';
import React, { ChangeEvent, useState } from 'react';
import { CustomTextField } from './EditLayer';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ToastNotification } from "../../ToastNotifications";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { IImageDimensions, GetImageDimensionsForFile, LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL } from "../../Globals";
import useStore from "../../store";
import { UploadMapImageFile } from "../LayerLibraryOps";
import { CUSTOM_GROUP_PLACEHOLDER_IMAGE } from "../LayerLibraryItem";


// NOTE: There is a restriction on name length server-side (max 40 chars), and custom layers
//       get an automatic prefix like "c_orgnm_" of 8 characters, so 32 is the enfored max in the app.
export const LAYER_NAME_MAX_LENGTH = 32;

export const CITATION_MAX_COUNT = 5;
export const CITATION_MAX_URL_COUNT = 6;

export const SHORT_DESCRIPTION_MAX_LENGTH = 300;
export const LONG_DESCRIPTION_MAX_LENGTH = 3000;
export const TIME_PERIOD_MAX_LENGTH = 50;
export const SOURCE_URL_MAX_LENGTH = 500;
export const PUBLISHER_MAX_LENGTH = 100;
export const KEYWORDS_MAX_LENGTH = 300;
export const CITATION_TEXT_MAX_LENGTH = 1000;
export const CITATION_URL_MAX_LENGTH = 250;

const THUMBNAIL_IMAGE_S3_FILENAME_MAX_LENGTH = 256;
const THUMBNAIL_IMAGE_S3_UPLOAD_MAX_WIDTH = 800;
const THUMBNAIL_IMAGE_S3_UPLOAD_MAX_HEIGHT = 400;
const THUMBNAIL_IMAGE_S3_UPLOAD_MAX_SIZE_KB = 600;
const THUMBNAIL_IMAGE_S3_UPLOAD_MAX_SIZE_BYTES = THUMBNAIL_IMAGE_S3_UPLOAD_MAX_SIZE_KB*1024;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerInfoProps
{
  layer?: ILayer;
  newLayerName?: string;
  setNewLayerName: any;
  newLayerDescription?: ILayerDescription;
  setNewLayerDescription: any;
  setLayerNameWasChanged: any;
  setLayerDescriptionWasChanged: any;
}

//-------------------------------------------------------------------------------
// Edit layer info
//-------------------------------------------------------------------------------
export function EditLayerInfo(props: EditLayerInfoProps) 
{
  // Get needed state data from the store
  const { store_uploadingMapImage, 
        } = useStore();

  const [uploadFile, setUploadFile] = useState<File|undefined>(undefined);
  const [lockUploadFilenameEditing, setLockUploadFilenameEditing] = useState<boolean>(false);









  //-------------------------------------------------------------------------------
  // Disables Enter/newlines in certain multiline TextField elements.
  //-------------------------------------------------------------------------------
  function OnTextFieldKeyDown(event: React.KeyboardEvent<HTMLDivElement>) 
  {
    if (event.key === "Enter") 
      event.preventDefault();
  }

  //-------------------------------------------------------------------------------
  // The layer name has changed.
  //-------------------------------------------------------------------------------
  function OnLayerNameChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newName: string = event.target.value;

    // Set the new value
    props.setNewLayerName(newName);

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerNameWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The layer short description has changed.
  //-------------------------------------------------------------------------------
  function OnShortDescriptionChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, shortDescription: newValue });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The layer long description has changed.
  //-------------------------------------------------------------------------------
  function OnLongDescriptionChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, longDescription: newValue });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The layer time period has changed.
  //-------------------------------------------------------------------------------
  function OnTimePeriodChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, timePeriod: newValue });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }  

  //-------------------------------------------------------------------------------
  // The layer source URL has changed.
  //-------------------------------------------------------------------------------
  function OnSourceURLChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, sourceUrl: newValue });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }  

  //-------------------------------------------------------------------------------
  // The layer publisher has changed.
  //-------------------------------------------------------------------------------
  function OnPublisherChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, publisher: newValue });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }    

  //-------------------------------------------------------------------------------
  // The layer keywords has changed.
  //-------------------------------------------------------------------------------
  function OnKeywordsChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Set the new value
    props.setNewLayerDescription({...props.newLayerDescription, keywords: newValue, keywordsArray: undefined });

    // Tell calling code that the style was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A citation's text has changed.
  //-------------------------------------------------------------------------------
  function OnCitationTextChanged(citation: ILayerDescriptionCitation, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerDescription || !citation) return;

    const newValue: string = event.target.value;

    const updatedCitation: ILayerDescriptionCitation =
    {
      ...citation,
      text: newValue
    }

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: props.newLayerDescription.citations.map(entry => entry.id === citation.id ? updatedCitation : entry),
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A citation URL has changed.
  //-------------------------------------------------------------------------------
  function OnCitationURLChanged(citation: ILayerDescriptionCitation, urlIndex: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!props.newLayerDescription || !citation) return;

    const newValue: string = event.target.value;

    const updatedUrls: string[] = citation.urls;
    updatedUrls[urlIndex] = newValue;

    const updatedCitation: ILayerDescriptionCitation =
    {
      ...citation,
      urls: updatedUrls
    }

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: props.newLayerDescription.citations.map(entry => entry.id === citation.id ? updatedCitation : entry),
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Add a citation.
  //-------------------------------------------------------------------------------
  function OnAddCitationButton()
  {
    if(!props.newLayerDescription) return;

    if(props.newLayerDescription.citations.length >= CITATION_MAX_COUNT)
    {
      ToastNotification('error', `Each layer can have at most ${CITATION_MAX_COUNT} citations.`)
      return; 
    }
  
    // Get the max id

    let maxID: number = 0;
    props.newLayerDescription.citations.map(cit => 
    {
      if(cit && cit.id && cit.id > maxID)
        maxID = cit.id;
      return null;
    })
    
    // Create a new entry and add it

    const newEntry: ILayerDescriptionCitation = 
    {
      id: maxID+1,
      text: '',
      urls: []
    }

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: [...props.newLayerDescription.citations, newEntry]
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Add a citation URL.
  //-------------------------------------------------------------------------------
  function OnAddCitationURLButton(citation: ILayerDescriptionCitation)
  {
    if(!props.newLayerDescription || !citation) return;

    if(citation.urls.length >= CITATION_MAX_URL_COUNT)
    {
      ToastNotification('error', `Each citation can have at most ${CITATION_MAX_URL_COUNT} URLs.`)
      return; 
    }

    // Create a new citation

    const updatedCitation: ILayerDescriptionCitation =
    {
      ...citation,
      urls: [...citation.urls, '']
    }

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: props.newLayerDescription.citations.map(entry => entry.id === citation.id ? updatedCitation : entry),
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A citation is being deleted.
  //-------------------------------------------------------------------------------
  function OnDeleteCitation(citation: ILayerDescriptionCitation)
  {
    if(!props.newLayerDescription) return;

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: props.newLayerDescription.citations.filter(entry => entry.id !== citation.id)
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A citation URL is being deleted.
  //-------------------------------------------------------------------------------
  function OnDeleteCitationURL(citation: ILayerDescriptionCitation, citationUrlIndex: number)
  {
    if(!props.newLayerDescription) return;

    const updatedCitation: ILayerDescriptionCitation =
    {
      ...citation,
      urls: citation.urls.filter((entry,index) => index !== citationUrlIndex)
    }

    const updatedDescription: ILayerDescription =
    {
      ...props.newLayerDescription,
      citations: props.newLayerDescription.citations.map(entry => entry.id === citation.id ? updatedCitation : entry),
    }

    // Update the layer description
    props.setNewLayerDescription(updatedDescription);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The image S3 filename text box has changed.
  //-------------------------------------------------------------------------------
  function OnImageS3Changed(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Update the layer description
    props.setNewLayerDescription({...props.newLayerDescription, mapImage: newValue });

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a map image file to upload.
  //-------------------------------------------------------------------------------
  const OnUploadFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!event.target.files || event.target.files.length === 0) return;

    const file: File = event.target.files[0];
    if(!file) return;

    if(!file.name) return;  // If the user cancels

    // Restrict images to a certain size

    if(file.size > THUMBNAIL_IMAGE_S3_UPLOAD_MAX_SIZE_BYTES)
    {
      ToastNotification('error', `The file you selected is too large (over ${THUMBNAIL_IMAGE_S3_UPLOAD_MAX_SIZE_KB} KB)`);
      return;
    }

    // Restrict image to a certain width and height

    const dimensions: IImageDimensions = await GetImageDimensionsForFile(file);
    if(!dimensions)
    {
      ToastNotification('error', `Unable to read image dimensions`);
      return;
    }

    if(dimensions.width > THUMBNAIL_IMAGE_S3_UPLOAD_MAX_WIDTH)
    {
      ToastNotification('error', `The image's width exceeds the max (${THUMBNAIL_IMAGE_S3_UPLOAD_MAX_WIDTH})`);
      return;
    }

    if(dimensions.height > THUMBNAIL_IMAGE_S3_UPLOAD_MAX_HEIGHT)
    {
      ToastNotification('error', `The image's height exceeds the max (${THUMBNAIL_IMAGE_S3_UPLOAD_MAX_HEIGHT})`);
      return;
    }

    // The filename text box cannot be manually edited any more
    setLockUploadFilenameEditing(true);

    setUploadFile(file);
  
    // Update the legend entry
    props.setNewLayerDescription({...props.newLayerDescription, mapImage: file.name });

    // Tell calling code that the description was changed (so it knows to save it)
    //props.setLayerDescriptionWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Begin the map image upload.
  //-------------------------------------------------------------------------------  
  async function OnUploadMapImage()
  {
    if(!props.newLayerDescription) return;

    // Verify input

    // if(!props.newLayerDescription.mapImage || props.newLayerDescription.mapImage.trim().length === 0)
    // {
    //   ToastNotification('error', 'The image filename cannot be empty');
    //   return;
    // }

    if(!uploadFile)
    {
      ToastNotification('error', 'Please select a local image file to upload');
      return;
    }
  
    // Upload the image file

    const newFilename: string | undefined = await UploadMapImageFile(uploadFile, props.layer);
    if(!newFilename)
      return;

    // Update the legend entry
    props.setNewLayerDescription({...props.newLayerDescription, mapImage: newFilename });

    // Clear the file set for upload
    setUploadFile(undefined);

    // Tell calling code that the description was changed (so it knows to save it)
    props.setLayerDescriptionWasChanged(true);
  }





  





  if(!props.layer) return null;

  // Main render

  return (

    <Stack>

      {/* Layer type */}

      <Stack direction='row' sx={{ alignItems: 'center' }}>
        <Typography sx={{ fontSize: '1.1rem', color: theme_textColorMain, opacity: '0.6' }}>
          Layer Type:
        </Typography>
        <Typography sx={{ ml: 1, fontSize: '1.3rem', color: theme_textColorBlended, opacity: 1, fontWeight: 'bold', textTransform: 'capitalize' }}>
          {props.layer.geoserverSourceType}
        </Typography>
        <Typography sx={{ ml: 1, fontSize: '1.3rem', color: theme_orange, opacity: 0.8, fontWeight: 'normal', textTransform: 'capitalize' }}>
          {props.layer.geoserverVectorType}
        </Typography>
      </Stack>

      {/* Layer Name */}

      <CustomTextField name='layerName' variant='standard' size='small' 
                       value={props.newLayerName} onChange={OnLayerNameChanged}
                        autoComplete='off' inputProps={{ maxLength: LAYER_NAME_MAX_LENGTH }}
                        label=
                        {
                          <Stack direction='row' sx={{ alignItems: 'center' }}>
                            <Typography sx={{ color: theme_textColorBlended }}>
                              Layer Name   
                            </Typography>
                            <Typography sx={{ ml: 1, color: theme_errorRed, opacity: 0.8, fontSize: '0.8rem' }}>
                              (required)
                            </Typography>
                          </Stack>
                        }
                        sx={{ mt: 4, p: 0 }}/>

      {/* Short Description */}

      <CustomTextField name='shortDescription' multiline variant='standard' size='small' maxRows={3} autoComplete='off'
                       value={props.newLayerDescription?.shortDescription} onChange={OnShortDescriptionChanged}
                       onKeyDown={OnTextFieldKeyDown} 
                       inputProps={{ maxLength: SHORT_DESCRIPTION_MAX_LENGTH }}
                       label=
                       {
                         <Stack direction='row' sx={{ alignItems: 'center' }}>
                           <Typography sx={{ color: theme_textColorBlended }}>
                             Description
                           </Typography>
                           <Typography sx={{ ml: 1, color: theme_errorRed, opacity: 0.8, fontSize: '0.8rem' }}>
                             (required)
                           </Typography>
                         </Stack>
                       }
                       sx={{ p:0, mt: 2 }}/>

      <Stack direction='row'>

        {/* Publisher */}

        <CustomTextField name='publisher' variant='standard' size='small' autoComplete='off'
                        value={props.newLayerDescription?.publisher} onChange={OnPublisherChanged}
                        inputProps={{ maxLength: PUBLISHER_MAX_LENGTH }}
                        label={<Typography sx={{ color: theme_textColorBlended }}>Publisher</Typography>}
                        sx={{ p:0, mt: 2, width: '60%' }}/>

        {/* Time Period */}

        <CustomTextField name='timePeriod' variant='standard' size='small' autoComplete='off'
                          value={props.newLayerDescription?.timePeriod} onChange={OnTimePeriodChanged}
                          inputProps={{ maxLength: TIME_PERIOD_MAX_LENGTH }}
                          label={<Typography sx={{ color: theme_textColorBlended }}>Time Period</Typography>}
                          sx={{ ml:4, p:0, mt: 2, width: '40%' }}/>
      </Stack>

      {/* Long Description */}

      <CustomTextField name='longDescription' multiline variant='standard' size='small' maxRows={6} autoComplete='off'
                       value={props.newLayerDescription?.longDescription} onChange={OnLongDescriptionChanged}
                       inputProps={{ maxLength: LONG_DESCRIPTION_MAX_LENGTH }}
                       label={<Typography sx={{ color: theme_textColorBlended }}>Layer Details</Typography>}
                       sx={{ p:0, mt: 2 }}/>

      {/* Source URL */}

      <CustomTextField name='sourceURL' variant='standard' size='small' autoComplete='off'
                       value={props.newLayerDescription?.sourceUrl} onChange={OnSourceURLChanged}
                       inputProps={{ maxLength: SOURCE_URL_MAX_LENGTH }}
                       label={<Typography sx={{ color: theme_textColorBlended }}>Source URL</Typography>}
                       sx={{ p:0, mt: 2 }}/>

      {/* Keywords */}

      <CustomTextField name='keywords' variant='standard' size='small' autoComplete='off'
                       value={props.newLayerDescription?.keywords} onChange={OnKeywordsChanged}
                       inputProps={{ maxLength: KEYWORDS_MAX_LENGTH }}
                       label=
                       {
                         <Stack direction='row' sx={{ alignItems: 'center' }}>
                           <Typography sx={{ color: theme_textColorBlended }}>
                             Search Keywords
                           </Typography>
                           <Typography sx={{ ml: 1, color: theme_textColorMain, opacity: 0.5, fontSize: '0.7rem' }}>
                             (separated by commas)
                           </Typography>
                         </Stack>
                       }
                       sx={{ p:0, mt: 2 }}/>

      {/* Citations */}

      {props.newLayerDescription?.citations.map(function(citation, citationIndex)
      {
        return (
          
          <Stack key={citation.id} sx={{ mt: 2, bgcolor: theme_textColorMain+'15', p: 1, borderRadius: 2 }}>

            {/* Citation text + delete citation button */}

            <Stack direction='row' sx={{ width: '100%' }}>
              {/* Citation text */}
              <CustomTextField multiline variant='standard' size='small' maxRows={4} autoComplete='off'
                               value={citation.text} onChange={(e)=>OnCitationTextChanged(citation,e)}
                               inputProps={{ maxLength: CITATION_TEXT_MAX_LENGTH }}
                               label={<Typography sx={{ color: theme_textColorBlended }}>Citation {citationIndex+1}</Typography>}
                               sx={{ width: '100%', p:0, mt: 0 }}/>
              {/* Delete citation button */}
              <Tooltip title='Delete this citation' arrow placement='right'>
                <IconButton sx={{ ml: 0, p: 0.8 }} onClick={(_)=>OnDeleteCitation(citation)}>
                  <DeleteForeverIcon sx={{ color: theme_textColorBlended}}/>
                </IconButton>
              </Tooltip>
            </Stack>

            {citation.urls.map(function(citationURL,urlIndex)
            {
              return (

                // Citation URL + delete URL button

                <Stack key={citationURL} direction='row' sx={{ width: '100%' }}>
                  {/* Citation URL */}
                  <CustomTextField variant='standard' size='small' autoComplete='off'
                                   value={citationURL} onChange={(e)=>OnCitationURLChanged(citation,urlIndex,e)}
                                   inputProps={{ maxLength: CITATION_URL_MAX_LENGTH, 
                                                 style: {fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.6} }}
                                   label={<Typography sx={{ color: theme_textColorBlended, fontSize: '0.8rem' }}>URL {urlIndex+1}</Typography>}
                                   sx={{ width: '100%', p:0, mt: 1, ml: 3 }}/>
                  {/* Delete citation button */}
                  <Tooltip title='Delete this citation link' arrow placement='right'>
                    <IconButton sx={{ p: 0.8 }} onClick={(_)=>OnDeleteCitationURL(citation,urlIndex)}>
                      <DeleteForeverIcon sx={{ color: theme_textColorBlended, opacity: 0.8, width: '18px', height: '18px' }}/>
                    </IconButton>
                  </Tooltip>
                </Stack>
              )
            })}

            {/* Add new citation URL button */}

            <Stack sx={{ mt: 1, mr: '32px', alignItems: 'end' }}>
              <Button variant='outlined' sx={{ width: '140px', p:0, textTransform: 'none', fontSize: '0.7rem', opacity: 0.8 }}
                      onClick={(_)=>OnAddCitationURLButton(citation)}>
                Add Citation Link (URL)
              </Button>
            </Stack>

          </Stack>
        )

      })}

      {/* Add new citation button */}

      <Stack sx={{ mt: 2, width: '100%', alignItems: 'center' }}>
        <Button variant='outlined' sx={{ width: '35%', textTransform: 'none' }}
                onClick={(_)=>OnAddCitationButton()}>
          Add Citation
        </Button>
      </Stack>

      {/* Layer thumbnail image upload UI */}
          
      <Stack sx={{ mt: 4, width: '100%', alignItems:'left' }}>

        <Typography sx={{ width: '100%', fontSize: '1.1rem', color: theme_textColorBlended}}>
          Thumbnail Image
        </Typography>

        <Stack sx={{ mt: 2, borderRadius: 3, boxShadow: 4, bgcolor: theme_bgColorLight1, 
                     justifyContent: 'right', width: '40%' }}>

          {props.newLayerDescription && props.newLayerDescription.mapImage && props.newLayerDescription.mapImage.length > 0
            ?
              <Box component="img" alt="" 
                   src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(props.newLayerDescription.mapImage)}
                   sx={{ m: '3px', borderRadius: 2.8 }}/>
            :
              <Box component="img" alt="" 
                   src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(CUSTOM_GROUP_PLACEHOLDER_IMAGE)}
                   sx={{ m: '3px', borderRadius: 2.8 }}/>
          }

        </Stack>

        {/* Image S3 filename */}

        <CustomTextField name='image_s3' variant='standard' size='small' autoComplete='off'
                         disabled={lockUploadFilenameEditing}
                         value={props.newLayerDescription?.mapImage} onChange={OnImageS3Changed}
                         inputProps={{ maxLength: THUMBNAIL_IMAGE_S3_FILENAME_MAX_LENGTH }}
                         label={<Typography sx={{ color: theme_textColorBlended }}>Image Filename</Typography>}
                         sx={{ p: 0, ml: 0, mt: 2, width: '100%' }}/>

        <Stack direction='row' sx={{ mt: 2, width: '100%', justifyContent: 'center' }}>

          <label htmlFor="upload-map-image">
            <input style={{ display: 'none' }} id="upload-map-image" name="upload-map-image" type="file" 
                    onChange={OnUploadFileSelected} accept="image/png, image/jpeg"/>
            <Button variant='text' component="span"
                    sx={{ px: 2.5, color: theme_limeGreen, bgcolor: theme_limeGreen+'20', fontSize : '1.1rem', alignItems: 'center', textTransform: 'none' }}>
              <DriveFolderUploadIcon sx={{ color: theme_limeGreen, width: '36px', height: '36px', mr: '8px' }}/>
              Select a local image file to upload
            </Button>
          </label>

          <Button variant='contained' onClick={(_)=>OnUploadMapImage()} 
                  disabled={store_uploadingMapImage === true}
                  sx={{ width: '120px', fontWeight: 'bold', ml: 2 }}>
            Upload
          </Button>
          
        </Stack>

      </Stack>

    </Stack>
  )
}





//-------------------------------------------------------------------------------
// Validate layer description.
// Returns an error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
export function ValidateLayerDescription(newLayerDescription: ILayerDescription|undefined): string
{
  if(!newLayerDescription) return 'Invalid layer description';

  let errStr: string | undefined = ValidateShortDescription(newLayerDescription.shortDescription);
  if(errStr !== '') return errStr;

  errStr = ValidateLongDescription(newLayerDescription.longDescription);
  if(errStr !== '') return errStr;

  errStr = ValidateTimePeriod(newLayerDescription.timePeriod);
  if(errStr !== '') return errStr;

  errStr = ValidateSourceURL(newLayerDescription.sourceUrl);
  if(errStr !== '') return errStr;

  errStr = ValidatePublisher(newLayerDescription.publisher);
  if(errStr !== '') return errStr;

  errStr = ValidateCitations(newLayerDescription.citations);
  if(errStr !== '') return errStr;

  errStr = ValidateKeywords(newLayerDescription.keywords);
  if(errStr !== '') return errStr;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified layer name.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
export function ValidateLayerName(value: string|undefined): string
{
  if(!value || value.length === 0)
    return 'The layer name cannot be empty';

  if(value.length < 3 || value.length > LAYER_NAME_MAX_LENGTH)
    return `The layer name must be between 3 and ${LAYER_NAME_MAX_LENGTH} characters long`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified short description.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateShortDescription(value: string | undefined): string
{
  if(!value || value.length === 0)
    return 'The description cannot be empty';

  if(value.length > SHORT_DESCRIPTION_MAX_LENGTH)
    return `The descripton cannot exceed ${SHORT_DESCRIPTION_MAX_LENGTH} characters`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified long description.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateLongDescription(value: string | undefined): string
{
  if(!value) return '';

  if(value.length > LONG_DESCRIPTION_MAX_LENGTH)
    return `The layer details text cannot exceed ${LONG_DESCRIPTION_MAX_LENGTH} characters`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified value.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateTimePeriod(value: string | undefined): string
{
  if(!value) return '';

  if(value.length > TIME_PERIOD_MAX_LENGTH)
    return `The time period cannot exceed ${TIME_PERIOD_MAX_LENGTH} characters`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified value.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateCitations(citations: ILayerDescriptionCitation[]): string
{
  if(!citations) return '';

  for(let i=0; i < citations.length; i++)
  {
    if(!citations[i].text || citations[i].text.trim().length === 0)
      return 'Citation text cannot be empty';

    if(citations[i].text.trim().length > CITATION_TEXT_MAX_LENGTH)
      return `Citation text cannot exceed ${CITATION_TEXT_MAX_LENGTH} characters`;

    for(let j=0; j < citations[i].urls.length; j++)
    {
      if(!citations[i].urls[j] || citations[i].urls[j].trim().length === 0)
        return 'Citation URL cannot be empty';
  
      if(citations[i].urls[j].trim().length > CITATION_URL_MAX_LENGTH)
        return `The citation URL cannot exceed ${CITATION_URL_MAX_LENGTH} characters`;
    }
  }

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified value.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateSourceURL(value: string | undefined): string
{
  if(!value) return '';

  if(value.length > SOURCE_URL_MAX_LENGTH)
    return `The source URL cannot exceed ${SOURCE_URL_MAX_LENGTH} characters`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified value.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidatePublisher(value: string | undefined): string
{
  if(!value) return '';

  if(value.length > PUBLISHER_MAX_LENGTH)
    return `The publisher text cannot exceed ${PUBLISHER_MAX_LENGTH} characters`;

  // Validated
  return '';
}

//-------------------------------------------------------------------------------
// Validate the specified value.
// Returns the error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
function ValidateKeywords(value: string | undefined): string
{
  if(!value) return '';

  if(value.length > KEYWORDS_MAX_LENGTH)
    return `The keywords text cannot exceed ${KEYWORDS_MAX_LENGTH} characters`;

  // Validated
  return '';
}  
  