// API debug info component

import { Accordion, AccordionDetails, Button, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { styled } from "@mui/material";
import { MapToggleButton } from "./CustomMuiStyles";
import useStore from "./store";
import { theme_errorRed, theme_limeGreen, theme_orange, theme_textColorMain } from "./Theme";
import BugReportIcon from '@mui/icons-material/BugReport';
import { DEV_MODE, FriendlyFileSize_Short, FriendlyNumber, FriendlyTimeSpan, FriendlyTimeSpan_Short } from "./Globals";
import { useState } from "react";
import React from "react";
import { JsonViewer, NamedColorspace } from "@textea/json-viewer";
import MyCircularProgress from "./Components/CircularProgress";
import { getReasonPhrase } from "http-status-codes";
import { OutputAllLayersToClipboard } from "./Map/MapOps";


export const API_DEBUG_MAX_ENTRIES = 10;

const ACCORDION_WIDTH = '280px';
const OUTPUT_VIEWER_WIDTH = '400px'; 
const VIEWER_MIN_HEIGHT = '20px';
const VIEWER_MAX_HEIGHT = '400px';

const MAIN_BG_COLOR = '#212121';
const MAIN_BORDER_COLOR = '#454545';
const ACCORDION_SUMMARY_BG_COLOR = '#424242';

const customJsonViewerTheme: NamedColorspace = 
{
  scheme: 'Custom',
  author: 'aturta',
  base00: '#20211C',  // background
  base01: '#343d46',  // ?
  base02: '#3A3A3A',  // tree lines, null value background
  base03: '#65737e',  // ?
  base04: '#a7adba',  // item count
  base05: '#c0c5ce',  // ?
  base06: '#dfe1e8',  // ?
  base07: '#eff1f5',  // color for: keys, brackets, collapse/expand arrows, copy icon, "hidden X items" text
  base08: '#C25B5B',  // value: NULL
  base09: '#FD9721',  // value: string
  base0A: '#ebcb8b',  // ?
  base0B: '#A6E22E',  // value: float
  base0C: '#63D3E8',  // array index value
  base0D: '#8fa1b3',  // ?
  base0E: '#AC7CE1',  // value: boolean
  base0F: '#A6E22E'   // value: number
}

type APIDebugStatus = 'running' | 'success' | 'error';

export interface IAPIDebugData
{
  id: number;
  httpMethod: string;
  module: string;
  start: Date;
  end?: Date;
  status: APIDebugStatus;
  totalTimeMS: number;
  url?: string;
  responseStatus?: number;
  error?: string;
  size?: number;
  inputJSON?: any;
  outputJSON?: any;
  log_guid?: string;
  stats?: IAPIDebugStats
}

export interface IAPIDebugStats
{
  events: IAPIDebugStatsEvent[];
}

export interface IAPIDebugStatsEvent
{
  step: string;
  time: number;
  duration: number;
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface APIDebugInfoProps
{
}

//-------------------------------------------------------------------------------
// BundAPI debug info component
//-------------------------------------------------------------------------------
const APIDebugInfo = (props: APIDebugInfoProps) => 
{
  //const theme = useTheme();

  // Get needed state data from the store
   const { store_APIDebugMode, store_setAPIDebugMode, store_APIDebugData, 
           store_setAPIDebugData, store_isLoggedIn, 
         } = useStore();

  const [activeAccordionItem, setActiveAccordionItem] = useState<number|undefined>();
  const [activeViewMode, setActiveViewMode] = useState<'input'|'output'|'error'|'stats'>('output');
  


  //-------------------------------------------------------------------------------
  // The debug panel toggle button was clicked.
  //-------------------------------------------------------------------------------
  const onDebugPanelButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => 
  {
    store_setAPIDebugMode(!store_APIDebugMode);
  }

  //-------------------------------------------------------------------------------
  // Returns the total MS for the specified stats.
  //-------------------------------------------------------------------------------
  function GetStatsTotalMS(events: IAPIDebugStatsEvent[])
  {
    let totalMS = 0;
    for(let i=0; i < events.length; i++)
      totalMS += events[i].duration;

    return totalMS;
  }

  //-------------------------------------------------------------------------------
  // Clear all events.
  //-------------------------------------------------------------------------------
  const onClear = () => 
  {
    store_setAPIDebugData([]);
  }

  //-------------------------------------------------------------------------------
  // Accordion change.
  //-------------------------------------------------------------------------------
  const onAccordionChange = (item: number | undefined) => (event: React.SyntheticEvent, isExpanded: boolean) => 
  {
    setActiveAccordionItem(isExpanded ? item : undefined);
  }

  //-------------------------------------------------------------------------------
  // Accordion change.
  //-------------------------------------------------------------------------------
  const onClickViewMode = (value: any) => 
  {
    setActiveViewMode(value);
  }





  // Only usable in DEV mode
  if(!store_isLoggedIn || !DEV_MODE) return null;

  // Main render

  return (

    <Stack sx={{ position: 'absolute', right: 220, top: 10 }}>

      {/* Toggle button */}

      <Stack sx={{ alignItems: 'end' }}>
        <MapToggleButton sx={{ borderRadius: 20, p: 0.3, pl: 0.5, pr: 0.5, border: 1, boxShadow: 3 }}
                         value='apiDebugToggleButton' selected={store_APIDebugMode} 
                         onChange={onDebugPanelButtonClick}>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <BugReportIcon sx={{ width: '28px', height: '28x' }}/>
          </Stack>

        </MapToggleButton>
      </Stack>

      {/* API debug UI (only when in api debug mode) */}

      {store_APIDebugMode 
        ?
          <Stack sx={{ bgcolor: MAIN_BORDER_COLOR, borderRadius: 1, mt: 1, boxShadow: 3 }}>
            <Stack direction='column' sx={{ bgcolor: MAIN_BG_COLOR, borderRadius: 1, m: '2px', p: '7px', alignItems: 'center' }}>

              <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: '0.9rem', color: theme_textColorMain, opacity: 0.7, mb: 0.8 }}>
                  API Debug Data
                </Typography>
                
                <Stack direction='row'>

                  <Tooltip title='Copy a complete list of current Mapbox layers to the clipboard'>
                    <Button variant='outlined' onClick={(_)=>OutputAllLayersToClipboard()} 
                            sx={{ mr: '5px', p: 0, width: '48px', minWidth: '40px', height: '20px', minHeight: '20px', ml: 1 }}>
                      <Typography sx={{ color: theme_textColorMain, opacity: 0.7, textTransform: 'none', fontSize: '0.7rem' }}>
                        Layers
                      </Typography>
                    </Button>
                  </Tooltip>
                  
                  <Button variant='outlined' onClick={(_)=>onClear()} 
                          sx={{ p: 0, width: '40px', minWidth: '40px', height: '20px', minHeight: '20px', ml: 1 }}>
                    <Typography sx={{ color: theme_textColorMain, opacity: 0.7, textTransform: 'none', fontSize: '0.7rem' }}>
                      Clear
                    </Typography>
                  </Button>

                </Stack>

              </Stack>

              <Stack>

                {store_APIDebugData.map(debugDataItem =>

                  <Accordion key={debugDataItem.id} expanded={activeAccordionItem===debugDataItem.id} disableGutters={true}
                             onChange={onAccordionChange(debugDataItem.id)}
                             sx={{ width: ACCORDION_WIDTH, m:0, p:0, background: MAIN_BG_COLOR }}>

                    {/* ACCORDION SUMMARY */}

                    <AccordionSummary expandIcon={null}
                                      sx={{ width: '100%', p: 0, pl:0.5, pr:0.5, bgcolor: ACCORDION_SUMMARY_BG_COLOR }}>

                      <Stack direction='row' sx={{ width: '100%', justifyContent: 'none', alignItems: 'center' }}>

                        <Typography sx={{ width: '35px', fontSize: '0.7rem', color: theme_limeGreen, opacity: 0.8 }}>
                          {debugDataItem.httpMethod}
                        </Typography>

                        <Typography sx={{ width: '120px', maxWidth: '120px', overflow: 'none', 
                                          fontSize: '0.7rem', color: theme_orange, opacity: 0.8 }}>
                          {debugDataItem.module}
                        </Typography>

                        <Typography sx={{ width: '40px', fontSize: '0.7rem', color: theme_textColorMain, opacity: debugDataItem.totalTimeMS > 2000 ? 0.6 : 0.3, textAlign: 'right' }}>
                          {debugDataItem.end 
                            ? 
                              FriendlyTimeSpan_Short(debugDataItem.totalTimeMS)
                            :  
                              FriendlyTimeSpan_Short(Date.now() - debugDataItem.start.getTime())
                          }
                        </Typography>

                        <Typography sx={{ width: '55px', fontSize: '0.6rem', color: '#84ffff', textAlign: 'right', 
                                          opacity: debugDataItem.size && debugDataItem.size >= 2000 ? 0.6 : 0.35 }}>
                          {FriendlyFileSize_Short(debugDataItem.size)}
                        </Typography>

                        {debugDataItem.status === 'running'
                          ?
                            <Stack sx={{ width: '25px', alignItems: 'end'}}>
                              <MyCircularProgress circleSize={10}/>
                            </Stack>
                          :null
                        }
                        {debugDataItem.status === 'error'
                          ? 
                            <Typography sx={{ width: '25px', fontSize: '0.7rem', color: '#f44336', fontWeight: 'bold', textAlign: 'right' }}>
                              ERR
                            </Typography>                  
                          :null
                        }
                        {debugDataItem.status === 'success'
                          ? 
                            <Typography sx={{ width: '25px',fontSize: '0.7rem', color: '#81c784', textAlign: 'right' }}>
                              ok
                            </Typography>
                          :null
                        }

                      </Stack>

                    </AccordionSummary>

                    {/* ACCORDION CONTENT */}

                    <AccordionDetails sx={{ p: 0.4, bgcolor: '#303030', borderRadius: 1 }}>

                      <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>

                        {/* View mode toggle buttons */}

                        <ToggleButtonGroup size='small' value={activeViewMode}>
                          <ViewModeToggleButton value='input' onClick={(e,v)=>onClickViewMode(v)}>
                            Input
                          </ViewModeToggleButton>
                          <ViewModeToggleButton value='output' onClick={(e,v)=>onClickViewMode(v)}>
                            Output
                          </ViewModeToggleButton>
                          <ViewModeToggleButton value='error' onClick={(e,v)=>onClickViewMode(v)}>
                            Response
                          </ViewModeToggleButton>
                          <ViewModeToggleButton value='stats' onClick={(e,v)=>onClickViewMode(v)}>
                            Stats
                          </ViewModeToggleButton>
                        </ToggleButtonGroup>

                        {/* Start time */}

                        {/* <Typography sx={{ fontSize: '0.6rem', color: theme_textColorMain, opacity: 0.45 }}>
                          {debugDataItem.start.toLocaleTimeString()}
                        </Typography> */}

                      </Stack>

                      {/* Data viewer */}

                      {/* Input parameters JSON viewer */}

                      {activeViewMode==='input'
                        ?
                          <Stack sx={{ mt: 0.5, minHeight: VIEWER_MIN_HEIGHT, maxHeight: VIEWER_MAX_HEIGHT, width: '100%', overflow: 'auto',
                                      '&::-webkit-scrollbar-track': { background: "#303030" },
                                      '&::-webkit-scrollbar-thumb': { backgroundColor: '#5E5E5E' },
                                      '&::-webkit-scrollbar-thumb:hover': { background: '#919191' },
                                    }}>
                            <JsonViewer value={debugDataItem.inputJSON}
                                          theme={customJsonViewerTheme}
                                          //theme='dark'
                                          editable={false}
                                          indentWidth={2}
                                          displayDataTypes={false}
                                          displaySize={true}
                                          maxDisplayLength={5}
                                          collapseStringsAfterLength={25}
                                          defaultInspectDepth={2}
                                          style={{ fontSize: '0.7rem' }}
                                          />
                          </Stack>
                        :null
                      }

                      {/* Output JSON viewer */}

                      {activeViewMode==='output'
                        ?
                          <Stack sx={{ mt: 0.5, bgcolor: '#303030', p: '3px', borderRadius: 1, width: OUTPUT_VIEWER_WIDTH }}>
                            <Stack sx={{ minHeight: VIEWER_MIN_HEIGHT, maxHeight: VIEWER_MAX_HEIGHT, overflow: 'auto',
                                        '&::-webkit-scrollbar-track': { background: "#303030" },
                                        '&::-webkit-scrollbar-thumb': { backgroundColor: '#5E5E5E' },
                                        '&::-webkit-scrollbar-thumb:hover': { background: '#919191' },
                                        '&::-webkit-scrollbar': { width: '0.5em' },
                                      }}>
                              <JsonViewer value={debugDataItem.outputJSON}
                                          //theme='dark'
                                          theme={customJsonViewerTheme}
                                          editable={false}
                                          indentWidth={2}
                                          displayDataTypes={false}
                                          displaySize={true}
                                          maxDisplayLength={5}
                                          collapseStringsAfterLength={25}
                                          defaultInspectDepth={2}
                                          style={{ fontSize: '0.7rem' }}
                                          />
                            </Stack>
                          </Stack>
                        :null
                      }

                      {activeViewMode==='error' && debugDataItem.responseStatus
                        ?
                          <Stack direction='column' sx={{ mt: 1, minHeight: VIEWER_MIN_HEIGHT, maxHeight: VIEWER_MAX_HEIGHT,
                                                          width: '100%', alignItems: 'center' }}>
                            <Typography sx={{ width: '100%', textAlign: 'left', mb: 1, color: debugDataItem.responseStatus===200?'#6fbf73':'#f6685e', opacity: 0.8, fontSize: '0.8rem' }}>
                              <b>{debugDataItem.responseStatus} {getReasonPhrase(debugDataItem.responseStatus)}</b>
                            </Typography>
                            <Typography sx={{ width: '100%', textAlign: 'left', mb: 1, color: theme_textColorMain, opacity: 0.6, fontSize: '0.7rem', textTransform: 'capitalize' }}>
                              {debugDataItem.error}
                            </Typography>
                          </Stack>
                        :null
                      }

                      {/* Stats viewer */}

                      {activeViewMode==='stats'
                        ?
                          <Stack direction='column' sx={{ mt: 1, minHeight: VIEWER_MIN_HEIGHT, maxHeight: VIEWER_MAX_HEIGHT,
                                                          width: '100%', alignItems: 'center' }}>

                            {debugDataItem.log_guid
                              ?
                                <Typography sx={{ width: '100%', textAlign: 'left', mb: 1, color: theme_textColorMain, opacity: 0.4, fontSize: '0.7rem' }}>
                                  log_guid:<br></br>{debugDataItem.log_guid}
                                </Typography>
                              :null
                            }

                            {debugDataItem.stats?.events.map(event =>

                              <Stack key={Math.random()} direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>

                                {event.step && event.step.toLowerCase().indexOf('error') >= 0
                                  ?
                                    <Typography sx={{ color: theme_errorRed, opacity: 1, fontSize: '0.6rem', width: '100%' }}>
                                      {event.step}
                                    </Typography>
                                  :
                                    <Typography sx={{ color: theme_textColorMain, opacity: 0.5, fontSize: '0.6rem', width: '100%' }}>
                                      {event.step}
                                    </Typography>
                                }
                                <Typography sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '0.6rem', width: '90px', textAlign: 'right'}}>
                                  {FriendlyNumber(event.duration)} ms
                                </Typography>

                                {debugDataItem.stats && event.duration / GetStatsTotalMS(debugDataItem.stats.events)  * 100 >= 5 
                                  ?
                                    <Typography sx={{ color: '#fdae61', opacity: 1, fontSize: '0.6rem', width: '40px', textAlign: 'right' }}>
                                      {FriendlyNumber(event.duration / GetStatsTotalMS(debugDataItem.stats.events)  * 100.0) }%
                                    </Typography>
                                  :
                                    <Typography sx={{ color: '#fdae61', opacity: 1, fontSize: '0.6rem', width: '40px', textAlign: 'right' }}>
                                    </Typography>
                                }

                              </Stack>
                            )}

                            <Typography sx={{ mt: 1, color: theme_limeGreen, fontSize: '0.6rem', textAlign: 'center' }}>
                              Total: {debugDataItem.stats ? FriendlyTimeSpan(GetStatsTotalMS(debugDataItem.stats.events)) : '--'}
                            </Typography>

                          </Stack>
                        :null
                      }
                      
                    </AccordionDetails>
                  </Accordion>
                )}

              </Stack>

            </Stack>
          </Stack>
        :null
      }
        
    </Stack>
  )
}

export default APIDebugInfo;



//-------------------------------------------------------------------------------
// Preps the JSON value for storage (Used in the CallServer class).
//-------------------------------------------------------------------------------
export function APIDebugJSONPrep(json: any)
{
  // Remove log_guid and stats (they are stored separately)

  delete json.log_guid;
  delete json.stats;

  return json;
}


// Custom MUI style for the AccordionSummary
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  padding: 0, 
  //marginTop: -2, 
  //marginBottom: -2,
  //height: '30px',
  minHeight: 20,
  maxHeight: 20,
  overflow: 'hidden',
  borderRadius: 3,

  '&.Mui-expanded': 
  {
    minHeight: 25,
    maxHeight: 25,
  },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}));

// Customized toggle buttons (for the 3 view mode buttons)
const ViewModeToggleButton = styled(ToggleButton)(
{
  color: '#C4C16D', 
  borderColor: '#999655', 
  backgroundColor: '#4D4F34',

  padding: 0,
  paddingLeft: 8,
  paddingRight: 8,
  height: '20px',
  fontSize: '0.8rem',
  borderRadius: 30, 
  textTransform: 'none',

  '&:hover': 
  {
    color: '#C4C16D',
    backgroundColor: '#786947',
  },

  "&.Mui-selected, &.Mui-selected:hover": 
  {
    color: '#EBC288',
    backgroundColor: '#784848',
  }
});

