// HBV comparison panel

import { IconButton, Stack, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorMain, theme_orange, theme_textColorBlended } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import HbvPanelCore from './HbvPanelCore';


export const HBV_COMPARE_PANEL_SECTION_WIDTH_PIXELS = 300;  // width of each scenario panel


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface HbvComparePanelProps
{
  onClose: any;
}

//-------------------------------------------------------------------------------
// HBV comparison panel component
//-------------------------------------------------------------------------------
const HbvComparePanel = (props: HbvComparePanelProps) => 
{
  // Get needed state data from the store
  const { store_hbvComparedScenarios, store_setHbvComparedScenarios, 
          store_drawerOpen, 
        } = useStore();

 
  

  

  //-------------------------------------------------------------------------------
  // Clear the comparison panel.
  //-------------------------------------------------------------------------------
  const onClose = async () => 
  {
    store_setHbvComparedScenarios([]);
  }




  // ******************************************************************************************
  // Main render - we have HBV scenario comparison data
  // ******************************************************************************************

  if(store_hbvComparedScenarios.length > 0)
  return (

    <Stack direction='column' sx={{ width: '100%', height: '100%' }}>

      {/* Close button */}

      <Stack direction='row' sx={{ width: '100%', justifyContent: 'right', mt: '5px' }}>
        <IconButton size="small" onClick={onClose}
                    sx={{ padding: 0, bgcolor: theme_bgColorMain, width: '30px', height: '30px', mr: '5px' }}>
          <CloseIcon sx={{ opacity: 0.7, width: '16px', height: '16px', color: theme_textColorBlended }} />
        </IconButton>
      </Stack>

      {/* HBV Scenario panels */}

      <Stack direction='column' sx={{ width: '100%', height: '100%', alignItems: 'center',
                                      mt: `${store_drawerOpen?-30:50}px` }}>

        {/* Panel title */}

        <Typography sx={{ fontSize: '1.5rem', color: theme_orange }}>
          Highest and Best Value Comparison
        </Typography>

        {/* AOI name + acres */}

        {/* <Stack direction='row' sx={{ justifyContent: 'center', mb: 2 }}>
          <TypographyWithAutoTooltip sx={{ fontSize: '0.9rem', color: theme_textColorBlended, textTransform: 'none', maxWidth: '85%' }}>
            {store_aoi?.aoi_name}
          </TypographyWithAutoTooltip>
          <Typography sx={{ color: 'white', opacity: 0.7, ml: 2, fontSize: '0.9rem', textTransform: 'none' }}>
            {FriendlyNumber(store_aoi?.acres)} acres
          </Typography>
        </Stack> */}

        <Stack direction='row' sx={{ width: '100%', height: '100%', justifyContent: 'space-evenly', mt: 1 }}>

          {store_hbvComparedScenarios.map(hbvScenario =>
            <Stack key={hbvScenario.scenario_id} sx={{ width: `${HBV_COMPARE_PANEL_SECTION_WIDTH_PIXELS}px`, height: '100%', p: 0, mt: 0 }}>
              <HbvPanelCore scenario={hbvScenario}/>
              {/* impactPanelHeight={GetCompareScenariosImpactPanelHeight() */}
            </Stack>
          )}

        </Stack>
      </Stack>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall through (should never happen)
  // ******************************************************************************************

  return (
    <div>No HBV scenario comparison data</div>
  )
}





// Custom style for the 3D toggle icon button
// export const MyToggleIconButton = styled(ToggleButton)(
//   {
//     color: theme_textColorBlended,
//     borderColor: theme_bgColorLight,
  
//     '&:hover': 
//     {
//       borderColor: theme_orange,
//     },
  
//     // selected (and overides hover color)
//     "&.Mui-selected, &.Mui-selected:hover": 
//     {
//       color: theme_orange,
//       //backgroundColor: theme_bgColorLight1
//     }
//   })


export default HbvComparePanel;
