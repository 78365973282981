// Layer info panel component

import useStore from '../store';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_limeGreen, theme_orange, theme_textColorBlended } from "../Theme";
import DOMPurify from "dompurify";
import { DEV_MODE, LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL } from '../Globals';
import CloseIcon from '@mui/icons-material/Close';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerInfoPanelProps
{
  //layer: ILayer;
  // localLayerItem: ILocalLayerItem;
  // onLayerCheckBox: any;
}

//-------------------------------------------------------------------------------
// Layer info panel component
//-------------------------------------------------------------------------------
export function LayerInfoPanel(props: LayerInfoPanelProps) 
{
  // Get needed state data from the store
  const { store_activeInfoLayer, store_setActiveInfoLayer } = useStore();

  // const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.localLayerItem.id});

  // const layer : ILayer = props.localLayerItem.layer;





  //-------------------------------------------------------------------------------
  // Close the panel.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    store_setActiveInfoLayer(null);
  }



  if(!store_activeInfoLayer) return null;

  // Main render

  return (

    <Dialog open={store_activeInfoLayer != null} onClose={onClose} fullWidth>

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center'}}>
            {store_activeInfoLayer.friendlyName}
            {DEV_MODE
              ?
                <Typography sx={{ ml: 2, color: theme_textColorBlended, fontSize: '0.8rem', opacity: 0.8 }}>
                  {store_activeInfoLayer.id}
                </Typography>
              :null
            }
          </Stack>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '32px', height: '32px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '25px', height: '25px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      <DialogContent sx={{ p: 0, background: theme_bgColorGradient2 }}>

        {/* If we have description data... */}
        {store_activeInfoLayer.description
          ?
            <Stack sx={{ width: '100%', p: 2 }}>

              {/* {store_activeInfoLayer.description.mapImageUrl
                ?
                  <Stack sx={{ mb: 2, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <Stack sx={{ borderRadius: 2, bgcolor: theme_bgColorLight1, width: '40%' }}>

                      <Box component="img" alt="" src={store_activeInfoLayer.description.mapImageUrl}
                          sx={{ width: '100%', borderRadius: 2, p: '2px' }}/>

                    </Stack>
                  </Stack>
                :null
              } */}

              {/* <Stack direction='row' sx={{ alignItems: 'left', width: '100%' }}>
                <Box component="img" alt="" src='https://sfx-app-layer-info-map-images.s3.amazonaws.com/Plant+Hardiness+Zone.png'
                      sx={{ height: '70px', borderRadius: 2 }}/>
              </Stack> */}

              {/* Short Description */}

              {store_activeInfoLayer.description.shortDescription && store_activeInfoLayer.description.shortDescription.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Description
                    </Typography>
                    <Typography sx={{ ml: 1, opacity: '0.8' }}>
                      {store_activeInfoLayer.description.shortDescription}
                    </Typography>
                  </Stack>
                :null
              }

              {/* Time Period */}

              {store_activeInfoLayer.description.timePeriod && store_activeInfoLayer.description.timePeriod.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Time Period:
                    </Typography>
                    <Typography sx={{ ml: 1, opacity: '0.8' }}>
                      {store_activeInfoLayer.description.timePeriod}
                    </Typography>
                  </Stack>
                :null
              }

              {/* Publisher */}

              {store_activeInfoLayer.description.publisher && store_activeInfoLayer.description.publisher.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Publisher:
                    </Typography>
                    <Typography sx={{ ml: 1, opacity: '0.8' }}>
                      {store_activeInfoLayer.description.publisher}
                    </Typography>
                  </Stack>
                :null
              }

              {/* Source Link/URL */}

              {store_activeInfoLayer.description.sourceUrl && store_activeInfoLayer.description.sourceUrl.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Link to Source:
                    </Typography>
                    <Link href={store_activeInfoLayer.description.sourceUrl} rel="noopener noreferrer" target="_blank"
                          sx={{  ml: 1, opacity: '0.8' }}>
                      {store_activeInfoLayer.description.sourceUrl}
                    </Link>
                  </Stack>
                :null
              }

              {/* Long Description */}

              {store_activeInfoLayer.description.longDescription && store_activeInfoLayer.description.longDescription.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Details:
                    </Typography>
                    <Typography component='span' sx={{ ml: 1, opacity: '0.8' }}>
                      {/* NOTE: Even though the longDescription comes from our own db, it is run through 
                                a sanitizer to strip it of all unsafe code/scripting just in case. */}
                      <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(store_activeInfoLayer.description.longDescription)}} style={{whiteSpace: 'pre-line'}}/>
                    </Typography>
                  </Stack>
                :null
              }

              {/* Citations */}

              {store_activeInfoLayer.description.citations && store_activeInfoLayer.description.citations.length > 0
                ?
                  <Stack>
                    <Typography sx={{ mt: 1, color: theme_orange, fontSize: '1.2rem', opacity: 0.8 }}>
                      Citations:
                    </Typography>

                    {store_activeInfoLayer.description.citations.map((citation, citationIndex) => 

                      <Stack key={citation.id} sx={{ my: 1 }}>

                        <Typography sx={{ ml: 1, fontSize: '0.8rem', color: theme_limeGreen, opacity: 0.9, mb: 0.5 }}>
                          Citation {citationIndex+1}
                        </Typography>
                        
                        {/* Citation text */}

                        <Typography component='span' sx={{ ml: 1, opacity: '0.8' }}>
                        {/* NOTE: Even though the citation text comes from our own db, it is run through 
                                  a sanitizer to strip it of all unsafe code/scripting just in case. */}
                          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(citation.text)}} style={{whiteSpace: 'pre-line'}}/>
                        </Typography>

                        {/* Citation links */}

                        <Stack sx={{ mt: 0.5 }}>
                          {citation.urls.map(citationUrl => 
                            <Link key={citationUrl as string} href={citationUrl} rel="noopener noreferrer" target="_blank" sx={{  ml: 3, my: '2px', fontSize: '0.8rem', opacity: '0.8' }}>
                              {citationUrl}
                            </Link>
                          )}
                        </Stack>

                      </Stack>
                    )}

                  </Stack>
                :null
              }

              {/* Attribution images */}

              {store_activeInfoLayer.description.images && store_activeInfoLayer.description.images.length > 0
                ?
                  <Stack sx={{ mt: 2, alignItems: 'center' }}>

                    {store_activeInfoLayer.description.images.map(image => 
                      <Box key={image.id} component="img" alt="" src={image.url} 
                           sx={{ bgcolor: image.bgcolor, p:1, maxWidth: '300px', mt: 2, borderRadius: 2 }}/>
                    )}

                  </Stack>
                :null
              }

            </Stack>
          :null
        }

        {/* Static map image (top right corner of dialog) */}

        {store_activeInfoLayer.description && store_activeInfoLayer.description.mapImage
          ?
            <Stack sx={{ position: 'absolute', top: 1, right: 45, justifyContent: 'center', 
                         alignItems: 'center', height: '100px' }}>
              <Stack sx={{ borderRadius: 3, bgcolor: theme_bgColorLight1, height: '100%' }}>
                <Box component="img" alt="" src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(store_activeInfoLayer.description.mapImage)}
                      sx={{ height: '100%', borderRadius: 3, p: '2px' }}/>
              </Stack>
            </Stack>
          :null
        }

      </DialogContent>

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>
        <Button variant='contained' autoFocus onClick={(_)=>store_setActiveInfoLayer(null)}>
          Close
        </Button>
      </DialogActions>

    </Dialog>
  )
}


