import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import WarningIcon from '@mui/icons-material/Warning';
import { theme_bgColorMain, theme_orange } from './Theme';
import React from 'react';


const theme = createTheme();


//-------------------------------------------------------------------------------
// 
//-------------------------------------------------------------------------------
export default function AppErrorPage()
{


  // Main render

  return (

    <div style={{ backgroundImage: "url(https://images.squarespace-cdn.com/content/v1/5eb08f475674db04ac75aec5/1591059926958-QET4PEL9R3F73NE6PLJO/shutterstock_298180979+%5BConverted%5D-01.jpg?format=2500w)",
                  backgroundSize: 'cover', height: '100vh', position: "relative", display: "flex",
                  justifyContent: "center", alignItems: "center" }}>
      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ bgcolor: theme_bgColorMain, borderRadius: 2, boxShadow: 5 }}>
          <CssBaseline />
          <Box sx={{ marginTop: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 0, p: 1 }}>

            <WarningIcon sx={{ width: '48px', height: '48px', color: '#f4a582', mt: 2 }}/>

            {/* <img src={stratifyx_logo_for_light_bg.default} alt="" height='150px' /> */}
            <Typography component="h1" variant="h5" sx={{ color: theme_orange, mt: 2 }}>
              This app has encountered an unexpected error and needs to be restarted.
            </Typography>

            <Typography component="h1" variant="h6" sx={{ color: 'white', mt: 2, mb: 2 }}>
              Please click your browser's refresh button or the F5 key.
            </Typography>

            {/* <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} component={RouterLink} to="/Account/LogIn" >
              Restart App
            </Button> */}

          </Box>

          {/* <Copyright sx={{ mt: 5 }} /> */}

        </Container>
      </ThemeProvider>
    </div>
   )

}