// Drawer menu - Project accordion

import { Accordion, Box, Typography, AccordionDetails, Stack } from "@mui/material";
import { useStore } from "../store";
import { theme_bgColorLight1, theme_textColorBlended, theme_orange, theme_textColorMain } from "../Theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { DrawerMenuAccordionSummary } from './DrawerMenuAccordionStyle';
import Aois from "../Aois/Aois";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface DrawerMenuAoiProps 
{
  onAccordionChange: any;
}

//-------------------------------------------------------------------------------
// Drawer menu accordion AOI component
//-------------------------------------------------------------------------------
const DrawerMenuAoi = (props: DrawerMenuAoiProps) => 
{
  const { onAccordionChange } = props;

  // Get needed state data from the store
  const { store_project, store_aoi, store_projectIsLoading, store_aoiIsLoading,
          store_activeDrawerMenuItem, store_bundleIsLoading, 
        } = useStore();





  // NOTE: At this point we know a user is logged in and the user profile is loaded


  // Main render - there is no active saved project, or a project is loading in
  // (no AOI accordion at all)

  if(!store_project || !store_project.project_id || store_projectIsLoading || store_bundleIsLoading)
  return null;

  // Main render - we have an active saved project

  return (

    <Accordion expanded={store_activeDrawerMenuItem === 'aoi'} disableGutters={false}
               onChange={onAccordionChange('aoi')}
               sx={{ background: theme_bgColorLight1 }}>
            
      <DrawerMenuAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme_textColorBlended }} />}>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          <MyLocationIcon sx={{ color: theme_textColorBlended, width: '30px', height: '30px', ml: -1 }}/>

            {!store_aoi || store_aoiIsLoading || store_activeDrawerMenuItem === 'aoi'
              ?
                // No active aoi (or AOI menu is open)

                <Typography sx={{ ml: 1, color: theme_textColorMain, fontSize: '1rem' }}>
                  Area of Interest
                </Typography>
              :
                // There is an active saved AOI

                <Stack direction='column' sx={{ ml: 1 }}>
                  
                  <Typography sx={{ color: theme_textColorMain, fontSize: '0.7rem', opacity: 0.8 }}>
                    Area of Interest
                  </Typography>

                  <TypographyWithAutoTooltip placement='right' arrow sx={{ color: theme_orange, fontSize: '0.9rem', maxWidth: '200px' }}>
                    {store_aoi?.aoi_name}
                  </TypographyWithAutoTooltip>

                </Stack>
            }
        </Box>

      </DrawerMenuAccordionSummary>

      <AccordionDetails>
        <Aois />
      </AccordionDetails>

    </Accordion>
  )
}

export default DrawerMenuAoi;
