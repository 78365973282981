// Circular progress component

import { CircularProgressProps, CircularProgress, circularProgressClasses, Stack } from "@mui/material";
import { theme_limeGreen } from "../Theme";

const DEFAULT_SIZE = 40;

interface MyCircularProgressProps extends CircularProgressProps
{
  circleSize?: string | number;
}

//-------------------------------------------------------------------------------
// Circular progress component
//
// Inspired by the former Facebook spinners.
//-------------------------------------------------------------------------------
export default function MyCircularProgress(props: MyCircularProgressProps) 
{


  
  // Main render

  return (
    <Stack sx={{ position: 'relative', alignItems: 'center' }}>
      <CircularProgress variant="determinate" 
                        size={props.circleSize ? props.circleSize : DEFAULT_SIZE} 
                        thickness={4} value={100}
                        sx={{ color: theme_limeGreen, opacity: 0.2 }}/>
      <CircularProgress variant="indeterminate" disableShrink
                        size={props.circleSize ? props.circleSize : DEFAULT_SIZE} thickness={4}
                        sx={{ color: theme_limeGreen, opacity: 1, animationDuration: '550ms', 
                        position: 'absolute', left: 0,
                        [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' } }}/>
    </Stack>
  )
}