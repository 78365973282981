import { DEV_MODE, DEV_MODE_CONSOLE_LOGGING } from "./Globals";


//-------------------------------------------------------------------------------
// Basic wrapper class for console.log, console.warn, console.error
//-------------------------------------------------------------------------------
export default abstract class Debug
{

  //-------------------------------------------------------------------------------
  // Log normal entries.
  //-------------------------------------------------------------------------------
  public static log(message?: any, ...optionalParams: any[])
  {
    if(DEV_MODE && DEV_MODE_CONSOLE_LOGGING)
      console.log(message, ...optionalParams);
  }

  //-------------------------------------------------------------------------------
  // Log warnings.
  //-------------------------------------------------------------------------------
  public static warn(message?: any, ...optionalParams: any[])
  {
    if(DEV_MODE && DEV_MODE_CONSOLE_LOGGING)
      console.warn(message, ...optionalParams);
  }

  //-------------------------------------------------------------------------------
  // Log errors.
  //-------------------------------------------------------------------------------
  public static error(message?: any, ...optionalParams: any[])
  {
    if(DEV_MODE && DEV_MODE_CONSOLE_LOGGING)
      console.error(message, ...optionalParams);
  }
}