import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogTitleBar } from '../Components/DialogTitleBar';
import { Stack, Divider, TextField, Typography } from '@mui/material';
import useStore from '../store';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { DialogStyled } from '../CustomMuiStyles';
import ChangePwdDialog from './ChangePwdDialog';
import { useState } from 'react';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AccountSettingsDialogProps
{
  setCurrTheme: React.Dispatch<React.SetStateAction<any>>;
}

//-------------------------------------------------------------------------------
// Account settings dialog Component
//-------------------------------------------------------------------------------
const AccountSettingsDialog = (props: AccountSettingsDialogProps) =>
{
  // Get needed state data from the store
  const { store_username, store_userInfo, store_accountSettingsOpen,
          store_setAccountSettingsOpen,
        } = useStore();

  

  const [changePwdDialogOpen, setChangePwdDialogOpen] = useState<boolean>(false);



  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onCloseDialog = () => 
  {
    store_setAccountSettingsOpen(false);
  }

  //-------------------------------------------------------------------------------
  // Change password handler.
  //-------------------------------------------------------------------------------
  const onChangePwdButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    setChangePwdDialogOpen(true);
  }



  

  // ******************************************************************************************
  // Main render
  // ******************************************************************************************

  if(store_userInfo)
  return (

    <div>

      <DialogStyled onClose={onCloseDialog} open={store_accountSettingsOpen} maxWidth='md'>

        <DialogTitleBar onClose={onCloseDialog} width='auto'
                        icon={ <AdminPanelSettingsIcon htmlColor='#777777' fontSize='large' /> }>
          Account
        </DialogTitleBar>

        <DialogContent>

          <Stack direction='column'>

            {/* Name (First + Last) */}

            <Stack direction='row' sx={{ mt: 2 }}>

              <TextField  id="firstName" label="First Name" name="firstName" fullWidth 
                          value={store_userInfo.first_name} sx={{ mr: 2 }} />

              <TextField id="lastName" label="Last Name" name="lastName" fullWidth 
                         value={store_userInfo.last_name}/>

              <Button sx={{ ml: 2 }}>
                Update
              </Button>

            </Stack>

            {/* Email */}

            <Stack direction='row' sx={{ mt: 2 }}>

              <TextField autoComplete="email" name="email" fullWidth value={store_username}
                         id="email" label="Email Address" autoFocus disabled={true}/>

              <Button sx={{ ml: 2 }}>
                Change...
              </Button>

            </Stack>

            {/* Password */}

            <Stack direction='row' sx={{ mt: 2 }}>

              <TextField autoComplete="password" name="password" type='password' value='password'
                         id="password" label="Password" autoFocus disabled={true}/>

              <Button sx={{ ml: 2 }} onClick={onChangePwdButtonClick}>
                Change...
              </Button>

            </Stack>

            {/* Phone number */}

            <Stack direction='row' sx={{ mt: 2 }}>

              <TextField autoComplete="phone" name="phone"
                         id="phone" label="Phone Number" autoFocus/>

              <Button sx={{ ml: 2 }}>
                Update
              </Button>

            </Stack>

          </Stack>

          <Divider sx={{ mt: 2, mb: 0 }}/>


          {/* Change password dialog */}

          <ChangePwdDialog changePwdDialogOpen={changePwdDialogOpen} setChangePwdDialogOpen={setChangePwdDialogOpen}/>


        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={onCloseDialog} sx={{ mt: -2 }}>
            Close
          </Button>
        </DialogActions>
      </DialogStyled>

    </div>
  )

  // ******************************************************************************************
  // Main render - fall through case (should never happen)
  // ******************************************************************************************

  return (
    <Typography>
      Bundle unhandled state
    </Typography>
  )
}



export default AccountSettingsDialog;
