// Parcel interfaces

import { BBox, MultiPolygon } from 'geojson';
import { TVectorLayerAttributeType } from '../Layers/LayerInterfaces';


export interface IParcel
{
  // Data from the API call
  id: number;
  geometry: MultiPolygon;
  attributes: IParcelAttributes;

  // Additional app-only data
  //acres: number;
}

// This interface contains dynamic key/value pairs (attribute_name: attribute_value)
// NOTE: This  interface is used by both parcel identify and parcels returned by the 
//       parcel search.
export interface IParcelAttributes
{
  [key: string]: string | number | undefined;
}

export type TSortOrder = 'asc' | 'desc';

export interface IParcelSearchExpression
{
  clauses: IParcelSearchClause[];
  operator: TParcelSearchExpressionOperator;
  max_parcels?: number;
  states: string[]; // 2 char state codes
  area_filter_aoi_id?: number;
  area_filter_bbox?: BBox;
  area_filter_aoi_buffer_miles?: number;
  sort_attribute_name?: string;
  sort_order?: TSortOrder;
  attributes: string[];
}

export interface IParcelSearchClause
{
  attribute_name? : string;
  data_type?: TVectorLayerAttributeType;
  operator: TParcelSearchStringOperator | TParcelSearchNumberOperator | TParcelSearchEnumOperator;
  value?: string | number;
  value_max?: string; // NOTE: it's a string so the user can freely type floats (with - and . chars) without validation interfering

  clause_id: number;  // internal id used as a react key
  helpStr?: string;
}

//export type TParcelAttributeType = 'number' | 'string' | 'enum';

export const ParcelSearchStringOperators = [ "is equal to", 
                                             "is equal to (fuzzy)",
                                             "is not equal to",
                                             //"is not equal to (fuzzy)",                                             
                                             "begins with",
                                             "does not begin with",
                                             "ends with",
                                             "does not end with",
                                             "contains",
                                             "contains (fuzzy)",
                                             //"does not contain (fuzzy)",
                                             "does not contain",
                                             "is like",
                                             "is not like",
                                             "is empty",
                                             "is not empty"] as const;
export type TParcelSearchStringOperator = typeof ParcelSearchStringOperators[number];

export const ParcelSearchNumberOperators = [ "is equal to", 
                                             "is not equal to",
                                             "is less than",
                                             "is less than or equal to",
                                             "is greater than",
                                             "is greater than or equal to",
                                             "is in range",
                                             "is not in range",
                                             "is empty",
                                             "is not empty"] as const;
export type TParcelSearchNumberOperator = typeof ParcelSearchNumberOperators[number];

export const ParcelSearchEnumOperators = [ "is equal to", 
                                           "is not equal to" ] as const;
export type TParcelSearchEnumOperator = typeof ParcelSearchEnumOperators[number];

export type TParcelSearchExpressionOperator = "and" | "or";

export type TParcelSearchAreaFilter = 'None' | 'AOI' | 'Viewport';

export const DEFAULT_PARCEL_ATTRIBUTES: string[] = 
[
  'parcelnumb',
  'county',
  'owner',
  'mailadd',
  'mail_city',
  'mail_state2',
  'mail_zip',
  'll_gisacre',
  'landval',
  'parval',
  'cdl_majority_category',
  'cdl_majority_percent',
]