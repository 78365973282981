// Cognito user pool

import { CognitoUserPool } from 'amazon-cognito-identity-js';

export const userPoolData =
{
  UserPoolId: process.env.REACT_APP_STRATIFYX_COGNITO_POOL || '',
  ClientId: process.env.REACT_APP_STRATIFYX_COGNITO_CLIENT_ID || ''
}

const cognitoUserPool = new CognitoUserPool(userPoolData);
export default cognitoUserPool;