// State store helper functions.
//
// Some of the state store functions can get quite complicated.  Insetead of putting them
// directly in the store, we update the object here then use the much simpler 'set' methods
// to update the state store.

import useStore from "./store";
import { IProject, IProjectUserSettingsNrrImpactState, IProjectUserSettingsNrrState } from "./Projects/ProjectInterfaces";
import { INRR } from "./Bundles/BundleInterfaces";
import Debug from "./Debug";

//-------------------------------------------------------------------------------
// Generates a default IProjectUserSettingsNrrState.
//-------------------------------------------------------------------------------
function GetDefaultNrrState(nrr: INRR): IProjectUserSettingsNrrState
{
  const newImpactStates: IProjectUserSettingsNrrImpactState[] = [];
  nrr.impacts.forEach(impact_id => newImpactStates.push(GetDefaultNrrImpactState(impact_id)));

  const newNrrState: IProjectUserSettingsNrrState =
  {
    nrr_id: nrr.nrr_id,
    enabled: true,
    impactStates: newImpactStates,
  }

  return newNrrState;
}

//-------------------------------------------------------------------------------
// Generates a default IProjectUserSettingsNrrImpactState.
//-------------------------------------------------------------------------------
function GetDefaultNrrImpactState(impact_id: number): IProjectUserSettingsNrrImpactState
{
  const newImpactState: IProjectUserSettingsNrrImpactState = 
  {
    nrr_impact_id: impact_id,
    enabled: true,
  }

  return newImpactState;
}

//-------------------------------------------------------------------------------
// Syncs up the saved nrrState data in the active project's 'user_settings' with
// the currently-loaded list of NRRs of the current bundle.  This is needed because 
// over time new NRRs or impacts will be added or removed, so the user_settings must
// be kept up to date.
//
// If 'clearOldStatesFirst' is used, any existing state data is wiped and ignored.
// This means all the new saved nrr states will use default values.  This is needed
// when the user switches bundles.
//-------------------------------------------------------------------------------
export function SyncActiveProjectSavedNrrStates(clearOldStatesFirst: boolean = false)
{
  // Compares the current 'nrrStates' in store_project.user_settings against the
  // currently-active bundle's nrrs and impacts (store_nrrs).
  //
  // If any NRRs are missing from user_settings, they are added with default values.
  // If any saved NRR states are no longer needed in user_settings, they are removed.
  //
  // Within each NRR, the same things happens to the list of impacts.
  //
  // Once this runs, all loaded nrrs and impacts should have a matching state in
  // the active project's user_settings.
  //
  // If changes are made, the project is updated in the state store and marked as 
  // dirty so auto-save will kick in.

  let changesMade: boolean = false;

  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject)
  {
    Debug.error("storeOps.SyncActiveProjectSavedNrrStates> No active project")
    return;
  }

  if(clearOldStatesFirst) // In this case, the old nrr state data is wiped and ignored (this is useful for bundle changes, where it makes sense to start fresh)
    activeProject.user_settings.nrrStates = [];

  if(activeProject.user_settings.nrrStates === undefined)
    activeProject.user_settings.nrrStates = [];

  const actualNrrs: INRR[] = useStore.getState().store_nrrs;
  
  for(let i=0; i < actualNrrs.length; i++)
  {
    const savedNrrState: IProjectUserSettingsNrrState | undefined = activeProject.user_settings.nrrStates.find(nrrState => nrrState.nrr_id === actualNrrs[i].nrr_id);
    if(!savedNrrState)
    {
      // This NRR has no saved nrrState in user_settings - add it

      activeProject.user_settings.nrrStates.push(GetDefaultNrrState(actualNrrs[i]));
      changesMade = true;
    }
    else
    {
      // This NRR already has a saved nrrState in user_settings - just need to verify it

      // Verify the list of impacts

      for(let j=0; j < actualNrrs[i].impacts.length; j++)
      {
        const impact_id = actualNrrs[i].impacts[j];
        const savedImpactState: IProjectUserSettingsNrrImpactState | undefined = savedNrrState.impactStates.find(impactState => impactState.nrr_impact_id === impact_id);
        if(!savedImpactState)
        {
          // This impact has no impactState in user_settings - add it

          savedNrrState.impactStates.push(GetDefaultNrrImpactState(impact_id));
          changesMade = true;
        }
        else
        {
          // This impact already has an impactState in user_settings - just need to verify it
        }
      }

      // Remove any dead nrr impact state entries that are no longer needed

      const newNrrImpactStates: IProjectUserSettingsNrrImpactState[] = RemoveDeadNrrImpactStates(actualNrrs[i].impacts, savedNrrState.impactStates);
      if(newNrrImpactStates.length < savedNrrState.impactStates.length )
      {
        savedNrrState.impactStates = newNrrImpactStates;
        changesMade = true;
      }
    }
  }

  // Remove any dead nrr state entries that are no longer needed

  const newNrrStates: IProjectUserSettingsNrrState[] = RemoveDeadNrrStates(actualNrrs, activeProject.user_settings.nrrStates);
  if(newNrrStates.length < activeProject.user_settings.nrrStates.length )
  {
    activeProject.user_settings.nrrStates = newNrrStates;
    changesMade = true;
  }

  // If changes were made, update the project in the state store and flag it 
  // as "dirty" so it will be auto-saved.

  if(changesMade)
  {
    useStore.getState().store_setProject(activeProject);
    useStore.getState().store_setProjectIsDirty(true);
  }
}

//-------------------------------------------------------------------------------
// Remove nrr states for which there is no longer an nrr in the active bundle.
// NOTE: This is not vital, it's just done to keep the user settings clean.
//-------------------------------------------------------------------------------
function RemoveDeadNrrStates(actualNrrs: INRR[], nrrStates: IProjectUserSettingsNrrState[]): IProjectUserSettingsNrrState[]
{
  const newNrrStates: IProjectUserSettingsNrrState[] = [];
  for(let i=0; i < nrrStates.length; i++)
  {
    // Check if this nrrState matches an NRR in the active bundle
    const nrr: INRR | undefined = actualNrrs.find(nrr => nrr.nrr_id === nrrStates[i].nrr_id);
    if(nrr) // Match found - we keep this nrr
      newNrrStates.push(nrrStates[i]);
  }

  // If any entries were removed, return the new list (otherwise return the input list)
  return newNrrStates.length < nrrStates.length ? newNrrStates : nrrStates;
}

//-------------------------------------------------------------------------------
// Remove nrr impact states for which there is no longer an impact in the actual nrr.
// NOTE: This is not vital, it's just done to keep the user settings clean.
//-------------------------------------------------------------------------------
function RemoveDeadNrrImpactStates(actualImpacts: number[], nrrImapactStates: IProjectUserSettingsNrrImpactState[]): IProjectUserSettingsNrrImpactState[]
{
  const newImpactStates: IProjectUserSettingsNrrImpactState[] = [];
  for(let i=0; i < nrrImapactStates.length; i++)
  {
    // Check if this impactState matches an impact in the actual impact list
    const impact_id: number | undefined = actualImpacts.find(impact_id => impact_id === nrrImapactStates[i].nrr_impact_id);
    if(impact_id) // Match found - we keep this impact
      newImpactStates.push(nrrImapactStates[i]);
  }

  // If any entries were removed, return the new list (otherwise return the input list)
  return newImpactStates.length < nrrImapactStates.length ? newImpactStates : nrrImapactStates;
}

//-------------------------------------------------------------------------------
// Change the 'enable' state of an NRR.
//-------------------------------------------------------------------------------
export function storeOps_setActiveProjectNrrState(nrr_id: number, enabled: boolean)
{
  // Drill down into the object to find the 'enabled' for this nrr

  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject)
  {
    Debug.warn("storeOps.storeOps_setActiveProjectNrrState> Failed to get active project")
    return;
  }

  let nrrState: IProjectUserSettingsNrrState | undefined = activeProject.user_settings.nrrStates.find(nrrState => nrrState.nrr_id === nrr_id);
  if(!nrrState) 
  {
    Debug.warn("storeOps.storeOps_setActiveProjectNrrState> Failed to get active project nrrState")
    return;
  }

  if(nrrState.enabled === enabled) return;  // Already set to this value - no need to do anything

  // Update it's value
  nrrState.enabled = enabled;

  // Update the store
  useStore.getState().store_setProject(activeProject);
  useStore.getState().store_setProjectIsDirty(true);
}

//-------------------------------------------------------------------------------
// Change the 'enable' state of an NRR impact.
//-------------------------------------------------------------------------------
export function storeOps_setActiveProjectNrrImpactState(nrr_id: number, impact_id: number, enabled: boolean)
{
  // Drill down into the object to find the 'enabled' for this nrr/impact

  const activeProject: IProject | null = useStore.getState().store_project;
  if(!activeProject)
  {
    Debug.warn("storeOps.storeOps_setActiveProjectNrrImpactState> Failed to get active project")
    return;
  }

  let nrrState: IProjectUserSettingsNrrState | undefined = activeProject.user_settings.nrrStates.find(nrrState => nrrState.nrr_id === nrr_id);
  if(!nrrState) 
  {
    Debug.warn("storeOps.storeOps_setActiveProjectNrrImpactState> Failed to get active project nrrState")
    return;
  }

  let nrrImpactState: IProjectUserSettingsNrrImpactState | undefined = nrrState.impactStates.find(impactState => impactState.nrr_impact_id === impact_id);
  if(!nrrImpactState) 
  {
    Debug.warn("storeOps.storeOps_setActiveProjectNrrImpactState> Failed to get nrrImpactState")
    return;
  }

  if(nrrImpactState.enabled === enabled) return;  // Already set to this value - no need to do anything

  // Update it's value
  nrrImpactState.enabled = enabled;

  // Update the store
  useStore.getState().store_setProject(activeProject);
  useStore.getState().store_setProjectIsDirty(true);
}
