// UI component to create a new AOI from selected parcels

import { Stack, Button, Typography } from "@mui/material";
import { useState } from "react";
import { theme_limeGreen, theme_orange, theme_textColorBlended } from "../Theme";
import useStore from "../store";
import React from "react";
import RoundedInputBox from "../Components/RoundedInputBox";
import { CreateAoiFromSelectedParcels, ExitParcelsMode } from "../Parcels/ParcelOps";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiFromParcelsProps
{
}

//-------------------------------------------------------------------------------
// Create AOI from parcels component
//-------------------------------------------------------------------------------
const AoiFromParcels = (props: AoiFromParcelsProps) => 
{
  // Get needed state data from the store
  const { store_setAoiUIMode, store_selectedParcels, 
        } = useStore();

  const [newAoiName, setNewAoiName] = useState<string>('');



  //-------------------------------------------------------------------------------
  // Create a new AOI.
  //-------------------------------------------------------------------------------
  const onCreateNewAoiButton = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newAoiName || newAoiName.trim().length === 0)
      return;
    
    CreateAoiFromSelectedParcels(newAoiName.trim());
  }

  //-------------------------------------------------------------------------------
  // Cancel the AOI creation operation.
  //-------------------------------------------------------------------------------
  const onCancel = () => 
  {
    store_setAoiUIMode('default');

    ExitParcelsMode();
  }






  // ******************************************************************************************
  // Main render
  // ******************************************************************************************

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '0.9rem', color: theme_textColorBlended }}>
        Create a new Area of Interest (AOI) from the selected parcels
      </Typography>


      {store_selectedParcels.length === 0
        ?
          // No parcels selected
          <Typography sx={{ fontSize: '1rem', color: theme_orange, mt: 2, mb: 2 }}>
            Please select one or more parcels.
          </Typography>
        :
          // Parcels selected - show the AOI name text box + OK button
          <RoundedInputBox title='New area of interest' onAccept={onCreateNewAoiButton}
                           value={newAoiName} setValue={setNewAoiName}/>
      }

      {/* Cancel button */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} onClick={onCancel}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default AoiFromParcels;




