// Parcel attributes selector - INACTIVE attributes list

import { IVectorLayerAttribute, ILayer } from '../../Layers/LayerInterfaces';
import { theme_errorRed, theme_textColorBlended } from '../../Theme';
import { Stack, Typography } from "@mui/material";
import { GetParcelLayer } from '../ParcelOps';
import { IsAttribSelected } from './AttribSelectorActivePanel';
import { AttribSelectorInactiveItem } from './AttribSelectorInactiveItem';




//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AttribSelectorInactivePanelProps
{
  OnAttribCheckBoxChange: any;
  invisibleAttributeNames: string[];
}

//-------------------------------------------------------------------------------
// Parcel attributes selector - INACTIVE attributes list
//-------------------------------------------------------------------------------
export function AttribSelectorInactivePanel(props: AttribSelectorInactivePanelProps) 
{
  // Get needed state data from the store
  // const { 
  //       } = useStore();








  //-------------------------------------------------------------------------------
  // Returns the list of VISIBLE (not filtered out) and INACTIVE/NOT SELECTED attributes.
  //-------------------------------------------------------------------------------
  function GetVisibleInactiveAttributes(): IVectorLayerAttribute[]
  {
    const parcelLayer: ILayer | undefined = GetParcelLayer();
    if(!parcelLayer) return [];

    const parcelLayerAttributes: IVectorLayerAttribute[] | undefined = parcelLayer.attributes;
    if(!parcelLayerAttributes) return [];

    const visibleAttributes: IVectorLayerAttribute[] = [];

    for(let i=0; i < parcelLayerAttributes.length; i++)
    {
      const attribute = parcelLayerAttributes[i];

      // We skip active attributes
      if(IsAttribSelected(attribute))
        continue;

      // Only include items that are visible (based on the search/filter)
      if(!props.invisibleAttributeNames.find(attribName => attribName === attribute.attribute_name))
         visibleAttributes.push(attribute);
    }

    return visibleAttributes;
  }


  



















  // Main render

  return (

    <Stack sx={{ ml: 2, width: '400px', height: '100%' }}>

      <Typography sx={{ ml: '10px', my: 1, color: theme_textColorBlended, fontSize: '1.2rem', fontWeight: 'bold', opacity: 0.8 }}>
        Inactive
      </Typography>

      <Stack sx={{ overflow: 'auto', mt: 1 }}>

        {GetVisibleInactiveAttributes().length === 0
          ?
            <Typography sx={{ ml: 1.2, mt: 1.5, color: theme_errorRed, opacity: 0.7, fontSize: '1rem', textAlign: 'left' }}>
              No search matches.
            </Typography>              
          :
            GetVisibleInactiveAttributes().map(attrib => 
              <AttribSelectorInactiveItem key={attrib.attribute_name} attrib={attrib} OnAttribCheckBoxChange={props.OnAttribCheckBoxChange}/>
            )
        }
      </Stack>

    </Stack>
  )
}


