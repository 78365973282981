// Edit layer groups

import { Button, Checkbox, Stack, Typography } from "@mui/material";
import { theme_errorRed, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import { ILayer } from '../../Layers/LayerInterfaces';
import { ILayerLibraryGroup } from "../LayerLibraryInterfaces";
import useStore from "../../store";
import { SPECIAL_UNGROUPPED_LAYERS_GROUP_ID } from "../LayerLibraryGroupButtons";
import { ConvertCustomLayerIntoMainLayer } from "../LayerLibraryOps";




//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerGroupsProps
{
  layer?: ILayer;
  newLayerGroups?: number[];
  setNewLayerGroups: any;
  setGroupListWasChanged: any;
}

//-------------------------------------------------------------------------------
// Edit layer groups
//-------------------------------------------------------------------------------
export function EditLayerGroups(props: EditLayerGroupsProps) 
{
  // Get needed state data from the store
  const { store_permEditMainLayer, store_layerLibraryGroups,
        } = useStore();












  //-------------------------------------------------------------------------------
  // A group checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnGroupCheckBoxChange = (group: ILayerLibraryGroup, event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    if(!props.newLayerGroups || !group) return;

    let updatedGroupIDs: number[];

    if(checked) // Adding this as a new group to the list
    {
      updatedGroupIDs = [...props.newLayerGroups, group.layer_library_group_id];

      // Remove the group from the special "no group" group
      updatedGroupIDs = updatedGroupIDs.filter(gid => gid !== SPECIAL_UNGROUPPED_LAYERS_GROUP_ID);
    }
    else // Removing this group from the list
    {
      updatedGroupIDs = props.newLayerGroups.filter(gid => gid !== group.layer_library_group_id);

      // If the layer is now part of no groups, add it to the special "no group" group
      if(updatedGroupIDs.length === 0)
        updatedGroupIDs = [ SPECIAL_UNGROUPPED_LAYERS_GROUP_ID ];
    }

    // Update the layer's group list
    props.setNewLayerGroups(updatedGroupIDs);

    // Tell calling code that the group list was changed (so it knows to save it)
    props.setGroupListWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Convert this custom layer into a main layer.
  //-------------------------------------------------------------------------------
  async function OnConvertCustomLayerToMain()
  {
    const ret: boolean = await ConvertCustomLayerIntoMainLayer(props.layer);
    if(!ret)
      return;

    // Update the layer's group list
    props.setNewLayerGroups([ SPECIAL_UNGROUPPED_LAYERS_GROUP_ID ]);

    // We'd also need to update the store_editLayer, since that is what the current layer
    // editor window is actually displaying.  BUT this would cause the editor to fully reset.
    // For now, the simplest way is to close the editor.  When the user re-opens it, the
    // layer will be a main layer with no groups.  The user can then assign it to a main group.
    useStore.getState().store_setEditLayer(undefined);
  }

  //-------------------------------------------------------------------------------
  // Returns the number of groups the specified layer is part of.
  // (does not include the special "no group" group with id of -1)
  //-------------------------------------------------------------------------------
  function GetGroupCountForLayer(): number
  {
    if(!props.newLayerGroups) return 0;

    let count = 0;
    for(let i=0; i < props.newLayerGroups.length; i++)
      if(props.newLayerGroups[i] !== SPECIAL_UNGROUPPED_LAYERS_GROUP_ID)
        count++;

    return count;
  }
  






  




  if(!props.layer) return null;

  if(props.layer.isCustomLayer === true) return (

    <Stack sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>

      <Typography sx={{ mt: 3, fontSize: '1.4rem', color: theme_errorRed, textAlign: 'center', opacity: 0.7 }}>
        Group membership cannot be changed for custom layers.
      </Typography>

      {store_permEditMainLayer === true
        ?
          <Stack sx={{ alignItems: 'center' }}>
            <Button variant='outlined' onClick={_=>OnConvertCustomLayerToMain()}
                    sx={{ mt: 5, width: '400px', textTransform: 'none' }}>
              Convert this
              <Typography sx={{ mx: 1, fontWeight: 'bold',color: theme_orange }}>
                CUSTOM
              </Typography>
              layer into a
              <Typography sx={{ mx: 1, fontWeight: 'bold', color: theme_orange }}>
                MAIN
              </Typography>
              layer
            </Button>
            <Typography sx={{ mt: 2, fontSize: '0.8rem', color: theme_errorRed+'B0' }}>
              If you do this, the layer will become visible to ALL organizations.
            </Typography>
            <Typography sx={{ mt: 1, fontSize: '0.8rem', color: theme_errorRed+'B0' }}>
              If you have any unsaved changes, they will be lost.  Please save first.
            </Typography>
            <Typography sx={{ mt: 1, fontSize: '0.8rem', color: theme_errorRed+'B0' }}>
              This action is not reversible without admin assistance.
            </Typography>
            <Typography sx={{ mt: 3, fontSize: '0.8rem', color: theme_textColorMain+'80', maxWidth: '60%' }}>
              After conversion, this layer will not be part of any main groups, and thus users will not be able to see it.  You will need to go to the layer library's "[ Layers with no groups ]" section and assign it to one or more main groups.
            </Typography>
          </Stack>
        :null
      }

    </Stack>
  )

  if(!props.newLayerGroups) return null;

  // Main render

  return (

    <Stack>

      {GetGroupCountForLayer() ===  0
        ?
          <Typography sx={{ fontSize: '1.2rem', color: theme_textColorBlended, mb: 2 }}>
            This layer is not a member of any groups.
          </Typography>
        :
          <Typography sx={{ fontSize: '1.2rem', color: theme_textColorBlended, mb: 2 }}>
            This layer is a member of <b>{GetGroupCountForLayer()}</b> {GetGroupCountForLayer()===1?'group':'groups'}.
          </Typography>
      }

      {store_layerLibraryGroups.map(function(group)
      {
        // The special "Ungrouped Layers" group is not a real group - skip showing it in this UI
        if(group.layer_library_group_id === SPECIAL_UNGROUPPED_LAYERS_GROUP_ID)
          return null;

        const foundGroupID: number|undefined = props.newLayerGroups?.find(gid => gid===group.layer_library_group_id);
        const inGroup: boolean = foundGroupID!==undefined;

        //const inGroup: boolean = IsLayerInLayerLibraryGroup(props.layer, group.layer_library_group_id);
        return (
          <Stack key={group.layer_library_group_id} direction='row' sx={{ ml: 2, alignItems: 'center', justifyContent: 'left' }}>
            <Checkbox size='large' checked={inGroup} onChange={(e,c)=>OnGroupCheckBoxChange(group,e,c)}
                      sx=
                      {{ 
                        ml: '-10px', mr: 0, color: theme_textColorBlended+'B0', width: '40px', height: '40px',
                        '&.Mui-checked': { color: theme_textColorBlended } 
                      }} />
              <Typography sx={{ ml: 1.5, color: inGroup ? theme_orange+'D0' : theme_textColorMain+'90', fontSize: '1.2rem', fontWeight: inGroup ? 'bold' : 'normal' }}>
                {group.name}
              </Typography>
          </Stack>
        )}
      )}
      





    </Stack>
  )
}





