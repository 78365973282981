// HBV scenarios component

import { Box, Button, Checkbox, IconButton, Stack, Typography } from "@mui/material";
import useStore from "../store";
import { theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { useState } from "react";
import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import BalanceIcon from '@mui/icons-material/Balance';
import { IProjectScenario } from "../Projects/ProjectInterfaces";
import { ToastNotification } from "../ToastNotifications";
import { CompareHbvScenarios, DeleteHbvScenario, LoadHbvScenario, RenameHbvScenario, ResetHbv } from "../HBV/HbvOps";
import RoundedInputBox2 from "../Components/RoundedInputBox2";
import { FriendlyCurrency } from "../Globals";
import YesNoDialog from "../Components/YesNoDialog";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";


const MAX_SCENARIOS_FOR_COMPARE_TOOL: number = 3;


interface IScenarioAoiGroup
{
  aoi_id: number;
  aoi_name: string;
  scenarios: IProjectScenario[];
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ScenariosProps
{
}

//-------------------------------------------------------------------------------
// HBV scenarios component
//-------------------------------------------------------------------------------
const Scenarios = (props: ScenariosProps) => 
{
  // Get needed state data from the store
  const { store_project, store_setProjectHbvScenarioSelected, store_hbvScenario, 
          store_aoi, store_userInfo, 
        } = useStore();

  const [renameScenarioID, setRenameScenarioID] = useState<number|null>(null);
  const [origHbvScenarioName, setOrigHbvScenarioName] = useState<string|null>(null);
  const [newHbvScenarioName, setNewHbvScenarioName] = useState<string|null>(null);

  const [deleteScenarioConfirmation, setDeleteScenarioConfirmation] = useState(false);  
  const [deleteScenario, setDeleteScenario] = useState<IProjectScenario|null>(null);



  //-------------------------------------------------------------------------------
  // A scenario comparison checkbox was changed.
  //-------------------------------------------------------------------------------
  const onHbvScenarioCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, 
                                       checked: boolean, projectHbvScenario: IProjectScenario) => 
  {
    if(!store_project) return;

    // Update the checked state of the clicked item

    for(let i=0; i < store_project.scenarios.length; i++)
      if(store_project.scenarios[i].hbv_scenario_id === projectHbvScenario.hbv_scenario_id)
        store_setProjectHbvScenarioSelected(projectHbvScenario.hbv_scenario_id, event.target.checked);
  }

  //-------------------------------------------------------------------------------
  // Returns the number of scenarios currently checked (for comparison feature).
  //-------------------------------------------------------------------------------
  function GetCheckedScenariosCount(): number
  {
    const project = useStore.getState().store_project;
    if(!project) return 0;

    let selectedItemCount: number = 0;
    for(let i=0; i < project.scenarios.length; i++)
      if(project.scenarios[i].selected)
        selectedItemCount++;

    return selectedItemCount;
  }

  //-------------------------------------------------------------------------------
  // Returns TRUE if the user has checked the correct number of scenarios.
  //-------------------------------------------------------------------------------
  function CanDoScenarioCompare(): boolean
  {
    const count = GetCheckedScenariosCount();
    return count >= 2 && count <= MAX_SCENARIOS_FOR_COMPARE_TOOL;
  }

  //-------------------------------------------------------------------------------
  // Compares 2 selected HBV scenarios.
  //-------------------------------------------------------------------------------
  const onCompareClick = () => 
  {
    if(!store_project) return;

    // If a scenario is active (or a previous scenario compare), clear it
    ResetHbv();

    // Build a list of the scenarios the user has selected to compare

    const scenariosToCompare: number[] = [];
    for(let i=0; i < store_project.scenarios.length; i++)
      if(store_project.scenarios[i].selected)
        scenariosToCompare.push(store_project.scenarios[i].hbv_scenario_id);

    // At least 2 scenarios must be selected
    if(scenariosToCompare.length < 2)
    {
      ToastNotification('error', `Please select at least 2 scenarios to compare`);
      return;
    }

    // At most 3 scenarios must be selected
    if(scenariosToCompare.length > MAX_SCENARIOS_FOR_COMPARE_TOOL)
    {
      ToastNotification('error', `You have too many scenarios selected (max 3)`);
      return;
    }

    CompareHbvScenarios(scenariosToCompare);
  }

  //-------------------------------------------------------------------------------
  // Load the clicked HBV Scenario.
  //-------------------------------------------------------------------------------
  const onScenarioButtonClick = (clickedHbvScenario: IProjectScenario) => 
  {
    ResetHbv();
    LoadHbvScenario(clickedHbvScenario);
  }

  //-------------------------------------------------------------------------------
  // Confirm deletion of the clicked HBV Scenario.
  //-------------------------------------------------------------------------------
  const onConfirmScenarioDelete = (clickedHbvScenario: IProjectScenario) => 
  {
    setDeleteScenario(clickedHbvScenario);
    setDeleteScenarioConfirmation(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the specified HBV Scenario.
  //-------------------------------------------------------------------------------
  const onScenarioDelete = () => 
  {
    setDeleteScenarioConfirmation(false);

    if(!deleteScenario || !store_project || !store_project.project_id ||
       !store_project.scenario_group_id) return;

    DeleteHbvScenario(deleteScenario.hbv_scenario_id, store_project.scenario_group_id);
  }

  //-------------------------------------------------------------------------------
  // Initiate the process of renaming the clicked HBV Scenario.
  //-------------------------------------------------------------------------------
  const onInitiateScenarioRenameClick = (clickedHbvScenario: IProjectScenario) => 
  {
    // Start out with the current name
    setNewHbvScenarioName(clickedHbvScenario.name);

    // Save the original name, so we will know if the user made changes when they hit "Ok"
    setOrigHbvScenarioName(clickedHbvScenario.name);

    // Tell the UI we are in the process of renaming a scenario (and which one)
    setRenameScenarioID(clickedHbvScenario.hbv_scenario_id);
  }

  //-------------------------------------------------------------------------------
  // Rename the HBV scenario.
  //-------------------------------------------------------------------------------
  const onScenarioRenameClick = () => 
  {
    if(!renameScenarioID || !newHbvScenarioName || !store_project || !store_project.project_id || !store_project.scenario_group_id) return;

    // If the name hasn't changed, do nothing
    if(origHbvScenarioName && origHbvScenarioName.trim() === newHbvScenarioName.trim())
    {
      setRenameScenarioID(null);
      return;
    }

    // We rename the scenario in the UI immediately, regardless of the outcome of the 
    // server call that comes after.  We want the UI to appear fast and responsive, not
    // wait for the server call outcome.  In the unlikely event the server call does fail,
    // a toast notification error message will let the user know.

    // Rename the scenario in the project's hbv scenarios list (note: no need to mark the project as dirty)
    useStore.getState().store_setProjectHbvScenarioName(renameScenarioID, newHbvScenarioName);

    // If we've renamed the active hbv scenario, also update the active scenario in the store
    const activeHbvScenario = useStore.getState().store_hbvScenario;
    if(activeHbvScenario && renameScenarioID === activeHbvScenario.scenario_id)
      useStore.getState().store_setHbvScenarioName(newHbvScenarioName);

    RenameHbvScenario(store_project.scenario_group_id, renameScenarioID, newHbvScenarioName);
  }

  //-------------------------------------------------------------------------------
  // Cancel the renaming of an HBV scenario.
  //-------------------------------------------------------------------------------
  const onScenarioRenameCancel = () => 
  {
    setRenameScenarioID(null);
  }

  //-------------------------------------------------------------------------------
  // Re-run the clicked HBV Scenario.
  //-------------------------------------------------------------------------------
  // const onReRunScenario = (clickedHbvScenario: IProjectHbvScenarioListItem) => 
  // {
  //   if(!store_project || !store_project.project_id) return;

  //   ReRunHbvScenario_NoScenarioData(clickedHbvScenario.hbv_scenario_id, store_project.project_id);
  // }

  //-------------------------------------------------------------------------------
  // Returns the scenarios in the active project grouped by the AOI they each 
  // belong to.
  //
  // Groups are sorted by AOI name, and within each AOI scenarios are sorted by name.
  //-------------------------------------------------------------------------------
  function GetScenarioAoiGroups(): IScenarioAoiGroup[]
  {
    if(!store_project) return [];

    const scenarioGroups: IScenarioAoiGroup[] = [];

    for(let i=0; i < store_project.scenarios.length; i++)
    {
      // Some older scenarios somehow ended up with undefined aoi_name, which would crash the sorting, so
      // assign these a generic "No AOI Name" name.
      if(store_project.scenarios[i].aoi_name === undefined || store_project.scenarios[i].aoi_name === null)
        store_project.scenarios[i].aoi_name = '[No AOI Name]';

      let group: IScenarioAoiGroup | undefined = scenarioGroups.find(item => item.aoi_id === store_project.scenarios[i].aoi_id);

      // If there is no group for this scenario, add a new group
      if(!group)
      {
        group = 
        {
          aoi_id: store_project.scenarios[i].aoi_id,
          aoi_name: store_project.scenarios[i].aoi_name,
          scenarios: []
        }
        group.scenarios.push(store_project.scenarios[i]); // Add this scenario to the new group
        scenarioGroups.push(group);
      }
      else // Add this scenario to the existing group
        group.scenarios.push(store_project.scenarios[i]);
    }

    // Sort groups by AOI name
    scenarioGroups.sort((a, b) => a.aoi_name.localeCompare(b.aoi_name))

    // Within each group, sort by scenario name
    for(let i=0; i < scenarioGroups.length; i++)
      scenarioGroups[i].scenarios.sort((a, b) => a.name.localeCompare(b.name))

    // Done
    return scenarioGroups;
  }









  // Main render - HBV data is active

  if(store_project && store_aoi)
  return (

    <Stack sx={{ ml: '-10px', width: 'calc(100% + 20px)', mt: -1.2 }}>

      {/* <Typography sx={{ color: theme_orange, mt: 0, mb: 0 }}>
        Saved Scenarios
      </Typography> */}

      {store_project.scenarios.length === 0
        ?
          <Typography sx={{ color: theme_textColorBlended, fontSize: '0.9rem', mt: 0, mb: 0 }}>
            You have no saved scenarios.
          </Typography>
        :
          <Stack>
            <Stack sx={{ overflow: 'auto', maxHeight: '370px' }}>

              {GetScenarioAoiGroups().map(scenarioAoiGroup =>

                <Stack key={scenarioAoiGroup.aoi_id} sx={{ mb: 2 }}>

                  <TypographyWithAutoTooltip placement='right' arrow sx={{ maxWidth: '100%', fontSize: '0.9rem', textTransform: 'none', color: theme_orange }}>
                    {scenarioAoiGroup.aoi_name}
                  </TypographyWithAutoTooltip>

                  {scenarioAoiGroup.scenarios.map(projectHbvScenario => 

                    <div key={projectHbvScenario.hbv_scenario_id}>
                      {projectHbvScenario.hbv_scenario_id === renameScenarioID
                        ?
                          // The item is in RENAME mode

                          <Stack direction='row' sx={{ width: '100%', alignItems: 'center', mt: '3px', mb: '3px', pr: '5px' }}>

                            <RoundedInputBox2 title='New scenario name' 
                                              onAccept={onScenarioRenameClick} onCancel={onScenarioRenameCancel}
                                              value={newHbvScenarioName} setValue={setNewHbvScenarioName}/>
                          </Stack>
                        :

                          // The item is not in rename mode

                          <Stack direction='row' sx={{ width: '100%', alignItems: 'center', mt: '3px', mb: '3px' }}>

                            <Checkbox size="small" sx={{ pl: 0, pt: 0.2, pb: 0.2, color: theme_limeGreen, opacity: 0.5 }} 
                                      checked={projectHbvScenario.selected}
                                      onChange={(e,c) => onHbvScenarioCheckBoxChange(e,c,projectHbvScenario)}/>

                            <Button variant='outlined' size='small' onClick={(e) => onScenarioButtonClick(projectHbvScenario)}
                                    sx={{ ml: '-5px', mr: '5px', width: '100%', justifyContent: 'left', borderRadius: 10,
                                          borderColor: store_hbvScenario && projectHbvScenario.hbv_scenario_id===store_hbvScenario.scenario_id ? theme_orange : '#6F92C9' }}>
                              <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>

                                <TypographyWithAutoTooltip placement='right' arrow sx={{ maxWidth: '100%', fontSize: '0.8rem', textTransform: 'none', color: theme_textColorMain }}>
                                  {projectHbvScenario.name}
                                </TypographyWithAutoTooltip>

                                <Stack direction='row' sx={{ width: '100%', justifyContent: 'center' }}>
                                  <Typography sx={{ fontSize: '0.6rem', textTransform: 'none', color: theme_orange, opacity: 0.8 }}>
                                    {FriendlyCurrency(projectHbvScenario.dollars)}
                                  </Typography>
                                  <Typography sx={{ ml: '10px', fontSize: '0.6rem', opacity: 0.9, textTransform: 'none', color: theme_textColorBlended }}>
                                    {projectHbvScenario.last_run_date}
                                  </Typography>
                                </Stack>

                                {/* "Created by" is only shown if the scenario was created by a different user
                                This is done to save screen space, as most of the time, all scenarios will be 
                                self-created. */}

                                {projectHbvScenario.created_by && store_userInfo && projectHbvScenario.created_by !== `${store_userInfo.first_name} ${store_userInfo?.last_name}`
                                  ?
                                    <Typography sx={{ fontSize: '0.6rem', textTransform: 'none', color: theme_limeGreen, opacity: 0.8 }}>
                                      Created by { projectHbvScenario.created_by}
                                    </Typography>
                                  :null
                                }

                              </Stack>
                            </Button>

                            <Stack sx={{ alignItems: 'center' }}>

                              <IconButton size="small" sx={{ p: '2px', mb: '3px' }} onClick={(e) => onInitiateScenarioRenameClick(projectHbvScenario)}>
                                <EditIcon sx={{ color: theme_limeGreen, width: '16px', height: '16px'}}/>
                              </IconButton>

                              <IconButton size="small" sx={{ p: '2px', mt: '3px', opacity: 0.4 }} onClick={(e) => onConfirmScenarioDelete(projectHbvScenario)}>
                                <DeleteIcon sx={{ color: theme_textColorBlended, width: '16px', height: '16px'}}/>
                              </IconButton>

                            </Stack>

                          </Stack>
                      }
                    </div>
                        
                  )}
                </Stack>
              )}

            </Stack>

            <Button variant='outlined' onClick={onCompareClick} 
                    sx={{ fontSize: '0.8rem', textTransform: 'none', borderRadius: 100, mt: 2, 
                          pl: 0, pr: 0,
                          opacity: CanDoScenarioCompare() ? 1 : 0.6,
                          color: CanDoScenarioCompare() ? theme_limeGreen : theme_textColorBlended,
                          fontWeight: CanDoScenarioCompare() ? 'bold' : 'normal',
                       }}>
              <BalanceIcon sx={{ mr: 0.8 }}/>
              {CanDoScenarioCompare() ? 'Compare selected scenarios' : 'Select 2 or 3 scenarios to compare'}
            </Button>

          </Stack>
      }

      {/* "Delete AOI" Yes/No confirmation dialog */}

      <YesNoDialog open={deleteScenarioConfirmation} setOpen={setDeleteScenarioConfirmation}
                   onYes={onScenarioDelete}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended }} />
          <Box sx={{ color: theme_textColorMain }}>Delete HBV scenario</Box>
          <Box sx={{ ml: 1, color: theme_orange, fontWeight: 'bold' }}>{deleteScenario?.name}</Box>
          <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
        </Stack>
      </YesNoDialog>
      
    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall-through case, ideally should never get called.
  // ******************************************************************************************

  return (
    <Box sx={{ color: theme_textColorBlended }}>
      HBV Scenarios unknown state
    </Box>
  )


}





export default Scenarios;
