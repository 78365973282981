// Base map bundle layer item component.

import { FormControlLabel, Checkbox, Typography, Stack } from "@mui/material";
import { IBaseMapLayer } from "./Layers/LayersPanel";
import { theme_textColorBlended, theme_textColorMain } from "./Theme";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface BaseMapLayerItemProps
{
  layer: IBaseMapLayer | null;
  onChange: any;
  MuiIcon: any;
}

//-------------------------------------------------------------------------------
// BaseMapBundleLayerItem component
//-------------------------------------------------------------------------------
export function BaseMapLayerItem(props: BaseMapLayerItemProps) 
{
  


  return (

    <FormControlLabel sx={{ height: '26px', ml: '-9px', mb: '0px' }}
      control=
      {
        <Checkbox sx={{ pr: '5px', color: theme_textColorBlended + 'A0' }} name={props.layer?.name} size="small"
                  checked={props.layer?.enabled} onChange={props.onChange}/> 
      }
      label=
      {
        <Stack direction='row' sx={{ width: '100%', alignItems: 'center' }}>

          {props.MuiIcon}

          <Typography sx={{ width: '100%', fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.8 }}>
            {props.layer?.name}
          </Typography>

        </Stack>
      }/>

  );
}








/*
//-------------------------------------------------------------------------------
// AccordionSummary custom style.
//-------------------------------------------------------------------------------
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  //padding: 0, 
  //marginTop: -10, 
  //marginBottom: -10,
  //height: '30px',

  minHeight: 30,
  maxHeight: 30,
  overflow: 'hidden',

  '&.Mui-expanded': 
  {
    minHeight: 30,
    maxHeight: 30,
    //backgroundColor: '#FFF2F2',
  },

  //backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, 0.2)',
  //flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': 
  // {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}));
*/