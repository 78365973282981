// Edit layer library group window

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, styled } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { ToastNotification } from '../ToastNotifications';
import { LAYER_LIBRARY_GROUP_NAME_MAX_CHARS } from './CreateNewGroup';
import { ILayerLibraryGroup } from './LayerLibraryInterfaces';
import { EditLayerLibraryGroup } from './LayerLibraryOps';



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditGroupProps
{
  group: ILayerLibraryGroup | undefined;
  showDialog: boolean;
  setShowDialog: any;
}

//-------------------------------------------------------------------------------
// Edit layer library group window
//-------------------------------------------------------------------------------
export function EditGroup(props: EditGroupProps) 
{
  const [groupName, setGroupName] = useState<string>('');










  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    if(props.group && props.group.name !== groupName)
      setGroupName(props.group.name);

  }, [props.group]);


  //-------------------------------------------------------------------------------
  // Cancel without editing the new group.
  //-------------------------------------------------------------------------------
  const OnClose = () => 
  {
    // Reset the group name back to the current name
    if(props.group && props.group.name)
      setGroupName(props.group?.name)

    props.setShowDialog(false);
  }

  //-------------------------------------------------------------------------------
  // The group name has changed.
  //-------------------------------------------------------------------------------
  function OnGroupNameTextChange(newName: string)
  {
    if(newName.length > LAYER_LIBRARY_GROUP_NAME_MAX_CHARS)
      return;

    setGroupName(newName);
  }

  //-------------------------------------------------------------------------------
  // Accept the info.
  //-------------------------------------------------------------------------------
  const OnAcceptEdit = async () => 
  {
    if(!groupName || groupName.trim().length === 0)
    {
      ToastNotification('error', 'Please enter in a name for the layer library group');
      return;
    }

    if(!props.group) return;

    const ret: boolean = await EditLayerLibraryGroup(props.group.layer_library_group_id, groupName);
    if(ret === false)
      return;

    // Success - close the dialog
    props.setShowDialog(false);
  }





  





  if(!props.group) return null;

  // Main render

  return (

    <Dialog open={props.showDialog} onClose={OnClose} maxWidth='md'>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' alignItems='center'>
            <Typography sx={{ fontSize: '1.3rem', color: theme_textColorMain }}>
              Rename layer group
            </Typography>
            <Typography sx={{ ml: 1, fontSize: '1.3rem', fontWeight: 'bold', color: theme_orange }}>
              {props.group.name}
            </Typography>
          </Stack>

          <IconButton size="small" onClick={OnClose}
                      sx={{ ml: 12, padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0, background: theme_bgColorGradient2 }}>

        <Stack sx={{ mx: 2, mt: 2 }}>

          <CustomTextField variant='outlined' autoFocus size='small' id="newGroupName" name="newGroupName" fullWidth
                    value={groupName} onChange={(e)=>OnGroupNameTextChange(e.target.value)} sx={{ p:0, m:0 }}/>

        </Stack>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='outlined' onClick={OnClose} sx={{ width: '100px' }}>
            Cancel
          </Button>
        </Stack>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='contained' onClick={(_)=>OnAcceptEdit()} sx={{ width: '200px', fontWeight: 'bold' }}>
            Accept Changes
          </Button>
        </Stack>

      </DialogActions>

    </Dialog>
  )
}







// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
 const CustomTextField = styled(TextField)(() => (
{

  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain,
  },  
  "& .MuiOutlinedInput-notchedOutline": 
  {
    borderColor: theme_textColorBlended,
  },
  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    }
  },
  "&:hover": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_orange,
    }
  },
}));
