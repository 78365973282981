// HBV panel impact item component

import { Box, IconButton, Stack, Typography } from "@mui/material";
import { theme_limeGreen, theme_orange, theme_textColorBlended } from "../Theme";
import { FriendlyCurrency, FriendlyNumber } from "../Globals";
import { IsMapLayerEnabled } from "../Layers/LayerOps";
import TypograpyWithAutoTooltip from '../Components/TypograpyWithAutoTooltip';
import MapIcon from '@mui/icons-material/Map';
import { IHbvImpact } from "./HbvInterfaces";
import { GetImpactName } from "../Bundles/BundleOps";

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface HbvPanelImpactItemProps
{
  hbvImpact: IHbvImpact;
  onViewImpact: any;
  itemIndex?: number;
  selectedImpactIndex: number | undefined;
  setSelectedImpactIndex: any;
  selectedPopoutImpactIndex: number | undefined;
  setSelectedPopoutImpactIndex: any;
}

//-------------------------------------------------------------------------------
// HBV panel impact item component
//-------------------------------------------------------------------------------
const HbvPanelImpactItem = (props: HbvPanelImpactItemProps) => 
{
  const {hbvImpact } = props;




  //-------------------------------------------------------------------------------
  // When the mouse enter this impact section, the pie chart piece for it will be
  // "selected" visually.
  //-------------------------------------------------------------------------------
  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => 
  {
    if(props.itemIndex === undefined) return;

    props.setSelectedImpactIndex(props.itemIndex);
  }

  //-------------------------------------------------------------------------------
  // When the mouse leaves this impact section, the pie chart piece for it will be
  // "deselected" visually.
  //-------------------------------------------------------------------------------
  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => 
  {
    if(props.itemIndex === undefined) return;

    props.setSelectedImpactIndex(undefined);
    props.setSelectedPopoutImpactIndex(undefined);
  }









  // Main render

  return (

    <Stack direction='row'
           sx={{ width: '100%', mt: 0, mb: 0, alignItems: 'center', justifyContent: 'space-between' }}
           onMouseEnter={(event) => onMouseEnter(event)}
           onMouseLeave={(event) => onMouseLeave(event)}>

      {/* Impact color tab (references the color in the chart) */}
      <Box sx={{ width: '7px', height: '32px', bgcolor: props.hbvImpact.color, ml: 0,
                 borderTopRightRadius: 6, borderBottomRightRadius: 6 }}/>

      <Stack direction='column' 
             sx={{ 
                   width: hbvImpact.layer ? 'calc(100% - 23px)' : '100%', 
                   ml: '3px', borderRadius: 2, p: '4px', 
                   bgcolor: props.itemIndex === props.selectedImpactIndex || props.itemIndex === props.selectedPopoutImpactIndex ? `${props.hbvImpact.color}60` : null,
                }}>

        <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>

          <TypograpyWithAutoTooltip sx={{ width: 'calc(100% - 90px)', fontSize: '0.9rem' }}>
            {GetImpactName(hbvImpact.impact_id)}
          </TypograpyWithAutoTooltip>

          <Typography noWrap sx={{ width: '90px', textOverflow: 'ellipsis', textAlign: 'right', ml: '2px',  color: theme_limeGreen, fontSize: '1.0rem' }}>
            {FriendlyCurrency(hbvImpact.dollar,0)}
          </Typography>

        </Stack>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='column'>
            <Typography noWrap sx={{ textAlign: 'left', color: 'white', opacity: 0.5, fontSize: '0.8rem' }}>
              {FriendlyNumber(hbvImpact.acres,0)} acres
            </Typography>
          </Stack>

          <TypograpyWithAutoTooltip sx={{ maxWidth: '72%', color: theme_orange, opacity: 0.9, fontSize: '0.8rem', textAlign:'right' }}>
            {FriendlyNumber(hbvImpact.impact)} {hbvImpact.unit}
          </TypograpyWithAutoTooltip>

        </Stack>

      </Stack>

      {/* View impact icon button (right side of item) */}
      
      {hbvImpact.layer
        ?
          <IconButton size='small' onClick={(_) => props.onViewImpact(hbvImpact)} disabled={hbvImpact.layer===undefined}
                      sx={{ p:0, ml:'0px', mr: '0px', textTransform: 'none', opacity: 0.8, width: '20px', height: '20px',
                            color: hbvImpact.layer && IsMapLayerEnabled(hbvImpact.layer.id) ? theme_orange : theme_textColorBlended }}>
            <MapIcon sx={{ width: '20px', height: '20px' }}/>
          </IconButton>
        :null
      }

    </Stack>
  )
}





export default HbvPanelImpactItem;
