// Allows the user to change the base map

import { Stack, ToggleButton, styled, Box } from "@mui/material";
import useStore from "./store";
import { theme_bgColorLight, theme_bgColorLight1, theme_orange } from "./Theme";
import { BaseMap } from "./BaseMapPanel";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface BaseMapPanelImageButtonProps
{
  name: string;
  baseMap: BaseMap;
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
  image: any;
  onBaseMapChanged: any;
  imageSize: number;
  textFontSize: string;
  // setBasePanelOpacity: React.Dispatch<React.SetStateAction<number>>;
}

//-------------------------------------------------------------------------------
// Base map panel image toggle button component
//-------------------------------------------------------------------------------
const BaseMapPanelImageButton = (props: BaseMapPanelImageButtonProps) => 
{
  // Get needed state data from the store
  const { store_baseMap,
        } = useStore();



  // Main render

  return (

    <BaseMapImageToggleButton value={props.baseMap} selected={store_baseMap===props.baseMap} 
                              sx={{ borderRadius: 0,
                                    borderTopLeftRadius: props.borderTopLeftRadius,
                                    borderTopRightRadius: props.borderTopRightRadius,
                                    borderBottomLeftRadius: props.borderBottomLeftRadius, 
                                    borderBottomRightRadius: props.borderBottomRightRadius,
                                  }} 
                              onChange={props.onBaseMapChanged}>

      <Stack direction='column'>
        
        {/* {window.innerHeight >= 400 ? : } */}

        <Box sx={{ m: 0, p: '2px', bgcolor: '#2D5791', width: `${props.imageSize+4}px`, height: `${props.imageSize+4}px`, borderRadius: '5px' }}>
          <img src={props.image} alt="" style={{ width: `${props.imageSize}px`, height: `${props.imageSize}px`, borderRadius: '3px' }} />
        </Box>

        <div style={{ maxWidth: `${props.imageSize}px`, fontSize: props.textFontSize }}>
          {props.name}
        </div>

      </Stack>
    </BaseMapImageToggleButton>
  );
}











// Custom style for the main base map image toggle buttons
const BaseMapImageToggleButton = styled(ToggleButton)(
{
  color: '#E0E0E0',
  backgroundColor: theme_bgColorLight1,

  '&:hover': 
  {
    color: '#FFFFFF',
    backgroundColor: theme_bgColorLight,
  },

  // selected (and overides hover color)
  "&.Mui-selected, &.Mui-selected:hover": 
  {
    color: '#1C305A',
    backgroundColor: theme_orange//'#78A1E8'
  }
});


export default BaseMapPanelImageButton;