//import ReactDOM from 'react-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from './Account/LogIn';
import SignUp from './Account/SignUp';
import ConfirmUser from './Account/ConfirmUser';
import ReactDOM from 'react-dom/client';
import ErrorBoundary from './ErrorBoundary';
import ForgotPassword from './Account/ForgotPassword';
import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER_ID } from './Globals';

TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Account/LogIn" element={<LogIn />} />
        <Route path="/Account/SignUp" element={<SignUp />} />
        <Route path="/Account/ConfirmUser" element={<ConfirmUser />} />
        <Route path="/Account/ForgotPassword" element={<ForgotPassword />} />
      </Routes>
    </BrowserRouter>
  </ErrorBoundary>
  // </React.StrictMode>
);
