// HBV details panel core component

import { Box, IconButton, Stack, styled, ToggleButton, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight, theme_bgColorLight1, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import HbvChart from './HbvChart';
import { useState } from "react";
import HbvPanelImpactItem from "./HbvPanelImpactItem";
import { AddLayerToMap, GetLayerByID, IsMapLayerEnabled, RemoveLayerFromMap } from "../Layers/LayerOps";
import { GetHbvPaint_2D, GetHbvPaint_3D } from "./HbvOps";
import MapIcon from '@mui/icons-material/Map';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import React from "react";
import { IHbvImpact, IHbvScenario } from "./HbvInterfaces";
import { ILayer } from "../Layers/LayerInterfaces";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";
import { FriendlyNumber } from "../Globals";


const IMPACT_TILE_HEIGHT: number = 55;
const IMPACT_TILES_SHOWN_BEFORE_SCROLLING: number = 8;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface HbvPanelCoreProps
{
  scenario: IHbvScenario;
  impactPanelHeight?: number;
  allowScenarioRefresh?: boolean;
}

//-------------------------------------------------------------------------------
// HBV panel core component
//-------------------------------------------------------------------------------
const HbvPanelCore = (props: HbvPanelCoreProps) => 
{
  const {scenario, impactPanelHeight, allowScenarioRefresh } = props;

  // Get needed state data from the store
  const { store_setLayerEnabled, store_map, store_setLayerType, store_setLayerStyle, 
        } = useStore();

  const [hbv3DMode, setHbv3DMode] = useState<boolean>(false);
  
  const [selectedImpactIndex, setSelectedImpactIndex] = useState<number | undefined>(undefined);
  const [selectedPopoutImpactIndex, setSelectedPopoutImpactIndex] = useState<number | undefined>(undefined);
  




  //-------------------------------------------------------------------------------
  // Toggle between 2D and 3D mode for the main HBV layer.
  //-------------------------------------------------------------------------------
  const onHbv3DToggleChanged = (event: React.MouseEvent<HTMLElement>) => 
  {
    if(hbv3DMode)
    {
      // Turning 3D mode OFF

      onHbv2DStyle();
      setHbv3DMode(false);
    }
    else
    {
      // Turning 3D mode ON

      onHbv3DStyle();
      setHbv3DMode(true);
    }
  }  
  
  //-------------------------------------------------------------------------------
  // HBV Style 1 - 2D
  //-------------------------------------------------------------------------------
  const onHbv2DStyle = async () => 
  {
    if(!store_map) return;

    const hbvLayer = GetLayerByID(-1);
    if(!hbvLayer) return;
    
    // Apply it to the layer (state store)

    store_setLayerType(hbvLayer.id, 'fill');
    store_setLayerStyle(hbvLayer.id, GetHbvPaint_2D(hbvLayer));

    // Remove and re-add the layer (an easy way to apply the style)
    RemoveLayerFromMap(hbvLayer.id);
    if(hbvLayer.enabled)  // No need to re-add it if it's not currently enabled
      AddLayerToMap(hbvLayer.id);

    store_map.setBearing(0);
    store_map.setPitch(0);
  }

  //-------------------------------------------------------------------------------
  // HBV Style 2 - 3D
  //-------------------------------------------------------------------------------
  const onHbv3DStyle = async () => 
  {
    if(!store_map) return;

    const hbvLayer = GetLayerByID(-1);
    if(!hbvLayer) return;

    if(!hbvLayer.enabled) return;

    // Apply it to the layer (state store)

    store_setLayerType(hbvLayer.id, 'fill-extrusion');
    store_setLayerStyle(hbvLayer.id, GetHbvPaint_3D(hbvLayer));

    // Remove and re-add the layer (an easy way to apply the style)
    RemoveLayerFromMap(hbvLayer.id);
    AddLayerToMap(hbvLayer.id);

    store_map.setBearing(0);
    store_map.setPitch(60);
  }

  //-------------------------------------------------------------------------------
  // The "View Main HBV" button was clicked.
  //
  // When the main HBV is turne ON, all other impacts are turned OFF.
  // When the main HBV is turned OFF, nothing else changes.
  //-------------------------------------------------------------------------------
  const onClickViewHbvLayerButton = () => 
  {
    if(!scenario.hbv_data.layer) return;
    
    const mapMainHbvLayer = GetLayerByID(scenario.hbv_data.layer.id);
    if(!mapMainHbvLayer) return;

    // Turn OFF any impact layers that are currently on
    TurnOffAllImpactLayers();
    
    // Toggle the state of the main HBV layer

    if(mapMainHbvLayer.enabled === false)
    {
      store_setLayerEnabled(mapMainHbvLayer.id, true);
      AddLayerToMap(mapMainHbvLayer.id);
    }
    else
    {
      store_setLayerEnabled(mapMainHbvLayer.id, false);
      RemoveLayerFromMap(mapMainHbvLayer.id);
    }
  }

  //-------------------------------------------------------------------------------
  // One of the "View Impact" buttons was clicked.
  //
  // When an impact is turned ON, all other impacts are turned OFF.
  // When an impact is turned OFF, nothing else changes.
  //-------------------------------------------------------------------------------
  const onViewImpactButtonClick = (hbvImpact: IHbvImpact) => 
  {
    if(!hbvImpact.layer || !scenario.hbv_data.layer) return;

    // Turn off the main HBV layer (if it's currently visible on the map)

    const mapMainHbvLayer = GetLayerByID(scenario.hbv_data.layer.id);
    if(!mapMainHbvLayer) return;
    
    if(mapMainHbvLayer.enabled)
    {
      store_setLayerEnabled(mapMainHbvLayer.id, false);
      RemoveLayerFromMap(mapMainHbvLayer.id);
    }

    // If 3D mode was on, turn it off (currently impact layers don't support 3D mode)
    if(hbv3DMode)
    {
      setHbv3DMode(false);
      onHbv2DStyle();
    }

    // Get the clicked impact layer (we need to know its current 'enabled' state)

    const clickedLayer = GetLayerByID(hbvImpact.layer.id);
    if(!clickedLayer) return;

    if(clickedLayer.enabled === true)
    {
      // The clicked layer was ON.  Turn it OFF.

      store_setLayerEnabled(hbvImpact.layer.id, false);
      RemoveLayerFromMap(hbvImpact.layer.id);
    }
    else
    {
      // The clicked layer was OFF.  Turn it on, and all other impact layers off.

      // Turn OFF any impact layers that are currently on
      TurnOffAllImpactLayers();

      // Turn ON the clicked impact layer
      store_setLayerEnabled(hbvImpact.layer.id, true);
      AddLayerToMap(hbvImpact.layer.id);
    }
  }

  //-------------------------------------------------------------------------------
  // Turn OFF any impact layers that are currently on.
  //-------------------------------------------------------------------------------
  function TurnOffAllImpactLayers()
  {
    for(let i=0; i < scenario.hbv_data.break_out.length; i++)
    {
      const breakOutImpactLayer: ILayer | undefined = scenario.hbv_data.break_out[i].layer;
      if(!breakOutImpactLayer) continue;

      const mapImpactLayer = GetLayerByID(breakOutImpactLayer.id);
      if(!mapImpactLayer) return;

      if(mapImpactLayer.enabled)
      {
        store_setLayerEnabled(mapImpactLayer.id, false);
        RemoveLayerFromMap(mapImpactLayer.id);
      }
    }
  }

  //-------------------------------------------------------------------------------
  // Refresh this HBV scenario.
  //-------------------------------------------------------------------------------
  // const onScenarioRefreshClick = () => 
  // {
  //   if(!scenario || !scenario.scenario_id || !store_project || !store_project.project_id) return;

  //   ResetHbv();

  //   ReRunHbvScenario_GetScenarioData(scenario.scenario_id, store_project.project_id);
  // }









  // ******************************************************************************************
  // Main render - an HBV scenario is active
  // ******************************************************************************************

  if(scenario.hbv_data)
  return (

    <Stack direction='column' sx={{ width: '100%', height: '100%', alignItems: 'center' }}>

      {/* AOI name + acres */}

      <Stack direction='row' sx={{ justifyContent: 'center', mb: 2, width: '100%' }}>
        <TypographyWithAutoTooltip sx={{ fontSize: '0.8rem', color: theme_textColorBlended, textTransform: 'none', maxWidth: '55%' }}>
          {scenario.aoi_name}
        </TypographyWithAutoTooltip>
        <Typography sx={{ color: 'white', opacity: 0.7, ml: 1, fontSize: '0.8rem', textTransform: 'none' }}>
          {FriendlyNumber(scenario.aoi_acres)} acres
        </Typography>
      </Stack>

      {/* Scenario name + total dollars */}

      <Stack direction='row' sx={{ width:'100%', alignItems: 'center' }}>

        <Stack direction='column' sx={{ width: '100%', alignItems: 'center', mr: '2px' }}>

          {/* Scenario name */}

          <Stack sx={{ height: '52px', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '1.1rem', color: theme_textColorMain, textTransform: 'none', textAlign: 'center' }}>
              {scenario.name}
            </Typography>
          </Stack>

        </Stack>
        
        {/* View layer button + 3D toggle */}

        {scenario.hbv_data.layer
          ?
            <Stack direction='column' sx={{ alignItems: 'center' }}>

              {scenario.hbv_data.hbv > 0 && IsMapLayerEnabled(scenario.hbv_data.layer.id)
                ?
                  <MyToggleIconButton value='3d' selected={hbv3DMode} onChange={onHbv3DToggleChanged}
                                      sx={{ p: 0, borderWidth: 0, width: '22px', height: '22px', mb: '3px' }}>
                    <ThreeDRotationIcon sx={{ color: 'inherit', width: '20px', height: '20px', opacity: 0.8 }} />
                  </MyToggleIconButton>
                :null
              }

              <IconButton size='small' onClick={(e) => onClickViewHbvLayerButton()}
                          sx={{ p:0, textTransform: 'none', opacity: 0.8, 
                                color: scenario.hbv_data.layer && IsMapLayerEnabled(scenario.hbv_data.layer.id) ? theme_orange : theme_textColorBlended }}>
                <MapIcon/>
              </IconButton>

            </Stack>
          :null
        }

      </Stack>

      {/* Chart panel */}

      <HbvChart scenario={scenario} allowScenarioRefresh={allowScenarioRefresh}
                selectedImpactIndex={selectedImpactIndex} 
                setSelectedImpactIndex={setSelectedImpactIndex}
                selectedPopoutImpactIndex={selectedPopoutImpactIndex}
                setSelectedPopoutImpactIndex={setSelectedPopoutImpactIndex}/>

      {/* HBV impacts list */}

      <Stack sx={{ width: '100%', height: impactPanelHeight ? `${impactPanelHeight}px` : null, mt: 1 }}>
      {/* NOTE: This extra Stack is here to force the charts to line up when comparing scenarios */}

        <Box sx={{ width: '100%', borderRadius: 2, 
                   pl:0, pr:'4px', pt:0.5, pb:0.5, overflow: 'auto', maxHeight: `${IMPACT_TILE_HEIGHT*IMPACT_TILES_SHOWN_BEFORE_SCROLLING}px` }}>

          <Stack direction='column'>

            {scenario.hbv_data.nrrGroups && scenario.hbv_data.nrrGroups.map(hbvResultsNrrGroup => 

              <Stack key={hbvResultsNrrGroup.nrr_id} direction='column'>

                <Typography sx={{ color: theme_textColorBlended, mt: '2px', mb: '2px' }}>
                  {hbvResultsNrrGroup.nrr_name}
                </Typography>

                <Stack sx={{ bgcolor: theme_bgColorLight1, borderRadius: 2, mb: 1 }}>

                  {hbvResultsNrrGroup.break_out.map(hbvImpact => 

                    // Ignore impacts that have a 0 dollar value
                    hbvImpact.dollar > 0 
                      ?
                        <HbvPanelImpactItem key={hbvImpact.impact_id} hbvImpact={hbvImpact} onViewImpact={onViewImpactButtonClick}
                                            selectedImpactIndex={selectedImpactIndex} 
                                            setSelectedImpactIndex={setSelectedImpactIndex} itemIndex={hbvImpact.itemIndex}
                                            selectedPopoutImpactIndex={selectedPopoutImpactIndex}
                                            setSelectedPopoutImpactIndex={setSelectedPopoutImpactIndex}/>                                      
                      : 
                        null
                    )}
                </Stack>

              </Stack>
            )}

          </Stack>
        </Box>
      </Stack>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall through (should never happen)
  // ******************************************************************************************

  return (
    <div>No HBV data</div>
  )

}



// Custom style for the 3D toggle icon button
export const MyToggleIconButton = styled(ToggleButton)(
  {
    color: theme_textColorBlended,
    borderColor: theme_bgColorLight,
  
    '&:hover': 
    {
      borderColor: theme_orange,
    },
  
    // selected (and overides hover color)
    "&.Mui-selected, &.Mui-selected:hover": 
    {
      color: theme_orange,
    }
  })


export default HbvPanelCore;
