
import useStore from "../store";
import { IHbvChartColor } from "./HbvInterfaces";


// TEMP:  A set of reasonable colors to use for the chart
// (HBV chart colors.png)
const colorTable: string[] = 
[
  '#FFCF62',
  '#9DD866',
  '#abd9e9',
  '#b2abd2',
  '#C7AD6F',
  '#95F5A6',
  '#bababa',
  '#EA96A3',
  '#FF8467',
  '#6F4E7C',
  '#CA472F',
  '#FFA056',
  '#0B84A5',

  '#CF7DDB',
  '#5A75ED',
  '#1CFFE8',
  '#365EFF',
  '#FFFF00',
  '#47913C',
  '#DB4DCE',
  '#36B5FF',

];
let colorTableNextIndex = 0;



//-------------------------------------------------------------------------------
// Returns a chart color for the specified string/name.
// If the item is new, a new color will be assigned.
// If the item already has a color, that color will be returned.
//-------------------------------------------------------------------------------
export function GetChartColor(itemName: string): string
{
  // If a color has already been assigned for this item name, return it
  const store_chartColors = useStore.getState().store_chartColors;

  let chartColor: IHbvChartColor | undefined = store_chartColors.find(chartColor => chartColor.name === itemName);
  if(chartColor)
    return chartColor.color;

  // Assign a new color, add it to the store, and return it
  const colorStr = colorTable[colorTableNextIndex++];
  useStore.getState().store_addChartColor(itemName, colorStr);
  return colorStr;
}
