// HBV chart

import useStore from "../store";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { theme_limeGreen, theme_textColorBlended } from "../Theme";
import { IHbvImpact, IHbvScenario } from "./HbvInterfaces";
import { FriendlyCurrency, FriendlyDateFromStr } from "../Globals";
import { useEffect, useState } from "react";
//import { PieChart } from 'react-minimal-pie-chart';
import PieChartWithToolTip from './PieChartWithToolTip';
//import { ResponsivePie } from '@nivo/pie'
//import { ResponsiveBar } from '@nivo/bar'
//import { IsometricProjection } from 'react-isometric-projection';
// import Chart from "react-google-charts";
import RefreshIcon from '@mui/icons-material/Refresh';
import { ResetHbv, ReRunHbvScenario_GetScenarioData } from "./HbvOps";


export const PIE_CHART_BG_COLOR = '#183D61';

// const data = [
//   ['Land Use', 'Corn', 'Soy', 'Hunting Lease', 'CRP'],
//   ['Land Use', 933, 456, 50, 10],
// ];

// export interface IHbvPanelChartDataItem
// {
//   id: number;
//   value: number;
//   valuePercent: number;
//   name: string;
//   color: string;
//   acres: number;
//   impactValue: number;
//   impactUnits: string;
//   impactLayer?: ILayer;
// }

export interface IHbvPanelPieChartDataItem
{
  id: number;
  color: string;
  value: number;
  //percent: number;
  //totalValue?: number;
  //key?: string | number;
  title?: string | number;
  //[key: string]: any;
}


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface HbvChartProps
{
  scenario: IHbvScenario;
  allowScenarioRefresh?: boolean;
  selectedImpactIndex: number | undefined;
  setSelectedImpactIndex: any;
  selectedPopoutImpactIndex: number | undefined;
  setSelectedPopoutImpactIndex: any;
}

//-------------------------------------------------------------------------------
// HBV chart component
//-------------------------------------------------------------------------------
const HbvChart = (props: HbvChartProps) => 
{
  const {scenario } = props;

  // Get needed state data from the store
  const { store_project } = useStore();
  
  //const [data, setData] = useState<IHbvPanelChartDataItem[]>([]);
  const [data, setData] = useState<IHbvPanelPieChartDataItem[]>([]);



  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    //setData(GetHbvPanelChartData(scenario));
    setData(GetHbvPanelPieChartData(scenario));

  }, [scenario]);


  //-------------------------------------------------------------------------------
  // When the mouse enter the chart area, make sure none of it's pie pieces are
  // popped out (sometimes that happens if the mouse moves fast from the impact tiles
  // to the chart).
  //-------------------------------------------------------------------------------
  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => 
  {
    props.setSelectedImpactIndex(undefined);
    props.setSelectedPopoutImpactIndex(undefined);
  }

  //-------------------------------------------------------------------------------
  // Refresh this HBV scenario.
  //-------------------------------------------------------------------------------
  const onScenarioRefreshClick = () => 
  {
    if(!scenario || !scenario.scenario_id || !store_project || !store_project.project_id || 
       !store_project.scenario_group_id) return;

    ResetHbv();

    ReRunHbvScenario_GetScenarioData(scenario.scenario_id, store_project.scenario_group_id);
  }




  // Main render

  return (

    <Box sx={{ width: '100%', mb: 1 }} onMouseEnter={(event) => onMouseEnter(event)}>


{/* 
      <div className="container mt-5">
        <Chart
            width={'100%'}
            height={'400px'}
            chartType= "ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            
            options={{
              isStacked: true,
              chartArea: { width: '20%' },
              backgroundColor: 'transparent',
              hAxis: {
                minValue: 0,
                textStyle:{color: 'white'},
              },
              vAxis: {
                title: 'Value ($)',
                textStyle:{color: 'white'},
              },
            }}
            rootProps={{ 'data-testid': '1' }}
          />              
      </div> */}

      {/* <ResponsivePie
        data={props.data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        valueFormat=" >-$,.0f"
        innerRadius={0.65}
        startAngle={180}
        endAngle={0}
        padAngle={2}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  0.2
              ]
          ]
        }}
        arcLinkLabel={function(e){return e.label+" ("+e.value+")"}}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="orange"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
        arcLabelsSkipAngle={10}
        legends={[]}
    /> */}

    <PieChartWithToolTip data={data} scenario={scenario}
                         selectedImpactIndex={props.selectedImpactIndex}
                         setSelectedImpactIndex={props.setSelectedImpactIndex}
                         selectedPopoutImpactIndex={props.selectedPopoutImpactIndex} 
                         setSelectedPopoutImpactIndex={props.setSelectedPopoutImpactIndex}/>
                {/* 
                label={(data) => data.dataEntry.title}
                labelPosition={100}
                labelStyle={{
                  fontSize: "8px",
                  color: "FFFFFA",
                  fontWeight: "100",
                }} */}

      {/* <Stack direction='column' sx={{ width: '100%', height:'100%' }}>

        {data.map(dataItem => 

          <Stack key={dataItem.id} direction='row' sx={{ height: `${dataItem.valuePercent}%` }}>

            <Box sx={{ position: 'relative', width: '80px', height: `100%`, boxShadow: 0,
                       bgcolor: `${dataItem.color}`, borderRadius: 0 }}/>

            <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>

              <Typography sx={{ ml: 1, color: theme_limeGreen, fontSize: '1rem' }}>
                {FriendlyCurrency(dataItem.value,0)}
              </Typography>

              <Typography sx={{ ml: 0, color: theme_orange, fontSize: '1rem', maxWidth: '150px', textAlign: 'right' }}>
                {dataItem.name}
              </Typography>

            </Stack>

          </Stack>

        )}
      </Stack> */}

    <Stack direction='column' sx={{ height: '55px', mt: '-65px', alignItems: 'center' }}>

      <Stack direction='row' sx={{ alignItems: 'center' }}>

        <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, textTransform: 'none' }}>
          {FriendlyDateFromStr(scenario.last_run_date)}
        </Typography>

        {props.allowScenarioRefresh && props.allowScenarioRefresh === true && scenario.scenario_id
          ?
            <Tooltip title='Refresh this HBV scenario'>
              <IconButton sx={{ p:0, ml: '4px' }} onClick={(e) => onScenarioRefreshClick()}>
                <RefreshIcon sx={{ width: '15px', height: '15px', color: theme_textColorBlended }}/>
              </IconButton>
            </Tooltip>
          :null
        }
      </Stack>

      <Typography sx={{ color: theme_limeGreen, justifyContent: 'center', textAlign: 'center', fontSize: '1.4rem', mt: '10px' }}>
        {FriendlyCurrency(scenario.hbv_data.hbv)}
      </Typography>

    </Stack>

    </Box>
  )
}





export default HbvChart;





/*
function GetHbvPanelChartData(scenario: IHbvScenario): IHbvPanelChartDataItem[]
{

  const hbv = scenario.hbv_data;
  if(!hbv) return [];

  let chartData: IHbvPanelChartDataItem[] = [];

  // Get dollar total

  let dollarTotal: number = 0;
  for(let i=0; i < hbv.break_out.length; i++)
  {
    const hbvInfoItem: IHbvImpact = hbv.break_out[i];
    dollarTotal += hbvInfoItem.dollar;
  }

  // Figure out the percent of dollar total for each item

  let newId = 1;

  for(let i=0; i < hbv.break_out.length; i++)
  {
    const hbvInfoItem: IHbvImpact = hbv.break_out[i];
    if(hbvInfoItem.dollar <= 0) continue;  // ignore zero value items

    const newChartDataItem: IHbvPanelChartDataItem = 
    {
      id: newId++,
      value: hbvInfoItem.dollar,
      valuePercent: hbvInfoItem.dollar / dollarTotal * 100.0,
      name: hbvInfoItem.land_use,
      //color: GetRandomColor(),
      color: GetChartColor(hbvInfoItem.land_use),
      acres: hbvInfoItem.acres,
      impactValue: hbvInfoItem.impact,
      impactUnits: hbvInfoItem.unit,
      impactLayer: hbvInfoItem.layer,
      //label: hbvInfoItem.land_use,
    }
    chartData.push(newChartDataItem);
  }

  // Sort (dollar descending)
  //chartData = chartData.sort((a: IHbvPanelChartDataItem, b: IHbvPanelChartDataItem) => b.value - a.value);

  return chartData;
}
*/

//-------------------------------------------------------------------------------
// Extracts the data needed for the pie chart from the specified scenario.
//-------------------------------------------------------------------------------
function GetHbvPanelPieChartData(scenario: IHbvScenario): IHbvPanelPieChartDataItem[]
{
  const hbv = scenario.hbv_data;
  if(!hbv) return [];

  let chartData: IHbvPanelPieChartDataItem[] = [];

  // Get dollar total for this scenario

  // let dollarTotal: number = 0;
  // for(let i=0; i < hbv.break_out.length; i++)
  // {
  //   const hbvInfoItem: IHbvImpact = hbv.break_out[i];
  //   dollarTotal += hbvInfoItem.dollar;
  // }

  // Figure out the percent of dollar total for each item

  let newId = 1;

  for(let i=0; i < hbv.break_out.length; i++)
  {
    const hbvInfoItem: IHbvImpact = hbv.break_out[i];
    if(hbvInfoItem.dollar <= 0) continue;  // ignore zero value items

    if(hbvInfoItem.color === undefined) continue; // this should never happen
  
    const newChartDataItem: IHbvPanelPieChartDataItem = 
    {
      id: newId++,
      value: hbvInfoItem.dollar,
      //percent: hbvInfoItem.dollar / dollarTotal * 100.0,
      title: hbvInfoItem.land_use,
      color: hbvInfoItem.color,  //GetRandomColor(),
    }

    // If we're in compare mode, we need to adjust the percent to be relative to the highest hbv dollar value
    // if(scenario.comparisonMaxDollarValue && scenario.comparisonMaxDollarValue != scenario.hbv_data.hbv)
    // NOTE: Commented out because the chart doesn't use this percent anyway, so it's not needed
    // newChartDataItem.percent = hbvInfoItem.dollar / scenario.comparisonMaxDollarValue * 100.0;

    chartData.push(newChartDataItem);
  }

  // If we're in scenario comparison mode, add an extra special pie piece such that the 
  // dollar totals will match across all compared scenarios.  We want the pie pieces to
  // be sized relative to each other based on the scenario with the highest dollar value.

  if(scenario.comparisonMaxDollarValue && scenario.comparisonMaxDollarValue !== scenario.hbv_data.hbv)
  {
    const extraChartDataItem: IHbvPanelPieChartDataItem = 
    {
      id: newId++,
      value:  scenario.comparisonMaxDollarValue - scenario.hbv_data.hbv,
      //percent: (scenario.comparisonMaxDollarValue - scenario.hbv_data.hbv) / scenario.comparisonMaxDollarValue * 100.0,
      title: 'Extra value compared to highest HBV',
      color: 'transparent', //PIE_CHART_BG_COLOR,
    }
    chartData.push(extraChartDataItem);
  }

  return chartData;
}
