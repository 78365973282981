// Globals

import { Link } from "@mui/material";
import { appBuildDateTimeStamp } from "./App";
import { theme_textColorBlended } from "./Theme";


// This flag is only to be used during development to enable certain functionality teporarily.
// When deploying to either DEV or PROD, it should be set to FALSE.
export const TESTING_ONLY_FEATURES_ENABLED = true;



// NOTE: All env vars come in as strings (at least when deployed via BiutBucket pipeline)
export const DEV_MODE = StrToBool(process.env.REACT_APP_DEV_MODE);
export const DEV_MODE_CONSOLE_LOGGING = StrToBool(process.env.REACT_APP_DEV_MODE_CONSOLE_LOGGING); // (used in Debug.tsx)

export const CALLSERVER_BASE_URL = process.env.REACT_APP_STRATIFYX_API;


export const AOI_MAX_SIZE_ACRES = 30000;
export const AOI_BBOX_EXTENT_MAX_SIZE_ACRES = 60000;

//export const GOOGLE_TAG_MANAGER_ID = 'GTM-PG737DR';
export const GOOGLE_TAG_MANAGER_ID = process.env.REACT_APP_GTM_ID || 'GTM-5T8NMS2';

// S3 bucket containing all the layer thumbnail images (used mainly for the layer library) 
export const LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL = 'https://sfx-app-layer-info-map-images.s3.amazonaws.com/';

// S3 bucket containing layer legend images (only used for certain special layers)
export const LAYER_LEGEND_IMAGES_BASE_URL = 'https://sfx-app-layer-legend-images.s3.amazonaws.com/';

export const NRR_INFO_STATIC_IMAGES_BASE_URL = 'https://sfx-app-nrr-info-images.s3.amazonaws.com/';

// This is a special layer - the app needs to know its name to allow the user to highlight parcels
export const PARCEL_LAYER_NAME = 'Parcels';

// For now, bundles go away in the app, but we still load a default bundle 
// behind the scenes (with dev and prod having different bundles).  Each bundle
// loads in a list of all the NRRs and reference layers the app will work with.
export const DEFAULT_BUNDLE_ID: number = process.env.REACT_APP_BUNDLE_ID ? Number.parseInt(process.env.REACT_APP_BUNDLE_ID) : 6;

export const DEFAULT_TERRAIN_3D_EXAGGERATION = 1.5;


//-------------------------------------------------------------------------------
// A bunch of helper functions which are used all over the app.
//-------------------------------------------------------------------------------




//-------------------------------------------------------------------------------
// Converts a string to boolean (accepts multiple formats)
//-------------------------------------------------------------------------------
export function StrToBool(str: string | null | undefined): boolean | undefined
{
  if(str === undefined || str === null)
    return undefined;

  switch(str.toLowerCase())
  {
    case 'false':
    case 'f':
    case '0':
    case 'no':
    case 'n':
      return false;

    case 'true':
    case 't':
    case '1':
    case 'yes':
    case 'y':
      return true;
  }

  return undefined;
}

//-------------------------------------------------------------------------------
// Wait X number of milliseconds.
// Ex:  await Delay(2000);
//-------------------------------------------------------------------------------
export function Delay(delayMS: number)
{
  return new Promise( res => setTimeout(res, delayMS) );
}

//-------------------------------------------------------------------------------
// Friendly display of large numbers (with commas)
//-------------------------------------------------------------------------------
export function FriendlyNumber(num: number | undefined | null, decimals: number = 0) : string
{
  if(num === undefined || num === null) return '';
  return num.toLocaleString(undefined, { maximumFractionDigits: decimals })
}

//-------------------------------------------------------------------------------
// Friendly display of currency (USD)
//-------------------------------------------------------------------------------
export function FriendlyCurrency(num: number | undefined | null, decimals: number = 0) : string
{
  if(num === undefined || num === null) return '';
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: decimals, maximumFractionDigits: decimals }).format(num);
}

//-------------------------------------------------------------------------------
// Shows the specified number of milliseconds as a more friendly (and short) 
// time span.
//-------------------------------------------------------------------------------
export function FriendlyTimeSpan(milliseconds: number | undefined): string | undefined
{
  if(!milliseconds) return undefined;

  if(milliseconds < 2000) return `${Math.round(milliseconds)} ms`;

  const seconds = milliseconds/1000; if(seconds < 120) return `${Math.round(seconds)} seconds`;
  const minutes = seconds/60;        if(minutes < 120) return `${Math.round(minutes)} minutes`;
  const hours = minutes/60;          if(hours   < 48)  return `${Math.round(hours)} hours`;
  const days = hours/24;             if(days    < 14)  return `${Math.round(days)} days`;
  const weeks = days/7;              if(weeks   < 8)   return `${Math.round(weeks)} weeks`;
  const months = days/30;            if(months  < 24)  return `${Math.round(months)} months`;
  const years = days/365;                              return `${Math.round(years)} years`;
}

//-------------------------------------------------------------------------------
// Shows the number of seconds as a more friendly and short time span.
//
// This version takes up as little space as possible (at most 6 chars).
//-------------------------------------------------------------------------------
export function FriendlyTimeSpan_Short(milliseconds: number | undefined): string | undefined
{
  if(!milliseconds) return undefined;

  if(milliseconds < 1000) return `${Math.round(milliseconds)} ms`;
  
  const seconds = milliseconds/1000; if(seconds < 120) return `${Math.round(seconds)} s`;
  const minutes = seconds/60;        if(minutes < 120) return `${Math.round(minutes)} m`;
  const hours = minutes/60;          if(hours   < 48)  return `${Math.round(hours)} h`;
  const days = hours/24;             if(days    < 14)  return `${Math.round(days)} d`;
  const weeks = days/7;              if(weeks   < 8)   return `${Math.round(weeks)} w`;
  const months = days/30;            if(months  < 24)  return `${Math.round(months)} m`;
  const years = days/365;                              return `${Math.round(years)} y`;
}

//-------------------------------------------------------------------------------
// Returns a friendly file size.
//-------------------------------------------------------------------------------
export function FriendlyFileSize(sizeBytes: number | undefined): string | undefined
{
  if(!sizeBytes) return undefined;

  const b  = sizeBytes;  if(b  < 2*1024) return `${Math.round(b)} bytes`;
  const kb = b/1024;     if(kb < 2*1024) return `${Math.round(kb)} KB`;
  const mb = kb/1024;    if(mb < 2*1024) return `${Math.round(mb)} MB`;
  const gb = mb/1024;    if(gb < 2*1024) return `${Math.round(gb)} GB`;
  const tb = gb/1024;                    return `${Math.round(tb)} TB`;
}

//-------------------------------------------------------------------------------
// Returns a friendly file size.
//
// This version takes up as little space as possible (at most 6 chars).
//-------------------------------------------------------------------------------
export function FriendlyFileSize_Short(sizeBytes: number | undefined): string | undefined
{
  if(!sizeBytes) return undefined;

  const b  = sizeBytes;  if(b < 1000) return `${Math.round(b)} bytes`;
  const kb = b/1024;     if(kb < 1000) return `${Math.round(kb)} KB`;
  const mb = kb/1024;    if(mb < 1000) return `${Math.round(mb)} MB`;
  const gb = mb/1024;    if(gb < 1000) return `${Math.round(gb)} GB`;
  const tb = gb/1024;                  return `${Math.round(tb)} TB`;
}

//-------------------------------------------------------------------------------
// Formats the date to a standard/friendly format (ie: Jan 25, 2023)
//-------------------------------------------------------------------------------
export function FriendlyDate(date: Date)
{
  return date.toLocaleDateString('en-us', { year:"numeric", month:"short", day:"numeric"}) 
}

//-------------------------------------------------------------------------------
// Shows how long ago the app was last updated.
//-------------------------------------------------------------------------------
export function GetAppLastUpdateInfo(): string | undefined
{
  const appDeployDate = new Date(appBuildDateTimeStamp);
  let timeSpanMS: number = (new Date().getTime() - appDeployDate.getTime());
  timeSpanMS += 4*3600*1000;  // temp - add 5 hours as apparently timezone doesn't work when app is deployed?
  return FriendlyTimeSpan(timeSpanMS);
}

//-------------------------------------------------------------------------------
// Reformats the date string to a standard/friendly format (ie: Jan 25, 2023)
//-------------------------------------------------------------------------------
export function FriendlyDateFromStr(dateStr: string)
{
  return FriendlyDate(new Date(dateStr));
}

//-------------------------------------------------------------------------------
// Returns a random color (in #RRGGBB hex format)
//-------------------------------------------------------------------------------
export function GetRandomColor(): string
{
  return '#' + Math.floor(Math.random()*16777215).toString(16);
}

//-------------------------------------------------------------------------------
// Capitalize every word in the specified string (with everything else lower case).
//-------------------------------------------------------------------------------
export const CapitalizeAllWords = (str: string | undefined) => 
{
  if(!str || str?.length === 0) return undefined;
  str = str.toLocaleLowerCase();

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

//-------------------------------------------------------------------------------
// Returns a friendly version of the specified lat/lng coords.
//
// Eg: 40°N, 74°W  (NYC)
//-------------------------------------------------------------------------------
export function FriendlyCoords(latitude: number, longitude: number): string
{
  const latStr = Math.abs(latitude).toFixed(3) + ' °' + (latitude >= 0 ? 'N' : 'S');
  const lngStr = Math.abs(longitude).toFixed(3) + ' °' + (longitude >= 0 ? 'E' : 'W');
  return latStr + ', ' + lngStr;
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified string is a URL.
//-------------------------------------------------------------------------------
export function IsURL(urlStr: string): boolean
{
  let url;
  try 
  {
    url = new URL(urlStr);
  } 
  catch (_) 
  {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified value is a number.
//-------------------------------------------------------------------------------
export function IsNumber(value: any): boolean
{
   return value !== undefined && isFinite(value);
}

//-------------------------------------------------------------------------------
// Returns a CSV-save/escaped version of the specified value.
//-------------------------------------------------------------------------------
export function GetEscapedCSVValue(value: string | undefined): string
{
  if(!value) return '';

  return '"' + value.replace(/"/g,'""') + '"';
}

//-------------------------------------------------------------------------------
// Auto-detects urls in a string and converts them to proper MUI Link elements.
// NOTE: The links are created to always open in a new browser tab.
//-------------------------------------------------------------------------------
export function AutoDetectURLsAndReplaceWithLinks(text: string, colorStr: string = theme_textColorBlended)
{
  if(!text) return '';

  const delimiter = /((?:https?:\/\/)?(?:(?:[a-z0-9]?(?:[a-z0-9\-]{1,61}[a-z0-9])?\.[^\.|\s])+[a-z\.]*[a-z]+|(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})(?::\d{1,5})*[a-z0-9.,_\/~#&=;%+?\-\\(\\)]*)/gi;

  return (
    <>
      {text.split(delimiter).map(word => 
      {
        const match = word.match(delimiter);
        if (match) 
        {
          const url = match[0];
          return (
            <Link sx={{ color: colorStr }} href={url.startsWith('http') ? url : `http://${url}`} target="_blank" rel="noopener noreferrer">{url}</Link>
          );
        }
        return word;
      })}
    </>
  )
}

export interface IImageDimensions
{
  width: number;
  height: number;
}

//-------------------------------------------------------------------------------
// Get width/height of an image file that is about to be uploaded.
//-------------------------------------------------------------------------------
export const GetImageDimensionsForFile = (file: File) => new Promise<IImageDimensions>(resolve => 
{
  const img = new Image()
  img.onload = () => 
  {
    resolve(
    {
      height: img.height,
      width: img.width
    })
  }
  img.src = window.URL.createObjectURL(file);
})
