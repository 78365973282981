import * as React from 'react';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { DialogTitleBar } from './Components/DialogTitleBar';
import { Stack, FormGroup, FormControlLabel, Switch, Divider, Typography } from '@mui/material';
import useStore from './store';
import SettingsIcon from '@mui/icons-material/Settings';
import { DialogStyled } from './CustomMuiStyles';
import { UpdateMapScaleState, UpdateMapCompassState, UpdateMapGeolocateState } from './Map/MapOps';


export type MapScaleUnits = 'metric' | 'imperial';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AppSettingsDialogProps
{
  setCurrTheme: React.Dispatch<React.SetStateAction<any>>;
}

//-------------------------------------------------------------------------------
// App settings dialog Component
//-------------------------------------------------------------------------------
const AppSettingsDialog = (props: AppSettingsDialogProps) =>
{
  // Get needed state data from the store
  const { store_mapScaleControl, store_userProfile, store_appSettingsOpen, 
          store_setUserProfileMapScale, store_setUserProfileMapScaleUnits, 
          store_setUserProfileMapCompass, store_setUserProfileIsDirty, 
          store_setUserProfileMapGeolocate, store_setAppSettingsOpen, 
        } = useStore();



  //-------------------------------------------------------------------------------
  // Map scale setting was changed.
  //-------------------------------------------------------------------------------
  const onMapScaleChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  { 
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapScale(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapScaleState(event.target.checked, store_userProfile.map_scale_units);
  }

  //-------------------------------------------------------------------------------
  // Map compass setting was changed.
  //-------------------------------------------------------------------------------
  const onMapCompassChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapCompass(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapCompassState(event.target.checked);
  }

  //-------------------------------------------------------------------------------
  // Map scale units (metric vs imperial) setting was changed.
  //-------------------------------------------------------------------------------
  const onMapScaleUnitsChanged = () => 
  {
    if(!store_userProfile) return;

    const newValue: MapScaleUnits = store_userProfile.map_scale_units === 'metric' ? 'imperial' : 'metric';

    store_setUserProfileMapScaleUnits(newValue);
    store_setUserProfileIsDirty(true);

    store_mapScaleControl?.setUnit(newValue);
  }

  //-------------------------------------------------------------------------------
  // Map geolocation setting was changed.
  //-------------------------------------------------------------------------------
  const onMapGeolocateChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!store_userProfile) return;

    // Update app settings
    store_setUserProfileMapGeolocate(event.target.checked);
    store_setUserProfileIsDirty(true);

    // Update the map
    UpdateMapGeolocateState(event.target.checked);
  }
  
  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onCloseDialog = () => 
  {
    store_setAppSettingsOpen(false);
  }





  // Main render

  return (

    <div>

      <DialogStyled onClose={onCloseDialog} open={store_appSettingsOpen} maxWidth='md'>

        <DialogTitleBar onClose={onCloseDialog} width='300px'
                        icon={ <SettingsIcon htmlColor='#777777' fontSize='large' /> }>
          App Settings
        </DialogTitleBar>

        <DialogContent>

          <Stack direction='row'>

            <FormGroup>

              {/* Map scale + units */}

              <Stack direction='row' height='26px'>
                <FormControlLabel control={<Switch size='small'
                                  checked={store_userProfile?.map_scale} onChange={ onMapScaleChanged } />}
                                  label={<Typography sx={{ color: 'black', ml: 1  }}>Map Scale</Typography>} />

                {store_mapScaleControl ?
                  <Button size='small' variant='outlined' sx={{ width: '80px', height: '26px', color: 'black' }} 
                    onClick={() => { onMapScaleUnitsChanged(); }}>
                    {store_userProfile?.map_scale_units}
                  </Button>
                :null
                }
              </Stack>

              {/* Map compass */}

              <FormControlLabel control={<Switch size='small' checked={store_userProfile?.map_compass}
                                onChange={ onMapCompassChanged } />} sx={{ mt: '2px' }}
                                label={<Typography sx={{ color: 'black', ml: 1 }}>Map Compass</Typography>}/>

              {/* Map geolocate */}

              <FormControlLabel control={<Switch size='small' checked={store_userProfile?.map_geolocate}
                                onChange={ onMapGeolocateChanged } />} sx={{ mt: '2px' }}
                                label={<Typography sx={{ color: 'black', ml: 1 }}>Map Geolocate</Typography>}/>

            </FormGroup>

          </Stack>

          <Divider sx={{ mt: 2, mb: 0 }}/>

        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={onCloseDialog} sx={{ mt: -2, color: 'black' }}>
            Close
          </Button>
        </DialogActions>
      </DialogStyled>

    </div>
  );
}



export default AppSettingsDialog;




