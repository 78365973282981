// Layer library item component

import { Box, IconButton, Link, Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from "@mui/material";
import { ILayer } from "../Layers/LayerInterfaces";
import { LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL, PARCEL_LAYER_NAME } from "../Globals";
import { theme_bgColorLight1, theme_bgColorMain, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { AddLayerToMap, RemoveLayerFromMap } from "../Layers/LayerOps";
import useStore from "../store";
import { EnterParcelsMode, ExitParcelsMode } from "../Parcels/ParcelOps";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LegendCore from "../Legend/LegendCore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SettingsIcon from '@mui/icons-material/Settings';
import { ILayerLibraryGroup } from "./LayerLibraryInterfaces";


export const LAYER_LIBRARY_LAYERS_PER_ROW = 3;
export const LAYER_LIBRARY_LAYER_IMAGE_HEIGHT = 175;
export const LAYER_LIBRARY_LAYER_IMAGE_BORDER = 2;

export const CUSTOM_GROUP_PLACEHOLDER_IMAGE = 'Custom Layer Placeholder.png';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerLibraryItemProps
{
  layer: ILayer;
  layerLibraryGroup: ILayerLibraryGroup;
  OnEditLayer(layer: ILayer): any;
  OnConfirmDeleteLayer(layer: ILayer): any;
}

//-------------------------------------------------------------------------------
// Layer library item component
//-------------------------------------------------------------------------------
const LayerLibraryItem = (props: LayerLibraryItemProps) => 
{
  // Get needed state data from the store
  const { store_setActiveInfoLayer, store_setProjectIsDirty, store_permEditMainLayer, 
  } = useStore();






  //-------------------------------------------------------------------------------
  // Add the layer.
  //-------------------------------------------------------------------------------
  const onAddLayer = () => 
  {
    props.layer.activeInProject = true;

    if(props.layer.name === PARCEL_LAYER_NAME)
      EnterParcelsMode();
    else
      AddLayerToMap(props.layer.id, true);

    // Flag the project as "dirty" so it will be re-saved
    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // Remove the layer.
  //-------------------------------------------------------------------------------
  const onRemoveLayer = () => 
  {
    props.layer.activeInProject = false;
    
    if(props.layer.name === PARCEL_LAYER_NAME)
      ExitParcelsMode();
    else
      RemoveLayerFromMap(props.layer.id);

    // Flag the project as "dirty" so it will be re-saved
    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // Show the full layer info for this layer.
  //-------------------------------------------------------------------------------
  const onClickMoreInfoLink = () => 
  {
    store_setActiveInfoLayer(props.layer);
  }












  // Ignore HBV layers
  if(props.layer.name.toLowerCase().startsWith('hbv')) return null;

  // Main render

  return (

    <Stack sx={{ width: `calc(${100/LAYER_LIBRARY_LAYERS_PER_ROW}% - 20px)`, m: '10px' }}>

      <Stack sx={{ position: 'relative', borderRadius: 3, boxShadow: 4, 
                   bgcolor: props.layer.activeInProject ? theme_orange : theme_bgColorLight1, 
                   width: '100%', height: LAYER_LIBRARY_LAYER_IMAGE_HEIGHT, justifyContent: 'right' }}>

        {/* Layer image (if none available show a placeholder image) */}
        
        {props.layer.description && props.layer.description.mapImage
          ?
            <Box component="img" alt="" src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(props.layer.description.mapImage)}
                sx={{ height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px`, borderRadius: 2.8, m: `${LAYER_LIBRARY_LAYER_IMAGE_BORDER}px` }}/>
          :
            <Box component="img" alt="" src={LAYER_INFO_STATIC_MAP_IMAGES_BASE_URL + encodeURIComponent(CUSTOM_GROUP_PLACEHOLDER_IMAGE)}
                sx={{ height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px`, borderRadius: 2.8, m: `${LAYER_LIBRARY_LAYER_IMAGE_BORDER}px` }}/>
            // Empty box:
            //  <Box sx={{ height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px`, borderRadius: 2.8, m: `${LAYER_LIBRARY_LAYER_IMAGE_BORDER}px`, bgcolor: '#133358' }}/>
        }

        {/* For custom groups that have no image, overlay the text "Custom Layer" (centered) */}

        {props.layerLibraryGroup.isCustomGroup && (!props.layer.description || !props.layer.description.mapImage)
          ?
            <Box sx={{ position: 'absolute', top:2, left:0, width: '100%', 
                       justifyContent: 'center', alignItems: 'center', justifyItems: 'center', alignContent: 'center',
                       height: `${LAYER_LIBRARY_LAYER_IMAGE_HEIGHT-LAYER_LIBRARY_LAYER_IMAGE_BORDER*2}px` }}>
              <Typography sx={{ width: '100%', textAlign: 'center',  fontSize: '1rem', opacity: 0.18, color: theme_textColorMain }}>
                Custom Layer
              </Typography>
            </Box>
           :null
        }

        {props.layer.activeInProject
          ?
            <IconButton sx={{ position: 'absolute', right: 8, bottom: 8, width: '50px', height: '50px', 
                              color: theme_bgColorMain, bgcolor: theme_orange, opacity: 0.8, p:0 }}
                        onClick={(_) => onRemoveLayer()}>
              <RemoveCircleIcon sx={{ width: '50px', height: '50px' }}/>
            </IconButton>
          :
            <IconButton sx={{ position: 'absolute', right: 8, bottom: 8, width: '50px', height: '50px', 
                              color: theme_orange, bgcolor: theme_bgColorMain, opacity: 0.8, p:0 }}
                        onClick={(_) => onAddLayer()}>
              <AddCircleIcon sx={{ width: '50px', height: '50px' }}/>
            </IconButton>
        }

        {/* Layer Edit/Delete buttons */}

        {(props.layerLibraryGroup.isCustomGroup && (props.layer.DeleteCustomLayer || props.layer.EditCustomLayer)) ||
         (!props.layerLibraryGroup.isCustomGroup && store_permEditMainLayer)
          ?
            <Stack direction='row' sx={{ position: 'absolute', right: 10, top: 10, color: theme_bgColorMain, bgcolor: theme_bgColorLight1, 
                                         borderRadius: 2, boxShadow: 4 }}>
              <Stack direction='row' sx={{ bgcolor: theme_bgColorMain, m: '1px', borderRadius: 1.8, opacity: 0.85 }}>

                {props.layerLibraryGroup.isCustomGroup && props.layer.DeleteCustomLayer
                  ?
                    <Tooltip title='Delete this layer'>
                      <IconButton onClick={(_)=>props.OnConfirmDeleteLayer(props.layer)} sx={{ mx: 0.3, my: 0.1, p: '2px', color: theme_textColorBlended }}>
                        <DeleteForeverIcon sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                    </Tooltip>
                  :null
                }

                {(props.layerLibraryGroup.isCustomGroup &&  props.layer.EditCustomLayer) || 
                 (!props.layerLibraryGroup.isCustomGroup && store_permEditMainLayer)
                  ?
                    <Tooltip title='Edit this layer'>
                      <IconButton onClick={(_) => props.OnEditLayer(props.layer)} sx={{ mx: 0.3, my: 0.1, p: '2px', color: theme_textColorBlended }}>
                        <SettingsIcon sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                    </Tooltip>
                  :null
                }
              </Stack>
            </Stack>
          :null
        }

        {/* Legend tooltip button */}

        {props.layer.legend && (props.layer.legend.image_s3 || (props.layer.legend.entries && props.layer.legend.entries.length >= 1))
          ?
            <LayerLibraryLegendTooltip
                title={<LegendCore layer={props.layer} showTopEntriesCount={10}/>}>
                <IconButton sx={{ position: 'absolute', left: 8, bottom: 8, width: '25px', height: '25px', 
                                  color: theme_limeGreen, bgcolor: '#404040', opacity: 0.8, p:0,
                                  borderRadius: 1.5 }}>
                  <FormatListBulletedIcon sx={{ width: '20px', height: '20px' }}/>
                </IconButton>
            </LayerLibraryLegendTooltip>
          :null
        }

      </Stack>

      <Typography sx={{ mt: '7px', fontSize: '1.1rem', color: theme_orange }}>
        {props.layer.friendlyName}
      </Typography>

      {props.layer.description && props.layer.description.shortDescription
        ?
          <Typography sx={{ fontSize: '0.7rem', color: theme_limeGreen, mt: '5px' }}>
            {props.layer.description.shortDescription}
          </Typography>
        :null
      }

      <Stack direction='row' sx={{ width: '100%', alignItems: 'end', height: '100%', justifyContent: 'end', mt: '5px' }}>
        <Link onClick={(_)=>onClickMoreInfoLink()} tabIndex={0} component="button"
              sx={{ fontSize: '0.8rem', color: theme_textColorBlended, opacity: 0.8 }}>
          Show more info...
        </Link>
      </Stack>

    </Stack>

  )
}

export default LayerLibraryItem;





// Tooltip customized for the Layer Library legend tooltip
export const LayerLibraryLegendTooltip = styled(({ className, ...props }: TooltipProps) => (
  
  <Tooltip arrow placement='bottom-start'

  
          // This moves the tooltip closer to the anchor icon (so it doesn't dissaper 
          // if the user moves the mouse over to the tooltip, for example to click a link)
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, 0] }
                }
              ]
            }
          }}

          {...props} classes={{ popper: className }} />))(({ theme }) => (
{
  [`& .${tooltipClasses.arrow}`]: 
  {
      "&::before": 
      {
        backgroundColor: '#AAC7FF',
        //border: "1px solid #999"
      }
    },
  [`& .${tooltipClasses.tooltip}`]: 
  {
    backgroundColor: theme_bgColorMain,
    border: "1px solid #2E5894",
  },
}));

