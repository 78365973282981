// Layer operations

import mapboxgl, { AnyLayer, AnySourceData } from "mapbox-gl";
import Debug from "../Debug";
import { PARCEL_LAYER_NAME } from "../Globals";
import { ILegend, ILegendEntry } from "../Legend/LegendInterfaces";
import { GetMapSymbolLayerID } from "../Map/MapOps";
import useStore from "../store";
import { GeoserverOutputType, GeoserverVectorType, ILayer, ILayerDescription, ILayerProp, IVectorLayerAttribute, MapboxLayerType } from "./LayerInterfaces";
import { arrayMove } from "@dnd-kit/sortable";
import { GetLayerLibraryGroup } from "../LayerLibrary/LayerLibraryOps";
import { ILayerLibraryGroup } from "../LayerLibrary/LayerLibraryInterfaces";
import { SPECIAL_UNGROUPPED_LAYERS_GROUP_ID } from "../LayerLibrary/LayerLibraryGroupButtons";


//-------------------------------------------------------------------------------
// Adds the specified layer to the map.
//-------------------------------------------------------------------------------
export function AddLayerToMap(layerID: number, forceOnTop: boolean = false)
{
  const layer: ILayer | null = GetLayerByID(layerID);
  if(!layer) return;
  
  // Close the identify viewer if it's currently open
  useStore.getState().store_setIdentify(undefined);
  useStore.getState().store_setIdentifyIsLoading(false);

  // Set the layer's state to ENABLED
  useStore.getState().store_setLayerEnabled(layerID, true);

  // OPTION: Move the layer to the top (to ensure this layer is added on top)
  if(forceOnTop)
  {
    let store_layers = useStore.getState().store_layers;

    // Move the layer to the front of the store_layers list
    const layerIndex: number | undefined = GetLayerIndex(store_layers, layerID);
    if(layerIndex && layerIndex > 0)
      store_layers = arrayMove(store_layers, layerIndex, 0);

    // Update the layer list in the state store
    useStore.getState().store_setLayers(store_layers);
  }

  AddMapboxDataSource(layer);

  AddMapboxDataLayer(layer);



  // TEMP - EXPERIMENT with adding the FEMA Flood layer directly into Mapbox

  // useStore.getState().store_map?.addSource('tempSource1'.toString(), 
  // {
  //   type: 'raster',
  //   tiles: [ 'https://hazards.fema.gov/gis/nfhl/services/public/NFHLWMS/MapServer/WMSServer?request=GetMap&VERSION=1.1.1&TILED=true&STYLES=&SRS=EPSG:3857&service=WMS&format=image%2Fpng&layers=20&transparent=true&width=256&height=256&bbox={bbox-epsg-3857}' ],
  //   tileSize: 256,
  // });

  // const newMapboxLayer: mapboxgl.Layer = 
  // {
  //   'id': 'tempLayer1',
  //   'source': 'tempSource1',
  //   'type': 'raster',
  // };

  // useStore.getState().store_map?.addLayer(newMapboxLayer as AnyLayer);


}

//-------------------------------------------------------------------------------
// Removes the specified layer from the map.
//-------------------------------------------------------------------------------
export function RemoveLayerFromMap(layerID: number)
{
  const map = useStore.getState().store_map;
  if(!map) return;
  
  const layer: ILayer | null = GetLayerByID(layerID);
  if(!layer) return;

  // Set the layer's state to DISABLED
  useStore.getState().store_setLayerEnabled(layerID, false);

  // Close the identify viewer if it's currently open
  useStore.getState().store_setIdentify(undefined);
  useStore.getState().store_setIdentifyIsLoading(false);

  // Remove the mapbox data layer

  if (map.getLayer(layer.id.toString()))
  {
    map.removeLayer(layer.id.toString());
    Debug.log("LayerOps.RemoveLayerFromMap> removed mapbox layer %s", layer.name);
  }

  // Remove the mapbox data source

  if(map.getSource(layer.id.toString()))
  {
    map.removeSource(layer.id.toString());
    Debug.log("LayerOps.RemoveLayerFromMap> removed mapbox source %s", layer.name);
  }
}

//-------------------------------------------------------------------------------
// Adds the specified source to the map.
//-------------------------------------------------------------------------------
export function AddMapboxDataSource(newLayer: ILayer)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // If this a source (or layer) already exists, remove them first
  if (store_map.getLayer(newLayer.id.toString()))
    store_map.removeLayer(newLayer.id.toString());
  if(store_map.getSource(newLayer.id.toString())) 
    store_map.removeSource(newLayer.id.toString());

  // Add the SOURCE to the map

  if(newLayer.geoserverOutputType === 'geojson')
  {
    store_map.addSource(newLayer.id.toString(), 
    {
      type: 'geojson',
      data: newLayer.url,
      generateId: true,
      //maxzoom: 14,
    });
  }
  else if(newLayer.geoserverOutputType === 'raster')
  {
    // NOTE: If 'vector_layer_label_opacity' is 0, we add an env var to the URL called 'label_opacity'
    //       and set it to 0. The Geoserver style/SLD is set up to use this opacity value to show/hide 
    //       text labels (0 means invisible text labels, 1 means fully visible text labels).
    //       This only works for vector layers that are rendered as a raster through GS, and only if 
    //       the layer's SLD was set up for it.

    const urlOpacityEnvStr: string = newLayer.vector_layer_label_opacity === 0 ? '&env=label_opacity:0' : '';

    store_map.addSource(newLayer.id.toString(), 
    {
      type: 'raster',
      tiles: [ newLayer.url + urlOpacityEnvStr ],
      tileSize: 256,
      //minzoom: 0,
      //maxzoom: 14,
    });
  }
  else if(newLayer.geoserverOutputType === 'vector')
  {
    // "minzoom": 7,
    // "maxzoom": 12

    const mbSource: AnySourceData = 
    {
      type: 'vector',
      tiles: [ newLayer.url ],
      //minzoom: 0,
      //maxzoom: 14,
    }

    // TEMP
    // TEMP
    // if(newLayer.name === 'parcel_alabama')
    // {
    //   mbSource.minzoom = 10;
    // }
    // TEMP
    // TEMP

    store_map.addSource(newLayer.id.toString(), mbSource);
  }
  else
  {
    Debug.error("LayerOps.AddMapboxDataSource> INVALID SOURCE TYPE: " + newLayer.geoserverSourceType);
    return;
  }

  Debug.log("LayerOps.AddMapboxDataSource> Added mapbox source: %s %s (%s)", newLayer.id, newLayer.name, newLayer.geoserverSourceType);
}

//-------------------------------------------------------------------------------
// Add a layer to the map.
//
// NOTE:  The layer is not just added on top of everything - it is added based on
//        its order within the UI layer list (and also the list in the store, which
//        always matches the order displayed by the UI).
//-------------------------------------------------------------------------------
export function AddMapboxDataLayer(newLayer: ILayer)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  const store_layers = useStore.getState().store_layers;
  if(!store_layers) return;

  // If this layer already exists, remove it first
  if (store_map.getLayer(newLayer.id.toString()))
    store_map.removeLayer(newLayer.id.toString());

  // We need to figure out where to insert this new layer such that the 
  // layer order presented by the UI is correctly reflected by the actual 
  // map.

  // Find the index of this layer in the store's layer list

  let newLayerIndex = -1;
  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].id === newLayer.id)
    {
      newLayerIndex = i;
      break;
    }
  
  if(newLayerIndex < 0) return;

  // Search upward until we find the first active layer.  If no other 
  // active layers are found, we add this new layer just under the base 
  // map symbols layers (which means on top of all all our other user-added layers).

  let insertAfterLayerName : string | undefined = undefined;
  for(let i=newLayerIndex-1; i >= 0; i--)
    if(store_layers[i].enabled)
    {
      insertAfterLayerName = store_layers[i].id.toString();
      break;
    }

  if(insertAfterLayerName === undefined) // This new layer will become the top-most layer (just under base map symbols)
    insertAfterLayerName = GetMapSymbolLayerID(store_map, useStore.getState().store_baseMap);

  // To enable 3D, switch 'fill' to 'fill-extrusion'
  if(newLayer.mapboxLayerType === 'fill' && newLayer.enable3D)
    newLayer.mapboxLayerType = 'fill-extrusion';

  // Create a generic mapbox 'Layer'

  const newMapboxLayer: mapboxgl.Layer = 
  {
    'id': newLayer.id.toString(),
    'source': newLayer.id.toString(),
    'type': newLayer.mapboxLayerType,
  }

  if(newLayer.geoserverOutputType === 'vector')
    newMapboxLayer['source-layer'] = newLayer.name;

  // Apply the "paint"

  const opacityTypeStr: string = `${newLayer.mapboxLayerType}-opacity`;

  if(newLayer.mapboxPaint !== undefined)
  {
    // We have a paint specified, use it
    newLayer.mapboxPaint[opacityTypeStr] = newLayer.opacity;  // We'll add in the initial opacity dynamically (based on the layer type)
    newMapboxLayer['paint'] = newLayer.mapboxPaint;
  }
  else
  {
    // No paint specified - we still need to add in the initial opacity
    newMapboxLayer['paint'] = { [opacityTypeStr]: newLayer.opacity }
  }

  // Add the layer to mapbox
  store_map.addLayer(newMapboxLayer as AnyLayer, insertAfterLayerName);
  //store_map.addLayer(newMapboxLayer as AnyLayer);

  Debug.log("LayerOps.AddMapboxDataLayer> Added mapbox layer: %s %s", newLayer.id, newLayer.name);
}

//-------------------------------------------------------------------------------
// Reapplies all active layers to the map.
//-------------------------------------------------------------------------------
// export function ReapplyAllActiveLayersToMap(layers: ILayer[], map: mapboxgl.Map)
// {
//   layers.forEach(layer => 
//   {
//     if(layer.enabled) // Only apply this layer if it's currently set to 'enabled'
//       AddLayerToMap(map, layer);
//   });
// }

//-------------------------------------------------------------------------------
// Reapplies all active layers to the map.
//-------------------------------------------------------------------------------
export function RemoveAllLayersAndSourcesFromMap()
{
  const layers: ILayer[] = useStore.getState().store_layers;
  const map: mapboxgl.Map | null = useStore.getState().store_map;
  if(!map) return;

  layers.forEach(layer => 
  {
    RemoveLayerFromMap(layer.id);
  });
}

//-------------------------------------------------------------------------------
// Returns a list of all active identifiable layers (for the Identify feature).
// 
// - must be an active layer (currently visible on the map)
// - must be hosted on our own Geoserver
// - must be either a raster or vector layer
//-------------------------------------------------------------------------------
export function GetActiveIdentifiableLayers() : ILayer[]
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  let layers: ILayer[] = [];

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].enabled && store_layers[i].geoserver && 
       (store_layers[i].geoserverOutputType === 'vector' || store_layers[i].geoserverOutputType === 'raster'))
      layers.push(store_layers[i]);

  return layers;
}

//-------------------------------------------------------------------------------
// Returns a list of all active geojson layers.
//-------------------------------------------------------------------------------
export function GetAllActiveGeojsonLayers() : ILayer[]
{
  const store_layers : ILayer[] = useStore.getState().store_layers;

  let layers : ILayer[] = [];

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].enabled && (store_layers[i].geoserverOutputType === 'geojson'))
      layers.push(store_layers[i]);

  return layers;
}

//-------------------------------------------------------------------------------
// Returns the array index of the specified layer.
//-------------------------------------------------------------------------------
export function GetLayerIndex(layers: ILayer[], id: number) : number | undefined
{
  for(let i=0; i < layers.length; i++)
    if(layers[i].id === id)
      return i;

  return undefined;
}

//-------------------------------------------------------------------------------
// Returns the first layer with the specified name.
//-------------------------------------------------------------------------------
export function GetLayerByName(name: string) : ILayer | null
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].name === name)
      return store_layers[i];

  return null;
}

//-------------------------------------------------------------------------------
// Returns the layer with the specified layer id.
//-------------------------------------------------------------------------------
export function GetLayerByID(id: number) : ILayer | null
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].id === id)
      return store_layers[i];

  return null;
}

//-------------------------------------------------------------------------------
// Returns the layer with the specified layer id.
//-------------------------------------------------------------------------------
export function GetLayerFromListByID(layers: ILayer[], id: number) : ILayer | undefined
{
  if(!layers) return undefined;

  for(let i=0; i < layers.length; i++)
    if(layers[i].id === id)
      return layers[i];

  return undefined;
}

//-------------------------------------------------------------------------------
// Returns the active layer for the specified Geoserver layer name.
// NOTE: This needs to work based off the name instead of the ID, because the
//       indentify feature uses it an only has layer names.
//-------------------------------------------------------------------------------
export function GetActiveGeoserverLayer(layerName: string) : ILayer | null
{
  const store_layers : ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].enabled && store_layers[i].geoserver && 
       store_layers[i].name === layerName)
      return store_layers[i];

  return null;
}

//-------------------------------------------------------------------------------
// Load in data for all layers (using raw data from a server call).
// NOTE: Loads into the state store, not into the map itself.
//-------------------------------------------------------------------------------
export function LoadAllLayersFromServerCall(rawLayerData: any)
{
  let newLayers: ILayer[] = [];

  let parcelLayer: ILayer | undefined = undefined;

  for(let i=0; i < rawLayerData.length; i++)
  {
    const newLayer: ILayer | null = LoadLayerDataFromServerIntoILayer(rawLayerData[i]);
    if(!newLayer) continue; // failed to load this layer

    if(newLayer.name === PARCEL_LAYER_NAME)
    {
      parcelLayer = newLayer;
      continue; // we'll add this layer after
    }

    newLayers.push(newLayer);
    //Debug.log('Layers.LoadLayersFromServer> NEW LAYER: ' + apiLayer.layer_name);
  }

  // Sort the layers by their friendly names (ascending)
  newLayers = newLayers.sort((a: ILayer, b: ILayer) => a.friendlyName.localeCompare(b.friendlyName));

  // The special parcel layer is loaded first (so it's on top of all other layers), and hidden in the main layer list
  if(parcelLayer)
  {
    parcelLayer.hidden = true;
    newLayers.unshift(parcelLayer);
  }

  // Add all the new layers to the state store
  useStore.getState().store_setLayers(newLayers);

  Debug.log(`LayerOps.LoadAllLayersFromServerCall> Loaded ${newLayers.length} layers`);
}

//-------------------------------------------------------------------------------
// Load in a new layer into the state store (using raw data from a server call).
//-------------------------------------------------------------------------------
export function LoadLayerDataFromServerIntoILayer(rawLayerData: any): ILayer | null
{
  // If this layer has a legend, process the legend

  const legend: ILegend | undefined = rawLayerData.legend;
  if(legend && legend.entries && legend.entries.length > 0)
  {
    let countTotal = 0;

    for(let j=0; j < legend.entries.length; j++)
    {
      const legendEntry = legend.entries[j];
      if(legendEntry.count)
        countTotal += legendEntry.count;
    }

    // If sort info is specified in the legend, apply it

    if(legend.sort_by === 'count' && countTotal > 0)
    {
      if(legend.sort_order === 'asc')
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => a.count! - b.count!);
      else  // desc (default sort for count)
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => b.count! - a.count!);
    }
    else if(legend.sort_by === 'name')
    {
      if(legend.sort_order === 'desc')
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => b.name.localeCompare(a.name, undefined, {numeric: true, sensitivity: 'base'}));
      else  // asc (default sort for name)
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => a.name.localeCompare(b.name, undefined, {numeric: true, sensitivity: 'base'}));
    }
    else if(legend.sort_by === 'color')
    {
      if(legend.sort_order === 'asc')
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => a.color.localeCompare(b.color));
      else  // desc (default sort for color)
        legend.entries = legend.entries.sort((a: ILegendEntry, b: ILegendEntry) => b.color.localeCompare(a.color));
    }

    // Now that we know the countTotal, go back over the legend entries one more time
    // to set their 'countPercent'.

    // if(countTotal > 0)
    //   for(let j=0; j < legend.entries.length; j++)
    //   {
    //     const legendEntry: ILegendEntry = legend.entries[j];
    //   }

    // Auto-assign ids to each legend entry

    for(let k=0; k < legend.entries.length; k++)
    {
      const legendEntry: ILegendEntry = legend.entries[k];
      legendEntry['id'] = k+1;
    }
  }

// TEMP
// TEMP
let paint = undefined;

// 1a (-60 to -55 °F/-51.1 to -48.3 °C)

 //if(rawLayerData.layer_name === 'Cropland_2021_30m_cdls_3857_corn_res_test')
  // if(rawLayerData.layer_short_name === 'Flood Hazard Zones')
  // {
  //   const x = 1;
  // }

  // if(rawLayerData.layer_name === 'parcel_alabama')
  // {
  //   //mapboxLayerType = 'line';
  //   paint = 
  //   {
  //     'polygon-color': '#fc4e2a',
  //     'polygon-width': 2,
  //     'polygon-opacity': 0.6,
  //   };
// }

/*
  const attributes: IVectorLayerAttribute[] = [];
  if(rawLayerData.layer_name === 'Agricultural_Land_Value_2017')
  {
    const attrib1: IVectorLayerAttribute | undefined = 
      {
        id: 1,
        name: 'county',
        display_name: 'County Name',
        data_type: 'string',
        description: undefined,
        info_url: undefined,
        is_visible: true,
        is_range: false,
        decimal_places: undefined,
        enum_list: undefined,
        settings: {},
      }
      attributes.push(attrib1);

      const attrib2: IVectorLayerAttribute | undefined = 
      {
        id: 2,
        name: 'state',
        display_name: 'State Name',
        data_type: 'string',
        description: undefined,
        info_url: undefined,
        is_visible: true,
        is_range: false,
        decimal_places: undefined,
        enum_list: undefined,
        settings: {},
      }
      attributes.push(attrib2);

      const attrib3: IVectorLayerAttribute | undefined = 
      {
        id: 3,
        name: 'value_2017',
        display_name: '2017 Land Value',
        data_type: 'number',
        description: '2017 land value, including buildings, from the National Agricultural Statistics Service',
        info_url: 'https://downloads.usda.library.cornell.edu/usda-esmis/files/pn89d6567/vx021h90f/bv73c300q/AgriLandVa-08-03-2017.pdf',
        is_visible: true,
        is_range: false,
        decimal_places: 0,
        enum_list: undefined,
        settings: {},
      }
      attributes.push(attrib3);
  }
*/

  // if(rawLayerData.layer_name === 'acres_brownfieldlocations')
  // {
  //   rawLayerData.layer_location += '&env=size:8;fill_color:%23FF00FF;border_color:%23FFFF00;border_size:2;label_attrib:CITY_NAME;label_color:%23FF7722;label_halo_color:%23662211;';
  // }

// TEMP
// TEMP

  // The mapbox layer type is a combination of 'geoserverOutputType' and 'geoserverVectorType'.

  const geoserverOutputType: GeoserverOutputType = rawLayerData.output_type; // vector, raster, geojson
  const geoserverVectorType: GeoserverVectorType = rawLayerData.vector_type; // circle, line, polygon

  let mapboxLayerType: MapboxLayerType | null = GetMapboxLayerType(geoserverOutputType, geoserverVectorType);
  if(mapboxLayerType === null) return null;

  // If a vector polygon layer has no style set, it will be filled in with all black which is 
  // useless.  If we used a 'fill' layer type, the border thickness is 1 and can't be changed, so 
  // we'll set the mapboxLayerType to 'line' and provide a very basic style.
  
  if(geoserverOutputType === 'vector' && geoserverVectorType === 'polygon' && paint === undefined)
  {
    mapboxLayerType = 'line';
    paint = 
    {
      'line-color': 'black',
      'line-width': 1,
    }
  }

  // Assign some ids inside the description (for React keys)
  //
  // It also parse the 'keywords' field (which comes in as comma-separated) into a
  // proper keywords string array (so it's easier to work with).

  const description: ILayerDescription = rawLayerData.layer_description;
  let nextImageID = 1;
  let nextCitationID = 1;
  if(description)
  {
    if(description.images)
      description.images.map(image => image.id = nextImageID++);
    if(description.citations)
      description.citations.map(citation => citation.id = nextCitationID++);
    if(rawLayerData.layer_description.keywords)
    {
      const keywordsRawStr: string = rawLayerData.layer_description.keywords;
      description.keywordsArray = keywordsRawStr.split(",").map((item: string) => item.trim().toLowerCase());
    }
  }

  //const url_without_gwc: string = (rawLayerData.layer_location as string).replace('/gwc/service','');

  // Sort the attributes by display_name

  let sortedAttributeList: IVectorLayerAttribute[] | undefined = rawLayerData.attributes;
  if(sortedAttributeList)
    sortedAttributeList = sortedAttributeList.sort((a: IVectorLayerAttribute, b: IVectorLayerAttribute) => a.display_name.localeCompare(b.display_name));

  // If the style_info is undefined, create a default style

  // if(!rawLayerData.style_info)
  //   rawLayerData.style_info = GetDefaultLayerStyleInfo(geoserverVectorType);

  // Determine if this is a custom layer.  Currently we have no direct flag to determine this.
  // A layer is considered to be a "custom layer" if it is a member of a custom layer library group.
  // In theory, a custom layer must be a member of exactly one custom group.

  let isCustomLayer: boolean = false;
  const layer_library_ids: number[] = rawLayerData.layer_library_ids;
  if(layer_library_ids && layer_library_ids.length === 1)
  {
    const group: ILayerLibraryGroup | undefined = GetLayerLibraryGroup(layer_library_ids[0]);
    if(group && group.isCustomGroup)
      isCustomLayer = true;
  }

  // If this layer is not part of ANY groups, assign it to the special "Ungroupped Layers" group.
  // This is a special group that exists only in the app (not the db) with id of -1.  Admins can use
  // it to see/edit ungroupped layers in the app.
  if(!layer_library_ids || layer_library_ids.length === 0)
    rawLayerData.layer_library_ids = [ SPECIAL_UNGROUPPED_LAYERS_GROUP_ID ];

  // Process the layer entry

  const newLayer: ILayer = 
  {
    id: rawLayerData.layer_id,
    friendlyName: rawLayerData.layer_short_name,
    name: rawLayerData.layer_name,
    description: rawLayerData.layer_description,
    geoserver: rawLayerData.geoserver,
    geoserverSourceType: rawLayerData.source_type, // vector, raster
    geoserverOutputType: geoserverOutputType,
    geoserverVectorType: geoserverVectorType,
    mapboxLayerType: mapboxLayerType,
    enabled: false,
    opacity: 1,
    expanded: false,
    movable: true,
    url: rawLayerData.layer_location,
    legend: legend,
    mapboxPaint: paint,
    legendShowAll: false,
    enable3D: false,
    map_extent: rawLayerData.map_extent,
    props: rawLayerData.props,
    defaultProp: GetDefaultLayerProp(rawLayerData),
    aoi_id_filters: [],
    nrr_id: rawLayerData.nrr_id,
    impact_id: rawLayerData.impact_id,
    layer_library_group_ids: rawLayerData.layer_library_ids,
    activeInProject: false, // may need to change this
    has_text_labels: rawLayerData.has_text_labels, // only applicable for vector layers
    attributes: sortedAttributeList,
    EditCustomLayer: rawLayerData.EditCustomLayer,
    DeleteCustomLayer: rawLayerData.DeleteCustomLayer,
    style_info: rawLayerData.style_info,
    isCustomLayer: isCustomLayer,
    isHBVLayer: false,
  }

  // Success
  return newLayer;
}

//-------------------------------------------------------------------------------
// Get the mapboxLayer type.
//-------------------------------------------------------------------------------
export function GetMapboxLayerType(geoserverOutputType: GeoserverOutputType, geoserverVectorType: GeoserverVectorType) : MapboxLayerType | null
{
  let mapboxLayerType: MapboxLayerType;
  if(geoserverOutputType === 'raster') mapboxLayerType = 'raster';
  else  // vector or geojson
  {
    switch(geoserverVectorType)
    {
      case 'circle':  mapboxLayerType = 'circle'; break;
      case 'line':    mapboxLayerType = 'line'; break;
      case 'polygon': mapboxLayerType = 'fill'; break;
      default: 
        Debug.error('Invalid geoserverVectorType: ' + geoserverVectorType);
        return null;
    }
  }

  return mapboxLayerType;
}

//-------------------------------------------------------------------------------
// Get default layer prop (if any).
//-------------------------------------------------------------------------------
function GetDefaultLayerProp(rawLayerData: any): ILayerProp | undefined
{
  if(!rawLayerData || !rawLayerData.props || rawLayerData.props.length === 0) 
    return undefined;  // this layer has no props

  // If no 'default_prop' is set, return the first prop
  if(!rawLayerData.default_prop) 
    return rawLayerData.props[0];

  for(let i=0; i < rawLayerData.props.length; i++)
    if(rawLayerData.props[i].name === rawLayerData.default_prop)
      return rawLayerData.props[i];

  return undefined;  // not found
}

//-------------------------------------------------------------------------------
// Change a layer's opacity (value 0-1).
//-------------------------------------------------------------------------------
export function SetLayerOpacity(layer: ILayer, newOpacity: number)
{
  const store_map = useStore.getState().store_map;
  if(!store_map) return;

  // Update the opacity in the state store for this layer
  useStore.getState().store_setLayerOpacity(layer.id, newOpacity);

  // Update the opacity of the actual map layer
  if(layer.enabled)
  {
    // NOTE:  Depending on layer type, there can be different types of opacity:
    //        - raster-opacity
    //        - fill-opacity
    //        - fill-extrusion-opacity
    //        - circle-opacity
    //        - etc

    // const mapboxLayer = store_map.getLayer(layer.id.toString());
    // if(!mapboxLayer) return;

    // NOTE: This dynamic way of setting opacity for any layer type no longer works after the Mapbox Typescript changes
    //store_map.setPaintProperty(layer.id.toString(), `${mapboxLayer.type}-opacity`, newOpacity);

    // Currently all our GS-hosted layers come down as rasters (image tiles using WMS), so for now using 'raster-opacity' 
    // should work.
    store_map.setPaintProperty(layer.id.toString(), 'raster-opacity', newOpacity);

  }
}

//-------------------------------------------------------------------------------
// Returns TRUE if the specified layer is currently enabled and visible in the map.
//-------------------------------------------------------------------------------
export function IsMapLayerEnabled(id: number): boolean
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].id === id && store_layers[i].enabled)
      return true;

  return false;
}

