// Identify settings windows

import useStore from '../store';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_textColorBlended, theme_textColorMain } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface IdentifySettingsProps
{
}

//-------------------------------------------------------------------------------
// Identify settings window
//-------------------------------------------------------------------------------
export function IdentifySettings(props: IdentifySettingsProps) 
{
  // Get needed state data from the store
  const { store_identifySettingsOpen, store_setIdentifySettingsOpen, 
          store_project, store_setProject_IdentifyShowEmptyValues, 
          store_setProject_IdentifyShowOnlyParcelUserAttribs, store_setProjectIsDirty, 
        } = useStore();











  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    store_setIdentifySettingsOpen(false);
  }

  //-------------------------------------------------------------------------------
  // A checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnShowAttribWithEmptyValuesCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    store_setProject_IdentifyShowEmptyValues(checked);

    // Changes were made to the project settings - flag it as dirty so it will get saved
     store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // A checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnShowOnlyUserAttribsCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    store_setProject_IdentifyShowOnlyParcelUserAttribs(checked);

    // Changes were made to the project settings - flag it as dirty so it will get saved
     store_setProjectIsDirty(true);
  }  



















  if(!store_project || !store_project.user_settings) return null;

  // Main render

  return (

    <Dialog open={store_identifySettingsOpen} onClose={onClose} maxWidth='md'>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.3rem', color: theme_textColorMain }}>
            Identify Settings
          </Typography>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 1, background: theme_bgColorGradient2 }}>

        <Stack direction='column' sx={{ mt: 2 }}> {/* NOTE: Don't know why this Stack has a ~20px margin at the bottom */}

          {/* Show attributes with empty values */}

          <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>
            <Checkbox size='medium' checked={store_project.user_settings.identify_showEmptyValues} 
                onChange={(e,c)=>OnShowAttribWithEmptyValuesCheckBoxChange(e,c)}
                sx=
                {{ 
                  mr: 1, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
              <Typography sx={{ color: theme_textColorMain, opacity: 0.7 }}>
                Show attributes with empty values
              </Typography>
          </Stack>

          {/* For parcels show only user-selected attributes */}

          <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>
            <Checkbox size='medium' checked={store_project.user_settings.identify_showOnlyParcelUserAttributes} 
                onChange={(e,c)=>OnShowOnlyUserAttribsCheckBoxChange(e,c)}
                sx=
                {{ 
                  mr: 1, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
                  '&.Mui-checked': { color: theme_textColorBlended } 
                }} />
              <Stack>
                <Typography sx={{ color: theme_textColorMain, opacity: 0.7 }}>
                  For parcels show only user-selected attributes
                </Typography>
                <Typography sx={{ color: theme_textColorBlended, opacity: 0.6, mt: -0.2, fontSize: '0.8rem' }}>
                  The attributes will also appear in the order you have set up
                </Typography>
              </Stack>
          </Stack>

        </Stack>
      
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='contained' autoFocus onClick={onClose} sx={{ width: '100px' }}>
            Close
          </Button>
        </Stack>
        
      </DialogActions>

    </Dialog>
  )
}







// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
// const CustomTextField = styled(TextField)(() => (
// {

//   '& .MuiInputBase-root': 
//   {
//     color: theme_textColorMain,
//   },  
//   "& .MuiOutlinedInput-notchedOutline": 
//   {
//     borderColor: theme_textColorBlended,
//   },
//   "&.Mui-focused": 
//   {
//     "& .MuiOutlinedInput-notchedOutline": 
//     {
//       borderColor: theme_textColorBlended,
//     }
//   },
//   "&:hover": 
//   {
//     "& .MuiOutlinedInput-notchedOutline": 
//     {
//       borderColor: theme_orange,
//     }
//   },
// }));
