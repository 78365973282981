// Edit layer legend

import { Box, Button, Checkbox, IconButton, MenuItem, SelectChangeEvent, Stack, Tooltip, Typography } from "@mui/material";
import { theme_bgColorLight1, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import { ILayer } from '../../Layers/LayerInterfaces';
import { ILegend, ILegendEntry, LegendSortOrder } from "../../Legend/LegendInterfaces";
import { CustomSelect, MuiColorInputStyled } from "./EditLayerStyle";
import { ChangeEvent, ReactNode, useState } from "react";
import { CustomTextField } from "./EditLayer";
import { MuiColorInputColors, matchIsValidColor } from "mui-color-input";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { ToastNotification } from "../../ToastNotifications";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { UploadLegendImageFile } from "../LayerLibraryOps";
import useStore from "../../store";
import { IImageDimensions, GetImageDimensionsForFile, LAYER_LEGEND_IMAGES_BASE_URL } from "../../Globals";


const LEGEND_UNITS_MAX_LENGTH = 40;
const LEGEND_IMAGE_S3_FILENAME_MAX_LENGTH = 256;
const LEGEND_IMAGE_S3_UPLOAD_MAX_WIDTH = 800;
const LEGEND_IMAGE_S3_UPLOAD_MAX_HEIGHT = 1000;
const LEGEND_IMAGE_S3_UPLOAD_MAX_SIZE_MB = 1.0;
const LEGEND_IMAGE_S3_UPLOAD_MAX_SIZE_BYTES = LEGEND_IMAGE_S3_UPLOAD_MAX_SIZE_MB*1024*1024;

type TLayerLegendType  = 'normal' | 'image';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface EditLayerLegendProps
{
  layer?: ILayer;
  newLegend?: ILegend;
  setNewLegend: any;
  setLegendWasChanged: any;
}

//-------------------------------------------------------------------------------
// Edit layer legend
//-------------------------------------------------------------------------------
export function EditLayerLegend(props: EditLayerLegendProps) 
{
  // Get needed state data from the store
  const { store_uploadingLegendImage, 
        } = useStore();
  

  const [currEntryBeingEdited, setCurrEntryBeingEdited] = useState<ILegendEntry|undefined>(undefined);

  const [uploadFile, setUploadFile] = useState<File|undefined>(undefined);

  const [lockUploadFilenameEditing, setLockUploadFilenameEditing] = useState<boolean>(false);




  //-------------------------------------------------------------------------------
  // The "Sort By" combo box was changed.
  //-------------------------------------------------------------------------------
  const OnSortByAttributeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    // Update the legend
    props.setNewLegend({...props.newLegend, sort_by: event.target.value as string });

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The "Ascending Order" checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnAscSortOrderCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => 
  {
    const newSortOrder: LegendSortOrder = checked===true ? 'asc' : 'desc';

    // Update the legend
    props.setNewLegend({...props.newLegend, sort_order: newSortOrder });

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The units text box has changed.
  //-------------------------------------------------------------------------------
  function OnUnitsChanged(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Update the legend
    props.setNewLegend({...props.newLegend, units: newValue });

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }  

  //-------------------------------------------------------------------------------
  // Add a new legend entry.
  //-------------------------------------------------------------------------------
  function OnNewLegendEntry()
  {
    if(!props.newLegend) return;

    // Get the max id

    let maxID: number = 0;
    props.newLegend.entries.map(entry => 
    {
      if(entry.id > maxID)
        maxID = entry.id;
      return null;
    });

    // Create a new entry and add it to the legend

    const newEntry: ILegendEntry = 
    {
      id: maxID+1,
      name: '',
      color: '#000000'
    }

    const updatedLegend: ILegend =
    {
      ...props.newLegend,
      entries: [...props.newLegend.entries, newEntry ]
    }

    // Update the legend
    props.setNewLegend(updatedLegend);

    // The new entry goes into EDIT mode automatically
    setCurrEntryBeingEdited(newEntry);

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A legend entry is being deleted.
  //-------------------------------------------------------------------------------
  function OnDeleteLegendEntry(legendEntry: ILegendEntry)
  {
    if(!legendEntry || !props.newLegend) return;

    const updatedLegend: ILegend =
    {
      ...props.newLegend,
      entries: props.newLegend.entries.filter(entry => entry.id !== legendEntry.id)
    }

    // Update the legend
    props.setNewLegend(updatedLegend);

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A legend entry color has changed.
  //-------------------------------------------------------------------------------
  function OnColorChanged(legendEntry: ILegendEntry, value: string, colors: MuiColorInputColors)
  {
    if(!legendEntry || !props.newLegend) return;

    const updatedLegend: ILegend =
    {
      ...props.newLegend,
      entries: props.newLegend.entries.map(entry => entry.id === legendEntry.id ? ({ ...entry, color: value } as ILegendEntry) : entry),
    }

    // Update the legend
    props.setNewLegend(updatedLegend);

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A legend entry name has changed.
  //-------------------------------------------------------------------------------
  function OnEntryNameChanged(legendEntry: ILegendEntry, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    if(!legendEntry || !props.newLegend) return;

    const newName: string = event.target.value;

    const updatedLegend: ILegend =
    {
      ...props.newLegend,
      entries: props.newLegend.entries.map(entry => entry.id === legendEntry.id ? ({ ...entry, name: newName } as ILegendEntry) : entry),
    }

    // Update the legend
    props.setNewLegend(updatedLegend);
    
    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // A legend entry is being edited.
  //-------------------------------------------------------------------------------
  function OnEditLegendEntry(legendEntry: ILegendEntry)
  {
    if(!legendEntry || !props.newLegend) return;

    setCurrEntryBeingEdited(legendEntry);
  }

  //-------------------------------------------------------------------------------
  // Exit EDIT mode.
  //-------------------------------------------------------------------------------
  function OnStopEditing()
  {
    setCurrEntryBeingEdited(undefined);
  }

  //-------------------------------------------------------------------------------
  // The legend type combo box was changed.
  //-------------------------------------------------------------------------------
  const OnLegendTypeChanged = (event: SelectChangeEvent<unknown>, child: ReactNode) => 
  {
    const newLegendType: TLayerLegendType = event.target.value as TLayerLegendType;

    // Set the new value

    if(newLegendType === 'normal')
      props.setNewLegend({...props.newLegend, image_s3: undefined });
    else if(newLegendType === 'image')
      props.setNewLegend({...props.newLegend, image_s3: '', entries: [], sort_by: undefined, sort_order: undefined, units: undefined });
    else 
      return;

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }
  
  //-------------------------------------------------------------------------------
  // The image S3 filename text box has changed.
  //-------------------------------------------------------------------------------
  function OnImageS3Changed(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void 
  {
    const newValue: string = event.target.value;

    // Update the legend
    props.setNewLegend({...props.newLegend, image_s3: newValue });

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // The user has selected a legend image file to upload.
  //-------------------------------------------------------------------------------
  const OnUploadFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    if(!event.target.files || event.target.files.length === 0) return;

    const file: File = event.target.files[0];
    if(!file) return;

    if(!file.name) return;  // If the user cancels

    // Restrict images to a certain size

    if(file.size > LEGEND_IMAGE_S3_UPLOAD_MAX_SIZE_BYTES)
    {
      ToastNotification('error', `The file you selected is too large (over ${LEGEND_IMAGE_S3_UPLOAD_MAX_SIZE_MB} MB)`);
      return;
    }

    // Restrict image to a certain width and height

    const dimensions: IImageDimensions = await GetImageDimensionsForFile(file);
    if(!dimensions)
    {
      ToastNotification('error', `Unable to read image dimensions`);
      return;
    }

    if(dimensions.width > LEGEND_IMAGE_S3_UPLOAD_MAX_WIDTH)
    {
      ToastNotification('error', `The image's width exceeds the max (${LEGEND_IMAGE_S3_UPLOAD_MAX_WIDTH})`);
      return;
    }

    if(dimensions.height > LEGEND_IMAGE_S3_UPLOAD_MAX_HEIGHT)
    {
      ToastNotification('error', `The image's height exceeds the max (${LEGEND_IMAGE_S3_UPLOAD_MAX_HEIGHT})`);
      return;
    }

    // The filename text box cannot be manually edited any more
    setLockUploadFilenameEditing(true);

    setUploadFile(file);
  
    // Update the legend entry
    props.setNewLegend({...props.newLegend, image_s3: file.name });

    // Tell calling code that the legend was changed (so it knows to save it)
    //props.setLegendWasChanged(true);
  }

  //-------------------------------------------------------------------------------
  // Begin the legend image upload.
  //-------------------------------------------------------------------------------  
  async function OnUploadLegendImage()
  {
    if(!props.newLegend) return;

    // Verify input

    // if(!props.newLegend.image_s3 || props.newLegend.image_s3.trim().length === 0)
    // {
    //   ToastNotification('error', 'The image filename cannot be empty');
    //   return;
    // }

    if(!uploadFile)
    {
      ToastNotification('error', 'Please select a local image file to upload');
      return;
    }
  
    // Upload the image file

    const newFilename: string | undefined = await UploadLegendImageFile(uploadFile, props.newLegend, props.layer);
    if(!newFilename)
      return;

    // Update the legend entry
    props.setNewLegend({...props.newLegend, image_s3: newFilename });

    // Clear the file that was uploaded
    setUploadFile(undefined);

    // Tell calling code that the legend was changed (so it knows to save it)
    props.setLegendWasChanged(true);
  }












  if(!props.layer || !props.newLegend) return null;

  // Main render

  return (

    <Stack>

      {/* Legend type drop-down */}

      <Stack sx={{ mr: 4 }}>

        <Typography sx={{ mt: '-2px', fontSize: '0.8rem', color: theme_textColorBlended+'B0' }}>
          Legend Type
        </Typography>

        <CustomSelect id="legend-type-select" variant='standard' size='small'
                      value={props.newLegend.image_s3!==undefined ? 'image' : 'normal' } 
                      onChange={OnLegendTypeChanged}
                      sx={{ width: '320px', mt: '2px', mb: 4, bgcolor: theme_bgColorLight1, borderRadius: 2, p: 1 }}>
          <MenuItem key='normal' value='normal'>
            <Stack direction='column'>
              <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '1.1rem', fontWeight: 'bold' }}>
                Entries
              </Typography>
              <Typography sx={{ color: theme_limeGreen, fontSize: '0.8rem' }}>
                You specify individual legend entries and colors.
              </Typography>
            </Stack>
          </MenuItem>
          <MenuItem key='image' value='image'>
            <Stack direction='column'>
              <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '1.1rem', fontWeight: 'bold' }}>
                Image
              </Typography>
              <Typography sx={{ color: theme_limeGreen, fontSize: '0.8rem' }}>
                You upload the legend as an image.
              </Typography>
            </Stack>
          </MenuItem>
        </CustomSelect>
      </Stack>




      {props.newLegend.image_s3 === undefined
        ?
          // UI for 'normal' legend type

          <Stack>

            {/* Sort controls */}

            <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>

              {/* Sort By Attribute */}

              <Stack sx={{ }}>

                <Typography sx={{ mt: '-2px', fontSize: '0.8rem', color: theme_textColorBlended+'B0' }}>
                  Sort By
                </Typography>

                <CustomSelect id="sort-attrib-select" variant='standard' size='small'
                              value={props.newLegend.sort_by} onChange={OnSortByAttributeChanged}
                              sx={{ mt: '-3px', width: '80px' }}>

                  <MenuItem key={'No Sort'} value={'none'}>
                    <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '0.9rem' }}>
                      No Sort
                    </Typography>
                  </MenuItem>

                  <MenuItem key={'No Sort'} value={'name'}>
                    <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '0.9rem' }}>
                      Name
                    </Typography>
                  </MenuItem>

                  <MenuItem key={'No Sort'} value={'color'}>
                    <Typography sx={{ color: theme_textColorMain+'C0', fontSize: '0.9rem' }}>
                      Color
                    </Typography>
                  </MenuItem>

                </CustomSelect>
              </Stack>

              {/* Sort Order */}

              <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'left' }}>
                <Checkbox size='small' checked={props.newLegend.sort_order==='asc'}
                          disabled={props.newLegend.sort_by===undefined}
                          onChange={(e,c)=>OnAscSortOrderCheckBoxChange(e,c)}
                          sx=
                          {{ 
                            m: 0, color: theme_textColorBlended, width: '40px', height: '40px',
                            '&.Mui-checked': { color: theme_textColorBlended } 
                          }} />
                <Typography sx={{ color: theme_textColorBlended+'B0', fontSize: '0.8rem' }}>
                  Asc Order
                </Typography>
              </Stack>

              {/* Units */}

              <CustomTextField name='units' variant='standard' size='small' autoComplete='off'
                              value={props.newLegend.units} onChange={OnUnitsChanged}
                              inputProps={{ maxLength: LEGEND_UNITS_MAX_LENGTH }}
                              label={<Typography sx={{ color: theme_textColorBlended }}>Legend Units</Typography>}
                              sx={{ p: 0, ml: 5, width: '100%' }}/>

            </Stack>

            {/* Legend entries */}

            <Typography sx={{ mt: 3, fontSize: '0.7rem', color: theme_textColorBlended, opacity: 0.8 }}>
              {props.newLegend.entries.length} legend {props.newLegend.entries.length===1?'entry':'entries'}
            </Typography>

            <Stack sx={{ mt: 1, p: 0 }}>

              {props.newLegend.entries.length >= 1
                ?
                  props.newLegend.entries.map(legendEntry =>

                    legendEntry.id === currEntryBeingEdited?.id
                      ?
                        // -------------------------------
                        // Legend entry when in EDIT mode
                        // -------------------------------

                        <Stack key={legendEntry.id} direction='row' 
                              sx={{ my: 0, width: 'calc(100%)', alignItems: 'center', justifyContent: 'space-between',
                                    bgcolor: theme_textColorMain+'25', borderRadius: 1 }}>

                          {/* Color */}

                          <MuiColorInputStyled variant='standard' size='small' format='hex' isAlphaHidden={true}
                                              value={legendEntry.color} onChange={(v,c)=>OnColorChanged(legendEntry,v,c)}
                                              sx={{ width: '120px' }}/>

                          {/* Name */}

                          <CustomTextField variant='standard' size='small' autoComplete='off' 
                                          //inputRef={input => input && input.focus()} xxx
                                          inputProps={{style: {fontSize: '0.9rem'}}}
                                          value={legendEntry.name} onChange={(e)=>OnEntryNameChanged(legendEntry,e)}
                                          sx={{ p: 0, width: '270px', maxWidth: '270px' }}/>

                          <Stack direction='row'>

                            {/* Delete entry button */}

                            <Tooltip title='Delete this legend entry' arrow placement='left-end'>
                              <IconButton sx={{ ml: 1, p: 0.8 }} onClick={(_)=>OnDeleteLegendEntry(legendEntry)}>
                                <DeleteForeverIcon sx={{ width: '18px', height: '18px', opacity: 0.8, color: theme_textColorBlended }}/>
                              </IconButton>
                            </Tooltip>

                            {/* Edit legend entry button */}

                            <Tooltip title='Stop editing this legend entry' arrow placement='right-start'>
                              <IconButton sx={{ ml: 0.5, p: 0.8 }} onClick={(_)=>OnStopEditing()}>
                                <EditIcon sx={{ width: '20px', height: '20px', color: theme_orange }}/>
                              </IconButton>
                            </Tooltip>

                          </Stack>

                        </Stack>
                      :
                        // -------------------------------
                        // Legend entry when in VIEW mode
                        // -------------------------------

                        <Stack key={legendEntry.id} direction='row' sx={{ my: 0.1, width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>

                          {/* Color */}

                          <Stack direction='row' sx={{ width: '110px' }}>
                            <Stack sx={{ width: '24px', height: '24px', bgcolor: legendEntry.color, border: 0, borderRadius: '4px', color: 'black' }}/>
                            <Typography sx={{ ml: 1, color: theme_textColorMain+'D0' }}>
                              {legendEntry.color}
                            </Typography>
                          </Stack>

                          {/* Name */}

                          <Typography sx={{ width: '260px', maxWidth: '260px', color: theme_textColorMain+'D0', fontSize: '0.9rem', textWrap: 'wrap' }}>
                            {legendEntry.name}
                          </Typography>

                          <Stack direction='row'>

                            {/* Delete entry button */}

                            <Tooltip title='Delete this legend entry' arrow placement='left-end'>
                              <IconButton sx={{ ml: 1, p: 0.8 }} onClick={(_)=>OnDeleteLegendEntry(legendEntry)}>
                                <DeleteForeverIcon sx={{ width: '18px', height: '18px', opacity: 0.8, color: theme_textColorBlended}}/>
                              </IconButton>
                            </Tooltip>

                            {/* Edit legend entry button */}

                            <Tooltip title='Edit this this legend entry' arrow placement='right-start'>
                              <IconButton sx={{ ml: 0.5, p: 0.8 }} onClick={(_)=>OnEditLegendEntry(legendEntry)}>
                                <EditIcon sx={{ width: '20px', height: '20px', color: theme_textColorBlended}}/>
                              </IconButton>
                            </Tooltip>

                          </Stack>

                        </Stack>
                  )
                :
                  <Typography sx={{ color: theme_textColorMain, opacity: 0.5 }}>
                    This layer currently has no legend entries.
                  </Typography>
              }
            </Stack>

            {/* Add new legend entry button */}

            <Stack sx={{ mt: 2, width: '100%', alignItems: 'center' }}>
              <Button variant='outlined' sx={{ width: '35%', textTransform: 'none' }}
                      onClick={(_)=>OnNewLegendEntry()}>
                Add New Entry
              </Button>
            </Stack>

          </Stack>
        
        :
          // UI for 'image' legend type
          
          <Stack sx={{ width: '100%', alignItems:'center' }}>

            <Typography sx={{ width: '100%', fontSize: '1.1rem', color: theme_textColorBlended}}>
              Legend Image
            </Typography>

            <Stack sx={{ mt: 2, borderRadius: 3, boxShadow: 4, bgcolor: theme_bgColorLight1, 
                         justifyContent: 'right', width: '40%' }}>

              <Box component="img" alt="" 
                   src={LAYER_LEGEND_IMAGES_BASE_URL + encodeURIComponent(props.newLegend.image_s3)}
                   sx={{ m: '3px', borderRadius: 2.8 }}/>

            </Stack>

            {/* Image S3 filename */}

            <CustomTextField name='image_s3' variant='standard' size='small' autoComplete='off'
                             disabled={lockUploadFilenameEditing}
                             value={props.newLegend.image_s3} onChange={OnImageS3Changed}
                             inputProps={{ maxLength: LEGEND_IMAGE_S3_FILENAME_MAX_LENGTH }}
                             label={<Typography sx={{ color: theme_textColorBlended }}>Image Filename</Typography>}
                             sx={{ mt: 2, p: 0, ml: 0, width: '100%' }}/>

            <Stack direction='row' sx={{ mt: 2 }}>

              <label htmlFor="upload-legend-image">
                <input style={{ display: 'none' }} id="upload-legend-image" name="upload-legend-image" type="file" 
                      onChange={OnUploadFileSelected} accept="image/png, image/jpeg"/>
                <Button variant='text' component="span"
                        sx={{ px: 3, color: theme_limeGreen, bgcolor: theme_limeGreen+'20', fontSize : '1.0rem', alignItems: 'center', textTransform: 'none' }}>
                  <DriveFolderUploadIcon sx={{ color: theme_limeGreen, width: '36px', height: '36px', mr: '8px' }}/>
                  Select a local image file to upload
                </Button>
              </label>

              <Button variant='contained' onClick={(_)=>OnUploadLegendImage()} 
                      disabled={store_uploadingLegendImage === true}
                      sx={{ width: '120px', fontWeight: 'bold', ml: 2 }}>
                Upload
              </Button>
              
            </Stack>

          </Stack>
      }

    </Stack>
  )
}




//-------------------------------------------------------------------------------
// Validate the legend.
// Returns an error message if validation FAILS, or empty string if it succeeds.
//-------------------------------------------------------------------------------
export function ValidateLegend(legend: ILegend|undefined): string
{
  if(!legend) return 'Invalid layer lagend';

  for(let i=0; i < legend.entries.length; i++)
  {
    // Validate name
    if(legend.entries[i].name.trim().length === 0)
      return 'One of the legend entries has an empty name';

    // Validate color
    if(!matchIsValidColor(legend.entries[i].color))
      return 'One of the legend entries has an invalid color value';
  }
    
  // Validated
  return '';
}

