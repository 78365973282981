// Parcels UI panel component

import { Button, IconButton, Stack, styled, Switch, SwitchProps, Typography } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight, theme_bgColorMain, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { EnterParcelsMode, ExitParcelsMode, GetParcelLayer } from "./ParcelOps";
import { ToastNotification } from "../ToastNotifications";
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { MapToggleButton } from "../CustomMuiStyles";
import InfoIcon from '@mui/icons-material/Info';
import ParcelIdentify from "./ParcelIdentify";
import ParcelSearch from "./ParcelSearch";
import SearchIcon from '@mui/icons-material/Search';


export const MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_INACTIVE_PARCELS = 7.8;  // selected parcels appear gray with a red dot in the center (cannot be interacted with)
export const MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS = 10.5;  // selected parcels appear red with a yellow border and can be interacted with



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelPanelProps
{
}

//-------------------------------------------------------------------------------
// Parcel UI panel component
//-------------------------------------------------------------------------------
const ParcelPanel = (props: ParcelPanelProps) => 
{
  // Get needed state data from the store
  const { store_selectedParcels, store_userProfile, 
          store_setAoiUIMode, store_drawerOpen, store_setDrawerOpen,  
          store_activeDrawerMenuItem, store_setActiveDrawerMenuItem, store_aoiUIMode, 
          store_parcelEditMode, store_project, store_setActiveInfoLayer, 
          store_setParcelSearchEnabled, store_parcelSearchEnabled, 
        } = useStore();



        

  //-------------------------------------------------------------------------------
  // Saves selected parcels as a new AOI.
  //-------------------------------------------------------------------------------
  const onSaveSelectedParcelsAsAOI = () => 
  {
    if(!store_userProfile) return;

    if(useStore.getState().store_selectedParcels.length === 0)
    {
      ToastNotification('error', 'Please select at least one parcel for the new AOI');
      return;
    }

    // If the main menu left drawer is not already open, open it now

    if(!store_drawerOpen)
      store_setDrawerOpen(true);

    // If the AOI accordion menu is not already open, open it now

    if(store_activeDrawerMenuItem !== 'aoi')
      store_setActiveDrawerMenuItem('aoi');

    // If the current AOI is not in a saved state, tell the user to save it first

    const store_aoi = useStore.getState().store_aoi;
    if(store_aoi && store_aoi.isDirty)
    {
      ToastNotification('error',  'The active AOI is not saved.  Please save it first before creating a new AOI from selected parcels.');
      return false;
    }

    // Switch the AOI UI into "create from parcels" mode
    // This asks the user for the name of the new AOI.
    //
    // Once they hit OK, the AOI will be created (see: AoiFromParcels.tsx)

    if(store_aoiUIMode !== 'create-from-parcels')
      store_setAoiUIMode('create-from-parcels');
  }

  //-------------------------------------------------------------------------------
  // The "Join Adjacent Parcels" setting has changed.
  //-------------------------------------------------------------------------------
  // const onJoinAdjacentParcelsChanged = (event: React.ChangeEvent<HTMLInputElement>) => 
  // { 
  //   if(!store_userProfile) return;

  //   // Update app settings
  //   store_setUserProfileJoinAdjacentParcels(event.target.checked);
  //   store_setUserProfileIsDirty(true);
  // }

  //-------------------------------------------------------------------------------
  // The parcels panel toggle button was clicked.
  //-------------------------------------------------------------------------------
  const onParcelsPanelButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => 
  {
    if(store_parcelEditMode)
      ExitParcelsMode();
    else
      EnterParcelsMode();
  }

  //-------------------------------------------------------------------------------
  // The layer's info button was clicked.
  //-------------------------------------------------------------------------------
  const onLayerInfoClick = () => 
  {
    const layer = GetParcelLayer();
    if(!layer) return;

    store_setActiveInfoLayer(layer);
  }

  //-------------------------------------------------------------------------------
  // The parcel search button was clicked.
  //-------------------------------------------------------------------------------
  const OnEnableParcelSearch = () =>
  {
    store_setParcelSearchEnabled(true);
  }







  // If the bundle doesn't have a parcel layer, don't show the parcels UI at all
  if(!GetParcelLayer()) return null;


  // Main render

  return (

      <Stack sx={{ position: 'absolute', right: 100, top: 10, pointerEvents: 'none' }}>

        {/* Toggle button */}

        <Stack sx={{ alignItems: 'end', pointerEvents: 'all' }}>
          <MapToggleButton sx={{ borderRadius: 20, p: 0.3, pl: 1, pr: 1, border: 1, boxShadow: 3 }}
                          value='parcelToggleButton' selected={store_parcelEditMode} 
                          onChange={onParcelsPanelButtonClick}>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <HighlightAltIcon sx={{ width: '28px', height: '28x', mr: '2px' }}/>
              <Typography sx={{ fontSize: '0.9rem', color: theme_orange, textAlign: 'center' }}>
                Parcels
              </Typography>
            </Stack>

          </MapToggleButton>
        </Stack>

        {/* Parcel UI (only when in parcels mode) */}

        {store_parcelEditMode 
          ?
            <Stack alignItems='flex-end' sx={{ pointerEvents: 'all' }}>

              {/* Main Parcel UI */}

              <Stack sx={{ bgcolor: theme_textColorBlended, borderRadius: 1, mt: 1, boxShadow: 3, width: '240px' }}>
                <Stack direction='column' sx={{ bgcolor: theme_bgColorMain, borderRadius: 1, m: '1px', p: '7px', alignItems: 'center' }}>

                  <Stack sx={{ alignItems: 'center', width: '100%' }}>

                    <Stack direction='row' sx={{ width: '100%', justifyContent: 'space-between' }}>

                      {store_project && store_project.user_settings.mapView.zoom && store_project.user_settings.mapView.zoom < MAPBOX_ZOOM_LEVEL_REQUIRED_TO_SEE_PARCELS
                        ?
                          // NOT zoomed in far enough to see parcels

                          <Typography sx={{ width: '100%', fontSize: '0.7rem', color: theme_errorRed, p: 0.5, opacity: 0.75, fontWeight: 'bold' }}>
                            Zoom in further to see parcels.
                          </Typography>
                        :
                          // We are zoomed in far enough to see parcels

                          <Stack sx={{ width: '100%' }}>
                            <Typography sx={{ fontSize: '0.8rem', color: theme_textColorMain, opacity: 0.8 }}>
                              Click a parcel to select it.
                            </Typography>
                            <Typography sx={{ fontSize: '0.6rem', color: theme_textColorBlended, mt: '2px'  }}>
                              Hold CTRL to select multiple parcels.
                            </Typography>
                          </Stack>
                      }

                      <IconButton size='small' sx={{ ml: '0px', height: '26px', width: '26px', opacity: 0.9 }} 
                                  onClick={(_)=>onLayerInfoClick()}>
                        <InfoIcon color='primary' fontSize="small" sx={{ opacity: 1 }}/>
                      </IconButton>
                    </Stack>

                    {store_selectedParcels.length >= 1
                      ?
                        <Button size='small' variant='outlined' onClick={(e)=>onSaveSelectedParcelsAsAOI()}
                                sx={{ mt: 1, textTransform:'none', fontSize: '0.7rem', p: 0, pl: 1, pr: 1, 
                                      color: theme_limeGreen, opacity: store_selectedParcels.length > 0 ? 1 : 0.5 }}>
                          <MyLocationIcon sx={{ mr: '4px', width: '16px' }} />
                          Save selected parcel{store_selectedParcels.length===1?'':'s'} as an AOI
                        </Button>
                      :null
                    }

                    {!store_parcelSearchEnabled
                      ?
                        <Button variant='outlined' size='small' onClick={(_)=>OnEnableParcelSearch()}
                                sx={{ fontSize: '0.7rem', color: theme_textColorBlended, opacity: 1, textTransform: 'none', mt: 1.5 }}>
                          <SearchIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mx: 0.5 }} />
                          Search Parcels...
                        </Button>
                      :null
                    }

                  </Stack>


                </Stack>
              </Stack>

              {/* Parcel search */}

              <ParcelSearch/>

              {/* Parcel info */}

              <ParcelIdentify/>

            </Stack>

          :null
        }
          
      </Stack>

  )
}



export default ParcelPanel;



//-------------------------------------------------------------------------------
// MUI Switch custom style.
//-------------------------------------------------------------------------------
export const MySwitch = styled((props: SwitchProps) => (<Switch {...props} />))(({ theme }) => (
{
  "& .MuiSwitch-switchBase": 
  {
    color: "#7192CC",  // Knob color when OFF
    opacity: 1,
  },  
  "& .MuiSwitch-switchBase.Mui-checked": 
  {
    color: "#B2CCFF",  // Knob color when ON
    opacity: 1,
  },
  // "& .MuiSwitch-switchBase.Mui-disabled": 
  // {
  //   color: "#7090D0",  // Knob color when DISABLED and OFF
  //   opacity: 1,
  // },
  // "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked": 
  // {
  //   color: "#ABBED6",  // Knob color when DISABLED and ON
  //   opacity: 1,
  // },
  //"&:hover .MuiSwitch-switchBase": { color: 'brown' },    // Knob color on hover
  '& .MuiSwitch-track': 
  {
    backgroundColor: theme_bgColorLight,  // Track color when OFF
    opacity: 0.5,
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": 
  {
    backgroundColor: theme_bgColorLight,  // Track color when ON
    opacity: 1,
  },
  // "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": 
  // {
  //   backgroundColor: theme_bgColorMain,  // Track color when DISABLED
  //   opacity: 0.3,
  // },
}));

  