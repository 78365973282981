// UI component to edit the active AOI

import { Stack, Button, Typography, ButtonGroup } from "@mui/material";
import { theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import useStore from "../store";
import { ExitAoiEditMode, SaveActiveAoi } from "./AoiOps";
import { FriendlyNumber } from "../Globals";
import InfoIcon from '@mui/icons-material/Info';
import LightbulbIcon from '@mui/icons-material/Lightbulb';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiEditProps
{
}

//-------------------------------------------------------------------------------
// AOI rename component
//-------------------------------------------------------------------------------
const AoiEdit = (props: AoiEditProps) => 
{
  // Get needed state data from the store
  const { store_aoi, store_project, 
        } = useStore();



        






  //-------------------------------------------------------------------------------
  // Revert to the previously-saved (or loaded) AOI and exit AOI edit mode.
  //-------------------------------------------------------------------------------
  const onRevertAoi = async () => 
  {
    // Revert changes then exit AOI edit mode
    ExitAoiEditMode(true);
  }

  //-------------------------------------------------------------------------------
  // Save AOI changes and exit AOI edit mode.
  //-------------------------------------------------------------------------------
  const onAcceptAoiChanges = async () => 
  {
    // Save the AOi changes
    SaveActiveAoi();

    // Exit AOI edit mode
    ExitAoiEditMode();
  }

  





  if(!store_project || !store_aoi) return null;

  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center', mt: -2 }}>

      <Typography sx={{ fontSize: '1.2rem', color: theme_textColorBlended }}>
        Edit Area of Interest (AOI)
      </Typography>

      <Typography sx={{ mt: 2, mb: 2, fontSize: '0.8rem', color: theme_limeGreen }}>
        {store_aoi.aoi_name}
      </Typography>

      {/* Show polygon count and total acres */}

      <Stack direction='row' sx={{ fontSize: '0.9rem', opacity: 0.8, color: theme_textColorMain, alignItems: 'center' }}>
        <Typography sx={{ fontSize: '1.1rem', color: theme_orange, fontWeight: 'bold', ml: 0.5, mr: 0.5 }}>{store_aoi?.polygonCount}</Typography>
        section{store_aoi?.polygonCount===1?'':'s'} 
        <Typography sx={{ fontSize: '1.1rem', color: theme_orange, fontWeight: 'bold', ml: 2, mr: 0.5 }}>{FriendlyNumber(store_aoi?.acres)}</Typography>
        acres
      </Stack>

      {/* Save / Revert buttons */}

      {store_project.aois.length > 0
        ?
          <Stack direction='row' sx={{ mt: 2 }}>

            <ButtonGroup sx={{ mt: 1 }}>

              <Button variant='outlined' onClick={() => { onAcceptAoiChanges() }} disabled={!store_aoi.isDirty}
                    sx={{ color: store_aoi.isDirty ? theme_orange : theme_textColorMain, fontSize: '0.8rem' }}>
                Accept Changes
              </Button>

              <Button variant='outlined' onClick={onRevertAoi} sx={{ color: theme_textColorBlended, fontSize: '0.8rem', pl: 0.8, pr: 0.8 }}>
                Cancel
              </Button>

            </ButtonGroup>

          </Stack>
        :null
      }

      <Stack direction='row' sx={{ mt: 3, width: '100%', alignItems: 'center' }}>
        <InfoIcon sx={{ color: theme_textColorBlended, fontSize: '20px' }}/>
          <Typography sx={{ ml: 1, fontSize: '1rem', color: theme_textColorMain, opacity: 0.7 }}>
            Tips:
          </Typography>
      </Stack>

      <Stack sx={{ ml: 0, mt: 1 }}>

        <Stack direction='row' sx={{ mt: 0.2, width: '100%', alignItems: 'center' }}>
          <LightbulbIcon sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '18px' }}/>
          <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_textColorMain, opacity: '0.5' }}>
            To create a new polygon...
          </Typography>
        </Stack>

        <Stack direction='row' sx={{ mt: 0.2, width: '100%', alignItems: 'center' }}>
          <LightbulbIcon sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '18px' }}/>
          <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_textColorMain, opacity: '0.5' }}>
            To delete a polygon...
          </Typography>
        </Stack>

        <Stack direction='row' sx={{ mt: 0.2, width: '100%', alignItems: 'center' }}>
          <LightbulbIcon sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '18px' }}/>
          <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_textColorMain, opacity: '0.5' }}>
            To move a polygon...
          </Typography>
        </Stack>

        <Stack direction='row' sx={{ mt: 0.2, width: '100%', alignItems: 'center' }}>
          <LightbulbIcon sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '18px' }}/>
          <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_textColorMain, opacity: '0.5' }}>
            To move a polygon point...
          </Typography>
        </Stack>

        <Stack direction='row' sx={{ mt: 0.2, width: '100%', alignItems: 'center' }}>
          <LightbulbIcon sx={{ color: theme_limeGreen, opacity: 0.7, fontSize: '18px' }}/>
          <Typography sx={{ ml: 0.5, fontSize: '0.8rem', color: theme_textColorMain, opacity: '0.5' }}>
            To add a new polygon point...
          </Typography>
        </Stack>

      </Stack>



      {/* <Typography sx={{ mt: 2, fontSize: '0.8rem', color: theme_textColorBlended }}>
        To create a new polygon segment, click the small white icon with a square on the left side of the map, then click to create each point in the polygon.  To close out the polygon, click on the first point.
      </Typography>

      <Typography sx={{ mt: 2, fontSize: '0.8rem', color: theme_textColorBlended }}>
        To edit a polygon, left-click once.  The color will change to orange.  At this point you can left-click and drag to move the whoel polygon.
      </Typography> */}

    </Stack>    
  )
}

export default AoiEdit;


