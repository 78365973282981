// Parcel attributes selector item

import { theme_textColorBlended, theme_limeGreen, theme_orange, theme_textColorMain } from '../../Theme';
import { Checkbox, Stack, Typography } from "@mui/material";
import { IVectorLayerAttribute } from '../../Layers/LayerInterfaces';



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AttribSelectorInactiveItemProps
{
  attrib: IVectorLayerAttribute;
  OnAttribCheckBoxChange: any;
}

//-------------------------------------------------------------------------------
// Parcel attributes selector item
//-------------------------------------------------------------------------------
export function AttribSelectorInactiveItem(props: AttribSelectorInactiveItemProps) 
{
  // Get needed state data from the store
  // const { 
  //       } = useStore();




























  // Main render

  return (

    <Stack direction='row' sx={{ my: 0.5, alignItems: 'center' }}>

      <Checkbox size='medium' checked={false} 
          onChange={(e,c)=>props.OnAttribCheckBoxChange(e,c,props.attrib)}
          sx=
          {{ 
            mr: 1, color: theme_textColorBlended + 'A0', width: '40px', height: '40px',
            '&.Mui-checked': { color: theme_textColorBlended } 
          }} />

      <Stack>

        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <Typography sx={{ color: theme_limeGreen, opacity: 0.7 }}>
            {props.attrib.display_name}
          </Typography>
          <Typography sx={{ ml: 1, color: theme_orange, fontSize: '0.7rem', opacity: 0.6 }}>
            {props.attrib.unit}
          </Typography>
        </Stack>

        <Typography sx={{ fontSize: '0.7rem', color: theme_textColorMain, opacity: 0.55 }}>
          {props.attrib.description}
        </Typography>

      </Stack>
    </Stack>
  )
}



