import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from './UserPool';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import useStore from '../store';
import { LoadUserInfo, ValidateEmail } from './UserOps';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton, CircularProgress, Stack } from '@mui/material';
import AccountPageBase from '../Components/AccountPageBase';
import { stratifyx_logo_dark, theme_bgColorMain } from '../Theme';
import Debug from '../Debug';


//-------------------------------------------------------------------------------
// Log in
//-------------------------------------------------------------------------------
export default function LogIn() 
{
  // Get needed state data from the store
  const { store_setUsername, store_setIdToken, store_setAccessToken, 
          store_setRefreshToken, store_setIsLoggedIn, store_setLogInDate 
        } = useStore();

  //const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [logInSuccess, setLogInSuccess] = useState<boolean>(false);
  const [userConfirmationRequired, setUserConfirmationRequired  ] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [emailErrorText, setEmailErrorText] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordErrorText, setPasswordErrorText] = useState<string>('');

  const [loginInProgress, setLoginInProgress] = useState<boolean>(false);

  // NOTE: These are for the "show/hide password" feature
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //-------------------------------------------------------------------------------
  // Form SUBMIT
  //-------------------------------------------------------------------------------
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => 
  {
    event.preventDefault();

    setErrorMessage('');

    // Get form data and validate

    const data = new FormData(event.currentTarget);

    const rawEmailStr: string = data.get('email')!.toString().trim();
    let authEmail: string = rawEmailStr; // This will be email address we authenticate with
    let proxyEmail: string | undefined = undefined;  // If there is a proxy login, this will be the email of the proxy user

    // Check if we have a proxy login situation (2 email addresses separated by a space)
    // eg: "aturta2@gmail.com alex.turta@stratifyx.com"

    const emailsArray: string[] = rawEmailStr.split(' ');

    if(emailsArray.length > 2)
    {
      Debug.log('LogIn.handleSubmit> Invalid email');
      setErrorMessage('Invalid email');
      return;
    }

    if(emailsArray.length === 2)
    {
      // We may have a proxy login situation (2 emails)

      authEmail = emailsArray[0];
      proxyEmail = emailsArray[1];

      // Validate the proxy email

      const validationErrMsg = ValidateEmail(proxyEmail);
      setEmailError(validationErrMsg !== '');
      setEmailErrorText(validationErrMsg);
      if(validationErrMsg !== '') 
        return;
    }

    // Validate the auth email

    const validationErrMsg = ValidateEmail(authEmail);
    setEmailError(validationErrMsg !== '');
    setEmailErrorText(validationErrMsg);
    if(validationErrMsg !== '') 
      return;

    // Validate the pwd

    const password: string = data.get('password')!.toString();
    const pwdValidated = ValidateLogInPassword(password);
    setPasswordError(!pwdValidated);
    setPasswordErrorText(pwdValidated ? '' : 'Please enter a valid password');
    if(!pwdValidated) 
      return;

    // If this was a proxy login, the proxy username will be the main username
    // we care about.  We will never care about the auth username or other info for
    // the auth user again after this.
    //
    // The only think we need from the auth username are the auth tokens.
    
    const username = proxyEmail ? proxyEmail : authEmail;
   
    // Proceed with the login attempt

    const user = new CognitoUser(
    {
      Username: authEmail,
      Pool: UserPool,
    })

    const authDetails = new AuthenticationDetails(
    {
      Username: authEmail,
      Password: password
    });

    // Authenticate user

    setLoginInProgress(true);
    
    user.authenticateUser(authDetails,
    {

      // Login SUCCESS

      onSuccess: async data => 
      {
        Debug.log('LogIn.handleSubmit.onSuccess> ');

        // These are required for the LoadUserInfo call belog to work
        store_setUsername(username);
        store_setIdToken(data.getIdToken().getJwtToken());
        const accessToken: string = data.getAccessToken().getJwtToken();
        store_setAccessToken(accessToken);
        store_setRefreshToken(data.getRefreshToken().getToken());

        // Load the user info (user id, first name, last name, organization list)

        if(await LoadUserInfo() === false)
        {
          // Cognito auth worked, but we can't login without the user info

          store_setUsername('');
          store_setIdToken('');
          store_setAccessToken('');
          store_setRefreshToken('');
  
          Debug.log('LogIn.handleSubmit> Failed to load user info');
          setErrorMessage('Unable to log in');
          setLoginInProgress(false);
          return;
        }

        store_setIsLoggedIn(true);

        window.localStorage.setItem('username', username);

        store_setLogInDate(new Date());

        // const name: string = data.getIdToken().decodePayload().name;
        // const splitName = name.split(' ');
        // useStore.getState().store_setFirstName(splitName[0]);
        // useStore.getState().store_setLastName(splitName[1]);

        //LoadUserProfile();

        setLogInSuccess(true);
      },

      // Login FAILURE

      onFailure: err => 
      {
        Debug.log('LogIn.handleSubmit.onFailure> ', err);

        // CONFIRMATION required
        if(err.code === 'UserNotConfirmedException')
        {
          setErrorMessage('Email confirmation required.');
          setUserConfirmationRequired(true);
          store_setUsername(username); // The user confirmation page needs to know the username
        }
        else
          setErrorMessage(err.message);

        setLoginInProgress(false);
      },

      newPasswordRequired: data => 
      {
        // NOT IMPLEMENTED
        Debug.log('LogIn.handleSubmit.newPasswordRequired> ', data);
        setErrorMessage('New password required');
        setLoginInProgress(false);
      },

      mfaRequired: data => 
      {
        // NOT IMPLEMENTED
        Debug.log('LogIn.handleSubmit.mfaRequired> ', data);
        setErrorMessage('MFA required');
        setLoginInProgress(false);
      }
    });

    



    // temp
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

  }

  // If user confirmation is needed, navigate to the user confirmation page
  if (userConfirmationRequired) { return <Navigate to="/Account/ConfirmUser"/> }

  // If the login worked, navigate to the main app page
  if (logInSuccess) { return <Navigate to="/"/> }

  // Main render

  return (
    
    <AccountPageBase>

      <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

        <img src={stratifyx_logo_dark} alt="" height='100px' />

        {loginInProgress
          ?
            <Stack direction='row' sx={{ mt: 1, height: '260px', alignItems: 'center' }}>

              <CircularProgress/>

              <Typography alignContent='center' sx={{ fontSize: '1.4rem', ml: 3, color: theme_bgColorMain, opacity: 0.8 }}>
                Logging in...
              </Typography>

            </Stack>
          :
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

            <TextField margin="normal" required fullWidth id="email" label="Email Address"
                      name="email" autoComplete="email" autoFocus
                      helperText={emailErrorText} error={emailError}/>

            <TextField margin="normal" required fullWidth name="password" label="Password"
                      id="password" autoComplete="current-password" 
                      helperText={passwordErrorText} error={passwordError} 
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility"
                              onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}/>

            {/* <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me"/> */}

            <Grid item xs={12}>
              <Typography color='error.main' alignContent='center'>
                {errorMessage}
              </Typography>
            </Grid>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} >
              Log In
            </Button>

            <Grid container>
              <Grid item xs>

                {/* <Button variant='text' color="inherit" component={RouterLink} to={"/Account/ForgotPassword"} sx={{ color: '#1976D5', textTransform: 'none' }}>
                  Forgot password?
                </Button> */}

                <Link href="/Account/ForgotPassword" variant="body2">
                  Forgot password?
                </Link>

              </Grid>
              <Grid item>

                {/* <Button variant='text' color="inherit" sx={{ color: '#1976D5', textTransform: 'none' }} onClick={() => navigate("/Account/SignUp")} >
                  Don't have an account? Sign Up
                </Button> */}

                {/* <Button variant='text' color="inherit" component={RouterLink} to={"/Account/SignUp"} sx={{ color: '#1976D5', textTransform: 'none' }}>
                  Don't have an account? Sign Up
                </Button> */}

                {/* <Link component={RouterLink} to="/Account/SignUp">
                  Don't have an account? Sign Up
                </Link>                   */}

                <Link href="/Account/SignUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>

              </Grid>
            </Grid>
          </Box>
        }

        <Link href="/" variant="body2" sx={{ mt: 4, mb: 1 }}>Back to App</Link>

      </Box>

    </AccountPageBase>

  )
}

//-------------------------------------------------------------------------------
// Validate log in password.
// NOTE:  This version is far simpler than the SignUp version.
//-------------------------------------------------------------------------------
function ValidateLogInPassword(value: string) : boolean
{
  if(!value || (value.length < 4 || value.length > 256))
    return false;

  return true;  // validated
}