// Custom MUI styles

import { Button, Dialog, styled, TextField, ToggleButton, Tooltip, tooltipClasses, TooltipProps } from "@mui/material";
import { theme_textColorBlended, theme_bgColorLight, theme_orange, theme_bgColorLight1, theme_bgColorMain } from "./Theme";


// Styled Dialog
export const DialogStyled = styled(Dialog)(({ theme }) => (
  {
    '& .MuiDialogContent-root': { padding: theme.spacing(2) },
    '& .MuiDialogActions-root': { padding: theme.spacing(1) },
  }));

  
// Special text field rounded only on the left side.
// Used for CreateProject, RenameProject, CreateAOI, RenameAOI, etc
export const RoundedTextField = styled(TextField)(() => (
{

  backgroundColor: "#e0e0e0",
  
  borderTopLeftRadius: '100px', 
  borderBottomLeftRadius: '100px',
  borderTopRightRadius: 0, 
  borderBottomRightRadius: 0,

  "& .MuiOutlinedInput-notchedOutline": 
  {
    border: "none",
  },

  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      border: "none",
    }
  },

  '& .MuiFormLabel-root.Mui-disabled': 
  {
    color: 'red',
  },
}));


// Custom style for the smaller buttons (3D, effects)
export const MyToggleButton = styled(ToggleButton)(
{
  color: theme_textColorBlended,
  borderColor: theme_bgColorLight,

  '&:hover': 
  {
    borderColor: theme_orange,
  },

  // selected (and overides hover color)
  "&.Mui-selected, &.Mui-selected:hover": 
  {
    color: theme_orange,
    backgroundColor: theme_bgColorLight1
  }
})

// Custom gray tooltip
export const CustomGrayTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />))(({ theme }) => (
{
  [`& .${tooltipClasses.arrow}`]: 
  {
      //color: 'red',
      "&::before": 
      {
        backgroundColor: '#D0D0D0',
        border: "1px solid #999"
      }
    },
  [`& .${tooltipClasses.tooltip}`]: 
  {
    backgroundColor: '#999999',
    //color: 'rgba(0, 0, 0, 0.87)',
    //boxShadow: theme.shadows[1],
    //fontSize: '0.5rem',
  },
}));

// Tooltip customized for the NRR and impact info (in the Bundles drawer menu)
export const NrrAndImpactInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
  
  <Tooltip arrow placement='right-start'

          // This moves the tooltip closer to the anchor icon (so it doesn't dissaper 
          // if the user moves the mouse over to the tooltip, for example to click a link)
          PopperProps={{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [-30, -5] }
                }
              ]
            }
          }}

          {...props} classes={{ popper: className }} />))(({ theme }) => (
{
  [`& .${tooltipClasses.arrow}`]: 
  {
      "&::before": 
      {
        backgroundColor: '#CDE2FD',
        //border: "1px solid #999"
      }
    },
  [`& .${tooltipClasses.tooltip}`]: 
  {
    backgroundColor: '#CDE2FD',
    maxWidth: 500,
  },
}));


export const MapToggleButton = styled(ToggleButton)(
{
  color: theme_textColorBlended,
  backgroundColor: theme_bgColorMain,
  textTransform: 'none',

  '&:hover': 
  {
    color: theme_orange,
    backgroundColor: theme_bgColorMain,
  },

  "&.Mui-selected, &.Mui-selected:hover": 
  {
    color: theme_orange,
    backgroundColor: theme_bgColorLight1,
  }
});

export const MapFeedbackButton = styled(Button)(
  {
    color: '#CF9E8F',
    backgroundColor: '#732C2D',
    textTransform: 'none',
  
    '&:hover': 
    {
      color: 'pink',
      backgroundColor: '#7D1831',
    },
  
    "&.Mui-selected, &.Mui-selected:hover": 
    {
      color: 'pink',
      backgroundColor: '#7D1831',
    }
  });

