// API debug info component

import { Stack, styled, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import useStore from "./store";
import { theme_bgColorMain, theme_textColorBlended } from "./Theme";
import { DRAWER_MENU_WIDTH } from "./DrawerMenu/DrawerMenu";
import InfoIcon from '@mui/icons-material/Info';


export const API_DEBUG_MAX_ENTRIES = 10;


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface SearchBoxTooptipProps
{
}

//-------------------------------------------------------------------------------
// Search box tooltip component
//-------------------------------------------------------------------------------
const SearchBoxTooptip = (props: SearchBoxTooptipProps) => 
{
  //const theme = useTheme();

  // Get needed state data from the store
   const { store_drawerOpen
         } = useStore();







  // Main render

  return (

    <SearchBoxTooltip
      title=
      {
        <Stack>

          <Typography sx={{ mt: 0, fontSize: '1.1rem', color: theme_bgColorMain }}>
            Search for a point of interest
          </Typography>

          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              Lincoln memorial
            </Typography>
          </Stack>

          <Typography sx={{ mt: 1, fontSize: '1.1rem', color: theme_bgColorMain }}>
            Search for an address
          </Typography>

          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              2 Lincoln Memorial Circle SW
            </Typography>
          </Stack>

          <Typography sx={{ mt: 1, fontSize: '1.1rem', color: theme_bgColorMain }}>
            Search by ZIP code
          </Typography>

          <Stack sx={{ ml: 2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              20522
            </Typography>
          </Stack>

          <Typography sx={{ mt: 1, fontSize: '1.1rem', color: theme_bgColorMain }}>
            Search using coordinates
          </Typography>

          <Typography sx={{ mt: 1, ml: 1, fontSize: '0.7rem', color: theme_bgColorMain, opacity: 1 }}>
            Specify the latitude first.
          </Typography>

          <Typography sx={{ mt: 1, ml: 1, fontSize: '0.7rem', color: theme_bgColorMain, opacity: 1 }}>
            Degrees, minutes, seconds:
          </Typography>

          <Stack sx={{ mt: 1, ml: 2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              38 53 N 77 3 W
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              38° 53' N 77° 3' W
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
              38° 53' 22" N 77° 3' 1" W
            </Typography>
          </Stack>

          <Typography sx={{ mt: 1, ml: 1, fontSize: '0.7rem', color: theme_bgColorMain, opacity: 1 }}>
            If only 1 or 2 decimal places are provided, they are assumed to represent degrees/minutes/seconds:
          </Typography>

          <Stack sx={{ mt: 1, ml: 2 }}>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
                38.53N 77.03W
              </Typography>
              <Typography sx={{ ml: 2, fontSize: '0.6rem', color: 'black', opacity: 0.5 }}>
                (read as degrees and minutes)
              </Typography>
            </Stack>

            <Stack direction='row' sx={{ alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
                38.53.22N 77.03.01W
              </Typography>
              <Typography sx={{ ml: 2, fontSize: '0.6rem', color: 'black', opacity: 0.5 }}>
                (read as degrees, minutes, seconds)
              </Typography>
            </Stack>

          </Stack>

          <Typography sx={{ mt: 1, ml: 1, fontSize: '0.7rem', color: theme_bgColorMain, opacity: 1 }}>
            For decimal notation of the minutes/seconds, provide at least 3 decimal places:
          </Typography>

          <Stack sx={{ mt: 1, ml: 2 }}>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
                38.8893 -77.0502
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
                38.889N 77.050W
            </Typography>
            <Typography sx={{ fontSize: '0.8rem', color: theme_bgColorMain, opacity: 0.7 }}>
                38.889° N / 77.050° W
            </Typography>
          </Stack>

        </Stack>
      }>

      <Stack sx={{ position: 'absolute', left: 240 + (store_drawerOpen ? DRAWER_MENU_WIDTH : 0), bottom: 65 }}>
        <InfoIcon sx={{ color: theme_textColorBlended, fontSize: '17px', opacity: 1, bgcolor: theme_bgColorMain, borderRadius: 2, border: 1, borderColor: theme_textColorBlended }}/>
      </Stack>

    </SearchBoxTooltip>
  )
}

export default SearchBoxTooptip;



const SearchBoxTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement='right-start' {...props} classes={{ popper: className }} />))(({ theme }) => (
{
  [`& .${tooltipClasses.arrow}`]: 
  {
      "&::before": 
      {
        backgroundColor: '#CDE2FD',
        //border: "1px solid #999"
      }
    },
  [`& .${tooltipClasses.tooltip}`]: 
  {
    backgroundColor: '#CDE2FD',
    maxWidth: 440,
  },
}));