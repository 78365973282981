// Rounded input box (text box + button)

import { Button, inputLabelClasses, Stack } from "@mui/material";
import React from "react";
import { RoundedTextField } from "../CustomMuiStyles";
import { theme_bgColorMain, theme_orange } from "../Theme";

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface RoundedInputBoxProps
{
  title: string;
  buttonText?: string;
  buttonWidth?: any;
  onAccept: any;
  isDisabled?: boolean;
  value: string | null;
  setValue: any;
}

//-------------------------------------------------------------------------------
// Rounded input box (text box + button).
//-------------------------------------------------------------------------------
export default function RoundedInputBox(props: RoundedInputBoxProps) 
{
  const { title, buttonText, buttonWidth, onAccept, isDisabled, value, setValue } = props;




  //-------------------------------------------------------------------------------
  // The user changed the text field.
  //-------------------------------------------------------------------------------
  const onTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  {
    setValue(event.target.value);
  }


  
  // Main render

  return (

    <Stack direction='row' sx={{ width: '100%' }}>

      <RoundedTextField margin='none' variant='outlined' id={title} 
                label={title} name={title} autoFocus value={value}
                onChange={onTextChanged} disabled={isDisabled}
                sx={{ width: '100%', boxShadow: 2 }}
                      InputLabelProps={{ sx: { color: theme_bgColorMain, opacity: 0.3, [`&.${inputLabelClasses.shrink}`]: { color: theme_bgColorMain, m: '10px', opacity: 0.5 }}
                    }}/>

      <Button variant='contained' onClick={onAccept} disabled={isDisabled}
              sx={{ width: buttonWidth ? buttonWidth : '30px', borderRadius: 100, borderTopLeftRadius: 0, borderBottomLeftRadius: 0,
                    bgcolor: theme_orange, color: theme_bgColorMain, boxShadow: 2 }}>
        {buttonText ? buttonText : <div>Ok</div>}
      </Button>

    </Stack>

  )
}