// Parcel attributes selector

import useStore from '../../store';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton,  InputAdornment,  Stack, TextField, Typography, styled } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_orange, theme_textColorBlended, theme_textColorMain } from "../../Theme";
import CloseIcon from '@mui/icons-material/Close';
import { GetParcelLayer } from '../ParcelOps';
import React from 'react';
import { ILayer, IVectorLayerAttribute } from '../../Layers/LayerInterfaces';
import { IProjectParcelAttribute } from '../../Projects/ProjectInterfaces';
import SearchIcon from '@mui/icons-material/Search';
import { AttribSelectorActivePanel } from './AttribSelectorActivePanel';
import { AttribSelectorInactivePanel } from './AttribSelectorInactivePanel';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelAttribSelectorProps
{
}

//-------------------------------------------------------------------------------
// Parcel attributes selector
//-------------------------------------------------------------------------------
export function ParcelAttribSelector(props: ParcelAttribSelectorProps) 
{
  // Get needed state data from the store
  const { store_parcelAttribSelector, store_setParcelAttribSelector, store_project, 
          store_addProjectParcelAttrib, store_removeProjectParcelAttrib, 
          store_setProjectIsDirty, store_parcelExtraAttributeList,
          store_removeParcelExtraAttribute, 
        } = useStore();

  const [filterText, setFilterText] = React.useState<string>('');
  
  // Keeps track of which attributes are currently INVISIBLE (ie. filtered out based on the search text)
  const [invisibleAttributeNames, setInvisibleAttributeNames] = React.useState<string[]>([]);











  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    setFilterText('');
    setInvisibleAttributeNames([]);

    store_setParcelAttribSelector(false);
  }

  //-------------------------------------------------------------------------------
  // An attribute checkbox was changed.
  //-------------------------------------------------------------------------------
  const OnAttribCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, 
                                  checked: boolean, attrib: IVectorLayerAttribute) => 
  {
    if(checked)
    {
      // Add the new attribute to the active project's user settings
      store_addProjectParcelAttrib( { name: attrib.attribute_name } );

      // If this attribute happens to be in the "extra/temp" attributes list, remove it from there.
      // This circumstance can happen after a search, if the user searched on column X
      // then the user goes to the Parcel Attribute Selection window and adds column X 
      // to their list of attributes.
      const foundAttrib: IProjectParcelAttribute | undefined = store_parcelExtraAttributeList.find(a => a.name === attrib.attribute_name);
      if(foundAttrib)
        store_removeParcelExtraAttribute(attrib.attribute_name);
    }
    else
    {
      // Remove the new attribute from the active project's user settings
      store_removeProjectParcelAttrib(attrib.attribute_name);
    }

    // Changes were made to the project settings - flag it as dirty so it will get saved
    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // Clear all filters (show all attributes).
  //-------------------------------------------------------------------------------
  const OnClearFiltersButtonClick = () => 
  {
    setFilterText('');
    setInvisibleAttributeNames([]);
  }

  //-------------------------------------------------------------------------------
  // The user typed in the search/filter box.
  //-------------------------------------------------------------------------------
  const OnFilterTextChange = (valueRaw: string) => 
  {
    const parcelLayer: ILayer | undefined = GetParcelLayer();
    if(!parcelLayer) return;

    const parcelLayerAttributes: IVectorLayerAttribute[] | undefined = parcelLayer.attributes;
    if(!parcelLayerAttributes) return;

    const searchText = valueRaw.toLocaleLowerCase().trim();
    const newInvisibleAttributeNames: string[] = [];

    for(let i=0; i < parcelLayerAttributes.length; i++)
    {
      const attribute = parcelLayerAttributes[i];

      let matchFound: boolean = false;

      // Match by:  attribute_name, display_name, description, unit
      if((attribute.attribute_name && attribute.attribute_name.toLocaleLowerCase().includes(searchText)) ||
         (attribute.display_name && attribute.display_name.toLocaleLowerCase().includes(searchText)) ||
         (attribute.description && attribute.description.toLocaleLowerCase().includes(searchText)) ||
         (attribute.unit && attribute.unit.toLocaleLowerCase().includes(searchText)))
        matchFound = true;

      if(!matchFound) // We're building an INVISIBLE list (a list of items filtered out)
        newInvisibleAttributeNames.push(attribute.attribute_name);
    }

    setFilterText(valueRaw);
    setInvisibleAttributeNames(newInvisibleAttributeNames);
  }



















  if(!store_project) return null;

  // Main render

  return (

    <Dialog open={store_parcelAttribSelector} onClose={onClose} maxWidth='md'>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          {/* Search/filter box */}

          <Stack direction='row' sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <SearchIcon sx={{ color: theme_textColorBlended, mr: 1, my: 0.5, fontSize: '2rem' }} />
            <CustomTextField variant='outlined' size="small" value={filterText}
                label=
                {
                  <Typography sx={{ fontSize: '0.9rem', color: theme_textColorMain, opacity: 0.7 }}>
                    Search All Parcel Attributes
                  </Typography>
                } 
                sx={{ color: 'red' }}
                onChange={(e)=>OnFilterTextChange(e.target.value)}
                InputProps=
                {{
                  endAdornment: 
                  (
                    <InputAdornment position="end" sx={{ visibility: filterText===''?'hidden':'visible' }}>
                      <IconButton tabIndex={-1} onClick={(_)=>OnClearFiltersButtonClick()} sx={{ color: theme_textColorBlended }}>
                        <CloseIcon/>
                      </IconButton>
                    </InputAdornment>
                  )
                }}/>
          </Stack>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ pl: 0, pr: 0.5, background: theme_bgColorGradient2, height: '65vh' }}>

        <Stack direction='row' sx={{ height: '100%' }}> {/* NOTE: Don't know why this Stack has a ~20px margin at the bottom */}

          {/* The left side shows the ACTIVE (selected) attributes */}
          <AttribSelectorActivePanel OnAttribCheckBoxChange={OnAttribCheckBoxChange} invisibleAttributeNames={invisibleAttributeNames}/>

          {/* The right side shows the inactive attributes */}
          <AttribSelectorInactivePanel OnAttribCheckBoxChange={OnAttribCheckBoxChange} invisibleAttributeNames={invisibleAttributeNames}/>

        </Stack>
      
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack sx={{ alignItems: 'center' }}>
          <Button variant='contained' autoFocus onClick={onClose} sx={{ width: '100px' }}>
            Close
          </Button>
        </Stack>
        
      </DialogActions>

    </Dialog>
  )
}







// Custom TextField for the seach/filter box (to recolor the text and the outline/border)
const CustomTextField = styled(TextField)(() => (
{

  '& .MuiInputBase-root': 
  {
    color: theme_textColorMain,
  },  
  "& .MuiOutlinedInput-notchedOutline": 
  {
    borderColor: theme_textColorBlended,
  },
  "&.Mui-focused": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_textColorBlended,
    }
  },
  "&:hover": 
  {
    "& .MuiOutlinedInput-notchedOutline": 
    {
      borderColor: theme_orange,
    }
  },
}));
