// Custom Typography component 
// 
// Supports auto-tooltip when the full text doesn't fit and is cut off.

import { Tooltip, Typography, TypographyProps } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface TypographyWithAutoTooltipProps extends TypographyProps
{
  children: React.ReactNode;
  placement?: any;
  arrow?: boolean;
  followCursor?: boolean;
}


//-------------------------------------------------------------------------------
// Typography with auto-tooltip support component.
//-------------------------------------------------------------------------------
export const TypographyWithAutoTooltip: FC<TypographyWithAutoTooltipProps> = (props: TypographyWithAutoTooltipProps) => 
{
  const { children, placement, arrow, followCursor, ...other } = props;

  const ref = useRef<HTMLSpanElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);



  useEffect(() => 
  {
    const compareSize = () => 
    {
      if (ref.current) 
      {
        const compare = ref.current.scrollWidth > ref.current.clientWidth;
        setTooltipEnabled(compare);
      }
    }
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => window.removeEventListener("resize", compareSize);
  }, []);





  // Main render

  if(props.children)
  return (
    <Tooltip title={props.children} disableHoverListener={!tooltipEnabled} placement={props.placement} 
             arrow={props.arrow} followCursor={props.followCursor}>
      <Typography
        ref={ref}
        noWrap
        overflow="hidden"
        textOverflow="ellipsis"
        {...other}
      >
        {props.children}
      </Typography>
    </Tooltip>
  )

  return null;
}




export default TypographyWithAutoTooltip;
