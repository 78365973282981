// User feedback window component

import useStore from './store';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_limeGreen, theme_textColorBlended } from "./Theme";
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import { CallServer } from './CallServer';
import { ToastNotification } from './ToastNotifications';


interface IFeedbackDetails
{
  project_id?: string | null;
  project_name?: string;
  aoi_id?: number;
  aoi_name?: string;
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface FeedBackWindowProps
{
}

//-------------------------------------------------------------------------------
// User feedback window component
//-------------------------------------------------------------------------------
export function FeedBackWindow(props: FeedBackWindowProps) 
{
  // Get needed state data from the store
  const { store_feedbackWindowOpen, store_setFeedbackWindowOpen,
          store_isLoggedIn, store_userInfo, store_project, store_aoi 
        } = useStore();

  const [description, setDescription] = React.useState<string>('');




  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // Clear the description when the feedback window opens or closes
    setDescription('');
  }, [store_feedbackWindowOpen]);


  //-------------------------------------------------------------------------------
  // Close the window.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    store_setFeedbackWindowOpen(false);
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  function OnDescriptionTextChange(description: string)
  {
    if(description.length > 1024)
      return;

    setDescription(description);
  }

  //-------------------------------------------------------------------------------
  // Send feedback and close the window window.
  //-------------------------------------------------------------------------------
  async function OnSendFeedback()
  {
    // Check data

    if(description.trim().length === 0)
      return;

    // Prep details JSON (some state info to help with bug reports)

    const details: IFeedbackDetails = {}

    if(store_project)
    {
      details.project_id = store_project.project_id;
      details.project_name = store_project.name;
    }

    if(store_aoi)
    {
      details.aoi_id = store_aoi.aoi_id;
      details.aoi_name = store_aoi.aoi_name;
    }

    // Call server to send feedback

    const server = new CallServer();
    server.Add("description", description);
    server.AddJson("details", details);

    //useStore.getState().store_setFeedbackIsBeingSent(true);

    const result = await server.Call('post', '/feedback');

    //useStore.getState().store_setFeedbackIsBeingSent(false);

    if(result.success)
    {
      // Close the window
      store_setFeedbackWindowOpen(false);

      ToastNotification('success', 'Feedback sent!')
    }
    else
    {
      ToastNotification('error', 'Unable to send feedback')
      return;
    }
  }



  // Must be logged in and have user info loaded
  if(store_isLoggedIn === false || !store_userInfo ) return null;

  // Main render

  return (

    <Dialog open={store_feedbackWindowOpen} onClose={onClose} fullWidth>

      {/* Dialog title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Typography sx={{ fontSize: '1.2rem', color: theme_limeGreen }}>
            Report bugs or suggest improvements you'd like to see
          </Typography>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '32px', height: '32px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '25px', height: '25px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog content */}

      <DialogContent sx={{ p: 0, background: theme_bgColorGradient2 }}>

        <Stack sx={{ width: '100%', p: 0 }}>

          <Stack direction='column' sx={{ mt: 0  }}>

            <TextField variant='filled' multiline autoFocus size='small' id="description" name="description" fullWidth
                        value={description} onChange={(e)=>OnDescriptionTextChange(e.target.value)} rows={12}
                        sx={{ p:0, m:0, bgcolor: '#CEE6FF', multilineColor: { color: 'red' } }}/>

          </Stack>

        </Stack>

      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1}}>

        <Button variant='contained' autoFocus onClick={(_)=>OnSendFeedback()}
                sx={{ width: '100px', fontSize: '1.1rem' }}>
          Send
        </Button>

        {/* <Button variant='contained' autoFocus onClick={(_)=>onClose()}>
          Close
        </Button> */}

      </DialogActions>

    </Dialog>
  )
}


