// Layer list item component.  Supports drag-and-drop reordering using dnd-kit.

import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Checkbox, Typography, Stack, IconButton, Slider, Switch, FormControlLabel, SwitchProps, styled, Tooltip, CircularProgress } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ILayer } from './LayerInterfaces';
import useStore from '../store';
import { theme_bgColorMain, theme_orange, theme_textColorBlended } from '../Theme';
import { AddLayerToMap, RemoveLayerFromMap, SetLayerOpacity } from "./LayerOps";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import LegendCore from '../Legend/LegendCore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from 'react';
import { CallServer } from '../CallServer';
import Debug from '../Debug';
import { ToastNotification } from '../ToastNotifications';
import { BBox } from 'geojson';
import { ZoomMapToBBox } from '../Map/MapOps';
import SettingsIcon from '@mui/icons-material/Settings';



// Used for the local layer item (which has drag-and-drop reordering)
export interface ILocalLayerItem 
{
  id: number; // The dnd-kit requires a numerical id (hence why the layer name inside ILayer is not used)
  layer: ILayer;
}


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface LayerListItemProps
{
  localLayerItem: ILocalLayerItem;
  onLayerCheckBox: any;
}

//-------------------------------------------------------------------------------
// LayerListItem component
//-------------------------------------------------------------------------------
export function LayerListItem(props: LayerListItemProps) 
{
  // Get needed state data from the store
  const { store_map, store_setActiveInfoLayer, store_setProjectIsDirty, 
          store_setEditLayer, store_permEditMainLayer,
          store_setEditLayerFromLayerLibrary } = useStore();
          

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.localLayerItem.id});

  const layer : ILayer = props.localLayerItem.layer;

  const topDivStyle = 
  {
    transform: CSS.Transform.toString(transform),
    transition,
    width: '100%',
  };
  
  const [zoomToExtentRunning, setZoomToExtentRunning] = useState<boolean>(false);







  
  //-------------------------------------------------------------------------------
  // The layer's opacity slider was changed.
  //-------------------------------------------------------------------------------
  const handleOpacitySliderChange = (event: Event, newValue: number | number[]) => 
  {
    if (typeof newValue !== 'number') return;
    
    SetLayerOpacity(layer, newValue / 100.0);

    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // The layer's info button was clicked.
  //-------------------------------------------------------------------------------
  const onLayerInfoClick = (layer: ILayer) => 
  {
    store_setActiveInfoLayer(layer);
  }

  //-------------------------------------------------------------------------------
  // Remove this layer from the list of active layers.
  //-------------------------------------------------------------------------------
  const onRemoveLayer = async (layer: ILayer) => 
  {
    // Flag this layer as inactive
    layer.activeInProject = false;

    // Remove the layer from the map
    RemoveLayerFromMap(layer.id);

    // Flag the project as "dirty" so it will be re-saved
    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // Toggles text labels for a vector layer (using a Geoserver env variable).
  //-------------------------------------------------------------------------------
  function OnToggleVectorLabels(layer: ILayer, checked: boolean)
  {
    if(!store_map) return;

    // Change the layer's "label_opacity"
    layer.vector_layer_label_opacity = checked ? 1 : 0;

    // Remove and re-add the source/layer to Mapbox to force it to update the tiles 
    // (maybe there's a better way, but for now this works).
    RemoveLayerFromMap(layer.id);
    AddLayerToMap(layer.id, false);

    // Flag the project as "dirty" so it will be re-saved
    store_setProjectIsDirty(true);
  }

  //-------------------------------------------------------------------------------
  // The layer's "Zoom to Extent" button was clicked.
  //-------------------------------------------------------------------------------
  const onZoomToExtentClick = (layer: ILayer) => 
  {
    ZoomToExtent(layer.id);
  }

  //-------------------------------------------------------------------------------
  // Add an AOI to the specified AOI group.
  // NOTE: Used when importing an AOI from another project into the active project.
  //-------------------------------------------------------------------------------
  async function ZoomToExtent(layer_id: number): Promise<boolean>
  {
    // Call the server

    const server = new CallServer();
    server.Add('layer_id', layer_id);

    setZoomToExtentRunning(true);

    const result = await server.Call('get', '/extent');

    setZoomToExtentRunning(false);

    if(result.success)
    {
      Debug.log('LayerListItem.ZoomToExtent> API server call SUCCESS');

      const bbox: BBox | undefined = result.data.bbox;
      if(!bbox || bbox.length !== 4)
      {
        ToastNotification('error', "Unable to determine layer's extent")
        Debug.error('LayerListItem.ZoomToExtent> ERROR: Received empty or invalid bbox');
        return false;
      }

      // Zoom the map to the specified bbox
      ZoomMapToBBox(bbox);

      // Success
      return true;
    }
    else
    {
      // Failure
      ToastNotification('error', "Unable to determine layer's extent")
      Debug.error('LayerListItem.ZoomToExtent> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
      return false;
    }
  }

  //-------------------------------------------------------------------------------
  // Edit the specified layer.
  //-------------------------------------------------------------------------------
  function OnEditLayer(layer: ILayer)
  {
    store_setEditLayer(layer);
    store_setEditLayerFromLayerLibrary(false);
  }












  // Ignore layers that are marked as "hidden" (used to hide the Parcels special layer)
  if(layer.hidden) return null;

  // Ignore layers that are not active in the project
  if(!layer.activeInProject) return null;

  // Main render

  return (

    <div ref={setNodeRef} style={topDivStyle}>

      <Stack key={layer.id} direction='row' sx={{ width: '100%', alignItems: 'start' }}>

        <Stack sx={{ width: '100%' }}>

          {/* Layer checkbox */}

          <Stack direction='row' sx={{ width: '100%', alignItems: 'center' }}>

            <Checkbox sx={{ p: 0, color: theme_textColorBlended + 'A0', '&.Mui-checked': { color: theme_textColorBlended } }} 
                      id={layer.id.toString()} name={layer.name} size="medium" 
                      checked={layer.enabled} onChange={ props.onLayerCheckBox }/>

            <Stack sx={{ pl: '6px', width: 'calc(100% - 6px)' }}>

              <Stack direction='row' sx={{ alignItems: 'center' }}>

                <Stack direction='column' sx={{ mr: 1, width: '100%' }}>

                  <Typography sx={{ width: '100%', mr: '10px',
                                    color: theme_orange, fontSize: '0.8rem',
                                    opacity: layer.enabled ? 1 : 0.7,
                                    display: "-webkit-box", 
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    overflow: "hidden",
                                  }}>
                    {layer.friendlyName}
                  </Typography>

                  {layer.enabled
                    ?
                      <Slider disabled={!layer.enabled} onChange={handleOpacitySliderChange}
                              min={0} max={100} step={10} size='small' aria-label="Layer Opacity" 
                              value={layer.opacity * 100.0} valueLabelDisplay='off'
                              sx={{ width: '95%', mt: '-5px', mb: '5px', px: 0, py: '4px', opacity: 0.5 }}/>
                    :
                      null
                  }

                </Stack>

                {/* Zoom-to-extent button */}

                {layer.enabled && !layer.isHBVLayer
                  ?
                    <Tooltip title="Zoom to this layer's extent">
                      <IconButton size="small" onClick={(_) => onZoomToExtentClick(layer)}
                                  sx={{ mr: '2px', padding: 0, bgcolor: 'transparent', width: '20px', height: '20px' }}>
                        {zoomToExtentRunning === false
                          ?
                            <VisibilityIcon sx={{ opacity: 0.6, width: '16px', height: '16px', color: theme_textColorBlended }} />
                          :
                            <CircularProgress size='12px' sx={{ opacity: 0.7 }} />
                        }
                      </IconButton>
                    </Tooltip>
                  :null
                }

                {/* Layer edit button (only visible if user has the role/permission) */}

                {!layer.isHBVLayer && ((layer.isCustomLayer === true && layer.EditCustomLayer) || (layer.isCustomLayer !== true && store_permEditMainLayer))
                  ?
                    <Tooltip title='Edit this layer'>
                      <IconButton onClick={(_) => OnEditLayer(layer)} 
                                  sx={{ mx: 0.3, my: 0.1, p: '2px', color: theme_textColorBlended }}>
                        <SettingsIcon sx={{ width: '20px', height: '20px' }} />
                      </IconButton>
                    </Tooltip>
                  :null
                }


                {/* Layer info button */}

                {!layer.isHBVLayer
                  ?
                    <Tooltip title="View info about this layer">
                      <IconButton size='small' sx={{ height: '22px', width: '22px', opacity: layer.description ? 0.9 : 0.3 }} 
                                  onClick={(_)=>onLayerInfoClick(layer)} 
                                  disabled={!layer.description}>
                        <InfoIcon fontSize="small" sx={{ opacity: 1, width: '18px', height: '18px', color: theme_textColorBlended }}/>
                      </IconButton>
                    </Tooltip>
                  :null
                }

                {/* Remove layer button */}

                <Tooltip title="Remove this layer">
                  <IconButton size="small" onClick={(_) => onRemoveLayer(layer)}
                              sx={{ padding: 0, bgcolor: 'transparent', width: '22px', height: '22px' }}>
                    <CloseIcon sx={{ opacity: 0.5, width: '18px', height: '18px', color: '#FF595C' }} />
                  </IconButton>
                </Tooltip>

              </Stack>


            </Stack>
          </Stack>

          {/* Vector layer (that are output as rasters) can have SLD text labels dunamically turned ON/OFF */}

          {layer.enabled && layer.geoserverSourceType === "vector" && layer.geoserverOutputType === 'raster' && layer.has_text_labels === true
            ?
              <FormControlLabel sx={{ mb: '2px', ml: '20px' }}
                label=
                  {
                    <Typography sx={{ fontSize: '0.7rem',
                                      color: (layer.vector_layer_label_opacity === undefined || layer.vector_layer_label_opacity === 1) ? theme_textColorBlended : theme_textColorBlended+'A0' }}>
                      Show layer-specific labels
                    </Typography>
                  }
                control=
                  {
                    <CustomSwitch size='small' id={layer.id.toString()} name={layer.name}
                                  sx={{ mr: 0.5 }} 
                                  checked={layer.vector_layer_label_opacity === undefined || layer.vector_layer_label_opacity === 1} 
                                  onChange={(_,c)=>OnToggleVectorLabels(layer,c)}/>
                  }
              />
            :
              null
          }

          {/* Legend */}
          
          {layer.enabled && layer.legend
            ?
              // maxHeight: '110px', overflow: 'auto'
              <Stack sx={{ mt: '5px', ml: '28px', mb: '3px' }}>
                <LegendCore layer={layer} showTopEntriesCount={7}/>
              </Stack>
            :null
          }
        
        </Stack>

        {/* Drag handle - the listeners and attributes mark this as a draggable control */}
        {/* NOTE: Other good cursors to use:  grab, ns-resize, row-resize */}

        {layer.movable 
          ?
            <IconButton size='small' {...listeners} {...attributes}
                        sx={{ cursor: 'grab', height: '30px', width: '20px', mt: '-4px' }}>
              <DragIndicatorIcon color='primary' fontSize="small" sx={{ opacity: 0.4 }}/>
            </IconButton>
          :null
        }

      </Stack>
    </div>
  );
}








/*
//-------------------------------------------------------------------------------
// AccordionSummary custom style.
//-------------------------------------------------------------------------------
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  //padding: 0, 
  //marginTop: -10, 
  //marginBottom: -10,
  //height: '30px',

  minHeight: 40,
  maxHeight: 40,
  overflow: 'hidden',

  '&.Mui-expanded': 
  {
    minHeight: 40,
    maxHeight: 40,
    //backgroundColor: '#FFF2F2',
  },

  //backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, 0.2)',
  //flexDirection: 'row-reverse',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': 
  // {
  //   transform: 'rotate(90deg)',
  // },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}));
*/


//-------------------------------------------------------------------------------
// MUI Switch custom style.
//-------------------------------------------------------------------------------
const CustomSwitch = styled((props: SwitchProps) => (<Switch {...props} />))(({ theme }) => (
  {
    "& .MuiSwitch-switchBase": 
    {
      color: "#7CA0E0",  // Knob color when OFF
      opacity: 1,
    },  
    "& .MuiSwitch-switchBase.Mui-checked": 
    {
      color: "#CBE2FF",  // Knob color when ON
      opacity: 1,
    },
    "& .MuiSwitch-switchBase.Mui-disabled": 
    {
      color: "#7090D0",  // Knob color when DISABLED and OFF
      opacity: 1,
    },
    "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked": 
    {
      color: "#ABBED6",  // Knob color when DISABLED and ON
      opacity: 1,
    },
    //"&:hover .MuiSwitch-switchBase": { color: 'brown' },    // Knob color on hover
    '& .MuiSwitch-track': 
    {
      backgroundColor: theme_textColorBlended+'70',  // Track color when OFF
      opacity: 0.4,
    },
    "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": 
    {
      backgroundColor: theme_textColorBlended+'A0',  // Track color when ON
      opacity: 0.6,
    },
    "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": 
    {
      backgroundColor: theme_bgColorMain,  // Track color when DISABLED
      opacity: 0.3,
    },
  }));