// Project operations

import useStore from "../store";
import { Box, Stack, Typography } from "@mui/material";
import { theme_textColorBlended, theme_limeGreen, theme_orange, theme_textColorMain } from "../Theme";
import AddIcon from '@mui/icons-material/Add';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import EditIcon from '@mui/icons-material/Edit';
import ProjectCreation from './ProjectCreation';
import ProjectSelection from './ProjectSelection';
import ProjectRename from './ProjectRename';
import { IProject } from "./ProjectInterfaces";
import { SaveActiveProject, DefaultProject, GetOrgUsers, LoadProjectList, RemoveProjectBoundaryLayerFromMap, DeleteProject } from "./ProjectOps";
import { RemoveAoiLayerFromMap } from "../Aois/AoiOps";
import { RemoveAllLayersAndSourcesFromMap } from "../Layers/LayerOps";
import FeatureButton from "../Components/FeatureButton";
import ProjectShare from './ProjectShare';
import ShareIcon from '@mui/icons-material/Share';
import { DEV_MODE } from "../Globals";
import { ResetHbv } from "../HBV/HbvOps";
import { ExitParcelsMode, RemoveParcelSelectionLayerFromMap } from "../Parcels/ParcelOps";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { SaveUserProfile } from "../UserProfile";
import MyCircularProgress from "../Components/CircularProgress";
import { useState } from "react";
import YesNoDialog from "../Components/YesNoDialog";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ProjectUIProps 
{
}

//-------------------------------------------------------------------------------
// Project component
//-------------------------------------------------------------------------------
const Projects = (props: ProjectUIProps) => 
{
  // Get needed state data from the store
  const { store_isLoggedIn, store_project, store_projectUIMode,
          store_setProjectUIMode, store_setProject, store_setUserProfileIsDirty, 
          store_userProfile, store_userProfileIsLoading, store_projectListIsLoading, 
          store_projectIsLoading, store_setAoi, store_setNrrs, store_setNrrImpacts, 
          store_setBundle, store_setLayers, store_bundleIsLoading, 
        } = useStore();


  const [deleteProjectConfirmation, setDeleteProjectConfirmation] = useState(false);
      





  //-------------------------------------------------------------------------------
  // Go into "Create new project" mode.
  //-------------------------------------------------------------------------------
  const onNewProjectMode = async () => 
  {
    // Save the active project if needed (before we load a new project in)
    const store_project: IProject | null = useStore.getState().store_project;
    if(store_project && store_project.project_id && store_project.isDirty)
      await SaveActiveProject();  

    // Create a new unsaved project with default settings
    const newUnsavedProject: IProject = DefaultProject();

    // Set the new project as the active project
    store_setProject(newUnsavedProject);
    //store_setUserProfileActiveProjectID(null);

    // Clear the map of all layers
    RemoveAllLayersAndSourcesFromMap();

    // Remove the active project boundary
    RemoveProjectBoundaryLayerFromMap();

    // Clear the active bundles (and its NRRs, impacts, and layers)
    store_setBundle(null);
    store_setLayers([]);
    store_setNrrs([]);
    store_setNrrImpacts([]);

    // If the HBV was active, clear it
    ResetHbv();

    // If an AOI was active, clear it and remove it from the map
    store_setAoi(null);
    RemoveAoiLayerFromMap();

    // Also turn off special layer like parcels and boundaries
    RemoveParcelSelectionLayerFromMap();
    RemoveProjectBoundaryLayerFromMap();
  }

  //-------------------------------------------------------------------------------
  // Switching projects.
  //-------------------------------------------------------------------------------
  const onSwitchProjectsButton = () => 
  {
    LoadProjectList();

    store_setProjectUIMode('select');
  }

  //-------------------------------------------------------------------------------
  // Go into "Rename active project" mode.
  //-------------------------------------------------------------------------------
  const onRenameActiveProjectMode = async () => 
  {
    store_setProjectUIMode('rename');
  }

  //-------------------------------------------------------------------------------
  // Go into "Share active project" mode.
  //-------------------------------------------------------------------------------
  const onShareActiveProjectMode = async () => 
  {
    store_setProjectUIMode('share');

    GetOrgUsers();
  }

  //-------------------------------------------------------------------------------
  // Delete the active project (triggers user confirmation dialog).
  //-------------------------------------------------------------------------------
  async function onDeleteActiveProject()
  {
    if(!store_project) return;

    // Pop up the user confirmation dialog (if they hit Yes, that will trigger the actual delete)
    setDeleteProjectConfirmation(true);
  }

  //-------------------------------------------------------------------------------
  // Delete the active project (the user has just confirmed it).
  //-------------------------------------------------------------------------------
  async function onDeleteProjectConfirmed()
  {
    // Close the confirmation dialog
    setDeleteProjectConfirmation(false);

    // Delete the project

    if(!store_project || !store_project.project_id)
      return;

    const ret: boolean = await DeleteProject(store_project.project_id);
    if(ret === false)
      return;

    // The active project was deleted - create a "new" blank project, set it to active, 
    // and switch the UI to project selection mode.

    const newUnsavedProject: IProject = DefaultProject();
    
    useStore.getState().store_setProject(newUnsavedProject);
    useStore.getState().store_setBundle(null);
    useStore.getState().store_setUserProfileActiveProjectID(null);
    useStore.getState().store_setLayers([]);
    useStore.getState().store_setNrrs([]);
    useStore.getState().store_setNrrImpacts([]);
    store_setUserProfileIsDirty(true);

    // Force a save
    SaveUserProfile();

    // Refresh the project list
    LoadProjectList();

    // Do a few clean-up tasks - turn off some functions that are tied to the project
    ExitParcelsMode(); 
    RemoveAoiLayerFromMap();
    RemoveProjectBoundaryLayerFromMap();
    
  }

  
  




  // ******************************************************************************************
  // Main render - a user is NOT logged in
  // ******************************************************************************************

  if(!store_isLoggedIn) 
  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Please log in to see this section.
    </Box>
  )

  // ******************************************************************************************
  // Main render - the project or list of projects is currently being loaded
  // ******************************************************************************************

  if(!store_userProfile || !store_project || store_userProfileIsLoading || store_projectIsLoading || 
      store_projectListIsLoading || store_bundleIsLoading)
  return (
    <Stack direction='row' sx={{ alignItems: 'center' }}>
      <MyCircularProgress />
      <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_textColorBlended, ml: 2, textTransform: 'none' }}>
        Loading...
      </Typography>
    </Stack>
  )

  // ******************************************************************************************
  // Main render - create a new project
  // ******************************************************************************************

  if(store_project && !store_project.project_id  && store_projectUIMode !== 'select')
  return (
    <ProjectCreation />
  )

  // ******************************************************************************************
  // Main render - rename the active project
  // ******************************************************************************************

  if(store_project && store_project.project_id && store_projectUIMode === 'rename')
  return (
    <ProjectRename />
  )

  // ******************************************************************************************
  // Main render - switching to a different saved project
  // ******************************************************************************************

  if(store_project && store_projectUIMode === 'select')
  return (
    <ProjectSelection calledFromProjCreation={false}/>
  )

  // ******************************************************************************************
  // Main render - share the active project with another user
  // ******************************************************************************************

  if(store_project && store_project.project_id && store_projectUIMode === 'share')
  return (
    <ProjectShare />
  )
    
  // ******************************************************************************************
  // Main render - a saved project is active
  // ******************************************************************************************

  if(store_project && store_project.project_id)
  return (

    <Stack direction='column' sx={{ width: '100%' }}>

      <Stack direction='column' sx={{ alignItems: 'left' }}>

        {DEV_MODE
          ?
            <Typography sx={{ color: theme_textColorBlended, opacity: 0.75, mt: -3, mb: 1, fontSize: '0.7rem', ml: 3.6 }}>
              {store_project.project_id}
            </Typography>
          :null
        }

        {/* Open another project button */}

        {!store_projectListIsLoading
          ?
            <FeatureButton onClick={onSwitchProjectsButton} icon={<BookmarkBorderIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
              Open a different project
            </FeatureButton>
          :null
        }

        {/* Create a new project button */}

        <FeatureButton onClick={onNewProjectMode} icon={<AddIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Create a new project
        </FeatureButton>

        {/* Rename active project button */}

        <FeatureButton onClick={onRenameActiveProjectMode} icon={<EditIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Rename this project
        </FeatureButton>

        {/* Share active project button, but only if this project is part of an organization that
            allows project sharing. */}

        {store_project.organization && store_project.organization.settings.allow_project_sharing
          ?
            <FeatureButton onClick={onShareActiveProjectMode} icon={<ShareIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
              Share this project
            </FeatureButton>
          :null
        }

        {/* Delete this project button */}

        <FeatureButton onClick={onDeleteActiveProject} icon={<DeleteForeverIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '6px' }}/>}>
          Delete this project
        </FeatureButton>

      </Stack>

      {/* "Delete Project" Yes/No confirmation dialog */}

      <YesNoDialog open={deleteProjectConfirmation} setOpen={setDeleteProjectConfirmation}
                   onYes={onDeleteProjectConfirmed}>
        <Stack direction='row' sx={{ alignItems: 'center' }}>
          <DeleteForeverIcon sx={{ mr: 2, color: theme_textColorBlended }} />
          <Box sx={{ color: theme_textColorMain }}>Delete project</Box>
          <Box sx={{ ml: 1, color: theme_orange, fontWeight: 'bold' }}>{store_project.name}</Box>
          <Box sx={{ ml: 1, color: theme_textColorMain }}>?</Box>
        </Stack>
      </YesNoDialog>

    </Stack>
  )

  // ******************************************************************************************
  // Main render - fall-through case, ideally should never get called.
  // ******************************************************************************************

  return (
    <Box sx={{ color: theme_textColorBlended }}>
      Projects unhandled state
    </Box>
  )

};



export default Projects;

