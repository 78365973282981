// Shows toast notifications for the app.

import { Snackbar, Alert, AlertColor } from '@mui/material';
import React from 'react';
import useStore from './store';

export interface IToastNotification
{
  message: string;
  type: AlertColor;
  isOpen: boolean;
  autoHideDurationMs: number;
}

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ToastNotificationsProps 
{
}

//-------------------------------------------------------------------------------
// Coordinate display component
//-------------------------------------------------------------------------------
const ToastNotifications = (props: ToastNotificationsProps) => 
{
  // Get needed state data from the store
  const { store_toastNotification, store_setToastNotificationOpen,
        } = useStore();



  //-------------------------------------------------------------------------------
  // The user manually closed the notification.
  //-------------------------------------------------------------------------------
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => 
  {
    if (reason === 'clickaway') 
      return;

    store_setToastNotificationOpen(false);
  };





  // Main render
 
  return (

    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ opacity: 0.8 }}
              open={store_toastNotification?.isOpen} onClose={handleClose}
              autoHideDuration={store_toastNotification?.autoHideDurationMs} >

      <Alert variant='filled' onClose={handleClose} severity={store_toastNotification?.type} 
             sx={{ width: '100%' }}>
        {store_toastNotification?.message}
      </Alert>

    </Snackbar>

  );
}

  
export default ToastNotifications;





//-------------------------------------------------------------------------------
// Pop up a toast notification.
//-------------------------------------------------------------------------------
export function ToastNotification(type: AlertColor, message: string)
{
  if(!message || message.length === 0) return;

  // If a forced logout has occured, ignore any further toast notifications
  if(useStore.getState().store_forcedLogout) return;

  const notification: IToastNotification = 
  {
    message: message,
    type: type,
    isOpen: true,
    autoHideDurationMs: 6000, // 6 seconds
  }

  useStore.getState().store_setToastNotification(notification);
}