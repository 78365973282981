// Legend core component (list of legend entries for a layer).
//
// Starts out showing only a few entries entries, and provides a link button to show/hide the rest.

import { Stack, Typography, Box, Link } from "@mui/material";
import { theme_bgColorLight1, theme_bgColorMain, theme_limeGreen, theme_textColorBlended, theme_textColorMain } from "../Theme";
import useStore from "../store";
import { ILayer } from "../Layers/LayerInterfaces";
import { LAYER_LEGEND_IMAGES_BASE_URL } from "../Globals";



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface LegendCoreProps
{
  layer: ILayer;
  showTopEntriesCount?: number;
}

//-------------------------------------------------------------------------------
// Legend core component.
//-------------------------------------------------------------------------------
const LegendCore = ({ layer, showTopEntriesCount }: LegendCoreProps) => 
{
  // Get needed state data from the store
  const { store_setLegendShowAll } = useStore();  



  
  //-------------------------------------------------------------------------------
  // The "Show extra entries" link was clicked.
  //-------------------------------------------------------------------------------
  function OnShowExtraEntriesClick(layer_id: number)
  {
    store_setLegendShowAll(layer_id, true);
  }

  //-------------------------------------------------------------------------------
  // The "Hide extra entries" link was clicked.
  //-------------------------------------------------------------------------------
  function OnHideExtraEntriesClick(layer_id: number)
  {
    store_setLegendShowAll(layer_id, false);
  }




  // Main render - we have a valid layer+legend

  if(layer && layer.legend)
  return (

    <Stack direction='column' sx={{ maxHeight:  '350px', overflow: 'auto' }}>

      {layer.legend.units && layer.legend.units.length > 0
        ?
          <Stack direction='row' sx={{ mb: '4px' }}>
            <Typography sx={{ color: theme_textColorBlended, fontSize: '0.7rem' }}>
              Units:
            </Typography>
            <Typography sx={{ ml: '5px', color: theme_limeGreen, fontSize: '0.7rem', opacity: 0.85 }}>
              {layer.legend.units}
            </Typography>
          </Stack>
        :null
      }

      {layer.legend.entries && layer.legend.entries.length > 0
        ?
          <Box sx={{ borderRadius: 1, bgcolor: theme_bgColorLight1, p: '3px' }}>

            {layer.legend.entries.map(legendEntry => 

              layer.legendShowAll || !showTopEntriesCount || legendEntry.id <= showTopEntriesCount
              ?
                <Stack key={legendEntry.id} direction='row' sx={{ m: '0px', alignItems: 'center' }}>
                  
                  <Box sx={{ bgcolor: `${legendEntry.color}`, width: '18px', height: '12px', 
                            borderRadius: '0px', border: 1, ml: '2px', borderColor: theme_bgColorMain }} />

                  {/* For now, turn off the feature which shows count and count percent mini-graph */}

                  {/* {legendEntry.countPercent && legendEntry.countPercent >= 1 && legendEntry.countPercent <= 100
                    ?
                      <Stack direction='column' sx={{ width: '100%', mt: '0px', ml: '6px', mr: '5px' }}>

                        <Stack direction='row' sx={{ width: '100%', alignItems: 'center', mt: '0px', mr: '2px' }}>
                          <Typography sx={{ fontSize: '0.7rem', width: '100%', color: theme_textColorMain, opacity: 1 }}>
                            {legendEntry.name}
                          </Typography>
                          <Typography sx={{ fontSize: '0.55rem', opacity: 0.55, color: theme_textColorMain }}>
                            {legendEntry.countPercent.toFixed(0)}%
                          </Typography>
                        </Stack>

                        {legendEntry.countPercent >= 3
                          ?
                            <Stack direction='row' sx={{ height: '2px', mt: '-2px' }}>
                              <Box sx={{ bgcolor: 'transparent', width: `${100-legendEntry.countPercent}%`, height: '0px'  }} />
                              <Box sx={{ bgcolor: `${legendEntry.color}`, width: `${legendEntry.countPercent}%`, height: '2px', borderTopLeftRadius: 1, borderBottomLeftRadius: 1  }} />
                            </Stack>
                          :null
                        }
                      </Stack>
                    :
                      <Typography sx={{ fontSize: '0.7rem', width: '100%', ml: '6px', mr: '0px', color: theme_textColorMain, opacity: 1 }}>
                        {legendEntry.name}
                      </Typography>
                  } */}

                  <Typography sx={{ fontSize: '0.7rem', width: '100%', ml: '6px', mr: '0px', color: theme_textColorMain, opacity: 1 }}>
                    {legendEntry.name}
                  </Typography>

                </Stack>
              :null
            )}

          </Box>
        :null
      }

      {/* "Show more entries..." / "Hide extra entries" link buttons */}

      {showTopEntriesCount && layer.legend && layer.legend.entries && layer.legend.entries.length > showTopEntriesCount
        ?
          !layer.legendShowAll
          ?
            <Link id={layer.id.toString()} component="button" underline="hover"
                  sx={{ fontSize: '0.6rem', mt: '3px', opacity: 0.8, color: theme_textColorBlended }} onClick={(_)=>OnShowExtraEntriesClick(layer.id)}>
              &#11167; Show {layer.legend.entries.length-showTopEntriesCount} more entries...
            </Link>
          :
            <Link id={layer.id.toString()} component="button" underline="hover" 
                  sx={{ fontSize: '0.6rem', mt: '3px', opacity: 0.8, color: theme_textColorBlended }} onClick={(_)=>OnHideExtraEntriesClick(layer.id)}>
              &#11165; Hide extra entries
            </Link>
        :
          null// <Stack sx={{ height: '5px' }}/>
      }

      {/* If the legend is just an S3 image... */}

      {layer.legend.image_s3
        ?
          // <Box sx={{ borderRadius: 1, bgcolor: 'red', p: '0px' }}>

            <Box component="img" alt="" src={LAYER_LEGEND_IMAGES_BASE_URL + encodeURIComponent(layer.legend.image_s3)} sx={{ borderRadius: 1, width: '220px' }}/>

          // </Box>
        :null
      }

    </Stack>

  )

  // Fall-through case - this should never happen

  return (
    <div>No legend</div>
  )

}

export default LegendCore;
