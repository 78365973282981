// Upload an AOI from a file.

import { Stack, Typography, Button } from "@mui/material";
import { theme_textColorBlended, theme_limeGreen, theme_orange } from "../Theme";
import useStore from "../store";
import { useEffect, useState } from "react";
import { UploadAoiFromFile } from "./AoiOps";
import { ToastNotification } from "../ToastNotifications";
import MyCircularProgress from "../Components/CircularProgress";
import RoundedInputBox from "../Components/RoundedInputBox";


export const FILE_UPLOAD_MAX_SIZE_MB = 100;
export const FILE_UPLOAD_MAX_SIZE_BYTES = FILE_UPLOAD_MAX_SIZE_MB * 1024 * 1024;

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AoiUploadProps
{
  uploadFile: File | null;
}

//-------------------------------------------------------------------------------
// AOI upload component
//-------------------------------------------------------------------------------
const AoiUpload = (props: AoiUploadProps) => 
{
  // Get needed state data from the store
   const { store_setAoiUIMode, store_aoiFileIsUploading, 
         } = useStore();

  const [newAoiName, setNewAoiName] = useState<string>('');






  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() =>
  {
    if(props.uploadFile)
      setNewAoiName(props.uploadFile.name);
    




  // eslint-disable-next-line
  }, []); // called only once

  //-------------------------------------------------------------------------------
  // Cancel AOI selection.
  //-------------------------------------------------------------------------------
  const onCancel = () => 
  {
    // Exit aoi selection mode
    store_setAoiUIMode('default');
  }

  //-------------------------------------------------------------------------------
  // Upload the file.
  //-------------------------------------------------------------------------------
  const onFileUpload = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newAoiName || newAoiName.length === 0)
    {
      ToastNotification('error', 'Please enter a valid name for the new AOI');
      return;
    }

    if(!props.uploadFile)
    {
      ToastNotification('error', 'Please select a file');
      return;
    }

    await UploadAoiFromFile(props.uploadFile, newAoiName);

    // Exit aoi selection mode
    store_setAoiUIMode('default');
  }




  // Main render

  return (

    <Stack direction='column' sx={{ width: '100%', alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1.2rem', color: theme_textColorBlended }}>
        Upload an AOI from a file
      </Typography>

      {store_aoiFileIsUploading
        ?
          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <MyCircularProgress />
            <Typography sx={{ width: '100%', fontSize: '1rem', color: theme_orange, ml: 2, textTransform: 'capitalize' }}>
              Uploading AOI file...
            </Typography>
          </Stack>
        :
          // New AOI name + save button

          <RoundedInputBox title='New AOI Name' onAccept={onFileUpload}
                           value={newAoiName} setValue={setNewAoiName} 
                           buttonText='Upload' buttonWidth='90px' isDisabled={store_aoiFileIsUploading}/>
      }

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancel}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default AoiUpload;


