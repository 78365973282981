// NRR item component

import { Stack, Typography, styled, Accordion, AccordionDetails, Switch, SwitchProps, IconButton } from "@mui/material";
import useStore from "../store";
import { theme_bgColorLight, theme_textColorMain, theme_textColorBlended, theme_limeGreen, theme_bgColorMain } from "../Theme";
import { DEV_MODE } from "../Globals";
import { GetNrrImpact, GetNrrImpactState, GetNrrState } from "./BundleOps";
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import { INRR, INRRImpact } from "./BundleInterfaces";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { storeOps_setActiveProjectNrrImpactState, storeOps_setActiveProjectNrrState } from "../storeOps";
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NrrInfoPanel from "./NrrInfoPanel";
import ImpactInfoPanel from "./ImpactInfoPanel";
import { NrrAndImpactInfoTooltip } from "../CustomMuiStyles";
import SettingsIcon from '@mui/icons-material/Settings';
import { DRAWER_MENU_WIDTH } from "../DrawerMenu/DrawerMenu";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface NrrItemProps
{
  nrr: INRR;
}

//-------------------------------------------------------------------------------
// NRR item component
//-------------------------------------------------------------------------------
const NrrItem = (props: NrrItemProps) => 
{
  const { nrr/*, ...other*/ } = props;

  // Get needed state data from the store
  const { store_setNrrExpanded, store_nrrs, store_setEditUserValues, 
          store_setEditUserValuesNrrID, store_setEditUserValuesImpactID, 
        } = useStore();

  

  //-------------------------------------------------------------------------------
  // An NRR's accordion was expanded or collapsed.
  //-------------------------------------------------------------------------------
  const onAccordionExpandChange = (event: React.SyntheticEvent<Element, Event>, expanded: boolean, clickedNrr: INRR) => 
  {
    // An NRR accordion is being collapsed
    if(expanded === false)
    {
      store_setNrrExpanded(clickedNrr.nrr_id, false);
      return;
    }
    
    // An NRR accordion is being expanded - make sure all other accordions are collapsed
    for(let i=0; i < store_nrrs.length; i++)
      store_setNrrExpanded(store_nrrs[i].nrr_id, store_nrrs[i].nrr_id === clickedNrr.nrr_id);
  }

  //-------------------------------------------------------------------------------
  // The user turned an nrr on/off.
  // 
  // NOTE: We use onClick instead of onChange because onClick allows us to stop
  //       propagation of the mouse click event (otherwise it would trigger the accordion).
  //-------------------------------------------------------------------------------
  const onNrrSwitchClicked = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, nrr_id: number) => 
  {
    // Prevent click-through (which would trigger the accordion open/close)
    event.preventDefault();
    event.stopPropagation();

    const nrrState = GetNrrState(nrr_id);
    if(!nrrState) return;

    storeOps_setActiveProjectNrrState(nrr_id, !nrrState.enabled);
  }  

  //-------------------------------------------------------------------------------
  // The user turned an nrr impact on/off.
  //-------------------------------------------------------------------------------
  const onNrrImpactChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean, 
                              nrr_id: number, impact_id: number) => 
  {
    storeOps_setActiveProjectNrrImpactState(nrr_id, impact_id, checked);
  }

  //-------------------------------------------------------------------------------
  // Edit user values for a specific NRR impact.
  //-------------------------------------------------------------------------------
  function OnEditUserValues(nrr: INRR, impact_id: number)
  {
    store_setEditUserValuesNrrID(nrr.nrr_id);
    store_setEditUserValuesImpactID(impact_id);
    store_setEditUserValues(true);
  }





  // Main render

  return (

    <Accordion expanded={nrr.expanded} key={nrr.nrr_id} disableGutters={true}
          sx={{ m:0, width: '100%', boxShadow: '0', bgcolor: 'transparent', p: 0 }} 
          onChange={(e,expanded) => onAccordionExpandChange(e,expanded,nrr)}>

      <Stack direction='row' sx={{ width: '100%', alignItems: 'center' }}>

        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ opacity: 1, height: '1rem', p: 0 }} />}
                          sx={{ width: '100%', pl: 0, bgcolor: theme_bgColorLight, borderRadius: 1 }}>

          <Stack key={nrr.nrr_id} direction='row' sx={{ pl: '2px', alignItems: 'center', width: '100%' }}>
            <BlueSwitch size='small' checked={GetNrrState(nrr.nrr_id)?.enabled} onClick={(e) => onNrrSwitchClicked(e,nrr.nrr_id)}
                        sx={{ ml: -0.5 }}/>

                {/* <LandscapeIcon sx={{ width: '18px', height: '18px', ml: '0px', color: 'white', opacity: 0.7 }}/> */}

                <TypographyWithAutoTooltip placement='right' arrow
                                           sx={{ ml: '2px', fontSize: '0.6rem',
                                           color: nrr.expanded ? theme_bgColorMain : theme_textColorMain,
                                           fontWeight: nrr.expanded ? 'bold' : 'normal',
                                           width: `calc(${DRAWER_MENU_WIDTH}px - 112px)` }}>
                {nrr.name}
              </TypographyWithAutoTooltip>

          </Stack>

        </AccordionSummary>

        {/* NRR description tooltip */}

        {nrr.description
          ?
            <NrrAndImpactInfoTooltip
                title=
                {
                  <NrrInfoPanel nrr={nrr}/>
                }>
              <HelpOutlineIcon sx={{ color: nrr.expanded ? theme_bgColorLight : theme_limeGreen, mr: '2px',
                                     opacity: nrr.expanded ? 1 : 0.8, width: '15px', height: '15px' }}/>
            </NrrAndImpactInfoTooltip>
          :
            <HelpOutlineIcon sx={{ color: nrr.expanded ? theme_bgColorLight : theme_limeGreen, mr: '2px',
                                    opacity: 0.3, width: '16px', height: '16px' }}/>
        }

      </Stack>

      <AccordionDetails sx={{ mb: 0, p: 0, pb: 1, borderRadius: 1, opacity: GetNrrState(nrr.nrr_id)?.enabled ? 1 : 0.7 }}>

        {/* List of impacts for this NRR */}

        <Stack direction='column' sx={{ ml: '18px', mr: 0.5, mt: 1, bgcolor: '#6788C2', borderRadius: 1 }}>

          <Stack direction='row' sx={{ alignItems: 'center', justifyContent: 'space-between' }}>

            <Typography sx={{ color: theme_textColorMain, fontSize: '0.8rem', opacity: 1, m: '5px' }}>
              Outcomes
            </Typography>
            
            {DEV_MODE 
              ?
                <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem', opacity: 1, m: '5px' }}>
                  id  {nrr.nrr_id}
                </Typography>
              :null
            }
          </Stack>

          {nrr.impacts.map(impact_id => 
          {
            const impact: INRRImpact | null = GetNrrImpact(impact_id);
            if(!impact) return null;

            return (
            <Stack key={impact_id} direction='row' sx={{ alignItems: 'center', pr: 0.5 }}>
              <BlueSwitch size='small' checked={GetNrrImpactState(nrr.nrr_id, impact_id)?.enabled} 
                          onChange={(e,c) => onNrrImpactChanged(e,c,nrr.nrr_id,impact_id)} 
                          disabled={!GetNrrState(nrr.nrr_id)?.enabled}/>

              {/* <LandscapeIcon sx={{ width: '18px', height: '18px', ml: '2px', mr: '5px', color: 'white', opacity: 0.8 }}/> */}

              <TypographyWithAutoTooltip placement='right' arrow
                                        sx={{ width: '100%', ml: '3px', fontSize: '0.6rem', color: '#D0DEFF', maxWidth: '100%' }}>
                {impact.short_name}
              </TypographyWithAutoTooltip>

              <IconButton onClick={(_)=>OnEditUserValues(nrr, impact_id)} sx={{ p: 0, mr: '2px' }}>
                <SettingsIcon sx={{ color: theme_textColorBlended, width: '16px', height: '16px' }}/>
              </IconButton>

            {/* Impact description tooltip */}

            {impact.description
              ?
                <NrrAndImpactInfoTooltip 
                  title=
                  {
                    <ImpactInfoPanel nrr={nrr} impact={impact}/>
                  }>
                  <HelpOutlineIcon sx={{ color: theme_limeGreen, mr:'-2px', opacity: 0.8, width: '16px', height: '16px' }}/>
                </NrrAndImpactInfoTooltip>
              :
                <HelpOutlineIcon sx={{ color: theme_limeGreen, mr:'-2px', opacity: 0.3, width: '16px', height: '16px' }}/>
            }

            </Stack>
            )
          })}

        </Stack>

      </AccordionDetails>
    </Accordion>

  )
}

export default NrrItem;








//-------------------------------------------------------------------------------
// AccordionSummary custom style.
//-------------------------------------------------------------------------------
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => (
{
  // NOTE: These make the accordion header item a lot smaller/denser
  //padding: 0, 
  //marginTop: -10, 
  //marginBottom: -10,
  //height: '30px',

  minHeight: 26,
  maxHeight: 26,
  overflow: 'hidden',

  '&.Mui-expanded': 
  {
    minHeight: 26,
    maxHeight: 26,
    color: 'red',
    backgroundColor: '#ADCDFF',
  },

  //backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, 0.2)',
  //flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': 
  {
    marginRight: '-15px',
    color: theme_textColorBlended,
    //transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': 
  {
    marginRight: '-15px',
    //transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': 
  {
    margin: theme.spacing(0),
    padding: 0,
  },
}));

//-------------------------------------------------------------------------------
// MUI Switch custom style.
//-------------------------------------------------------------------------------
const BlueSwitch = styled((props: SwitchProps) => (<Switch {...props} />))(({ theme }) => (
{
  "& .MuiSwitch-switchBase": 
  {
    color: "#7CA0E0",  // Knob color when OFF
    opacity: 1,
  },  
  "& .MuiSwitch-switchBase.Mui-checked": 
  {
    color: "#CBE2FF",  // Knob color when ON
    opacity: 1,
  },
  "& .MuiSwitch-switchBase.Mui-disabled": 
  {
    color: "#7090D0",  // Knob color when DISABLED and OFF
    opacity: 1,
  },
  "& .MuiSwitch-switchBase.Mui-disabled.Mui-checked": 
  {
    color: "#ABBED6",  // Knob color when DISABLED and ON
    opacity: 1,
  },
  //"&:hover .MuiSwitch-switchBase": { color: 'brown' },    // Knob color on hover
  '& .MuiSwitch-track': 
  {
    backgroundColor: theme_bgColorMain,  // Track color when OFF
    opacity: 0.4,
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": 
  {
    backgroundColor: theme_bgColorMain,  // Track color when ON
    opacity: 0.6,
  },
  "& .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track": 
  {
    backgroundColor: theme_bgColorMain,  // Track color when DISABLED
    opacity: 0.3,
  },
}));


