// UI component to create a new project

import { Stack, Button, Typography, ToggleButtonGroup, ToggleButton, styled } from "@mui/material";
import { useState } from "react";
import { theme_bgColorMain, theme_errorRed, theme_limeGreen, theme_orange, theme_textColorBlended, theme_textColorMain } from "../Theme";
import { CreateNewProject, LoadProjectList } from "./ProjectOps";
import useStore from "../store";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import RoundedInputBox from "../Components/RoundedInputBox";
import { ToastNotification } from "../ToastNotifications";
import ProjectSelection from "./ProjectSelection";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ProjectCreationProps
{
}

//-------------------------------------------------------------------------------
// Project creation component
//-------------------------------------------------------------------------------
const ProjectCreation = (props: ProjectCreationProps) => 
{
  // Get needed state data from the store
  const { store_setProjectUIMode, store_setProjectName, store_userInfo, 
          store_setProjectOrg, store_userProfile, 
        } = useStore();

  const [newProjectName, setNewProjectName] = useState<string>('');

  const [selectedOrgID, setSelectedOrgID] = useState<number | undefined>(undefined);










  //-------------------------------------------------------------------------------
  // Create a new project.
  //-------------------------------------------------------------------------------
  const onCreateNewProjectButton = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newProjectName || !store_userInfo) return;

    // If the user is part of multiple organizations, they must select which org this 
    // new project will belong to.

    if(store_userInfo.organizations.length >= 2)
    {
      if(!selectedOrgID)
      {
        ToastNotification('warning','Please select which organization this new project belongs to');
        return;
      }
    }
    else if(store_userInfo.organizations.length === 1) // only 1 org - the new project will automatically use it
      store_setProjectOrg(store_userInfo.organizations[0].id);

    store_setProjectName(newProjectName);

    CreateNewProject();
  }

  //-------------------------------------------------------------------------------
  // Switching projects.
  //-------------------------------------------------------------------------------
  const onSwitchProjectsButton = () => 
  {
    LoadProjectList();

    store_setProjectUIMode('select');
  }
  
  //-------------------------------------------------------------------------------
  // An org button was clicked.
  //-------------------------------------------------------------------------------
  const onClickOrgButton = (org_id: number|undefined) => 
  {
    setSelectedOrgID(org_id);
    store_setProjectOrg(org_id);
  }




  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1.2rem', color: theme_textColorBlended }}>
        Create a new project
      </Typography>

      {/* New project:  name + save button */}

      <RoundedInputBox title='New Project' onAccept={onCreateNewProjectButton}
                       value={newProjectName} setValue={setNewProjectName}/>

      {/* If the user is part of multiple organizations, they must select one */}

      {store_userInfo && store_userInfo.organizations && store_userInfo.organizations.length >= 2
        ?
          <Stack sx={{ width: '100%' }}>
            <Typography sx={{ mt: 2.5, mb: 1.5, fontSize: '0.9rem', color: theme_textColorBlended, textAlign: 'center' }}>
              Select an organization for this project
            </Typography>
            <Stack sx={{ width: '100%', maxHeight: '280px', overflow: 'auto', alignItems: 'center' }}>
              {
                store_userInfo.organizations.map(org => 
                  <ToggleButtonGroup orientation='vertical' size='small' value={selectedOrgID} fullWidth sx={{ width: '94%' }}>
                    <CustomToggleButton size='small' value={org.id} onClick={(e,v)=>onClickOrgButton(v)}>
                      {org.name}
                    </CustomToggleButton>
                  </ToggleButtonGroup>
                )
              }
            </Stack>
          </Stack>

        :null
      }

      {/* If there is no active project set up in the user profile, also show the user's existing
          projects they can load.  This is useful for a brand new user logging in, with no active
          project set.. but it's possible someone else already shared a project for them to use. */}

      {!store_userProfile || !store_userProfile.active_project_id
        ?
          <Stack sx={{ width: '100%', mt: 2 }}>
            <ProjectSelection calledFromProjCreation={true}/>
          </Stack>
        :
          // Saved projects button

          <Button variant='text' sx={{ mt: '20px' }} onClick={onSwitchProjectsButton}>
            <BookmarkBorderIcon sx={{ color: theme_limeGreen, width: '20px', height: '20px', mr: '4px' }} />
            <Typography sx={{ color: theme_limeGreen, fontSize: '0.8rem' }}>
              Saved Projects
            </Typography>
          </Button>
      }

    </Stack>    
  )
}

export default ProjectCreation;


// Customized toggle buttons (for the 3 view mode buttons)
const CustomToggleButton = styled(ToggleButton)(
{
  color: theme_textColorMain+'BF',
  borderColor: theme_textColorBlended, 
  backgroundColor: theme_bgColorMain + '60',

  // padding: 0,
  // paddingLeft: 8,
  // paddingRight: 8,
  //height: '30px',
  margin: '4px',
  padding: '7px', 
  fontSize: '0.8rem',
  borderRadius: 10, 
  textTransform: 'none',

  '&:hover': 
  {
    color: theme_textColorMain,
    backgroundColor: theme_bgColorMain+'50',
  },

  "&.Mui-selected, &.Mui-selected:hover": 
  {
    color: theme_orange,
    backgroundColor: theme_errorRed+'40',
    borderColor: theme_orange,
  }
});
