// Parcel attributes selector - ACTIVE attributes list

import useStore from '../../store';
import { Stack, Typography } from "@mui/material";
import { theme_errorRed, theme_orange, theme_textColorMain } from "../../Theme";
import { GetParcelLayer } from '../ParcelOps';
import React, { useEffect } from 'react';
import { ILayer, IVectorLayerAttribute } from '../../Layers/LayerInterfaces';
import { IProjectParcelAttribute } from '../../Projects/ProjectInterfaces';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { AttribSelectorActiveItem } from './AttribSelectorActiveItem';


let nextLocalAttribID: number = 1;    // This is here because the drag-and-drop requires a unique number id for each item

// Used for the local attribute item (which has drag-and-drop reordering)
export interface ILocalActiveAttribItem
{
  id: number; // The dnd-kit requires a numerical id (hence why the layer name inside ILayer is not used)
  attrib: IVectorLayerAttribute;
}


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface AttribSelectorActivePanelProps
{
  OnAttribCheckBoxChange: any;
  invisibleAttributeNames: string[];
}

//-------------------------------------------------------------------------------
// Parcel attributes selector - ACTIVE attributes list
//-------------------------------------------------------------------------------
export function AttribSelectorActivePanel(props: AttribSelectorActivePanelProps) 
{
  // Get needed state data from the store
  const { store_project, store_setProjectIsDirty, store_setProjectParcelAttribList, 
        } = useStore();


  // This local list keeps track of the current list of ACTIVE/SELECTED and VISIBLE/NOT FILTERED 
  // attributes (the list on the left side of the window).  More importantly, it is also used by
  // the drag-and-drop reordering mechanism.
  const [sortableAttribList, setSortableAttribList] = React.useState<ILocalActiveAttribItem[]>([]);

  // Used for the drag-and-drop reordering
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates, })
  );









  //-------------------------------------------------------------------------------
  // Rebuilds the local/sortable/active attribute list whenever the user's attrib list
  // changes in project settings.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    ResetSortableAttribList();

  }, [store_project?.user_settings.parcel_attribute_list, props.invisibleAttributeNames]);

  //-------------------------------------------------------------------------------
  // Rebuilds the local 'sortableAttribList', which controls what attributes are 
  // shown as "active/selected" and "visible/not filtered" on the left side of the window.
  // This list is also used by the drag-and-drop mechanism.
  // 
  // NOTE: This should only be called once when the window is initially popped up.  
  //       The 'id' for all items will changed, and that is used by the drag-and-drop.
  //-------------------------------------------------------------------------------
  function ResetSortableAttribList()
  {
    const newSortableAttribList: ILocalActiveAttribItem[] = [];

    const attribList: IVectorLayerAttribute[] = GetActiveAttributes();

    for(let i=0; i < attribList.length; i++)
    {
      if(!IsAttribSelected(attribList[i]))
        continue;

      // Only include items that are visible (based on the search/filter)
      if(!props.invisibleAttributeNames.find(attribName => attribName === attribList[i].attribute_name))
        newSortableAttribList.push({id: nextLocalAttribID++, attrib: attribList[i]})
    }

    setSortableAttribList(newSortableAttribList);
  }

  //-------------------------------------------------------------------------------
  // Returns the list of ACTIVE/SELECTED attributes.
  //-------------------------------------------------------------------------------
  function GetActiveAttributes(): IVectorLayerAttribute[]
  {
    if(!store_project || !store_project.user_settings || !store_project.user_settings.parcel_attribute_list) return [];

    const parcelLayer: ILayer | undefined = GetParcelLayer();
    if(!parcelLayer) return [];

    const parcelLayerAttributes: IVectorLayerAttribute[] | undefined = parcelLayer.attributes;
    if(!parcelLayerAttributes) return [];

    const visibleAttributes: IVectorLayerAttribute[] = [];

    // We need to return these in whatever order they appear in the user's project 
    // settings, so loop over that list in order.

    for(let i=0; i < store_project.user_settings.parcel_attribute_list.length; i++)
    {
      const projSettingsAttribute = store_project.user_settings.parcel_attribute_list[i];

      // Find the actual attribute
      const foundAttrib: IVectorLayerAttribute | undefined = parcelLayerAttributes.find(a => a.attribute_name === projSettingsAttribute.name);
      if(!foundAttrib) continue;  // This can happen if an attribute gets removed later
  
      // Only include items that are visible (based on the search/filter)
      //if(!invisibleAttributeNames.find(attribName => attribName === foundAttrib.attribute_name))
      //  visibleAttributes.push(foundAttrib);

      visibleAttributes.push(foundAttrib);
    }

    return visibleAttributes;
  }

  //-------------------------------------------------------------------------------
  // A draggable item has been moved by the user.
  //-------------------------------------------------------------------------------
  function OnDragAndDrop(event: any) 
  {
    if(!event || !store_project || !store_project.user_settings || !store_project.user_settings.parcel_attribute_list) return;

    const {active, over} = event;

    if(!active || !over) return;
    
    if (active.id !== over.id) 
    {
      const sourceAttribItem: ILocalActiveAttribItem | undefined = sortableAttribList.find(item => item.id === active.id);
      const destLayerItem: ILocalActiveAttribItem | undefined = sortableAttribList.find(item => item.id === over.id);

      if(!sourceAttribItem || !destLayerItem) return;

      // Find the array index of both source and destination lists

      let sourceIndex: number = -1;
      let destIndex: number = -1;
      for(let i=0; i < sortableAttribList.length; i++)
      {
        if(sortableAttribList[i].id === active.id)
          sourceIndex = i;
        if(sortableAttribList[i].id === over.id)
          destIndex = i;
      }

      if(sourceIndex < 0 || destIndex < 0) return;

      // Re-sort the local list by swapping the 2 affected array elements.
      const projAttribsListNewSort: IProjectParcelAttribute[] = arrayMove(store_project.user_settings.parcel_attribute_list, sourceIndex, destIndex);

      // Apply the new sorted list to the state store
      store_setProjectParcelAttribList(projAttribsListNewSort);

      // Mark the project as "dirty" so the change will be saved
      store_setProjectIsDirty(true);
    }
  }





















  // Main render

  return (

    <Stack sx={{ mt: 0, mb: 0, width: '400px', height: '100%' }}>

      <Stack sx={{ ml: '25px', my: 1 }}>
        <Typography sx={{ ml: '15px', color: theme_orange, fontSize: '1.2rem', fontWeight: 'bold' }}>
          Active Parcel Attributes
        </Typography>
        <Stack direction='row' sx={{ mt: -0.3 }}>
          <Typography sx={{ color: theme_textColorMain, fontSize: '1.1rem', opacity: 0.4, mt: 0.1 }}>
            ↙
          </Typography>
          <Typography sx={{ ml: 0.5, color: theme_textColorMain, fontSize: '0.7rem', opacity: 0.4 }}>
            You can drag-and-drop to reorder these
          </Typography>
        </Stack>
      </Stack>

      <Stack sx={{ overflow: 'auto' }}>

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={OnDragAndDrop} 
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}>
          <SortableContext items={sortableAttribList} strategy={verticalListSortingStrategy}>

            {sortableAttribList.length === 0
              ?
                <Typography sx={{ ml: 5, color: theme_errorRed, opacity: 0.7, fontSize: '1rem', textAlign: 'left' }}>
                  No search matches.
                </Typography>              
              :
                sortableAttribList.map(sortableAttrib => 
                  <AttribSelectorActiveItem key={sortableAttrib.id} sortableAttrib={sortableAttrib} OnAttribCheckBoxChange={props.OnAttribCheckBoxChange}/>
                )
            }

            {/* {sortableAttribList.map(sortableAttrib => 
              <AttribSelectorActiveItem key={sortableAttrib.id} sortableAttrib={sortableAttrib} OnAttribCheckBoxChange={props.OnAttribCheckBoxChange}/>
            )} */}

          </SortableContext>
        </DndContext>

      </Stack>
    </Stack>
  )
}







//-------------------------------------------------------------------------------
// Returns TRUE if the specific attribute is selected (appears in the user's attribute
// list saved in the active project).
//-------------------------------------------------------------------------------
export function IsAttribSelected(attrib: IVectorLayerAttribute): boolean
{
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.user_settings || !store_project.user_settings.parcel_attribute_list) return false;

  const foundAttrib: IProjectParcelAttribute | undefined = store_project.user_settings.parcel_attribute_list.find(a => a.name === attrib.attribute_name);

  return foundAttrib !== undefined;
}
