// Drawer menu component.

import React, { useEffect } from "react";
import { Box, IconButton,Stack, Drawer, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import { ILayer } from '../Layers/LayerInterfaces';
import useStore from "../store";
import { DEV_MODE, GetAppLastUpdateInfo } from "../Globals";
import { stratifyx_logo_light } from "../Theme";
import { theme_bgColorGradient, theme_textColorBlended, theme_orange } from "../Theme";
import DrawerMenuProject from './DrawerMenuProject';
import DrawerMenuAoi from './DrawerMenuAoi';
import DrawerMenuHBV from './DrawerMenuHBV';
import { ResetHbv } from "../HBV/HbvOps";
import DrawerMenuScenarios from "./DrawerMenuScenarios";
import { ExitAoiEditMode } from "../Aois/AoiOps";


export const DRAWER_MENU_WIDTH = 295;

export type DrawerMenuItemType = 'none' | 'project' | 'aoi' | 'bundle' | 'scenarios' | 'hbv';

//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface DrawerProps 
{
  //coords : IMapView,
  sortableLayerList: ILayer[];
  setSortableLayerList: React.Dispatch<React.SetStateAction<ILayer[]>>;
  setCurrTheme: React.Dispatch<React.SetStateAction<any>>;
}


//-------------------------------------------------------------------------------
// Drawer menu component
//-------------------------------------------------------------------------------
const DrawerMenu = (props: DrawerProps) => 
{
  // Get needed state data from the store
  const { store_map, store_drawerOpen, store_setDrawerOpen, store_isLoggedIn,
          store_userProfile, store_project, store_aoiUIMode, 
          store_userProfileIsLoading, store_setActiveDrawerMenuItem, 
        } = useStore();

  //const [renderCrashTest1, setRenderCrashTest1] = useState<boolean>(false);
  


  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {

    // If there is no active saved project, auto-expand the Project accordion
    if(!store_project || !store_project.project_id)
      store_setActiveDrawerMenuItem('project');
      
  }, [store_project, store_setActiveDrawerMenuItem]);  


  





  //-------------------------------------------------------------------------------
  // Accordion change.
  //-------------------------------------------------------------------------------
  const onAccordionChange = (panel: DrawerMenuItemType) => (event: React.SyntheticEvent, isExpanded: boolean) => 
  {
    store_setActiveDrawerMenuItem(isExpanded ? panel : 'none');

    // If the user clicked away while we were in AOI edit mode, tevert any AOI changes and exit AOI edit mode.
    if(panel !== 'aoi' && store_aoiUIMode === 'edit')
      ExitAoiEditMode(true);

    // Reset the HBV state
    ResetHbv();
  }

  // interface AppBarProps extends MuiAppBarProps {
  //   open?: boolean;
  // }
  
  // const AppBar = styled(MuiAppBar, {
  //   shouldForwardProp: (prop) => prop !== 'open',
  // })<AppBarProps>(({ theme, open }) => ({
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   ...(open && {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     marginLeft: `${drawerWidth}px`,
  //     transition: theme.transitions.create(['margin', 'width'], {
  //       easing: theme.transitions.easing.easeOut,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //   }),
  // }));
  // const DrawerHeader = styled('div')(({ theme }) => ({
  //   display: 'flex',
  //   alignItems: 'center',
  //   padding: theme.spacing(0, 1),
  //   // necessary for content to be below app bar
  //   ...theme.mixins.toolbar,
  //   justifyContent: 'flex-end',
  // }));
  //const theme = useTheme();


  //-------------------------------------------------------------------------------
  // Drawer menu is being closed
  //-------------------------------------------------------------------------------
  const handleDrawerClose = () => 
  { 
    store_setDrawerOpen(false);

    // NOTE:  If the browser window is resized before the drawer is closed, the map
    //        width doesn't refresh visually, so there is whitespace on the right.
    //        To fix this, we wait a bit for the drawer animation to execute, then
    //        force the map to resize.
    window.setTimeout(()=>store_map?.resize(), 30);
  };
/*
  //-------------------------------------------------------------------------------
  // Test Trevor's API
  //-------------------------------------------------------------------------------
  function TestAPI_DownloadFile(filename : string)
  {
    setTestAPIText('');

    const filenameUriEncoded = encodeURIComponent(filename);

    // GET to /user (auth)

    const server = new CallServer(true);

    server.DownloadFile('/download/' + filenameUriEncoded).then(result => {
      if(result.success)
      {
        console.log('SUCCESS!');
        setTestAPIText('Success');
        setTestAPIColor('green');
      }
      else
      {
        Debug.error('FAILURE! err=' + result.errorCode + ' - ' + result.errorMessage);

        let errStr;
        if(result.errorCode > 0) 
          errStr = 'code ' + result.errorCode + ' - ' + result.errorMessage;
        else
          errStr = result.errorMessage;

        setTestAPIText('FAILED - ' + errStr);
        setTestAPIColor('error.main');
      }
    });
  }
*/
/*
  const ErrorComponent = () => 
  {
    throw Error("error!");
    return <></>;
  }

  //-------------------------------------------------------------------------------
  // Crash app on purpose for testing.
  //-------------------------------------------------------------------------------
  const onRenderCrashTest1 = async () => 
  {
    setRenderCrashTest1(true);
  }

  //-------------------------------------------------------------------------------
  // Crash app on purpose for testing.
  //-------------------------------------------------------------------------------
  const onHandlerCrashTest1 = async () => 
  {
    const test: any = {};
    console.log(test.should.crash);
  }

  //-------------------------------------------------------------------------------
  // Crash app on purpose for testing.
  //-------------------------------------------------------------------------------
  const onHandlerCrashTest2 = async () => 
  {
    throw new Error('💥 KABOOM 💥');
  }
*/




  // Main render - NOT logged in

  // if(!store_isLoggedIn)
  // return (
  //   <div>log in ploz</div>
  // )

  // Main render - the user profile is loading in

  // if(!store_userProfile || store_userProfileIsLoading)
  // return (
  //   <div>Loggin in...</div>
  // )

  // Main render

  return (

    <Drawer sx={{ width: DRAWER_MENU_WIDTH, flexShrink: 0,
                  '& .MuiDrawer-paper': { width: DRAWER_MENU_WIDTH, boxSizing: 'border-box', background: theme_bgColorGradient } }}
            variant="persistent" anchor="left" open={store_drawerOpen} 
            transitionDuration={{ enter: 150, exit: 250 }}>

      {/* Header area */}

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: '5px' }}>

        <Stack sx={{ width: '100%', alignItems: 'center' }}>
          <img src={stratifyx_logo_light} alt="" height='30px' />
        </Stack>

        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon sx={{ color: theme_orange }}/>
        </IconButton>

      </Box>

      {DEV_MODE 
        ?
          <Stack direction='row' sx={{ width: '100%', justifyContent: 'center' }}>
            <Typography sx={{ color: theme_orange, fontSize: '0.6rem', opacity: 0.5, mr: 1 }}>
              DEV MODE
            </Typography>
            <Typography sx={{ color: theme_textColorBlended, fontSize: '0.6rem', opacity: 0.5 }}>
              App was last updated {GetAppLastUpdateInfo()} ago
            </Typography>
          </Stack>
        :null
      }

      {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',
        ...theme.mixins.toolbar, padding: theme.spacing(0, 1), bgcolor: 'primary.main' }}>
        <IconButton onClick={handleDrawerClose} sx={{ bgcolor: 'primary.light' }}>
          <ChevronLeftIcon />
        </IconButton>
      </Box> */}

      {/* <Divider /> */}

      {!store_isLoggedIn
        ?
          // A user is NOT logged in
          <Box sx={{ color: theme_textColorBlended, m: 2, mt: 3, fontSize: '1.2rem' }}>
            Welcome to StratifyX!<br></br><br></br>Please log in to enable the full functionality of this app.
          </Box>
        :
          <div>
            {!store_userProfile || store_userProfileIsLoading
              ?
                // The user profile is loading

                <Box sx={{ color: theme_textColorBlended, m: 2, mt: 3, fontSize: '1.2rem' }}>
                  Logging in...
                </Box>
              :

                // A user is logged in and their user profile is loaded

                <div>

                  {/* Project */}
                  <DrawerMenuProject onAccordionChange={onAccordionChange} />

                  {/* AOI */}
                  <DrawerMenuAoi onAccordionChange={onAccordionChange} />

                  {/* Bundle */}
                  {/* <DrawerMenuBundle onAccordionChange={onAccordionChange} /> */}

                  {/* Highest Best Value */}
                  <DrawerMenuHBV onAccordionChange={onAccordionChange} />

                  {/* Scenarios */}
                  <DrawerMenuScenarios onAccordionChange={onAccordionChange} />

                </div>
            }
          </div>
      }
    </Drawer>
  )
}

export default DrawerMenu;
