// Layer library operations

import { group } from "console";
import { CallServer } from "../CallServer";
import Debug from "../Debug";
import { ILayer } from "../Layers/LayerInterfaces";
import { LoadLayerDataFromServerIntoILayer, RemoveLayerFromMap } from "../Layers/LayerOps";
import { ToastNotification } from "../ToastNotifications";
import useStore from "../store";
import { ILayerLibraryGroup } from "./LayerLibraryInterfaces";
import { SPECIAL_UNGROUPPED_LAYERS_GROUP_ID } from "./LayerLibraryGroupButtons";
import { ILegend } from "../Legend/LegendInterfaces";


//-------------------------------------------------------------------------------
// Returns all the layers that belong to the specified layer library group.
//-------------------------------------------------------------------------------
export function GetLayersForGroup(layer_library_group_id: number): ILayer[]
{
  const store_layers: ILayer[] = useStore.getState().store_layers;

  let group_layers: ILayer[] = [];

  for(let i=0; i < store_layers.length; i++)
    if(store_layers[i].layer_library_group_ids && store_layers[i].layer_library_group_ids.find(group_id => group_id === layer_library_group_id))
      group_layers.push(store_layers[i]);

  // Sort the layers by name (asc)
  group_layers = group_layers.sort((a: ILayer, b: ILayer) => a.friendlyName.localeCompare(b.friendlyName));

  return group_layers;
}

//-------------------------------------------------------------------------------
// Return the layer library group for the specified ID.
//-------------------------------------------------------------------------------
export function GetLayerLibraryGroup(groupID: number | undefined): ILayerLibraryGroup | undefined
{
  if(!groupID) return undefined;

  const store_layerLibraryGroups = useStore.getState().store_layerLibraryGroups;
  if(!store_layerLibraryGroups || store_layerLibraryGroups.length === 0) return undefined;

  return store_layerLibraryGroups.find(group => group.layer_library_group_id === groupID);
}

//-------------------------------------------------------------------------------
// Return TRUE if the layer is a member of the specified layer library group.
//-------------------------------------------------------------------------------
export function IsLayerInLayerLibraryGroup(layer: ILayer | undefined, groupID: number | undefined): boolean
{
  if(!layer || !groupID) return false;

  const foundGroupID: number | undefined = layer.layer_library_group_ids.find(gid => gid === groupID);

  return foundGroupID ? true : false;
}

//-------------------------------------------------------------------------------
// Create new layer library group.
//-------------------------------------------------------------------------------
export async function CreateNewLayerLibraryGroup(newGroupName: string): Promise<boolean>
{
  if(!newGroupName || newGroupName.length === 0)
  {
    Debug.error('LayerLibraryOps.CreateNewLayerLibraryGroup> ERROR: undefined or empty group name');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id) return false;

  // Call the server

  const server = new CallServer();
  server.Add("project_id", store_project.project_id);
  server.Add("name", newGroupName);

  //useStore.getState().store_setCreatingNewLayerLibraryGroup(true);  // Tell the UI we are doing work

  const result = await server.Call('post', '/layer_group');

  //useStore.getState().store_setCreatingNewLayerLibraryGroup(false); // Tell the UI we are no longer doing work

  if(result.success)
  {
    Debug.log('LayerLibraryOps.CreateNewLayerLibraryGroup> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.CreateNewLayerLibraryGroup> SUCCESS! data=' + JSON.stringify(result.data));
    
    const newGroupID: number | undefined = result.data?.layer_group_id;

    if(!newGroupID)
    {
      // Failure
      ToastNotification('error', "Unable to create new layer library group")
      Debug.error(`LayerLibraryOps.CreateNewLayerLibraryGroup> Unable to create new layer library group (received invalid group id)`);
      return false;
    }

    Debug.log(`LayerLibraryOps.CreateNewLayerLibraryGroup> newGroupID=${newGroupID}`);

    // Add the new group to the state store

    const newGroup: ILayerLibraryGroup = 
    {
      layer_library_group_id: newGroupID,
      name: newGroupName,
      info: 
      {
        creation_date: undefined,
        create_user_id: useStore.getState().store_userInfo?.id,
        organization_id: store_project.organization?.id
      },
      CreateCustomLayer: true,
      EditLayerLibraryGroup: true,
      DeleteLayerLibraryGroup: true,
      isCustomGroup: true
    }

    useStore.getState().store_addLayerLibraryGroup(newGroup);

    Debug.log(`LayerLibraryOps.CreateNewLayerLibraryGroup> Created new layer library group ${newGroupID}.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to create new layer library group")
    Debug.error(`LayerLibraryOps.CreateNewLayerLibraryGroup> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Delete new layer library group.
//-------------------------------------------------------------------------------
export async function DeleteLayerLibraryGroup(groupID: number | undefined): Promise<boolean>
{
  if(!groupID || groupID <= 0)
  {
    Debug.error('LayerLibraryOps.DeleteLayerLibraryGroup> ERROR: undefined or invalid group ID');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id) return false;

  // Call the server

  const server = new CallServer();
  server.Add("project_id", store_project.project_id);
  server.Add("layer_group_id", groupID);

  //useStore.getState().store_setDeletingLayerLibraryGroup(true);  // Tell the UI we are doing work

  const result = await server.Call('delete', '/layer_group');

  //useStore.getState().store_setDeletingLayerLibraryGroup(false); // Tell the UI we are no longer doing work

  if(result.success)
  {
    Debug.log('LayerLibraryOps.DeleteLayerLibraryGroup> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.DeleteLayerLibraryGroup> SUCCESS! data=' + JSON.stringify(result.data));
    
    // Delete the group from the state store
    useStore.getState().store_removeLayerLibraryGroup(groupID);

    Debug.log(`LayerLibraryOps.DeleteLayerLibraryGroup> Deleted layer library group ${groupID}.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete layer library group")
    Debug.error(`LayerLibraryOps.DeleteLayerLibraryGroup> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Edit/rename a layer library group.
//-------------------------------------------------------------------------------
export async function EditLayerLibraryGroup(groupID: number | undefined, 
                                            newGroupName: string | undefined): Promise<boolean>
{
  if(!groupID || groupID <= 0)
  {
    Debug.error('LayerLibraryOps.EditLayerLibraryGroup> ERROR: undefined or invalid group ID');
    return false;
  }

  if(!newGroupName || newGroupName.length === 0)
  {
    Debug.error('LayerLibraryOps.EditLayerLibraryGroup> ERROR: invalid group name');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id) return false;

  // Call the server

  const server = new CallServer();
  server.Add("project_id", store_project.project_id);
  server.Add("layer_group_id", groupID);
  server.Add("name", newGroupName);

  //useStore.getState().store_setEditingLayerLibraryGroup(true);  // Tell the UI we are doing work

  const result = await server.Call('post', '/layer_group');

  //useStore.getState().store_setEditingLayerLibraryGroup(false); // Tell the UI we are no longer doing work

  if(result.success)
  {
    Debug.log('LayerLibraryOps.EditLayerLibraryGroup> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.EditLayerLibraryGroup> SUCCESS! data=' + JSON.stringify(result.data));
    
    // Update the name in the state store
    useStore.getState().store_setLayerLibraryGroupName(groupID, newGroupName);

    Debug.log(`LayerLibraryOps.EditLayerLibraryGroup> Edited layer library group ${groupID}.`);
    return true;  // success
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to edit layer library group")
    Debug.error(`LayerLibraryOps.EditLayerLibraryGroup> ${result.errorCode} - ${result.errorMessage}`);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Create a new custom layer (by uploading a local Shapefile).
// Returns the new layer, or undefined if an error occurred.
//-------------------------------------------------------------------------------
export async function CreateNewCustomLayer(shapefile: File, newLayerName: string, 
                                           layerLibraryGroupID: number): Promise<ILayer|undefined>
{
  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('LayerLibraryOps.CreateNewCustomLayer> Invalid project');
    return undefined;
  }

  // Call the server

  const server = new CallServer();
  server.AddFile(shapefile);
  server.Add('project_id', store_project.project_id);
  server.Add('name', newLayerName);
  server.Add('layer_group_id', layerLibraryGroupID);
  
  useStore.getState().store_setCreatingNewLayer(true);  // Tell the UI we are doing some server work

  const result = await server.UploadFile('/layer');

  useStore.getState().store_setCreatingNewLayer(false);  // Tell the UI the server work is done

  if(result.success)
  {
    Debug.log('LayerLibraryOps.CreateNewCustomLayer> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.CreateNewCustomLayer> SUCCESS! data=' + JSON.stringify(result.data));

    const newLayer: ILayer | null = LoadLayerDataFromServerIntoILayer(result.data.layer);
    if(!newLayer)
    {
      ToastNotification('error', "Unable to load newly-created custom layer")
      Debug.error('LayerLibraryOps.CreateNewCustomLayer> Received invalid or null layer data');
      return undefined;
    }

    // Need to add a few extra things to make this layer fully work (the API call doesn't return everything needed)
    
    newLayer.isCustomLayer = true;
    newLayer.EditCustomLayer = true;
    newLayer.DeleteCustomLayer = true;
    newLayer.layer_library_group_ids = [ layerLibraryGroupID ];
  

    useStore.getState().store_addLayer(newLayer);

    ToastNotification('success', "The layer has been created");

    Debug.log(`LayerLibraryOps.CreateNewCustomLayer> New layer created (id ${newLayer.id})`);

    // Success
    return newLayer;
  }
  else
  {
    // Failure

    if(result.errorMessage.toLowerCase().includes('name is already in use'))
      ToastNotification('error', "A layer with that name already exists")
    else
      ToastNotification('error', "Unable to create new custom layer")

    Debug.error('LayerLibraryOps.CreateNewCustomLayer> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return undefined;
  }
}

//-------------------------------------------------------------------------------
// Delete a custom layer.
//-------------------------------------------------------------------------------
export async function DeleteCustomLayer(layer_id: number): Promise<boolean>
{
  if(!layer_id || layer_id <= 0)
  {
    Debug.error('LayerLibraryOps.DeleteCustomLayer> Invalid layer ID');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('LayerLibraryOps.DeleteCustomLayer> Invalid project');
    return false;
  }

  // Call the server

  const server = new CallServer();
  server.Add('project_id', store_project.project_id);
  server.Add('layer_id', layer_id);

  useStore.getState().store_setDeletingLayer(true);  // Tell the UI we are doing some server work

  const result = await server.Call('delete', '/layer');

  useStore.getState().store_setDeletingLayer(false);  // Tell the UI the server work is done

  if(result.success)
  {
    Debug.log('LayerLibraryOps.DeleteCustomLayer> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.DeleteCustomLayer> SUCCESS! data=' + JSON.stringify(result.data));

    // Remove the layer from the map
    RemoveLayerFromMap(layer_id);

    // Remove the layer from the state store
    useStore.getState().store_removeLayer(layer_id);

    // Notify user of success
    ToastNotification('success', "The layer has been deleted");

    Debug.log(`LayerLibraryOps.DeleteCustomLayer> layer ${layer_id} has been deleted`);

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to delete the custom layer");
    Debug.error('LayerLibraryOps.DeleteCustomLayer> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Convert a custom layer into a main layer.
//-------------------------------------------------------------------------------
export async function ConvertCustomLayerIntoMainLayer(layer: ILayer | undefined): Promise<boolean>
{
  if(!layer)
  {
    Debug.error('LayerLibraryOps.ConvertCustomLayerIntoMainLayer> Invalid layer');
    return false;
  }

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('LayerLibraryOps.ConvertCustomLayerIntoMainLayer> Invalid project');
    return false;
  }

  // Call the server

  const server = new CallServer();
  server.Add('project_id', store_project.project_id);
  server.Add('layer_id', layer.id);
  server.Add('convert_to_main', true);

  //useStore.getState().store_setDeletingLayer(true);  // Tell the UI we are doing some server work

  const result = await server.Call('put', '/layer');

  //useStore.getState().store_setDeletingLayer(false);  // Tell the UI the server work is done

  if(result.success)
  {
    Debug.log('LayerLibraryOps.ConvertCustomLayerIntoMainLayer> API server call SUCCESS');
    //Debug.log('LayerLibraryOps.ConvertCustomLayerIntoMainLayer> SUCCESS! data=' + JSON.stringify(result.data));

    // Update the layer
    const updatedLayer: ILayer = 
    {
      ...layer,
      layer_library_group_ids: [ SPECIAL_UNGROUPPED_LAYERS_GROUP_ID ],
      isCustomLayer: false,
      EditCustomLayer: false,
      DeleteCustomLayer: false,
    }
    useStore.getState().store_addOrUpdateLayer(updatedLayer);

    // Notify user of success
    ToastNotification('success', "The custom layer has been converted to a main layer, but it's not part of any groups yet.  To assign it to a main group, go to the layer library and open the [ Layers with no group ] section.");

    Debug.log(`LayerLibraryOps.ConvertCustomLayerIntoMainLayer> Custom layer id ${layer.id} has been converted to a main layer`);

    // Success
    return true;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to convert custom layer to a main layer");
    Debug.error('LayerLibraryOps.ConvertCustomLayerIntoMainLayer> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return false;
  }
}

//-------------------------------------------------------------------------------
// Upload a legend image file.
//-------------------------------------------------------------------------------
export async function UploadLegendImageFile(uploadFile: File, legend: ILegend, 
                                            layer: ILayer | undefined): Promise<string | undefined>
{
  if(!layer) return undefined;

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('LayerLibraryOps.UploadLegendImageFile> Invalid project');
    return undefined;
  }

  // Call the server

  const server = new CallServer();
  server.AddFile(uploadFile);
  server.Add('resource_type', 'legend');
  server.Add('layer_id', layer.id)

  useStore.getState().store_setUploadingLegendImage(true);  // Tell the UI we are doing some server work

  const result = await server.UploadFile('/upload_image');

  useStore.getState().store_setUploadingLegendImage(false);  // Tell the UI the server work is done

  if(result.success)
  {
    Debug.log('LayerLibraryOps.UploadLegendImageFile> API server call SUCCESS');

    const imageFilename: string | undefined = result.data.filename;
    if(!imageFilename)
    {
      ToastNotification('error', "Something went wrong")
      Debug.error('LayerLibraryOps.UploadLegendImageFile> ERROR: Received invalid or empty filename');
      return undefined;
    }

    ToastNotification('success', "The legend image has been uploaded");

    Debug.log(`LayerLibraryOps.UploadLegendImageFile> Legend image uploaded: ${imageFilename}`);

    // Success
    return imageFilename;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to upload legend image file")
    Debug.error('LayerLibraryOps.UploadLegendImageFile> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return undefined;
  }
}

//-------------------------------------------------------------------------------
// Upload a map thumbnail image file.
//-------------------------------------------------------------------------------
export async function UploadMapImageFile(uploadFile: File, 
                                         layer: ILayer | undefined): Promise<string | undefined>
{
  if(!layer) return undefined;

  const store_project = useStore.getState().store_project;
  if(!store_project || !store_project.project_id || !store_project.aoi_group_id)
  {
    Debug.error('LayerLibraryOps.UploadMapImageFile> Invalid project');
    return undefined;
  }

  // Call the server

  const server = new CallServer();
  server.AddFile(uploadFile);
  server.Add('resource_type', 'map');
  server.Add('layer_id', layer.id)

  useStore.getState().store_setUploadingMapImage(true);  // Tell the UI we are doing some server work

  const result = await server.UploadFile('/upload_image');

  useStore.getState().store_setUploadingMapImage(false);  // Tell the UI the server work is done

  if(result.success)
  {
    Debug.log('LayerLibraryOps.UploadMapImageFile> API server call SUCCESS');

    const imageFilename: string | undefined = result.data.filename;
    if(!imageFilename)
    {
    ToastNotification('error', "Something went wrong")
    Debug.error('LayerLibraryOps.UploadMapImageFile> ERROR: Received invalid or empty filename');
    return undefined;
    }

    ToastNotification('success', "The map thumbnail image has been uploaded");

    Debug.log(`LayerLibraryOps.UploadMapImageFile> Map thumbnail image uploaded: ${imageFilename}`);

    // Success
    return imageFilename;
  }
  else
  {
    // Failure
    ToastNotification('error', "Unable to upload map thumbnail image file")
    Debug.error('LayerLibraryOps.UploadMapImageFile> ERROR: ' + result.errorCode + ' - ' + result.errorMessage);
    return undefined;
  }
}