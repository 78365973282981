import React, { useEffect, useState } from 'react';
import { Button, IconButton, ThemeProvider, createTheme, Stack, Box, ButtonGroup, Typography, Popper } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import { useStore } from './store';
import { ValidatePreviousLogin } from './Account/UserOps';
import DrawerMenu, { DRAWER_MENU_WIDTH } from './DrawerMenu/DrawerMenu';
import Map from './Map/Map';
import Identify from './Identify/Identify';
import BaseMapPanel from './BaseMapPanel';
import { ILayer } from './Layers/LayerInterfaces';
import LayersIcon from '@mui/icons-material/Layers';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Navigate, useNavigate } from 'react-router-dom';
import { stratifyx_logo_light, theme_textColorMain } from './Theme';
import { theme_bgColorGradient, theme_textColorBlended, theme_orange, theme_bgColorGradient2 } from './Theme';
import AppSettingsDialog from './AppSettingsDialog';
import AccountSettingsDialog from './Account/AccountSettingsDialog';
import MapIcon from '@mui/icons-material/Map';
import { DefaultProject, LoadProject, LoadProjectList, ReSyncProject, SaveActiveProject } from './Projects/ProjectOps';
import { IProject } from './Projects/ProjectInterfaces';
import { LoadUserProfile, SaveUserProfile } from './UserProfile';
import Debug from './Debug';
import LayersPanel, { ResetBaseMapLayers } from './Layers/LayersPanel';
import mapboxglSupported from '@mapbox/mapbox-gl-supported';
import ToastNotifications, { ToastNotification } from './ToastNotifications';
import preval from 'preval.macro';
import HbvPanel, { HBV_PANEL_WIDTH_PIXELS } from './HBV/HbvPanel';
import HbvComparePanel, { HBV_COMPARE_PANEL_SECTION_WIDTH_PIXELS } from './HBV/HbvComparePanel';
import { LayerInfoPanel } from './Layers/LayerInfoPanel';
import ParcelPanel from './Parcels/ParcelPanel';
import APIDebugInfo from './APIDebugInfo';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LayerLibrary from './LayerLibrary/LayerLibrary';
import { ParcelTableViewer } from './Parcels/ParcelTableViewer';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import { FeedBackWindow } from './FeedbackWindow';
import { MapFeedbackButton } from './CustomMuiStyles';
import { ParcelAttribSelector } from './Parcels/ParcelAttribSelector/ParcelAttribSelector';
import { IdentifySettings } from './Identify/IdentifySettings';
import { TermsOfServiceDialog } from './Account/TermsOfServiceDialog';
import { UserMenu } from './Account/UserMenu';
import { EditLayer } from './LayerLibrary/EditLayer/EditLayer';
import { EditUserValues } from './EditUserValues';
import SearchBoxTooptip from './SearchBoxTooltip';


let appInitDone = false;
const AUTO_SAVE_TIMER_MS = 15000;  // 15 seconds
const AUTO_PROJECT_REFRESH_TIMER_MS = 60000;  // 60 seconds
export const appBuildDateTimeStamp = preval`module.exports = new Date().toLocaleString();`;

//-------------------------------------------------------------------------------
// APP MAIN
//-------------------------------------------------------------------------------
export default function App()
{
  // Get needed state data from the store
  const { store_map, store_drawerOpen, store_setDrawerOpen, store_isLoggedIn, 
          store_userInfo, store_userProfile, store_layers, store_forcedAppReload,  
          store_hbvComparedScenarios, store_hbvScenario, store_editLayerFromLayerLibrary, 
          store_forcedLogout, store_reset, store_setLayerLibraryActive, 
          store_baseMapPanelAnchorElement, store_setBaseMapPanelAnchorElement, 
          store_layersPanelAnchorElement, store_setLayersPanelAnchorElement,
          store_setIsLoggedIn, store_setUserInfo, store_setFeedbackWindowOpen, 
        } = useStore();

  const [mapboxglIsSupported, setMapboxglIsSupported] = useState<boolean|null>(null);
  const [sortableLayerList, setSortableLayerList] = useState<ILayer[]>([]);

  const navigate = useNavigate();

  const [theme, setCurrTheme] = useState(muiTheme);
  
  //const [terrain3DExaggeration, setTerrain3DExaggeration] = useState<number>(1.5);


  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    if(store_isLoggedIn)
    {
      // User has logged in - load initial data for the user
      LoadDataAfterLogin();
    }

  }, [store_isLoggedIn]);

  //-------------------------------------------------------------------------------
  // One-time init.
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    Debug.log('App> useEffect init');

    // Check if mapbox-gl is supported by the user's browser
    setMapboxglIsSupported(mapboxglSupported.supported({failIfMajorPerformanceCaveat: false}));

    // Set up the auto-save timer
    const autoSaveInterval = setInterval(() => { AutoSave() }, AUTO_SAVE_TIMER_MS);
    //return () => clearInterval(autoSaveInterval);

    // Set up the shared project auto-sync refresh timer
    const autoProjectRefreshInterval = setInterval(() => { AutoProjectRefresh() }, AUTO_PROJECT_REFRESH_TIMER_MS);

    // useEffect cleanup
    return () => 
    {
      clearInterval(autoSaveInterval);
      clearInterval(autoProjectRefreshInterval);
    }
  }, []);

  //-------------------------------------------------------------------------------
  // INIT - ONLY ONCE
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    // This ensures we only run this once (per refresh)
    if(appInitDone) return; 
    else appInitDone = true;

    // If a previous login is available, try to re-use it (auto login)
    ValidatePreviousLogin();

    // Initialize the 4 special base map layers (Labels, Boundaries, Roads, Water)
    //if(store_layers.length === 0)  // We only want to do it once

    ResetBaseMapLayers();

  // eslint-disable-next-line
  }, []) // <-- empty dependency array - this will only be called once

  //-------------------------------------------------------------------------------
  // Called after login to load in all the initial data.
  //-------------------------------------------------------------------------------
  async function LoadDataAfterLogin(): Promise<boolean>
  {
    if(!store_isLoggedIn) return false; // a user must be logged in

    // Load the user info, user profile, and default bundle (CONCURRENTLY)

    // Load the user info
    // if(await LoadUserInfo() === false)
    //   return false;

    // NOTE: No longer doing both concurrently - LoadBundle now requires the project id from the user profile
    // // Load the user profile
    // const promise1: Promise<boolean> = LoadUserProfile();
    // // Load the default bundle
    // const promise2: Promise<boolean> = LoadBundle(DEFAULT_BUNDLE_ID, false);
    // // Wait for both user profile and bundle to finish loading
    // await Promise.all([promise1, promise2]);
    // if(promise1.valueOf() === false || promise2.valueOf() === false)
    //   return false; // one of the 2 calls failed

    // Load the user profile
    if(await LoadUserProfile() === false)
    {
      // Critical error - cannot log in
      ToastNotification('error', "Cannot log in (unable to load user profile)");
      store_setUserInfo(null);
      store_setIsLoggedIn(false);
      return false;
    }

    // Load the default bundle
    // if(await LoadBundle(DEFAULT_BUNDLE_ID, false) === false)
    //   return false;

    // Load the project
    //
    // NOTE:  Need the active project ID from the LoadUserProfile call above.
    // NOTE:  The bundle (with info for all layers) is loaded inside LoadProject.

    const userProfile = useStore.getState().store_userProfile;
    if(!userProfile) return false;

    const activeProjectID: string | null = userProfile.active_project_id;
    if(!activeProjectID || activeProjectID.length <= 0)
    {
      // The user has no projects - create a new unsaved project for them to use

      const newUnsavedProject: IProject = DefaultProject();
      
      useStore.getState().store_setProject(newUnsavedProject);
      useStore.getState().store_setBundle(null);
      useStore.getState().store_setUserProfileActiveProjectID(null);

      // Load the project list - it's possible the user does have project (shared by others).
      // If they do, they will see the list of projects under the "create new project" UI.
      LoadProjectList();
    }
    else
    {
      // The user has an active/saved project - load it

      const returnState = await LoadProject(activeProjectID);
      if(!returnState)
        return false;
    }

    // Success
    return true;
  }
/*
  //-------------------------------------------------------------------------------
  // Called after login to load in all the initial data.
  //
  // This group can be called concurrently along with everything else, but within it
  // the order matters.
  //-------------------------------------------------------------------------------
  async function LoadDataAfterLogin_Group2(): Promise<boolean>
  {
    if(!store_isLoggedIn) return false; // a user must be logged in

    // Load the user profile

    let returnState: boolean = await LoadUserProfile();
    if(!returnState)
      return false;

    const userProfile: IUserProfile | null = useStore.getState().store_userProfile;
    if(userProfile === null)
      return false;

    //userProfile.active_project_id = null;

    // Load the current/active project
    //
    // NOTE:  Need the active project ID from the LoadUserProfile call above

    const activeProjectID: string | null = userProfile.active_project_id;
    if(!activeProjectID || activeProjectID.length <= 0)
    {
      // The user has no projects - create a new unsaved project for them to use

      const newUnsavedProject: IProject = DefaultProject();
      
      useStore.getState().store_setProject(newUnsavedProject);
      useStore.getState().store_setBundle(null);
      useStore.getState().store_setUserProfileActiveProjectID(null);
    }
    else
    {
      // The user has an active/saved project - load it

      returnState = await LoadProject(activeProjectID);
      if(!returnState)
        return false;
    }

    // Load the current/active bundle - this loads the NRR/impact list and the list of
    // reference layers.
    //
    // NOTE:  Before we had bundles, we needed the active bundle ID from the LoadProject
    //        call above.  Now, bundles are all but gone, and LoadProject simply uses
    //        DEFAULT_BUNDLE_ID.  So the bundle call could come before the project call.
    
    // const activeProject: IProject | null = useStore.getState().store_project;
    // if(!activeProject)
    //   return false;

    // const activeBundleID: number | null = activeProject.bundle_id;
    // if(!activeBundleID || activeBundleID <= 0)
    //   return false;

    returnState = await LoadBundle(DEFAULT_BUNDLE_ID, false);
    if(!returnState)
      return false;

    return true;  // success
  }
*/
  //-------------------------------------------------------------------------------
  // Handle drawer menu opening.
  //-------------------------------------------------------------------------------
  const handleDrawerOpen = () => 
  { 
    store_setDrawerOpen(true);
    
    // Wait a bit for the actual op to complete, then force the map to resize, which
    // causes the map's contents to re-center properly.
    window.setTimeout(()=>store_map?.resize(), 30);
  }

  //-------------------------------------------------------------------------------
  // The layers panel button was clicked.
  //-------------------------------------------------------------------------------
  const onLayersPanelButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    // Close the base map panel (if it's open)
    store_setBaseMapPanelAnchorElement(undefined);

    // Set the anchor element of the Popover control to the base map panel button.
    if(!store_layersPanelAnchorElement)
      store_setLayersPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|undefined));
    else
      store_setLayersPanelAnchorElement(undefined);
  }

  //-------------------------------------------------------------------------------
  // The layer library button was clicked.
  //-------------------------------------------------------------------------------
  const onLayersLibraryButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    // Close the base map panel (if it's open)
    store_setBaseMapPanelAnchorElement(undefined);

    // Open the layer library panel
    store_setLayerLibraryActive(true);
  }

  //-------------------------------------------------------------------------------
  // The base map panel button was clicked.
  //-------------------------------------------------------------------------------
  const onBaseMapPanelButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    // Set the anchor element of the Popover control to the base map panel button.
    if(store_baseMapPanelAnchorElement)
      store_setBaseMapPanelAnchorElement(undefined);
    else
      store_setBaseMapPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|undefined));
  }

  //-------------------------------------------------------------------------------
  // The base map panel needs to be closed.
  //-------------------------------------------------------------------------------
  const onBaseMapPanelClose = () => 
  {
    // Setting the anchor element to null will close the Popover
    store_setBaseMapPanelAnchorElement(undefined);
  };

  //-------------------------------------------------------------------------------
  // The LAYERS panel needs to be closed.
  //-------------------------------------------------------------------------------
  const onLayersPanelClose = () => 
  {
    // Setting the anchor element to null will close the Popover
    store_setLayersPanelAnchorElement(undefined);
  };

/*
  //-------------------------------------------------------------------------------
  // The legend panel toggle button was clicked.
  //-------------------------------------------------------------------------------
  const onLegendPanelButtonClick = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => 
  {
    // Set the anchor element of the Popover control to the legend panel button.
    setLegendPanelAnchorElement(legendPinnedOpen ? null : event.currentTarget.parentElement as (HTMLButtonElement|null));

    // Toggle the state
    setLegendPinnedOpen(!legendPinnedOpen);
  }

  //-------------------------------------------------------------------------------
  // The legend panel needs to be closed.
  //-------------------------------------------------------------------------------
  const onLegendPanelClose = () => 
  {
    setLegendPinnedOpen(false);

    // Setting the anchor element to null will close the Popover
    setLegendPanelAnchorElement(null);
  }
*/
  //-------------------------------------------------------------------------------
  // Mouse entered the BASE MAP panel button.
  //-------------------------------------------------------------------------------
  // const onBasePanelButtonMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => 
  // {
  //   setBaseMapPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|null));

  //   setLayersPanelAnchorElement(null);
  // }

  //-------------------------------------------------------------------------------
  // Mouse entered the LAYERS panel button.
  //-------------------------------------------------------------------------------
  // const onLayersButtonMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => 
  // {
  //   //setLayersPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|null));

  //   // Close the base map panel if it's currently open
  //   store_setBaseMapPanelAnchorElement(undefined);
  // }
/*
  //-------------------------------------------------------------------------------
  // Mouse entered the LEGEND panel button.
  //-------------------------------------------------------------------------------
  const onLegendButtonMouseEnter = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    // If the mouse enters the button and the legend toggle button is UP, open the legend panel
    if(!legendPinnedOpen)
      setLegendPanelAnchorElement(event.currentTarget.parentElement as (HTMLButtonElement|null));

    store_setBaseMapPanelAnchorElement(undefined);
    setLayersPanelAnchorElement(null);
  }  
  
  //-------------------------------------------------------------------------------
  // Mouse left the LEGEND panel button.
  //-------------------------------------------------------------------------------
  const onLegendButtonMouseLeave = () => 
  {
    // If the mouse leaves the button and the legend toggle button is UP, close the legend panel
    if(!legendPinnedOpen)
      setLegendPanelAnchorElement(null);
  }
*/

  // TEMP: Animates the margin transition (not used)
  // const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  //   open?: boolean;
  // }>(({ theme, open }) => ({
  //   flexGrow: 1,
  //   padding: theme.spacing(3),
  //   transition: theme.transitions.create('margin', {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.leavingScreen,
  //   }),
  //   marginLeft: `-${drawerMenuWidth}px`,
  //   ...(open && {
  //     transition: theme.transitions.create('margin', {
  //       easing: theme.transitions.easing.easeOut,
  //       duration: theme.transitions.duration.enteringScreen,
  //     }),
  //     marginLeft: 0,
  //   }),
  // }));

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  // const onBaseMapPanelButton = (event: React.MouseEvent<HTMLElement>) => 
  // {
  //   setShowBaseMapPanel(true);
  // }




  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  // useEffect(() => 
  // {

    // Load data from server

    // if(store_isLoggedIn && store_projectList === null)
    //   LoadProjectList();

    // if(store_isLoggedIn && store_bundleList === null)
    //   LoadBundleList();

  //}, [store_isLoggedIn, store_bundleList]);  

  //-------------------------------------------------------------------------------
  // Open the user feedback window.
  //-------------------------------------------------------------------------------
  function OnOpenFeedbackWindow()
  {
    store_setFeedbackWindowOpen(true);
  }









  


  // ******************************************************************************************
  // Main render - force-show the Terms of Service agreement if the user is logged in but has
  //               never agreed to the TOS.
  // ******************************************************************************************

  if(store_isLoggedIn && store_userProfile && 
     (store_userProfile.agreed_to_terms_of_service === undefined || store_userProfile.agreed_to_terms_of_service === false))
  return (
    <TermsOfServiceDialog/>
  )

  // ******************************************************************************************
  // Main render - Mapbox-GL-JS is not supported by the browser
  // ******************************************************************************************

  if(mapboxglIsSupported === false)
  return (

    <div style={{ backgroundImage: "url(https://images.squarespace-cdn.com/content/v1/5eb08f475674db04ac75aec5/1591059926958-QET4PEL9R3F73NE6PLJO/shutterstock_298180979+%5BConverted%5D-01.jpg?format=2500w)",
                  backgroundSize: 'cover', height: '100vh', position: "relative", display: "flex",
                  justifyContent: "center", alignItems: "center", boxShadow: '5' }}>

      <Stack direction='column' sx={{ alignItems: 'center' }}>

        <Box sx={{ height: '150px', mb: 2 }}>
          <img src={stratifyx_logo_light} alt="" height='100%' />
        </Box>

        <Typography sx={{ color: theme_orange, fontSize: '2rem' }}>
          This application requires a WebGL-enabled browser.
        </Typography>

        <Typography sx={{ color: theme_textColorBlended, fontSize: '1.5rem' }}>
          If your browser supports it, please turn on WebGL. 
        </Typography>

      </Stack>

    </div>
  )

  // ******************************************************************************************
  // Main render - force the user login page (eg: if the user's access token has expired).
  // ******************************************************************************************

  if(store_forcedLogout)
  {
    store_reset();
    //store_setForcedLogout(false);

    return (
      <Navigate to="/Account/LogIn" replace={true} />
    )
  }

  // ******************************************************************************************
  // Main render - force the app to completely reload.
  // ******************************************************************************************

  if(store_forcedAppReload)
  {
    store_reset();
    window.location.reload();

    return (
      <div/>
    )
  }

  // ******************************************************************************************
  // Main render - MAIN
  // ******************************************************************************************

  return (

      <div>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          {/* Mapbox map */}

          <Box sx={{ position: 'fixed',
                     width: `calc(100% - ${store_drawerOpen?DRAWER_MENU_WIDTH:0}px)`, 
                     height: '100%',
                     marginLeft: `${store_drawerOpen?DRAWER_MENU_WIDTH:0}px` }}>

            <Map />

            {/* Feature Identify component

                NOTE: It's placed inside the map's Box so the react-draggable component within 
                      can be set to 'parent' to limit where it can be dragged.
            */}
            <Identify />

          </Box>

          {/* Hbv panel */}
  
          <Box sx={{ position: 'fixed', visibility: store_hbvScenario ? 'visible' : 'collapse',
                     width: `${HBV_PANEL_WIDTH_PIXELS}px`, marginTop: 0, 
                     boxShadow: 5, borderRadius: 0, borderRadiusTopRight: 3, borderRadiusBottomRight: 3,
                     marginLeft: `${store_drawerOpen?DRAWER_MENU_WIDTH:0}px`,
                     height: '100%', p: '8px', pt: '0px',
                     background: theme_bgColorGradient2, opacity: 0.95 }}>

            <HbvPanel />

          </Box>

          {/* Hbv comparison panel */}
  
          <Box sx={{ position: 'fixed', marginTop: 0, background: theme_bgColorGradient2, opacity: 0.95, 
                     visibility: store_hbvComparedScenarios.length > 0 ? 'visible' : 'collapse',
                     boxShadow: 5, borderRadius: 0, borderRadiusTopRight: 3, borderRadiusBottomRight: 3,
                     width: `${HBV_COMPARE_PANEL_SECTION_WIDTH_PIXELS * store_hbvComparedScenarios.length + 50}px`, 
                     marginLeft: `${store_drawerOpen?DRAWER_MENU_WIDTH:0}px`, height: '100%', p: 0 }}>

            <HbvComparePanel onClose={null} />

          </Box>

          {/* Main menu button */}

          <Box sx={{ position: 'absolute', top: 27, left: 20 }}>

            {/* <Box sx={{ background: theme_bgColorGradient, borderRadius: 20, boxShadow: 3, width: '190px', height: '35px', opacity: 0.8 }}/> */}

            <Box sx={{ backgroundImage: 'radial-gradient(circle at 18px 17px, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 25px, #193D7D 32px)',
                      borderRadius: 25, boxShadow: 0, width: '155px', height: '35px', opacity: 0.9 }}/>

            <IconButton sx={{ top: -37, boxShadow: 3, color: '#1C305A', bgcolor: '#E7A44F',
                              ':hover': { bgcolor: '#ecd5ac', color: '#1C305A' } }} 
                        onClick={handleDrawerOpen}>
              <MenuIcon />
            </IconButton>

            <Stack direction='row' sx={{ position: 'absolute', top: -20, left: 53, opacity: 1 }}>
              {/* <img src={'Images/StratifyX_Logo_White_Icon_Only_Small.png'} alt="" height='50px' /> */}
              <img src={stratifyx_logo_light} alt="" height='20px' 
                   style={{ marginLeft: '8px', marginTop: '29px' }} />
            </Stack>

          </Box>

          {/* Main menu drawer */}

          <DrawerMenu sortableLayerList={sortableLayerList} setSortableLayerList={setSortableLayerList}
                      setCurrTheme={setCurrTheme}/>

          {/* Buttons for Base Map, Layers, and Layer Library */}

          <Box sx={{ position: 'absolute', right: 10, top: 100, boxShadow: 3, width: '50px',
                    background: theme_bgColorGradient, borderRadius: 1, padding: 0.3, opacity: 0.95 }}>

            <ButtonGroup orientation='vertical' variant='text' sx={{ width: '100%', height: '100%', alignItems: 'center' }}>

              <IconButton onClick={onBaseMapPanelButtonClick} sx={{ padding: 0.5 }}>
                <MapIcon sx={{ color: theme_textColorBlended, width: '100%', height: '100%' }}/>
              </IconButton>

              <Typography sx={{ fontSize: '0.6rem', opacity: 0.8, color: theme_orange }}>
                Base Map
              </Typography>

              <IconButton onClick={onLayersPanelButtonClick} sx={{ padding: 0.5, mt: 0.5 }}>
                <LayersIcon sx={{ color: theme_textColorBlended, width: '100%', height: '100%' }}/>
              </IconButton>

              <Typography sx={{ fontSize: '0.6rem', opacity: 0.8, color: theme_orange }}>
                Layers
              </Typography>

              {store_layers && store_layers.length>0
                ?
                  <Stack>
                    <IconButton onClick={onLayersLibraryButtonClick} sx={{ padding: 0.5, mt: 0.5 }}>
                      <LocalLibraryIcon sx={{ color: theme_textColorBlended, width: '100%', height: '100%' }}/>
                    </IconButton>
                    <Typography sx={{ fontSize: '0.6rem', opacity: 0.8, color: theme_orange, textAlign: 'center' }}>
                      Layer Library
                    </Typography>
                  </Stack>
                :null
              }

            </ButtonGroup>

          </Box>

          {/* Base Map panel */}

          <Popper open={Boolean(store_baseMapPanelAnchorElement)} anchorEl={store_baseMapPanelAnchorElement} placement='left-start'
                  sx={{ background: theme_bgColorGradient, borderRadius: 1, boxShadow: 2, opacity: 0.95 }}
                  modifiers={[
                    {
                      name: "offset",
                      options: { offset: [-2, 15] },
                    },
                  ]}>

            <BaseMapPanel onClose={onBaseMapPanelClose}/>

          </Popper> 

          {/* Layers panel */}

          <Popper open={Boolean(store_layersPanelAnchorElement)} anchorEl={store_layersPanelAnchorElement} placement='left-start'
                  sx={{ background: theme_bgColorGradient, borderRadius: 1, boxShadow: 2, opacity: 0.95 }}
                  modifiers={[
                    {
                      name: "offset",
                      options: { offset: [-2, 15] },
                    },
                  ]}>

            <LayersPanel onClose={onLayersPanelClose}/>

          </Popper>

          <ParcelPanel />

          {/* User feedback button */}

          {store_isLoggedIn === true && store_userInfo
            ?
              <Stack sx={{ position: 'absolute', right: 280, top: 10 }}>

                  <MapFeedbackButton onClick={(_)=>OnOpenFeedbackWindow()}
                    sx={{ borderRadius: 20, p: 0.3, pl: 1, pr: 1, border: 1, boxShadow: 3 }}>

                    <Stack direction='row' sx={{ alignItems: 'center' }}>
                      <RecordVoiceOverIcon sx={{ width: '28px', height: '28x', mr: '3px', color: '#D9B94A' }}/>
                      <Typography sx={{ fontSize: '0.9rem', color: '#D9B94A', textAlign: 'center', textTransform: 'none' }}>
                        Feedback
                      </Typography>
                    </Stack>

                  </MapFeedbackButton>
                </Stack>
              :null
          }

          <FeedBackWindow />
          <APIDebugInfo />
          <SearchBoxTooptip />
          <UserMenu />

          {/* A user is NOT logged in - show "Log In" and "Sign Up" buttons */}

          {!store_isLoggedIn
            ?
              <Box sx={{ position: 'absolute', top: 25, right: 15 }}>

                <Stack direction='row'>

                  <Button variant='contained' size='small' startIcon={<PersonAddIcon />}
                          onClick={() => navigate("/Account/SignUp")} 
                          sx={{ paddingTop: '0px', paddingBottom: 0, height: '30px', mt: '4px', marginRight: 1.8, borderRadius: 15, fontSize: '1rem', 
                                color: '#1C305A', bgcolor: theme_orange, boxShadow: 3, 
                                ':hover': { bgcolor: '#ecd5ac', color: '#1C305A' } }}>
                    Sign Up
                  </Button>

                  <Button variant='contained' size='small' startIcon={<LoginIcon />}
                          onClick={() => navigate("/Account/Login")} 
                          sx={{ paddingTop: '0px', paddingBottom: 0, height: '30px', mt: '4px', marginRight: 1.8, borderRadius: 15, fontSize: '1rem', 
                                color: '#1C305A', bgcolor: theme_textColorMain, boxShadow: 3, 
                                ':hover': { bgcolor: '#ecd5ac', color: '#1C305A' } }}>
                    Log In
                  </Button>

                </Stack>
              </Box>
            :null
          }

          <AppSettingsDialog setCurrTheme={setCurrTheme} />
          <AccountSettingsDialog setCurrTheme={setCurrTheme} />
          <ParcelTableViewer/>
          <ParcelAttribSelector/>
          <LayerInfoPanel/>
          <LayerLibrary/>
          <EditUserValues/>

          {store_editLayerFromLayerLibrary===false
            ?
              <EditLayer/>
            :null
          }

          <IdentifySettings/>
          <TermsOfServiceDialog/>
          <ToastNotifications/>

        </ThemeProvider>
      </div>
  )
}



// App-wide MUI theme
export const muiTheme = createTheme(
{
  palette: 
  {
    mode: 'light',
    // background: 
    // {
    //   default: "#ff0000"
    // },
    text: 
    {
      //default: "#000"
    },    
    primary: 
    {
      main: '#98C0FF',
      // light: '#E4F9F5'
    },
    error:
    {
      main: '#ef9a9a',
      // light: '#E4F9F5'
    },
  },
  typography: 
  {
    allVariants: 
    {
      color: "white"
    },
  },
  components: 
  {
    MuiMenu: 
    {
      styleOverrides: 
      {
        paper: 
        {
          background: theme_bgColorGradient,
          //backdropFilter: "blur(10px) !important",
        },
      },
    },
  },  
});  





// App-wide dark theme
// export const darkTheme = createTheme(
// {
//   palette: 
//   {
//     mode: 'dark',
//     primary: 
//     { 
//       main: '#98C0FF', //'#6689C4',
//       dark: '#49628C',
//       light:'#83B0FC',
//      }
//   },
// });


//-------------------------------------------------------------------------------
// Auto-save (triggered by the auto-save timer, or manually on logout).
//
// Periodically checks if any settings needs to be auto-saved to the server/db.
//   - user profile
//   - projects
//
// This is done so the user doesn't have to worry about constantly hitting a
// Save button whenever they make any minor changes in the app.
//-------------------------------------------------------------------------------
export async function AutoSave()
{
  //Debug.log('App.AutoSave>');

  // Auto-save the user profile
  if(useStore.getState().store_userProfile?.isDirty)
    await SaveUserProfile();

  // Auto-save the active project
  if(useStore.getState().store_project?.isDirty && useStore.getState().store_project?.project_id && useStore.getState().store_projectSurpressSaving === false)
    await SaveActiveProject();
}

//-------------------------------------------------------------------------------
// Check if the active project has changed database-side since it was last loaded.
// Specifically, it detects AOI and scenario lists changes.
//
// It sends the project's last load date to an API call, and if changes are detected
// to either the project's AOI group or scenario group, it returns a fresh copy of
// the project.
//
// Triggered by a timer.
//
// Only runs for projects that are being "shared - the project's AOI group and/or
// scenario group is being used by another project.
//-------------------------------------------------------------------------------
export async function AutoProjectRefresh()
{
  //Debug.log('App.AutoProjectRefresh>');

  const store_project = useStore.getState().store_project;
  if(!store_project) return;
  
  if(store_project.is_group_shared)
    await ReSyncProject();
}
