// Drawer menu - Project accordion

import { Accordion, Box, Typography, AccordionDetails, Stack } from "@mui/material";
import { useStore } from "../store";
import Projects from "../Projects/Projects";
import { theme_bgColorLight1, theme_textColorBlended, theme_orange, theme_textColorMain, theme_limeGreen } from "../Theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { DrawerMenuAccordionSummary } from './DrawerMenuAccordionStyle';
import TypographyWithAutoTooltip from "../Components/TypograpyWithAutoTooltip";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface DrawerMenuProjectProps 
{
  onAccordionChange: any;
}

//-------------------------------------------------------------------------------
// Drawer menu accordion PROJECT component
//-------------------------------------------------------------------------------
const DrawerMenuProject = (props: DrawerMenuProjectProps) => 
{
  const { onAccordionChange } = props;

  // Get needed state data from the store
  const { store_project, store_activeDrawerMenuItem, 
        } = useStore();





  // NOTE: At this point we know a user is logged in and the user profile is loaded

  // Main render

  return (

    <Accordion expanded={store_activeDrawerMenuItem === 'project'} disableGutters={false}
               onChange={onAccordionChange('project')}
               sx={{ background: theme_bgColorLight1 }}>
            
      <DrawerMenuAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme_textColorBlended }} />}>

        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>

          <FolderOpenIcon sx={{ color: theme_textColorBlended, width: '30px', height: '30px', ml: -1 }}/>
            {!store_project || !store_project.project_id
              ?
                // No active saved project

                <Typography sx={{ ml: 1, color: theme_textColorMain, fontSize: '1rem', opacity: 0.8 }}>
                  Project
                </Typography>
              :
                // There is an active saved project

                <Stack direction='column' sx={{ ml: 1, width: '100%' }}>
                  <Stack direction='row' sx={{ justifyContent: 'space-between', width: '100%' }}>
                    <Typography sx={{ color: theme_textColorMain, fontSize: '0.7rem', width: '50px' }}>
                      Project
                    </Typography>
                    <Typography noWrap overflow='hidden' sx={{ color: theme_limeGreen, fontSize: '0.7rem', opacity: 0.8, maxWidth: '140px' }}>
                      {store_project.organization?.name}
                    </Typography>
                  </Stack>

                  <TypographyWithAutoTooltip placement='right' arrow sx={{ color: theme_orange, fontSize: '0.9rem', maxWidth: '200px' }}>
                    {store_project?.name}
                  </TypographyWithAutoTooltip>

                </Stack>
            }
        </Box>

      </DrawerMenuAccordionSummary>

      <AccordionDetails>
        <Projects />
      </AccordionDetails>

    </Accordion>
  )
}

export default DrawerMenuProject;
