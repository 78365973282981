// Yes/No dialog component

import { Button, styled, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { theme_bgColorMain, theme_orange,theme_bgColorLight1, theme_textColorMain, theme_errorRed, theme_limeGreen } from "../Theme";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface YesNoDialogProps
{
  open: boolean;
  setOpen:  React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  onYes: any;
}

//-------------------------------------------------------------------------------
// Yes/No dialog component
//-------------------------------------------------------------------------------
const YesNoDialog = (props: YesNoDialogProps) => 
{


  //-------------------------------------------------------------------------------
  // Close the dialog.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    props.setOpen(false);
  }





  // Main render

  return (

      <QuestionDialog open={props.open} onClose={onClose}>
        <DialogTitle id="alert-dialog-title">
          {props.children}
        </DialogTitle>
        {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Dialog content text here
          </DialogContentText>
        </DialogContent> */}
        <DialogActions>
          <Button variant='outlined' onClick={onClose} sx={{ mr: 2, color: theme_errorRed, bgcolor: theme_errorRed+'30', fontSize: '1.0rem', fontWeight:'bold' }}>
            <ClearIcon sx={{ mr: 1 }}/>
            No
          </Button>
          <Button variant='outlined' onClick={(_)=>props.onYes()} autoFocus sx={{ color: theme_limeGreen, bgcolor: theme_limeGreen+'30', fontSize: '1.0rem', fontWeight:'bold' }}>
            <CheckIcon sx={{ mr: 1 }}/>
            Yes
          </Button>
        </DialogActions>
      </QuestionDialog>

  )
}

export default YesNoDialog;





// Styled Dialog used for asking users questions (like "Delete Project" YES/NO confirmation)
export const QuestionDialog = styled(Dialog)(({ theme }) => (
  {
    '& .MuiDialogContent-root': 
    { 
      padding: theme.spacing(2),
      //backgroundColor: 'yellow',
    },
    '& .MuiDialogContentText-root': 
    { 
      color: theme_textColorMain
    },

    '& .MuiDialogActions-root': 
    { 
      padding: theme.spacing(1) 
    },
    "& .MuiDialog-paper": 
    {
      backgroundColor: theme_bgColorLight1,
    },
    "& .MuiTypography-h6":  // controls title area
    {
      color: theme_orange,
      backgroundColor: theme_bgColorMain,
    },
  }));
  