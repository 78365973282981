// Parcel details viewer

import useStore from '../store';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography, styled, tableCellClasses } from "@mui/material";
import { theme_bgColorGradient2, theme_bgColorLight1, theme_bgColorMain, theme_textColorBlended } from "../Theme";
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IParcel, IParcelAttributes, TSortOrder } from './ParcelInterfaces';
import { ZoomMapToGeojsonExtent } from '../Map/MapOps';
import { CapitalizeAllWords, FriendlyCurrency, FriendlyNumber } from '../Globals';
import { CapitalizeParcelOwner, ExportSelectedParcelsToCSVFile } from './ParcelOps';
import React, { useEffect } from 'react';
import { GetParcelLayerAttribute } from '../AttributeOps';
import { IVectorLayerAttribute } from '../Layers/LayerInterfaces';



//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ParcelTableViewerProps
{
}

//-------------------------------------------------------------------------------
// Parcel details viewer
//-------------------------------------------------------------------------------
export function ParcelTableViewer(props: ParcelTableViewerProps) 
{
  // Get needed state data from the store
  const { store_parcelTableViewer, store_setParcelTableViewer,
          store_selectedParcels, store_setParcelAttribSelector, 
          store_parcelTableLocalSortAttribName, store_setParcelTableLocalSortAttribName,
          store_parcelTableLocalSortOrder, store_setParcelTableLocalSortOrder,
          store_parcelsTableCurrPage, store_setParcelsTableCurrPage, store_project, 
          store_parcelExtraAttributeList, 
          //store_setParcelTableViewerVertScroll, store_parcelTableViewerVertScroll, 
        } = useStore();

  const [rowsPerPage, setRowsPerPage] = React.useState(100);


  




  useEffect(() => 
  {
    // If the search was ran with a db/server-side sort, initialize the local/client-side sort to match it
    if(store_parcelTableLocalSortAttribName && store_parcelTableLocalSortAttribName !== '' && store_parcelTableLocalSortOrder)
      SortTable(store_parcelTableLocalSortAttribName, store_parcelTableLocalSortOrder);

    //setScrollPosition(store_parcelTableViewerVertScroll);
    //window.scrollTo(0, 15);
  }, [store_parcelTableLocalSortAttribName, store_parcelTableLocalSortOrder]); 


  //const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => 
  // {
  //     const position = window.scrollY;
  //     setScrollPosition(position);
  // };
  
  // useEffect(() => 
  // {
  //     window.addEventListener('scroll', handleScroll, { passive: true });
  
  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);






  //-------------------------------------------------------------------------------
  // Close the panel.
  //-------------------------------------------------------------------------------
  const onClose = () => 
  {
    //store_setParcelTableViewerVertScroll(scrollPosition);

    store_setParcelAttribSelector(false);
    store_setParcelTableViewer(false);
  }

  //-------------------------------------------------------------------------------
  // Zoom the map to the clicked parcel.
  //-------------------------------------------------------------------------------
  function ZoomToParcel(parcel: IParcel)
  {
    ZoomMapToGeojsonExtent(parcel.geometry);

    // Close the details viewer (so the map becomes visible)
    //store_setParcelTableViewerVertScroll(scrollPosition);
    store_setParcelTableViewer(false);
  }

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  // const OnScroll = (event: any) => 
  // {
  //   const scrollPos: number = event.target.scrollTop;

  //   setScrollPosition(scrollPos);

  //   //const { target: { value },} = event;

  //   const x = 1;
  //   // store_setParcelSearchStates(value as TStateAbbreviation[]);

  //   // setSelStatesIsOpen(false);
  // }


  // const handleScroll = (event: any) => 
  // {
  //   store_setParcelTableViewerVertScroll(event.target.scrollTop);
  // }

  //-------------------------------------------------------------------------------
  // Comparer for column sorting click handler.
  // Works with both string and number columns.
  // Undefined/null values always go to the bottom (in both asc and desc).
  // Returns -1, 0, or 1.
  //-------------------------------------------------------------------------------
  function SortByAttrib(a: string | number | undefined, b: string | number | undefined, sortOrder: TSortOrder): number 
  {
    if (a === b) return 0;  // equal
    if (!a) return 1; // if a is undefined/null, it goes to the bottom
    if (!b) return -1; // if b is undefined/null, it goes to the bottom

    return sortOrder === 'desc' ? (a < b ? 1 : -1) : (a < b ? -1 : 1)
  }

  //-------------------------------------------------------------------------------
  // Column sort click handler.
  //-------------------------------------------------------------------------------
  const OnSortClick = (attribute_name: string) => 
  {
    let newSortOrder: TSortOrder | undefined= undefined;

    if(attribute_name !== store_parcelTableLocalSortAttribName)
    {
      // A new sort attribute was clicked

      store_setParcelTableLocalSortAttribName(attribute_name);
      newSortOrder = 'asc';
      store_setParcelTableLocalSortOrder(newSortOrder);
    }
    else
    {
      // Same sort attribute, but flip the sort order

      newSortOrder = store_parcelTableLocalSortOrder==='asc'?'desc':'asc';
      store_setParcelTableLocalSortOrder(newSortOrder);
    }

    SortTable(attribute_name, newSortOrder);
  }

  //-------------------------------------------------------------------------------
  // Column sort click handler.
  //-------------------------------------------------------------------------------
  function SortTable(attribute_name: string, order: TSortOrder) 
  {
    store_selectedParcels.sort((a: IParcel, b: IParcel) => 
      SortByAttrib(
                    a.attributes[attribute_name as keyof IParcelAttributes],
                    b.attributes[attribute_name as keyof IParcelAttributes],
                    order
                  ));
  }

  //-------------------------------------------------------------------------------
  // Renders a table header cell.
  //-------------------------------------------------------------------------------
  const RenderTableHeaderCell = (attribute_name: string, enableSorting: boolean = true, 
                                 align: "center" | "left" | "right" | "inherit" | "justify" | undefined = undefined) => 
  {
    const attrib: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(attribute_name);
    if(!attrib) return;

    if(enableSorting)
    return (
      <CustomTableCell key={attribute_name} align={align} sortDirection={store_parcelTableLocalSortAttribName === attribute_name ? store_parcelTableLocalSortOrder : false}>
        <TableSortLabel active={store_parcelTableLocalSortAttribName === attribute_name}
                        direction={store_parcelTableLocalSortAttribName === attribute_name ? store_parcelTableLocalSortOrder : 'asc'}
                        onClick={(_)=>OnSortClick(attribute_name)}
                        sx={{ fontSize: '1rem',
                              fontWeight: store_parcelTableLocalSortAttribName === attribute_name ? 'bold' : 'normal',
                              bgcolor: store_parcelTableLocalSortAttribName === attribute_name ? '#C1C291' : undefined,
                              borderRadius: 1,
                              py: store_parcelTableLocalSortAttribName === attribute_name ? 0.5 : 0, 
                              px: store_parcelTableLocalSortAttribName === attribute_name ? 1 : 0 }}>
            {attrib.display_name}
            {/* {sortAttribName === attribute_name
              ? 
                <Box component="span" sx={visuallyHidden}>
                  {sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              : null
            } */}
        </TableSortLabel>
      </CustomTableCell>
    )
    else // no sorting (this should never trigger - right now all attributes are sortable)
    return (
      <CustomTableCell key={attribute_name}>{attrib.display_name}</CustomTableCell>
    )
  }

  //-------------------------------------------------------------------------------
  // Renders a table value cell.
  //-------------------------------------------------------------------------------
  const RenderTableValueCell = (parcelData: IParcel, attribute_name: string) => 
  {
    const attrib: IVectorLayerAttribute | undefined = GetParcelLayerAttribute(attribute_name);
    if(!attrib) return;

    let value: string | number | undefined = parcelData.attributes[attribute_name as keyof IParcelAttributes];

    // Formatting for some special cases

    if(value)
    {
      // Auto-capitalize: county, mailadd, mail_city
      if(attribute_name === 'county' || attribute_name === 'mailadd' || attribute_name === 'mail_city')
        value = CapitalizeAllWords(value as string);

      // Auto-capitalize the owner
      if(attribute_name === 'owner')
        value = CapitalizeParcelOwner(value as string);

      // Currency attributes
      if(attribute_name === 'landval' || attribute_name === 'parval')
        value = FriendlyCurrency(value as number);
      // All other numerical attributes
      else if(attrib.data_type === 'number')  // all other numbers
        value = FriendlyNumber(value as number, attrib.decimal_places);
    }

    return (
      <CustomTableCell key={attribute_name}>{value}</CustomTableCell>
    )
  }

  //-------------------------------------------------------------------------------
  // User clicked on a new page.
  //-------------------------------------------------------------------------------
  const OnChangePage = (event: unknown, newPage: number) => 
  {
    store_setParcelsTableCurrPage(newPage);
  }

  //-------------------------------------------------------------------------------
  // User changed the "rows per page" amount.
  //-------------------------------------------------------------------------------
  const OnChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => 
  {
    setRowsPerPage(parseInt(event.target.value, 10));
    store_setParcelsTableCurrPage(0);
  }

  //-------------------------------------------------------------------------------
  // Export the data to a CSV file.
  //-------------------------------------------------------------------------------
  const OnExportToCSVFileButton = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    /*const result: boolean = */await ExportSelectedParcelsToCSVFile();
  }

  //-------------------------------------------------------------------------------
  // Open the attribute selection window.
  //-------------------------------------------------------------------------------
  const OnAttribSelectionButton = async (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    store_setParcelAttribSelector(true);
  }


















  if(!store_project || !store_parcelTableViewer || !store_selectedParcels || store_selectedParcels.length === 0) return null;

  // Main render

  return (

    <Dialog open={store_parcelTableViewer} onClose={onClose} maxWidth='xl' fullWidth>

      {/* Dialog Title */}

      <DialogTitle sx={{ bgcolor: theme_bgColorLight1, justifyContent: 'space-between', pl: 2, pr: 1 }}>

        <Stack direction='row' sx={{ justifyContent: 'space-between' }}>

          <Stack direction='row' sx={{ alignItems: 'center' }}>
            <Typography sx={{ fontSize: '1.5rem' }}>
              Parcel Details
            </Typography>
            <Typography sx={{ ml: 3, fontSize: '1.3rem', color: theme_textColorBlended }}>
              {store_selectedParcels.length} parcels
            </Typography>
            <Button size='small' variant='outlined' onClick={OnAttribSelectionButton}
                    sx={{ ml: 3, fontSize: '1rem', textTransform: 'none', minWidth: '130px' }}>
              Select columns...
            </Button>          
        </Stack>

          <IconButton size="small" onClick={onClose}
                      sx={{ padding: 0, width: '35px', height: '35px' }}>
            <CloseIcon sx={{ opacity: 0.9, width: '35px', height: '35px', color: theme_textColorBlended }} />
          </IconButton>

        </Stack>

      </DialogTitle>

      {/* Dialog Content */}

      <DialogContent sx={{ p: 0, background: theme_bgColorGradient2, overflow: 'auto' }}>
        
        <TableContainer component={Paper} sx={{ overflowX: 'initial' }}>
          <Table size="small" sx={{ minWidth: 250 }} aria-label="simple table" stickyHeader>

            {/* Table head */}

            <TableHead>
              <TableRow>

                <CustomTableCell_StickyColLeft sx={{ width: '20px' }}/>

                {/* Permanent user attribute list */}
                {store_project.user_settings.parcel_attribute_list.map(parcelAttribute =>
                  RenderTableHeaderCell(parcelAttribute.name,true,'center') 
                )}

                {/* Temp/extra attribute list (used to show extra fields when searching) */}
                {store_parcelExtraAttributeList.map(parcelAttribute =>
                  RenderTableHeaderCell(parcelAttribute.name,true,'center') 
                )}

              </TableRow>
            </TableHead>

            {/* Table body */}

            <TableBody>
              {store_selectedParcels
                .slice(store_parcelsTableCurrPage * rowsPerPage, store_parcelsTableCurrPage * rowsPerPage + rowsPerPage)
                .map(parcel => (

                <CustomTableRow key={parcel.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>

                  {/* Zoom-to-Parcel extra column */}

                  <CustomTableCell_StickyColLeft sx={{ bgcolor: '#DFE0A8', p:0 }}>
                    <IconButton onClick={(_)=>ZoomToParcel(parcel)}>
                      <VisibilityIcon/>
                    </IconButton>
                  </CustomTableCell_StickyColLeft>

                  {/* Permanent user attribute list */}
                  {store_project.user_settings.parcel_attribute_list.map(parcelAttribute =>
                    RenderTableValueCell(parcel,parcelAttribute.name)
                  )}

                  {/* Temp/extra attribute list (used to show extra fields when searching) */}
                  {store_parcelExtraAttributeList.map(parcelAttribute =>
                    RenderTableValueCell(parcel,parcelAttribute.name)
                  )}

                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {/* Dialog bottom bar */}

      <DialogActions sx={{ bgcolor: theme_bgColorLight1 }}>

        <Stack sx={{ alignItems: 'center', width: '100%' }}>
          <TablePagination rowsPerPageOptions={[50, 100, 250, 500]} component="div"
                           count={store_selectedParcels.length} labelRowsPerPage='Parcels per page'
                           rowsPerPage={rowsPerPage} onRowsPerPageChange={OnChangeRowsPerPage}
                           page={store_parcelsTableCurrPage} onPageChange={OnChangePage}                           
                           sx={{ alignItems: 'center', fontSize: '1.5rem', color: theme_textColorBlended }}/>
        </Stack>

        <Button size='small' variant='contained' sx={{ fontSize: '1rem', mr: 3, textTransform: 'none', minWidth: '175px' }} 
                onClick={OnExportToCSVFileButton}>
          Export Data (CSV)
        </Button>          

        <Button variant='contained' autoFocus onClick={onClose} sx={{ width: '100px' }}>
          Close
        </Button>
        
      </DialogActions>

    </Dialog>
  )
}






// Custom style for TableRow
const CustomTableRow = styled(TableRow)(({ theme }) => (
{
  backgroundColor: '#F7F8EC',
  '&:nth-of-type(odd)': 
  {
    backgroundColor: '#F2F4DF',
  },
  // hide last border
  '&:last-child td, &:last-child th': 
  {
    border: 0,
  },
}));

// Custom style for TableCell
const CustomTableCell = styled(TableCell)(({ theme }) => (
{
  [`&.${tableCellClasses.head}`]: 
  {
    backgroundColor: '#DFE0A8',
    color: theme_bgColorMain,
    fontSize: '1.0rem',
  },
  [`&.${tableCellClasses.body}`]: 
  {
    fontSize: '0.9rem',
  },
}));

// Custom style for TableCell (For "sticky" columns on left side)
const CustomTableCell_StickyColLeft = styled(CustomTableCell)(({ theme }) => (
{
  [`&.${tableCellClasses.head}`]: 
  {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2
  },
  [`&.${tableCellClasses.body}`]: 
  {
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1
  },
}));

// Custom style for TableCell (For "sticky" columns on right side)
// const CustomTableCell_StickyColRight = styled(CustomTableCell)(({ theme }) => (
// {
//   [`&.${tableCellClasses.head}`]: 
//   {
//     right: 0,
//     position: "sticky",
//     zIndex: theme.zIndex.appBar + 2
//   },
//   [`&.${tableCellClasses.body}`]: 
//   {
//     right: 0,
//     position: "sticky",
//     zIndex: theme.zIndex.appBar + 1
//   },
// }));