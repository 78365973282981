// UI component to rename the active project

import { Stack, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { theme_limeGreen, theme_textColorBlended } from "../Theme";
import useStore from "../store";
import React from "react";
import RoundedInputBox from "../Components/RoundedInputBox";


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
export interface ProjectRenameProps
{
}

//-------------------------------------------------------------------------------
// Project rename component
//-------------------------------------------------------------------------------
const ProjectRename = (props: ProjectRenameProps) => 
{
  // Get needed state data from the store
  const { store_setProjectUIMode, store_project, store_projectRename, 
          store_setProjectIsDirty, 
        } = useStore();

  const [newProjectName, setNewProjectName] = useState<string>('');

  //-------------------------------------------------------------------------------
  // 
  //-------------------------------------------------------------------------------
  useEffect(() => 
  {
    if(store_project)
      setNewProjectName(store_project.name)

  }, [store_project, setNewProjectName])



  //-------------------------------------------------------------------------------
  // The user changed the new project name text field.
  //-------------------------------------------------------------------------------
  // const onNewProjectNameChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
  // {
  //   setNewProjectName(event.target.value);
  // }

  //-------------------------------------------------------------------------------
  // Rename the saved project.
  //-------------------------------------------------------------------------------
  const onRenameProjectButton = (event: React.MouseEvent<HTMLButtonElement>) => 
  {
    if(!newProjectName) return;
    if(!store_project || !store_project.project_id) return;

    // If the name hasn't changed, exit rename mode and do nothing
    if(newProjectName.toLowerCase() === store_project.name.toLowerCase()) 
    {
      store_setProjectUIMode('default');
      return;
    }

    // Update the state store
    store_projectRename(store_project.project_id, newProjectName);
    store_setProjectIsDirty(true);
    store_setProjectUIMode('default');

    // NOTE:  The changed name will be auto-saved to the database when the next auto-save
    //        timer triggers.
  }

  //-------------------------------------------------------------------------------
  // Cancel the rename operation.
  //-------------------------------------------------------------------------------
  const onCancelRename = () => 
  {
    store_setProjectUIMode('default');
  }
  



  // Main render

  return (

    <Stack direction='column' sx={{ alignItems: 'center' }}>

      <Typography sx={{ mb: 2, fontSize: '1.2rem', color: theme_textColorBlended }}>
        Rename this project
      </Typography>

      {/* New project:  name + save button */}

      <RoundedInputBox title='New Project' onAccept={onRenameProjectButton}
                       value={newProjectName} setValue={setNewProjectName}/>

      {/* Offer the user a cancel button to back out */}

      <Button size='small' sx={{ mt: 1, fontSize: '1rem', color: theme_limeGreen }} 
              onClick={onCancelRename}>
        Cancel
      </Button>

    </Stack>    
  )
}

export default ProjectRename;





