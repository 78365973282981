// Drawer menu - HBV accordion

import { Accordion, Box, Typography, AccordionDetails } from "@mui/material";
import { useStore } from "../store";
import { theme_bgColorLight1, theme_textColorBlended, theme_textColorMain } from "../Theme";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import InsightsIcon from '@mui/icons-material/Insights';
import { DrawerMenuAccordionSummary } from './DrawerMenuAccordionStyle';
//import { FriendlyCurrency, FriendlyNumber } from "../Globals";
import Scenarios from "../Scenarios/Scenarios";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';


//-------------------------------------------------------------------------------
// Component props
//-------------------------------------------------------------------------------
interface DrawerMenuScenariosProps 
{
  onAccordionChange: any;
}

//-------------------------------------------------------------------------------
// Drawer menu Scenarios accordion component
//-------------------------------------------------------------------------------
const DrawerMenuScenarios = (props: DrawerMenuScenariosProps) => 
{
  const { onAccordionChange } = props;

  // Get needed state data from the store
  const { store_project, store_projectIsLoading, store_aoi, store_aoiIsLoading, 
          store_activeDrawerMenuItem, store_bundleIsLoading, 
        } = useStore();





  // NOTE: At this point we know a user is logged in and the user profile is loaded


  // Main render - there is no active saved project, or a project is loading in.
  //             - there is no active AOI, or an AOI is loading in.
  // (no accordion at all)

  if(!store_project || !store_project.project_id || store_projectIsLoading ||
     !store_aoi || store_aoiIsLoading || store_bundleIsLoading)
  return null;

  // Main render - we have an active saved project

  return (

    <Accordion expanded={store_activeDrawerMenuItem === 'scenarios'} disableGutters={false}
               onChange={onAccordionChange('scenarios')}
               sx={{ background: theme_bgColorLight1 }}>
            
      <DrawerMenuAccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: theme_textColorBlended }} />}>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>

          <BookmarkBorderIcon sx={{ color: theme_textColorBlended, width: '30px', height: '30px', ml: -1 }}/>

          <Typography sx={{ ml: 1, color: theme_textColorMain, fontSize: '1rem' }}>
            Scenarios
          </Typography>
          
            {/* {!store_hbvScenario || store_hbvIsRunning
              ?
                // No active HBV

                <Typography sx={{ ml: 1, color: theme_textColorMain, fontSize: '1rem' }}>
                  Scenarios
                </Typography>
              :
                // There is an active HBV

                <Stack direction='column' sx={{ ml: 1 }}>
                  
                  <Typography sx={{ color: theme_textColorMain, fontSize: '0.7rem', opacity: 0.8 }}>
                    {store_hbvScenario.name}
                  </Typography>

                  <Stack direction='row'>
                    <Typography sx={{ color: theme_orange, fontSize: '0.9rem' }}>
                      {FriendlyCurrency(store_hbvScenario.hbv_data.hbv)}
                    </Typography>
                    <Typography sx={{ fontSize: '0.9rem', ml: 0.5, mr: 0.5, color: theme_textColorBlended }}>
                      on
                    </Typography>
                    <Typography sx={{ color: theme_orange, fontSize: '0.9rem' }}>
                      {FriendlyNumber(store_hbvScenario.hbv_data.lmp_acres)}
                    </Typography>
                    <Typography sx={{ fontSize: '0.9rem', ml: 0.5, mr: 0.5, color: theme_textColorBlended }}>
                      acres
                    </Typography>
                  </Stack>

                </Stack>
            } */}
        </Box>

      </DrawerMenuAccordionSummary>

      <AccordionDetails>
        <Scenarios />
      </AccordionDetails>

    </Accordion>
  )
}

export default DrawerMenuScenarios;
